import React from 'react';
import s from './s.module.less';
import LineBar from '../LineBar';
import { useNavigate } from 'react-router-dom';
import Blackfriday2024 from '../CampainOpportunities/blackfriday2024/Enhance';

const Index: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div className={s.wrap}>
            <Blackfriday2024 />
            <LineBar
                lineBarId="klarity-2-1"
                icon="icon-2"
                title="Improve your services and pricing design"
                description="Conduct a comprehensive pricing check and adjust accordingly."
                details={`<ul>
                            <li>Price smartly: research diverse sources for affordable care and use Ava's price insights tool to set competitive rates</li>
                        </ul>`}
                cta="Get pricing insights"
                onCta={() => {
                    navigate('/create-uni-profile?type=uniprofile&from=home&step=7');
                }}
            />
            <LineBar
                lineBarId="klarity-2-2"
                icon="icon-6"
                title="Actively collect patient feedback"
                description="Leverage Reputation Management tools."
                details={`<ul>
                        <li>Reply to each patient feedback on Klarity</li>
                        <li>Develop action plans to enhance your services based on feedback</li>
                    </ul>`}
                cta="Manage reviews"
                onCta={() => {
                    navigate('/dashboard/reputation-management');
                }}
            />
            <LineBar
                lineBarId="klarity-2-3"
                icon="icon-5"
                title="Promote your services to build a patient base"
                description="Share your Practice Front to patients and social networks."
                details={`<ul>
                        <li>Share Practice Front with your existing patients to promote your full service menu</li>
                        </ul>`}
                cta="Open Practice Front"
                onCta={() => {
                    navigate('/dashboard/practice-front');
                }}
            />
        </div>
    );
};

export default Index;
