import React, { useCallback, useEffect } from 'react';
import s from './s.module.less';
import { Form, Input, Select, message } from 'antd';
import useSettings from 'hooks/useSettings';
import { EnumFields } from 'types/enumerationData';
import FileUpload from './FileUpload';

interface IProps {
    formId: string;
    taxIdType?: string;
    taxId?: string;
    w9Form?: string;
    handleSubmited?: (data: any, field: string) => void;
}

const TaxInfo = ({
    formId,
    taxIdType,
    taxId,
    w9Form,
    handleSubmited,
}: IProps) => {
    const [setting] = useSettings();
    const taxIdTypeOptions = setting[EnumFields.TAX_ID_TYPE] || [];
    const [formInstance] = Form.useForm();
    const taxIdTypeValue = Form.useWatch('taxIdType', formInstance);

    const taxIdDisable = taxIdTypeValue === 'NONE';

    useEffect(() => {
        if (taxIdDisable) {
            formInstance.setFieldsValue({
                taxId: '',
            });
        }
    }, [formInstance, taxIdDisable]);

    useEffect(() => {
        formInstance.setFieldsValue({
            taxIdType,
            taxId,
            w9Form,
        });
    }, [formInstance, w9Form, taxIdType, taxId]);

    const handleFinish = useCallback(async (values) => {
        handleSubmited?.(values, formId);
    }, [formId, handleSubmited]);

    const handleFinishFailed = useCallback((errors) => {
        if (errors?.errorFields.length > 0) {
            message.error(errors.errorFields[0].errors?.[0]);
        }
    }, []);

    const handleDownLoadPDF = useCallback(() => {
        window.open('https://www.irs.gov/pub/irs-pdf/fw9.pdf', '_blank');
    }, []);

    return (
        <div className={s.wrap}>
            <div className={s.inner}>
                <div className={s.title}>Step 3 of 4: Set up tax information</div>
                <div className={s.subTitle}>Tax information is required to ensure that you receive payment for the patient visits.</div>
                <div className={s.form}>
                    <Form
                        form={formInstance}
                        id={formId}
                        layout="vertical"
                        onFinish={handleFinish}
                        onFinishFailed={handleFinishFailed}
                    >
                        <div className={s.row}>
                            <div className={s.displayItem}>
                                <Form.Item
                                    className={s.formItem}
                                    name="taxIdType"
                                    label="Tax ID type"
                                    rules={[
                                        { required: true, message: 'Tax ID is a required field' },
                                    ]}
                                >
                                    <Select
                                        style={{ width: '40%' }}
                                        options={taxIdTypeOptions}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className={s.row}>
                            <div className={s.displayItem}>
                                <Form.Item
                                    label="Tax ID number"
                                    dependencies={['taxIdType']}
                                    style={{ width: '40%' }}
                                    name="taxId"
                                    validateFirst
                                    rules={[
                                        {
                                            required: !taxIdDisable,
                                            message: 'Tax ID number is a required field',
                                        },
                                    ]}
                                >
                                    <Input disabled={taxIdDisable} maxLength={200} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className={s.row}>
                            <div className={s.displayItem}>
                                <div className={s.subLabel}>
                                    Please complete and upload the W-9 form. You can find the w-9 form with this link <a className={s.w9Link} onClick={handleDownLoadPDF}>(w-9 form link)</a>
                                </div>
                                <div className={s.uploadBox}>
                                    <Form.Item
                                        name="w9Form"
                                        rules={[
                                            { required: true, message: 'w-9 form is a required field' },
                                        ]}
                                    >
                                        <FileUpload />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default TaxInfo;
