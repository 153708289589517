import React, { useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { Button, Select, message } from 'antd';
import { useRequest } from 'ahooks';
import { getOpsMemberList } from 'api/access';
import { updateOpsChannelWithdrawAssignee } from 'api/operation';

interface IProps {
    id: number;
    channelId: number;
    providerId: number;
    assignee: string;
    refetch: () => void;
}

type Item = { value: string; label: string, id?:number, firstName?:string };

const Assignee = ({
    id,
    channelId,
    providerId,
    assignee,
    refetch,
}: IProps) => {
    const [options, setOptions] = useState<Item[]>([]);
    const [assigneeState, setAssigneeState] = useState<Item>();
    const [loading, setLoading] = useState(false);

    const { data } = useRequest(getOpsMemberList);

    useEffect(() => {
        const res = data?.data?.data || [];
        const opts = res.map((e:any) => {
            return {
                ...e,
                value: e.id,
                label: e.gmail,
            };
        });
        setOptions(opts);
    }, [data]);

    useEffect(() => {
        const assigneeValue = options.find((e) => e.firstName === assignee);

        if (assigneeValue) {
            setAssigneeState(assigneeValue);
        }
    }, [assignee, options]);

    const handleSaveAssignee = async () => {
        if (assigneeState) {
            setLoading(true);
            const requestParam:any = {
                providerId,
                assignee: assigneeState.firstName!,
                assigneeUid: assigneeState.id,
                channelId,
                id,
            };
            const res = await updateOpsChannelWithdrawAssignee(requestParam);

            if (res?.error) {
                message.error(res.error);
            } else {
                message.success('assign successfully');
                refetch();
            }
            setLoading(false);
        }
    };

    const handleChange = useCallback((value: number) => {
        const assigneeValue = options.find((e) => e.id === value);

        if (assigneeValue) {
            setAssigneeState(assigneeValue);
        }
    }, [options]);

    return (
        <div className={s.wrap}>
            <div className={s.title}>Assignee</div>
            <Select
                value={assigneeState?.id}
                showSearch
                placeholder="Select a person"
                optionFilterProp="children"
                style={{ width: 376 }}
                onChange={handleChange}
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={options}
            />
            {/* <AutoComplete
                options={options}
                style={{ width: 376 }}
                onSearch={handleSearch}
            > */}
            {/* </AutoComplete> */}
            <div className={s.btns}>
                <Button type="primary" loading={loading} disabled={!assigneeState} onClick={handleSaveAssignee}>Save</Button>
            </div>
        </div>
    );
};

export default Assignee;
