import React, { useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { DatePicker, Form, Select, Spin, message } from 'antd';
import useSettings from 'hooks/useSettings';
import { EnumFields } from 'types/enumerationData';
import dayjs from 'dayjs';
import AppointmentTime from 'components/AppointmentTime';
import commonS from 'styles/common.module.less';
import useAvailability from 'hooks/useAvailability';

interface IProps {
    formId: string;
    handleSubmited?: (data: any, field: string) => void;
}

const Availability = ({
    formId,
    handleSubmited,
}:IProps) => {
    const [availabilityList, loading, fetchData] = useAvailability();
    const [showError, setShowError] = useState(false);
    const [setting] = useSettings();
    const timezoneOptions = setting[EnumFields.TIMEZONE];
    const [formInstance] = Form.useForm();

    useEffect(() => {
        if (availabilityList) {
            const { timezone, week, startDate } = availabilityList;
            formInstance.setFieldsValue({
                timezone: timezone || Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
                week,
                startDate: startDate ? dayjs(startDate) : undefined,
            });
        }
    }, [availabilityList, formInstance]);

    const handleFinish = useCallback(async (values) => {
        const errorData = values?.week?.some((weekDay) => {
            const { hourFromTo } = weekDay;
            return hourFromTo.some((item) => {
                return Object.values(item).some((v) => {
                    return typeof v !== 'number' && !v;
                });
            });
        });

        if (errorData) {
            setShowError(true);
            console.info('Please fill all the time fields');
        } else {
            handleSubmited?.({
                ...values,
                startDate: values.startDate.format('YYYY-MM-DD'),
            }, formId);
        }
    }, [formId, handleSubmited]);

    const handleFinishFailed = useCallback((errors) => {
        if (errors?.errorFields.length > 0) {
            message.error(errors.errorFields[0].errors?.[0]);
        }
    }, []);

    return (
        <div className={s.wrap}>
            <Spin spinning={loading}>
                <div className={s.inner}>
                    <div className={s.title}>Step 2 of 4: Set up your availability</div>
                    <div className={s.subTitle}>{'Get started by setting your regular availability for your practice. You can customize it further in the \'Availability\' section under the \'Calendar\' tab in your Kiwi EHR.'}</div>
                    <div className={s.form}>
                        <Form
                            form={formInstance}
                            id={formId}
                            className={commonS.formStyle1}
                            layout="vertical"
                            onFinish={handleFinish}
                            onFinishFailed={handleFinishFailed}
                        >
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <Form.Item
                                        className={s.formItem}
                                        label="Time zone"
                                        name="timezone"
                                        rules={[
                                            { required: true, message: 'Time zone is a required field' },
                                        ]}
                                    >
                                        <Select style={{ width: '376px' }} options={timezoneOptions} />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <Form.Item
                                        label="Start date"
                                        name="startDate"
                                        validateFirst
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Start date number is a required field',
                                            },
                                        ]}
                                    >
                                        <DatePicker style={{ width: '100%' }} format="MM/DD/YYYY" disabledDate={(current) => current && current < dayjs().startOf('day')} />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <Form.Item
                                        label="Recurring Availability"
                                        name="week"
                                        // rules={[
                                        //     {
                                        //         validator: (rules, value) => {
                                        //             const appointmentTime = formInstance.getFieldValue('week');

                                        //             const errorData = appointmentTime.some((weekDay) => {
                                        //                 const { hourFromTo } = weekDay;
                                        //                 return hourFromTo.some((item) => {
                                        //                     return Object.values(item).some((v) => {
                                        //                         return typeof v !== 'number' && !v;
                                        //                     });
                                        //                 });
                                        //             });

                                        //             if (errorData) {
                                        //                 return Promise.reject('Please fill all the time fields');
                                        //             }

                                        //             return Promise.resolve(true);
                                        //         },
                                        //     },
                                        // ]}
                                    >
                                        <AppointmentTime showError={showError} />
                                    </Form.Item>
                                    {showError && <div className={s.error}>Please fill all the time fields</div>}
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </Spin>
        </div>
    );
};

export default Availability;
