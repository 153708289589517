import React from 'react';
import s from './s.module.less';
import Photos from 'components/HelloKlarityFormComponents/Photos';
import { UniprofileFormData } from 'types/provider';

interface IProps {
    providerData: UniprofileFormData;
}

const PhotosTab = ({
    providerData,
}: IProps) => {
    const { profile } = providerData;

    const { additionalPhotoList = [] } = profile || {};

    return (
        <div className={s.wrap}>
            <Photos photos={additionalPhotoList} />
        </div>
    );
};

export default PhotosTab;
