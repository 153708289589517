import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal, Spin, Tabs, Popover, Input, Checkbox, message } from 'antd';
import type { TabsProps } from 'antd';
import { PATH } from 'constants/path';
import s from './s.module.less';
import { ProspectsIcon } from 'components/MenuIcons';
import dayjs from 'dayjs';
import { EditOutlined, RightOutlined } from '@ant-design/icons';
import UserAddIcon from 'assets/prospects/user.svg';
import ChannelsIcon from 'assets/prospects/channel.svg';
import AppointmentIconIcon from 'assets/prospects/calendar.svg';
import { getTimeStr } from 'utils/calendar';
import useProspectInfo from 'hooks/useProspectInfo';
import Message from './components/message';
import Appointment from './components/appointment';
import Notes from './components/notes';
import AddAndUpdateProspects from 'components/AddAndUpdateProspects';
import { EStatus, EProspectType } from 'types/common';
import { getAgeFromBirthday, displayItem } from 'utils/common';
import EhrIcon from 'assets/common/ehr.svg';
import CheckCircleIcon from 'assets/common/checkCircle.svg';
import UnCircleIcon from 'assets/common/unCheck.svg';
import { EMPTY_PLACEHOLDER } from 'constants/common';
import ProviderStore from 'store/Provider';
import { getDisableShowDirectLinkInProspect, setDisableShowDirectLinkInProspect } from 'utils/localstore';
import { updateAppointmentLink } from 'api/practiceFront';

const Page = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [data, loading, run] = useProspectInfo(
        id ? parseInt(id, 10) : undefined,
    );
    const [showEdit, setShowEdit] = React.useState(false);
    const [upcomingAmount, setUpcomingAmount] = React.useState('');
    const [showEnterDirectLink, setShowEnterDirectLink] = React.useState(false);
    const [disableShow, setDisableShow] = React.useState(false);
    const [integrationUrl, setIntegrationUrl] = React.useState('');
    const [getUser, setUser] = ProviderStore.useStore();
    const disableShowLocal = getDisableShowDirectLinkInProspect();
    const user = getUser('data');
    const hasLoad = user?.email;
    let directLink = user?.appointmentIntegrationUrl;
    if (directLink?.startsWith('http') && directLink.indexOf('://') > -1) {
        directLink = directLink.substring(directLink.indexOf('://') + 3);
    }

    const resetDirectLinkModal = () => {
        setIntegrationUrl('');
    };

    React.useEffect(() => {
        if (hasLoad && !directLink && !disableShowLocal) {
            setShowEnterDirectLink(true);
        }
    }, [hasLoad, user, directLink, disableShowLocal]);

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: <div>Message</div>,
            children: <Message prospect={data} />,
        },
        {
            key: '2',
            label: <div>Upcoming Appointment</div>,
            children: (
                <Appointment
                    onUpcomingChange={(amount) => {
                        setUpcomingAmount(`${amount}`);
                    }}
                    prospect={data}
                />
            ),
        },
        {
            key: '3',
            label: <div>Notes</div>,
            children: <Notes prospect={data} />,
        },
    ];
    const name = `${data?.firstName || ''} ${data?.lastName || ''}`;

    const handleOk = async () => {
        if (integrationUrl) {
            let url = integrationUrl;
            if (!url.startsWith('http')) {
                url = `https://${url}`;
            }
            const result = await updateAppointmentLink({
                appointmentIntegrationUrl: url,
                useIntegrationUrl: true,
            });
            if (!result.error) {
                message.success('Direct booking link updated successfully');
                setShowEnterDirectLink(false);
                setUser('data', {
                    ...user,
                    appointmentIntegrationUrl: url,
                });
            }
        } else {
            //checked
            setDisableShowDirectLinkInProspect('true');
            setShowEnterDirectLink(false);
        }
    };

    return (
        <div className={s.wrap}>
            <Spin spinning={loading}>
                <div className={s.header}>
                    <div className={s.headerTop}>
                        <span className={s.leftTopHome}>{ProspectsIcon}</span>
                        <span
                            onClick={() => {
                                navigate(`${PATH.DASHBOARD}/${PATH.PROSPECTS_MANAGE}`);
                            }}
                            className={s.link}
                        >
                            Prospects
                        </span>
                        <span className={s.leftTopNav}>
                            <RightOutlined />
                        </span>
                        <span className={s.leftTopName}>{name}</span>
                    </div>
                </div>
                <div className={s.content}>
                    <div className={s.contentLeft}>
                        <div className={s.contentTop}>
                            <div className={s.left}>
                                <h1 className={s.leftBtm}>
                                    <span className={s.title}>
                                        {name}
                                        {data?.type === EProspectType.PROSPECT && (
                                            <span className={s.statusP}>
                                                <span className={s.icon} />
                                                Prospect
                                            </span>
                                        )}
                                        {data?.type === EProspectType.PATIENT && (
                                            <span className={`${s.statusP} ${s.statusPatient}`}>
                                                <span className={s.icon} />
                                                Patient
                                            </span>
                                        )}
                                    </span>
                                </h1>
                            </div>
                        </div>
                        <div className={s.contentMid}>
                            <div className={s.contentItem}>
                                <img src={ChannelsIcon} className={s.contentImg} />
                                <span className={s.contentItemValue}>
                                    {data ? data.channel || 'Others' : ''}
                                </span>
                            </div>
                            <div className={s.contentItem}>
                                <img src={UserAddIcon} className={s.contentImg} />
                                <span className={s.contentItemValue}>
                                    Created:{' '}
                                    {getTimeStr(
                                        dayjs(data?.createTime),
                                        'MMM D, YYYY [at] HH:mm A',
                                    )}
                                </span>
                            </div>
                            <div className={s.contentItem}>
                                <img src={AppointmentIconIcon} className={s.contentImg} />
                                <span className={s.contentItemValue}>
                                    Appointment: {upcomingAmount}
                                </span>
                            </div>
                            <div className={s.contentItem}>
                                <img
                                    src={
                                        data?.status === EStatus.Active
                                            ? CheckCircleIcon
                                            : UnCircleIcon
                                    }
                                />
                                <span className={s.contentItemValue}>{data?.status}</span>
                            </div>
                            <div className={s.contentItem}>
                                <img src={EhrIcon} />
                                <span className={s.contentItemValue}>
                                    {data?.ehr === 'Yes' ? 'On EHR' : 'Not on EHR'}
                                </span>
                            </div>
                        </div>
                        <h3>
                            <span>Prospect Info</span>
                            <EditOutlined
                                onClick={() => {
                                    setShowEdit(true);
                                }}
                            />
                        </h3>
                        <div className={s.infoWrap}>
                            <div className={s.row}>
                                <div
                                    style={{
                                        width: '300px',
                                    }}
                                    className={s.displayItem}
                                >
                                    <div className={s.label}>Email</div>
                                    <div className={s.value}>{data?.email}</div>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <div className={s.label}>Phone number</div>
                                    <div className={s.value}>
                                        {displayItem(
                                            loading,
                                            'No phone number provided',
                                            data?.tel,
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <div className={s.label}>Address</div>
                                    <div className={s.value}>
                                        {displayItem(
                                            loading,
                                            'No address provided',
                                            `${data?.address ? data?.address : ''}${data?.city ? `, ${data?.city}` : ''
                                            }${data?.state ? `, ${data?.state}` : ''}${data?.zip ? `, ${data?.zip}` : ''
                                            }`,
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <div className={s.label}>Age</div>
                                    <div className={s.value}>
                                        {displayItem(
                                            loading,
                                            'Age not specified',
                                            getAgeFromBirthday(data?.birthday),
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <div className={s.label}>Birthday</div>
                                    <div className={s.value}>
                                        {data?.birthday
                                            ? getTimeStr(dayjs(data?.birthday), 'MMM, DD, YYYY')
                                            : displayItem(loading, 'Birthday not provided', '')}
                                    </div>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <div className={s.label}>Gender</div>
                                    <div className={s.value}>
                                        {data?.gender
                                            ? data?.gender
                                            : displayItem(loading, 'Gender not specified', '')}
                                    </div>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <div className={s.label}>Billing</div>
                                    <div className={s.value}>
                                        {data?.billing
                                            ? data?.billing
                                            : displayItem(
                                                loading,
                                                'No billing information provided',
                                                '',
                                            )}
                                    </div>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <div className={s.label}>Preferred methods of contact</div>
                                    <div className={s.value}>
                                        {data?.preferMethod
                                            ? data?.preferMethod?.split(',').join(', ')
                                            : displayItem(loading, EMPTY_PLACEHOLDER, '')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={s.contentRight}>
                        <Tabs defaultActiveKey="1" items={items} onChange={() => { }} />
                    </div>
                </div>
            </Spin>
            {showEdit && (
                <AddAndUpdateProspects
                    item={data}
                    show={showEdit}
                    onSuccess={() => {
                        run();
                        setShowEdit(false);
                    }}
                    onCannel={() => {
                        setShowEdit(false);
                    }}
                />
            )}
            <Modal
                open={showEnterDirectLink}
                title="Enter your direct booking link here"
                width="550px"
                okText="Save"
                onOk={handleOk}
                destroyOnClose
                onCancel={() => {
                    setShowEnterDirectLink(false);
                    resetDirectLinkModal();
                }}
                okButtonProps={{
                    loading: false,
                    disabled: !disableShow && !integrationUrl,
                }}
            >
                <div className={s.modalWrap}>
                    <div className={s.info}>
                        To enhance patient convenience, please provide your direct booking link. This link will be included in auto-reply emails for easy appointment scheduling.
                    </div>
                    <div className={s.inputLabel}>
                        <span style={{ marginRight: 4 }}>Scheduling link</span>
                        <Popover
                            content={<div className={s.pop}>Please insert a URL where your patient can directly schedule an appointment with you</div>}
                            trigger="hover"
                            arrow={false}
                            placement="topLeft"
                        >
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                    <path d="M7.1992 8C7.67968 7.02901 8.97612 6.33333 10.5 6.33333C12.433 6.33333 14 7.45262 14 8.83333C14 9.99953 12.8821 10.9792 11.37 11.2555C10.8954 11.3422 10.5 11.7064 10.5 12.1667M10.5 14.6667H10.5088M18.375 10.5C18.375 14.6421 14.8492 18 10.5 18C6.15076 18 2.625 14.6421 2.625 10.5C2.625 6.35786 6.15076 3 10.5 3C14.8492 3 18.375 6.35786 18.375 10.5Z" stroke="#6B7280" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </span>
                        </Popover>
                    </div>
                    <div className={s.inputVal}>
                        <Input
                            onChange={(e) => {
                                setIntegrationUrl(e.target.value);
                            }}
                            addonBefore="https://"
                            placeholder="mybooklink.com"
                            value={integrationUrl || directLink || ''}
                        />
                    </div>
                    <div className={s.checkWrap}>
                        <Checkbox onChange={(e) => {
                            setDisableShow(e.target.checked);
                        }}
                        >
                            <p className={s.text}>Don&apos;t show this again</p>
                        </Checkbox>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Page;
