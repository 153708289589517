import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { PATH } from 'constants/path';
import s from './s.module.less';
import ProviderStore from 'store/Provider';
import SubscriptionStore from 'store/Subscription';
import LandingIcon from './Frame.png';
import {
    LoadingOutlined,
} from '@ant-design/icons';
import ProspectManagement from '../ProspectsManagePage';
import { getHasViewProspect, setHasViewProspect } from 'utils/localstore';
import { openSubscriptionModal } from 'utils/globalLayerControl';
import { isFreeUser, shouldShowKlarityPlanUI } from 'utils/provider';
import HomeStore from 'store/Home';

const Page = () => {
    const navigate = useNavigate();
    const [getUser] = ProviderStore.useStore();
    const [getSubscription] = SubscriptionStore.useStore();
    const user = getUser('data');
    const loadingUser = getUser('loading');
    const loadingPlan = getSubscription('loading');
    const [getHomeSore] = HomeStore.useStore();
    const homeInfo = getHomeSore('data');
    const hasPlan = !isFreeUser() || shouldShowKlarityPlanUI(homeInfo);

    if (!user || loadingUser || loadingPlan) {
        return <div style={{ padding: 48 }}><LoadingOutlined /></div>;
    }

    if (getHasViewProspect() && hasPlan) {
        return <ProspectManagement />;
    }

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <h1>Never Miss a Patient Prospect</h1>
                {/* <div className={s.des}>
                    Streamline patient management by centralizing patient information, tracking message status, and enabling easy communication and scheduling.
                </div> */}
            </div>
            <div className={s.content}>
                <div className={s.info}>
                    With Prospect, you can seamlessly manage patient information, communication, and scheduling all in one place. Our system consolidates leads from various channels, making it easier for you to stay organized. Within 'My Prospects,’ you can efficiently oversee communication, ensuring prompt responses, facilitating effective scheduling, and boosting the likelihood of appointment conversions. Streamline your tasks and workflows, allowing you to focus on providing quality care. Explore how our Prospect Management can elevate your patient management approach.
                </div>
                <Button
                    type="primary"
                    size="large"
                    className={s.btn}
                    onClick={() => {
                        if (!hasPlan) {
                            openSubscriptionModal();
                            return;
                        }
                        setHasViewProspect('true');
                        navigate(`${PATH.DASHBOARD}/${PATH.PROSPECTS_MANAGE}`);
                    }}
                >
                    {/* Start without Gmail access */}
                    {hasPlan ? 'Start' : 'Upgrade to unlock'}
                </Button>
                <div className={s.imgWrap}>
                    <img src={LandingIcon} />
                </div>
            </div>
        </div>
    );
};

export default Page;
