import React from 'react';
import Icon from '@ant-design/icons';
import EhrOn from './EhrOn';
import EhrOff from './EhrOff';
import Check from './Check';
import Remove from './remove';
import Download from './Download';

const EhrOnIcon = <Icon component={EhrOn} />;
const EhrOffIcon = <Icon component={EhrOff} />;
const CheckIcon = <Icon component={Check} />;
const UnCheckIcon = <Icon component={Remove} />;
const DownloadIcon = <Icon component={Download} />;

export default {
    EhrOnIcon,
    EhrOffIcon,
    CheckIcon,
    UnCheckIcon,
    DownloadIcon,
};
