import React, { useCallback, useEffect, useMemo, useState } from 'react';
import s from './s.mdoule.less';
import { Form, Radio, Space, Tooltip } from 'antd';
import { Desktop } from 'assets/preview/Desktop';
import { Mobile } from 'assets/preview/Mobile';
import commonS from 'styles/common.module.less';
import os from 'assets/preview/OS.svg';
import { SurveyStepValue } from 'types/survey';
import { isProductionEnv } from 'utils/common';
import { Channel } from 'types/common';
import { Provider } from 'types/provider';
import SubscriptionStore from 'store/Subscription';
import { TSubscriptionType } from 'types/subscription';

interface IProps {
    channels?: Channel[]
    initFormValue: { minimalSurveyScore: number }
    onNext: (formData: number) => void;
    providerData?: Provider;
}

const ChannelSelection = ({
    channels = [],
    initFormValue,
    onNext,
    providerData,
}: IProps) => {
    const [formInstance] = Form.useForm();
    const [isMobileMode, setIsMobileMode] = useState(false);
    const [iframeInstance, setIframeInstance] = useState<HTMLIFrameElement | null>(null);
    const [iframeLoaded, setIframeLoaded] = useState(false);
    const [score, setScore] = useState(4);
    const [getSubscription] = SubscriptionStore.useStore();
    const currentPlan = getSubscription('currentPlan');
    const { type: currentType } = currentPlan || {};
    const host = isProductionEnv() ? 'https://provider.kiwihealth.com' : 'https://dev.d31frxe1u41op.amplifyapp.com';
    //const host = isProductionEnv() ? 'https://provider.kiwihealth.com' : 'http://localhost:3009';

    useEffect(() => {
        formInstance.setFieldsValue(initFormValue || {
            minimalSurveyScore: 4,
        });

        setScore(initFormValue?.minimalSurveyScore || 4);
    }, [formInstance, initFormValue]);

    const sendMsg = useCallback(() => {
        const iframeWin = iframeInstance?.contentWindow;

        if (iframeWin) {
            iframeWin.postMessage({ channels: channels || [], provider: providerData }, `${host}/reviewInvitation?isPreview=true`);
        }
    }, [iframeInstance, channels, providerData, host]);

    useEffect(() => {
        if (channels.length > 0 && providerData && iframeLoaded) {
            setTimeout(() => {
                sendMsg();
            }, 500);
        }
    }, [channels, providerData, sendMsg, iframeLoaded]);

    useEffect(() => {
        const iframeEl = document.getElementById('previewIframe') as HTMLIFrameElement;
        // eslint-disable-next-line func-names
        iframeEl.onload = () => {
            setIframeLoaded(true);
        };
    }, [sendMsg, channels, providerData]);

    useEffect(() => {
        const iframeEl = document.getElementById('previewIframe') as HTMLIFrameElement;

        setIframeInstance(iframeEl);
    }, []);

    const handleValuesChange = useCallback((e:{ minimalSurveyScore: number }) => {
        setScore(e?.minimalSurveyScore);
    }, []);

    const channelListRender = useMemo(() => {
        return (channels && channels.length > 0) ?
            <>
                <div className={s.selectionTitle}>Below is a list of top selected channels for patients to leave a review</div>
                <div className={s.channlesList}>
                    {
                        channels.map((channel, index) => {
                            const { logo } = channel;
                            return (
                                <div key={index} className={s.channel}>
                                    <img src={logo} />
                                </div>
                            );
                        })
                    }
                </div>
                <Tooltip
                    overlayClassName={s.tipCard}
                    overlayInnerStyle={{ padding: '24px' }}
                    color="#FFFFFF"
                    title={
                        <div className={s.tipContent}>
                            <div className={s.tipText}>How is the channel selected?</div>
                            <div className={s.tipSubText}>{'These channels are selected based on your channel listing with Kiwi Health and the reviews\' impact to your online presences.'}</div>
                        </div>
                    }
                >
                    <div className={s.tips}>How is the channel selected?</div>
                </Tooltip>
            </> :
            <>
                <div className={s.selectionTitle}>No active review channels found</div>
                <div className={s.nonChannelDesc}>It appears that you don’t have any channels currently set up to receive patient reviews. Let’s change that! Explore the “Channel Store”, list yourself on channels that align with your practice and start building your reputation today.</div>
            </>;
    }, [channels]);

    const tipRender = useMemo(() => {
        const scoreText = score === 4 ? '4 or highter' : 5;
        const reviewLimitation = currentType === TSubscriptionType.ESSENTIAL ? 5 : 10;
        return `Based on your plan and configuration, Kiwi Health will automatically send the review invitation link to up to ${reviewLimitation} patients who have given you a score of ${scoreText} in the survey each week.`;
    }, [currentType, score]);

    return (
        <div className={s.wrap}>
            <div className={s.selectionPart}>
                <div className={s.title}>Review channels selection</div>
                {channelListRender}
                <div className={s.title}>Select invitation recipients</div>
                <div className={s.selectionTitle}>Send invitations to patients who rate you with:</div>
                <Form
                    id={SurveyStepValue.CHANNEL_SELECTION}
                    form={formInstance}
                    name="reviewScoreForm"
                    className={`${commonS.formStyle1} ${s.form}`}
                    autoComplete="off"
                    layout="vertical"
                    onValuesChange={handleValuesChange}
                    onFinish={onNext}
                >
                    <Form.Item
                        className={s.formItem}
                        name="minimalSurveyScore"
                    >
                        <Radio.Group>
                            <Space direction="vertical">
                                <Radio value={4}>High satisfaction scores (4 or above)</Radio>
                                <Radio value={5}>Outstanding satisfaction scores (5)</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </Form>
                <div className={s.tip}>
                    <div className={s.tipIcon} />
                    <div className={s.tipContent}>{tipRender}</div>
                </div>
            </div>
            <div className={s.preview}>
                <div className={s.modeWrap}>
                    <span
                        onClick={() => {
                            setIsMobileMode(false);
                        }}
                        className={isMobileMode ? '' : s.active}
                    >
                        <Desktop />
                    </span>
                    <span
                        onClick={() => {
                            setIsMobileMode(true);
                        }}
                        className={isMobileMode ? s.active : ''}
                    >
                        <Mobile />
                    </span>

                </div>
                <div
                    className={isMobileMode ? `${s.iframeWrap} ${s.iframeWrapMobile}` : s.iframeWrap}
                >
                    <div className={s.topBar}>
                        <img src={os} />
                    </div>
                    <div className={s.previewContent}>
                        <iframe className={s.iframe} title="previewIframe" id="previewIframe" src={`${host}/reviewInvitation?isPreview=true`} />
                        {
                            channels.length === 0 && (
                                <div className={s.mask}>
                                    <span className={s.maskTips}>Not available due to lack of channels</span>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChannelSelection;
