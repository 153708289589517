import React, { useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { getFeatureList, featureVote, launchPF } from 'api/practiceFront';
import { Button, Spin, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import { setPFUrl } from 'utils/localstore';

type ListItemType = {
    icon:string,
    title: string,
    subTitle: string,
    featureCode: string,
    voted:boolean,
    voteCount:number
};

const listData:Partial<ListItemType>[] = [
    {
        icon: s.calendarIcon,
        title: 'Online booking integration',
        subTitle: 'Integrate with your practice calendar to allow patients to directly book an appointment from Practice Front',
        featureCode: 'code_1',
    },
    {
        icon: s.qaIcon,
        title: 'FAQs',
        subTitle: 'Provide answers to frequently asked questions tailored to your practice and preferences',
        featureCode: 'code_2',
    },
    {
        icon: s.talkIcon,
        title: '24/7 front desk AI receptionist',
        subTitle: 'Train and build your own AI receptionist, powered by GPT, to provide assistance to new patients visiting your Practice Front',
        featureCode: 'code_3',
    },
    {
        icon: s.starIcon,
        title: 'Reviews & Ratings',
        subTitle: 'Gather patients’ reviews and ratings across all channels (Google, Zocdoc, etc.) and display them on Practice Front',
        featureCode: 'code_4',
    },
    {
        icon: s.tableIcon,
        title: 'Themes library',
        subTitle: 'Choose from a wider variety of pre-designed website layouts that can be easily customized to your brand and content',
        featureCode: 'code_5',
    },
    {
        icon: s.paperIcon,
        title: 'Blogs',
        subTitle: 'A place for you create and share your own articles, vlogs, and healthcare insights/expertises',
        featureCode: 'code_6',
    },
    {
        icon: s.reduceIcon,
        title: 'Selective information display',
        subTitle: 'Easily opt out specific information on Practice Front, such as negative reviews or sections of your UniProfile',
        featureCode: 'code_7',
    },
];

const PracticeFrontVotePage = () => {
    const navigate = useNavigate();
    const [voteListData, setVoteListData] = useState<ListItemType[]>([]);
    const [loading, setLoading] = useState(false);
    const [launchLoading, setLaunchLoading] = useState(false);

    const fetchData = useCallback(async () => {
        setLoading(true);
        const res = await getFeatureList();
        setLoading(false);

        if (!res?.error) {
            setVoteListData(listData?.map((item: Partial<ListItemType>) => {
                const itemInData = res.data?.data?.find((e: Partial<ListItemType>) => e.featureCode === item.featureCode);

                if (itemInData) {
                    return {
                        ...item,
                        ...itemInData,
                    };
                }
                return item;
            }));
        } else {
            message.error(res.error);
        }
    }, []);

    const handleVote = useCallback(async (featureCode: string, voteType: number) => {
        const res = await featureVote(featureCode, voteType);

        if (!res?.error) {
            fetchData();
        } else {
            message.error(res.error);
        }
    }, [fetchData]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleExit = useCallback(() => {
        navigate(`${PATH.DASHBOARD}/${PATH.PRACTICE_FRONT}`, { replace: true });
    }, [navigate]);

    const handleBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);
    const handleNext = useCallback(async () => {
        setLaunchLoading(true);
        const res = await launchPF();

        setLaunchLoading(false);
        if (!res?.error) {
            setPFUrl(res.data?.data);
            navigate(`/${PATH.PRACTICE_FRONT_CREATE_SUCCESS}`);
        } else {
            message.error(res.error);
        }
    }, [navigate]);

    return (
        <Spin spinning={loading || launchLoading}>
            <div className={s.wrap}>
                <div className={s.content}>
                    <div className={s.header}>
                        <div className={s.tipTitle}>One more thing...</div>
                        <div className={s.title}>Upvote features you like to shape the future of Practice Front</div>
                        <div className={s.intro}>By upvoting your favorites, you&apos;re telling us what matters most to you and helping us prioritize what we work on next.</div>
                    </div>
                    <div className={s.voteList}>
                        {
                            voteListData?.map((vote) => {
                                const { icon, title, subTitle, featureCode, voted, voteCount = 0 } = vote;
                                return (
                                    <div key={featureCode} className={s.voteItem}>
                                        <div className={`${s.icon} ${icon}`} />
                                        <div className={s.voteIntro}>
                                            <div className={s.title}>{title}</div>
                                            <div className={s.detail}>{subTitle}</div>
                                        </div>
                                        <div className={`${s.upvoteBox}  ${voted ? s.active : ''}`} onClick={() => handleVote(featureCode, voted ? 0 : 1)}>
                                            <div className={s.upvote}>{ voted ? 'Upvoted' : 'Upvote' } ({voteCount})</div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
                <div className={s.footer}>
                    <div>
                        <Button type="primary" ghost style={{ color: '#374151', borderColor: '#D1D5DB' }} onClick={handleExit}>Exit</Button>
                        <Button type="ghost" style={{ color: '#006957' }} onClick={handleBack}>back</Button>
                    </div>
                    <Button type="primary" loading={launchLoading} onClick={handleNext}>Launch my Practice Front</Button>
                </div>
            </div>
        </Spin>
    );
};

export default PracticeFrontVotePage;
