import React from 'react';
import s from './s.module.less';
import { TITLE, CONFIG } from './constants';

const Solutions: React.FC = () => {
    return (
        <div className={s.wrap}>
            <h2 className={s.title}>{TITLE}</h2>
            <div className={s.list}>
                {CONFIG.map((configItem) => {
                    return (
                        <div className={s.item}>
                            <div className={s.left}>
                                <h3 className={s.subTitle}>{configItem.title}</h3>
                                <ul className={s.detailList}>
                                    {configItem.list.map((listItem) => (
                                        <li className={s.detailItem}>{listItem}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className={s.right}>
                                <img src={configItem.imgUrl} alt={configItem.title} />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Solutions;
