import React from 'react';

const Check = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M13.3656 4.23431C13.678 4.54673 13.678 5.05327 13.3656 5.36569L6.96559 11.7657C6.65317 12.0781 6.14664 12.0781 5.83422 11.7657L2.63422 8.56569C2.3218 8.25327 2.3218 7.74673 2.63422 7.43431C2.94664 7.1219 3.45317 7.1219 3.76559 7.43431L6.3999 10.0686L12.2342 4.23431C12.5466 3.9219 13.0532 3.9219 13.3656 4.23431Z" fill="currentColor" />
    </svg>

);

export default Check;
