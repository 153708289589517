import React, { useCallback, useEffect } from 'react';
import s from './s.module.less';
import { Form, Radio, Space, message } from 'antd';

interface IProps {
    formId: string;
    setUpLapOption?: string;
    handleSubmited?: (data: any, field: string) => void;
}

const Lab = ({
    formId,
    setUpLapOption,
    handleSubmited,
}: IProps) => {
    const [formInstance] = Form.useForm();

    useEffect(() => {
        formInstance.setFieldsValue({
            setUpLapOption,
        });
    }, [formInstance, setUpLapOption]);

    const handleFinish = useCallback(async (values) => {
        handleSubmited?.(values, formId);
    }, [formId, handleSubmited]);

    const handleFinishFailed = useCallback((errors) => {
        if (errors?.errorFields.length > 0) {
            message.error(errors.errorFields[0].errors?.[0]);
        }
    }, []);
    return (
        <div className={s.wrap}>
            <div className={s.inner}>
                <div className={s.title}>Step 4 of 4: Set up lab for your practice</div>
                <div className={s.subTitle}>Choose the lab integration that works best for your practice.</div>
                <div className={s.form}>
                    <Form
                        form={formInstance}
                        id={formId}
                        layout="vertical"
                        onFinish={handleFinish}
                        onFinishFailed={handleFinishFailed}
                    >
                        <div className={s.row}>
                            <div className={s.displayItem}>
                                <Form.Item
                                    name="setUpLapOption"
                                    rules={[
                                        { required: true, message: 'Please choose the lab integration' },
                                    ]}
                                >
                                    <Radio.Group>
                                        <Space direction="vertical">
                                            <Radio value="1">
                                                <>
                                                    <div className={s.radioTitle}>Rupa Health</div>
                                                    <div className={s.radioDesc}>Our recommended, fully integrated lab platform. Get started quickly with streamlined ordering, results management, and direct integration with your Kiwi EHR.</div>
                                                </>
                                            </Radio>
                                            <Radio value="2">
                                                <>
                                                    <div className={s.radioTitle}>Other lab platforms</div>
                                                    <div className={s.radioDesc}>{'Already using a different lab?  Let us know which one, and we\'ll work with you to connect it to your Kiwi EHR for seamless lab ordering and results tracking.'}</div>
                                                </>
                                            </Radio>
                                            <Radio value="3">
                                                <>
                                                    <div className={s.radioTitle}>No labs needed</div>
                                                    <div className={s.radioDesc}>{'Don\'t need lab results integrated with your practice workflow? Choose this option. You can always update this later with our Klarity support team.'}</div>
                                                </>
                                            </Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default Lab;
