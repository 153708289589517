import React from 'react';
import { Modal, Button } from 'antd';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { openConfigReviewInvitationPage, openConfigSurveyPage } from 'utils/navigateUtils';
import { isFreeUser } from 'utils/provider';

type Props = {
    show:boolean,
    onCannel?: ()=>void,
};

const SettingsOverviewModal = (props: Props) => {
    const { show, onCannel } = props;
    const isFree = isFreeUser();

    return (
        <Modal
            title="Settings"
            closable
            className={commonS.modalFixHeightWrap}
            width="1100px"
            onCancel={() => {
                onCannel?.();
            }}
            footer={null}
            open={show}
            destroyOnClose
        >
            <div className={s.wrap}>
                <div className={s.item}>
                    <div className={s.icon}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g clipPath="url(#clip0_792_34481)">
                                <path d="M11.049 2.92664C11.3483 2.00537 12.6517 2.00538 12.951 2.92664L14.4699 7.60055C14.6038 8.01254 14.9877 8.29148 15.4209 8.29149L20.3354 8.29168C21.3041 8.29172 21.7068 9.53127 20.9232 10.1007L16.9474 12.9895C16.5969 13.2441 16.4503 13.6955 16.5841 14.1075L18.1026 18.7815C18.4019 19.7028 17.3475 20.4689 16.5638 19.8995L12.5878 17.011C12.2373 16.7564 11.7627 16.7564 11.4122 17.011L7.43622 19.8995C6.65252 20.4689 5.5981 19.7028 5.8974 18.7815L7.41589 14.1075C7.54974 13.6955 7.40309 13.2441 7.05263 12.9895L3.07683 10.1007C2.29317 9.53127 2.69592 8.29172 3.66461 8.29168L8.57911 8.29149C9.01231 8.29148 9.39623 8.01254 9.53011 7.60055L11.049 2.92664Z" stroke="#00816B" strokeWidth="2" />
                            </g>
                            <defs>
                                <clipPath id="clip0_792_34481">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className={s.title}>
                        Send survey to existing patients to gather baseline feedback
                    </div>
                    <div className={s.des}>
                        Edit and refine the wording of both the survey invitation email template and the survey questions.
                    </div>
                    <div className={s.btnWrap}>
                        <Button
                            onClick={() => {
                                openConfigSurveyPage();
                                onCannel?.();
                            }}
                            type="primary"
                        >Configure survey
                        </Button>
                    </div>
                </div>

                <div className={isFree ? `${s.item} ${s.itemDisable}` : s.item}>
                    <div className={s.icon}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M7 8H17M7 12H11M12 20L8 16H5C3.89543 16 3 15.1046 3 14V6C3 4.89543 3.89543 4 5 4H19C20.1046 4 21 4.89543 21 6V14C21 15.1046 20.1046 16 19 16H16L12 20Z" stroke="#00816B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    <div className={s.title}>
                        Review invitation setup
                    </div>
                    <div className={s.des}>
                        Customize invitation recipients and select preferred review channels to maximize positive feedback from patienst.
                    </div>
                    <div className={s.btnWrap}>
                        <Button
                            onClick={() => {
                                if (isFree) {
                                    return;
                                }
                                openConfigReviewInvitationPage();
                                onCannel?.();
                            }}
                            type="primary"
                        >Configure review invitation
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default SettingsOverviewModal;
