import React from 'react';
import { Checkbox, Modal } from 'antd';
import { setDisableSurveyCapNotice } from 'utils/localstore';
import s from './s.module.less';
import Note from './note.svg';

type Props = {
    show:boolean,
    onCancel?: ()=>void,
    onOk?: ()=>void,
};

const SendSurveyNoteModal = (props: Props) => {
    const { show, onOk, onCancel } = props;
    const [disableNoteCheck, setDisableNoteCheck] = React.useState<boolean>(false);
    return (
        <Modal
            title={null}
            open={show}
            onCancel={onCancel}
            onOk={() => {
                if (disableNoteCheck) {
                    setDisableSurveyCapNotice('true');
                }
                onOk?.();
            }}
            okText="Ackowledge and send survey"
            zIndex={99}
        >
            <div className={s.noteWrap}>
                <div className={s.left}>
                    <img src={Note} />
                </div>
                <div className={s.right}>
                    <div className={s.title}>Survey cap notice</div>
                    <div className={s.des}>We will only send up to {5} surveys per week to avoid our survey emails being classified as spam. If you select more than {5} patients to receive the survey, the remaining surveys will be automatically scheduled for delivery in the subsequent weeks.</div>
                    <div className={s.check}>
                        <Checkbox
                            onChange={(e) => {
                                setDisableNoteCheck(e.target.checked);
                            }}
                        >
                            Don’t show this again
                        </Checkbox>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default SendSurveyNoteModal;
