import FeatureImg1 from 'assets/ehr-home/a-1.png';
import FeatureImg2 from 'assets/ehr-home/a-2.png';
import FeatureImg3 from 'assets/ehr-home/a-3.png';
import FeatureImg4 from 'assets/ehr-home/a-4.png';
import FeatureImg5 from 'assets/ehr-home/a-5.png';
import FeatureImg6 from 'assets/ehr-home/a-6.png';
import FeatureImg7 from 'assets/ehr-home/a-7.png';
import FeatureImg8 from 'assets/ehr-home/a-8.png';

export const TITLE = 'Overview of Kiwi Health\'s EHR comprehensive features';

export const CONFIG = [
    {
        title: 'Client engagement portal',
        description: 'A versatile platform for patient interaction, enabling scheduling, payments, and secure communication.',
        imgUrl: FeatureImg1,
    },
    {
        title: 'Customizable wellness programs',
        description: 'Create wellness programs for lasting patient relationships and health engagement.',
        imgUrl: FeatureImg2,
    },
    {
        title: 'HIPAA-compliant and cloud-based',
        description: 'Secure, accessible, and scalable cloud-based EHR system, ensuring data safety and constant availability.',
        imgUrl: FeatureImg3,
    },
    {
        title: 'Electronic paperwork',
        description: 'Streamline your documentation process with digital forms and records, enhancing efficiency and accuracy.',
        imgUrl: FeatureImg4,
    },
    {
        title: 'Scheduling',
        description: 'Simplify appointment management and enhance patient access with advanced scheduling.',
        imgUrl: FeatureImg5,

    },
    {
        title: 'Telehealth & webinars',
        description: 'Offer virtual care and educational sessions with integrated telehealth and webinar capabilities.',
        imgUrl: FeatureImg6,
    },
    {
        title: 'Billing, insurance billing & superbills',
        description: 'Simplify financial processes with versatile billing, multiple payment methods, easy superbill generation, and HSA/FSA cards.',
        imgUrl: FeatureImg7,
    },
    {
        title: 'Reporting',
        description: 'Create custom reports on business, finances, and client care for deep operational insights.',
        imgUrl: FeatureImg8,
    },

];
