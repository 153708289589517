import React from 'react';
import { Button } from 'antd';
import s from './s.module.less';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import useProviderHomeInfo from 'hooks/useProviderHomeInfo';

const InitStep: React.FC<{ className: string }> = ({ className }) => {
    const navigate = useNavigate();
    const [data, loadingHome, fetchData] = useProviderHomeInfo();
    const practiceFrontCompleted = !!data?.practiceFrontCompleted;
    return (
        <div className={className}>
            <div className={s.content}>
                <p>
                    Boost your visibility and attract new patients by creating a state-of-the-art personal website with Kiwi's cutting-edge technology and listing on multiple channel.
                </p>
            </div>
            <div className={s.buttonWrap}>
                <Button
                    type="primary"
                    onClick={() => {
                        navigate(`${PATH.DASHBOARD}/${PATH.PRACTICE_FRONT}`);
                    }}
                >
                    {practiceFrontCompleted ? 'Manage Practice Front' : 'Set up Practice Front'}
                </Button>
                <Button
                    onClick={() => {
                        navigate(`${PATH.DASHBOARD}/${PATH.CHANNEL}`);
                    }}
                >
                    Explore Channels
                </Button>
            </div>
        </div>
    );
};

export default InitStep;
