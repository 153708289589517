import React, { useCallback, useEffect, useRef, useState } from 'react';
import s from './s.module.less';
import { Button, Checkbox, Form, Modal, Radio, Spin, message } from 'antd';
import FormItem from './formItem';
import {
    CopyOutlined,
} from '@ant-design/icons';
import { Email } from 'constants/contact';
import copy from 'copy-to-clipboard';
import commonS from 'styles/common.module.less';
import { getConnectChannelList } from 'api/channel';
import { ISubmitKlarityPlanChannelConnectInput } from 'types/klarityPlan';

type TChannel = { id: number, price: number, name: string, fieldName: string };

const labels: string[][] = [
    ['Username', 'Password'],
    ['Email address', 'Password'],
];

interface IProps {
    // formId: string;
    handleSubmit?: (data: ISubmitKlarityPlanChannelConnectInput) => void;
    setNextButtonDisabled: (v: boolean) => void;
    setConnectChannes: (v: any) => void;
    forceNext: () => void;
}

const ConnectChannel = ({
    // formId,
    handleSubmit,
    setNextButtonDisabled,
    setConnectChannes,
    forceNext,
}: IProps) => {
    const [formInstance] = Form.useForm();
    const [channelList, setChannelList] = useState([]);
    const [radioChecked, setRadioChecked] = useState(false);
    const [selectedChannels, setSelectedChannels] = useState<TChannel[]>([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const copyText = useRef('Hi Klarity Onboarding Team,\r\nI\'m currently using [Psychology Today/Mental Health Match] for my practice, but I have some questions/concerns about providing my login credentials.\r\nCould you please clarify how my information will be used and protected?');

    const fetChannels = useCallback(async () => {
        setLoading(true);

        try {
            const res = await getConnectChannelList();

            if (res && !res.error) {
                setChannelList(res.data?.data || []);
            } else {
                message.error(res?.error);
            }
        } catch (e) {
            console.error(e);
        }

        setLoading(false);
    }, []);

    useEffect(() => {
        fetChannels();
    }, [fetChannels]);

    useEffect(() => {
        if (selectedChannels.length > 0) {
            setRadioChecked(false);
        }
        setConnectChannes(selectedChannels);
    }, [selectedChannels, setConnectChannes]);

    useEffect(() => {
        if (selectedChannels.length === 0 && !radioChecked) {
            setNextButtonDisabled(true);
        } else {
            setNextButtonDisabled(false);
        }
    }, [radioChecked, selectedChannels, setNextButtonDisabled]);

    const handleCopyEmail = useCallback(() => {
        copy(copyText.current, {
            format: 'text/plain',
        });
        message.success('Copied to the clipboard');
    }, []);

    const handleRadioChange = useCallback((e) => {
        setRadioChecked(e.target.checked);
        if (e.target.checked) {
            setSelectedChannels([]);
            setTotalPrice(0);
        }
    }, []);

    const handleChannelChecked = useCallback((e: TChannel) => {
        if (selectedChannels.find((item) => item.id === e.id)) {
            const restData = selectedChannels.filter((item) => item.id !== e.id);
            setSelectedChannels(restData);
            setTotalPrice(totalPrice - e.price);
        } else {
            setTotalPrice(totalPrice + e.price);
            setSelectedChannels([...selectedChannels, e]);
        }
    }, [selectedChannels, totalPrice]);

    const handleFinish = useCallback(async (values) => {
        const keys = Object.keys(values);
        const formData = keys.reduce((r, c) => {
            const channel = selectedChannels.find((item) => item.fieldName === c);

            r[c] = {
                channelId: channel?.id,
                channelName: channel?.name,
                account: values[c].userName,
                password: values[c].password,
            };

            return r;
        }, {});

        handleSubmit?.({
            reqList: Object.values(formData),
        });
    }, [handleSubmit, selectedChannels]);

    const handleFinishFailed = useCallback((errors) => {
        setShowModal(true);
    }, []);

    return (
        <div className={s.wrap}>
            <Modal
                width={780}
                closable={false}
                open={showModal}
                footer={
                    <div className={s.modalFooter}>
                        <Button onClick={forceNext}>Proceed anyway</Button>
                        <Button type="primary" onClick={() => setShowModal(false)}>Go back to enter credentials</Button>
                    </div>
                }
            >
                <div className={s.modalWrap}>
                    <div className={s.tipIcon} />
                    <div className={s.content}>
                        <div className={s.title}>Missing credentials for selected channels</div>
                        <div className={s.descBox}>
                            <div className={s.desc}>You have selected channels but haven't provided your credentials for Psychology Today or Mental Health Match. Without these credentials, you'll miss out on valuable benefits such as expanded reach, cost savings, enhanced visibility, and time savings. To make the most of Kiwi Health's features, please provide your credentials.</div>
                            <div className={s.desc}>If you have any concerns, you can contact our provider support team:</div>
                            <div className={s.desc}><a className={s.link} href={`mailto:${Email.klarity_provider_support}`}>providersupport@helloklarity.com</a> with this email template:</div>
                        </div>
                        <div className={s.copyText}>
                            <p>Hi Klarity Onboarding Team,</p>
                            <p>I'm currently using [Psychology Today/Mental Health Match] for my practice, but I have some questions/concerns about providing my login credentials.</p>
                            <p>Could you please clarify how my information will be used and protected?</p>
                        </div>
                        <Button icon={<CopyOutlined />} onClick={handleCopyEmail}>Copy to clipboard</Button>
                    </div>
                </div>
            </Modal>
            <Spin spinning={loading}>
                <div className={s.step}>Step 1 of 3: Connect your current listings</div>
                <div className={s.title}>Do you already have accounts in these channels?</div>
                <div className={s.desc}>We need to know if you already have accounts with Psychology Today or Mental Health Match. This will help us avoid creating duplicate profiles, ensuring that all your patient records remain intact and accessible. Please provide your account details below.</div>
                <div className={s.channelSelect}>
                    {
                        channelList?.map((channel) => {
                            const { id, name, logo } = channel;
                            const checked = !!selectedChannels.find((item) => item.id === id);
                            const channelInfo = {
                                id,
                                name,
                                price: name === 'Psychology Today' ? 30 : 25,
                                fieldName: name.replace(/\s+/g, '').toLowerCase(),
                            };
                            return (
                                <div key={id} className={`${s.channel} ${checked ? s.checked : ''}`} onClick={() => handleChannelChecked(channelInfo)}>
                                    <Checkbox checked={checked} className={s.checkbox} />
                                    <img src={logo} alt={name} />
                                    <div className={s.channeName}>{name}</div>
                                </div>
                            );
                        })
                    }
                    <div className={`${s.deselecAll} ${radioChecked ? s.checked : ''}`}>
                        <Radio checked={radioChecked} onChange={handleRadioChange}><span className={s.radio}>{'I don\'t have accounts on these channels.'}</span></Radio>
                    </div>
                </div>
                {
                    selectedChannels.length > 0 &&
                    (
                        <>
                            <div className={s.manageChannel}>
                                <div className={s.title}>Let us help you manage your channels</div>
                                <div className={s.list}>
                                    <div className={s.item}>
                                        <div className={s.title}>📈 Expanded reach:</div>
                                        <div className={s.desc}>{'We\'ll add additional locations (Up to 4 states) to your profile, increasing your reach to a wider audience.'}</div>
                                    </div>
                                    <div className={s.item}>
                                        <div className={s.title}>💸 Cost savings:</div>
                                        <div className={s.desc}>{`You'll no longer need to pay the monthly fee for these premium channels – we'll cover it for you! This will save you up to $${totalPrice}/month!`}</div>
                                    </div>
                                    <div className={s.item}>
                                        <div className={s.title}>🌟 Enhanced visibility:</div>
                                        <div className={s.desc}>{'Our team of experts will optimize your profile for better search engine ranking, attracting more potential clients. We\'ll ensure your profile information matches your UniProfile and Klarity practice profile, creating a cohesive online presence.'}</div>
                                    </div>
                                    <div className={s.item}>
                                        <div className={s.title}>🕒 Time savings:</div>
                                        <div className={s.desc}>Let us handle the updates and maintenance, freeing you to focus on your practice.</div>
                                    </div>
                                </div>
                            </div>
                            <div className={s.info}>
                                <div className={s.title}>Enter your channel login information</div>
                                <div className={s.form}>
                                    <div className={s.title}>🔓 Rest assured, your data is safe with us.</div>
                                    <div className={s.desc}>Only authorized Kiwi Support Specialists will have access to your credentials. We take your privacy and security seriously.</div>
                                    <Form
                                        form={formInstance}
                                        id="formId"
                                        className={commonS.formStyle1}
                                        layout="vertical"
                                        onFinish={handleFinish}
                                        onFinishFailed={handleFinishFailed}
                                    >
                                        {
                                            selectedChannels.map((channel, index) => {
                                                const { name, id, fieldName } = channel;
                                                return (
                                                    <div key={id} className={s.formItemBox}>
                                                        <Form.Item
                                                            name={fieldName}
                                                            rules={[
                                                                {
                                                                    validator: (rules, value) => {
                                                                        if (!value?.userName || !value?.password) {
                                                                            return Promise.reject();
                                                                        }
                                                                        return Promise.resolve(true);
                                                                    },
                                                                },
                                                            ]}
                                                        >
                                                            <FormItem label={labels[index]} title={name} />
                                                        </Form.Item>
                                                    </div>
                                                );
                                            })
                                        }
                                    </Form>
                                </div>
                            </div>
                        </>
                    )
                }
            </Spin>
        </div>
    );
};

export default ConnectChannel;
