import React, { useMemo } from 'react';
import { Button } from 'antd';
import s from './s.module.less';
import WatchVideo from '../WatchVideo';
import { useNavigate } from 'react-router-dom';
import { HOME, UNIPROFILE } from 'constants/common';
import useGetUniprofileEntry from 'hooks/useGetUniprofileEntry';
import ProviderUniprofileStatusTag from 'components/ProviderUniprofileStatusTag';
import TimeRemaining from '../TimeRemaining';
import { EProfileStatus } from 'types/channel';

const InitStep: React.FC<{
    firstUniprofileSubmitAt?: string;
    firstLoginAt?: string;
    uniprofileStatus?: EProfileStatus
    className: string;
    isSubmittedUniproile: boolean;
}> = ({ className, isSubmittedUniproile, uniprofileStatus, firstLoginAt, firstUniprofileSubmitAt }) => {
    const [uniprofileEntryPath] = useGetUniprofileEntry();
    const navigate = useNavigate();

    return (
        <div className={`${s.wrap} ${className}`}>
            <div className={s.header}>
                <h2 className={s.h2}>Complete your UniProfile</h2>
                <div className={s.statusNtime}>
                    <ProviderUniprofileStatusTag status={uniprofileStatus} /> | <TimeRemaining isCompleted={!!firstUniprofileSubmitAt} completeTime={firstUniprofileSubmitAt} startTime={firstLoginAt} />
                </div>
            </div>
            <div className={s.content}>
                <div className={s.paragraph}>
                    UniProfile is the foundation for your practice. It gathers your experience, services, and pricing to build your Klarity profile and ensure consistent information across all channels.
                </div>
                <div className={s.paragraph}>
                    You will need:
                </div>
                <ul>
                    <li>A resume, better in PDF format.</li>
                    <li>Practice license, malpractice insurance, and DEA license (if needed)</li>
                    <li>A clear, professional, portrait photo</li>
                </ul>
            </div>
            <div className={s.buttonWrap}>
                {isSubmittedUniproile ? (
                    <Button
                        type="primary"
                        onClick={() => {
                            navigate(`${uniprofileEntryPath}?type=${UNIPROFILE}&from=${HOME}`);
                        }}
                    >
                        Open UniProfile
                    </Button>
                ) : (
                    <Button
                        type="primary"
                        onClick={() => {
                            navigate(`${uniprofileEntryPath}?type=${UNIPROFILE}`);
                        }}
                    >
                        Complete UniProfile
                    </Button>
                )}

                <WatchVideo link="https://www.youtube.com/watch?v=nXXvhwEWFfY" />
            </div>
        </div>
    );
};

export default InitStep;
