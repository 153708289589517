import { Button, message, Modal, Spin } from 'antd';
import React, { useMemo, useState } from 'react';
import s from './s.module.less';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import { addToShoppingCart, checkHasSetAddon, removeFromShoppingCart } from 'api/ehr/index';

import { EhrAddOnItem, EAddOnProductCode, TShoppingCartItem } from 'types/ehr';

import SelfEPrescriptionAndEpcsModal from './components/SelfEPrescriptionAndEpcs';
import SelfEPrescriptionModal from './components/SelfEPrescription';
import SelfEFaxModal from './components/SelfEFax';
import SelfEFaxWarning from './components/SelfEFaxWarning';

import CollaboratingPhysicianSeatModal from './components/CollaboratingPhysicianSeat';
import CollaboratingPhysicianEPrescriptionAndEpcsModal from './components/CollaboratingPhysicianEPrescriptionAndEpcs';
import CollaboratingPhysicianEPrescriptionModal from './components/CollaboratingPhysicianEPrescription';

import AssistantSeatModal from './components/AssistantSeat';
import AssistantEPrescriptionAndEpcsModal from './components/AssistantEPrescriptionAndEpcs';
import AssistantEPrescriptionModal from './components/AssistantEPrescription';
import {
    TAssistant,
    TCollaboratingPhysician,
    TSelfEPrescription,
} from 'types/common';
import { Email } from 'constants/contact';
import useAddOnProduct from 'hooks/useAddOnProduct';
import useShoppingCart from 'hooks/useShoppingCart';
import EhrActiveStatus from 'components/EhrActiveStatus';
import EhrPaymentStatus from 'components/EhrPaymentStatus';

import useGetEHRAddOnUniprofileInfo from 'hooks/useGetEHRAddOnUniprofileInfo';
import EHRAvaRecommandLabel from 'components/EHRAvaRecommandLabel';
import ProviderStore from 'store/Provider';
import { submitNonCMDChannelSelected } from 'api/channel';

const EhrProviderAddOnPage = () => {
    const navigate = useNavigate();
    const [allItems, loading, refetch] = useAddOnProduct();
    const [shoppingCart, loadingCart, refetchCart] = useShoppingCart();
    const shoppingCartItems = shoppingCart?.items;
    const [addonUniprofileInfo, loadingUniprofileInfo] =
        useGetEHRAddOnUniprofileInfo();
    const cartCount = shoppingCart?.count || 0;

    const [checkSettedAddonLoading, setCheckSettedAddonLoading] = useState(false);
    const [showAddonUnSelectedTip, setShowAddonUnSelectedTip] = useState(false);

    const selfItems = allItems?.self;
    const collaboratorItems = allItems?.collaboratingPhysician;
    const assistantItems = allItems?.assistant;

    const [showModalMap, setShowModalMap] = React.useState<Partial<Record<EAddOnProductCode, boolean>>>({
        [EAddOnProductCode.ASSISTANT_SEAT]: false,
    });
    const [isShowMoreMap, setIsShowMoreMap] = React.useState<Partial<Record<EAddOnProductCode, boolean>>>({
        [EAddOnProductCode.ASSISTANT_SEAT]: false,
    });

    const clearFlagRef = React.useRef<Record<EAddOnProductCode, {} | undefined>>({
        [EAddOnProductCode.SELF_EPRESCRIPTION]: undefined,
        [EAddOnProductCode.SELF_EPRESCRIPTION_EPCS]: undefined,
        [EAddOnProductCode.SELF_EFAX]: undefined,
        [EAddOnProductCode.COLLABORATING_PHYSICIAN_EPRESCRIPTION]: undefined,
        [EAddOnProductCode.COLLABORATING_PHYSICIAN_EPRESCRIPTION_EPCS]: undefined,
        [EAddOnProductCode.COLLABORATING_PHYSICIAN_SEAT]: undefined,
        [EAddOnProductCode.ASSISTANT_SEAT]: undefined,
        [EAddOnProductCode.ASSISTANT_EPRESCRIPTION]: undefined,
        [EAddOnProductCode.ASSISTANT_EPRESCRIPTION_EPCS]: undefined,
    });

    const removingFlagRef = React.useRef<Record<EAddOnProductCode, boolean>>({
        [EAddOnProductCode.SELF_EPRESCRIPTION]: false,
        [EAddOnProductCode.SELF_EPRESCRIPTION_EPCS]: false,
        [EAddOnProductCode.SELF_EFAX]: false,
        [EAddOnProductCode.COLLABORATING_PHYSICIAN_EPRESCRIPTION]: false,
        [EAddOnProductCode.COLLABORATING_PHYSICIAN_EPRESCRIPTION_EPCS]: false,
        [EAddOnProductCode.COLLABORATING_PHYSICIAN_SEAT]: false,
        [EAddOnProductCode.ASSISTANT_SEAT]: false,
        [EAddOnProductCode.ASSISTANT_EPRESCRIPTION]: false,
        [EAddOnProductCode.ASSISTANT_EPRESCRIPTION_EPCS]: false,
    });

    const [collaboratingPhysicianSeatsChangeFlag, setCollaboratingPhysicianSeatsChangeFlag] = React.useState<{}>();
    const [assistantSeatsChangeFlag, setAssistantSeatsChangeFlag] = React.useState<{}>();

    const [, forceUpdate] = React.useState({});

    const [pendingSelfEprescriptionEpcs, setPendingSelfEprescriptionEpcs] = React.useState<TSelfEPrescription[]>();
    const [pendingSelfEprescription, setPendingSelfEprescription] = React.useState<TSelfEPrescription[]>();
    const [pendingSelfEfax, setPendingSelfEfax] =
        React.useState<TSelfEPrescription[]>();

    const [pendingAssistantSeats, setPendingAssistantSeats] = React.useState<TAssistant[]>();
    const [pendingAssistantEprescriptionEpcs, setPendingAssistantEprescriptionEpcs] = React.useState<TAssistant[]>(); //emails
    const [pendingAssistantEprescription, setPendingAssistantEprescription] = React.useState<TAssistant[]>(); //emails

    const [pendingCollaboratingPhysicianSeats, setPendingCollaboratingPhysicianSeats] = React.useState<TCollaboratingPhysician[]>();
    const [pendingCollaboratingPhysicianEprescriptionEpcs, setPendingCollaboratingPhysicianEprescriptionEpcs] = React.useState<TCollaboratingPhysician[]>(); //emails
    const [pendingCollaboratingPhysicianEprescription, setPendingCollaboratingPhysicianEprescription] = React.useState<TCollaboratingPhysician[]>(); //emails

    const [productCodeAddOnItemMap, setProductCodeAddOnItemMap] = useState<Record<EAddOnProductCode, EhrAddOnItem>>();

    const [addingFax, setAddingFax] = useState(false);

    const [modal, contextHolder] = Modal.useModal();

    const [resetEfaxModal, setResetEfaxModal] = useState<Record<any, any>>();
    const [showEfaxWarning, setShowEfaxWarning] = useState(false);

    const [getUser] = ProviderStore.useStore();
    const user = getUser('data');
    const collaboratingPhysicianSeatShowRecommandTag = user?.physicianData && user.physicianData.length > 0;
    const ePrescriptionNEPCSShowRecommandTag = user?.licenseList && user.licenseList.filter((item) => item.type === 'DEA').length > 0;

    const shouldShowWarningPopup = useMemo(() => {
        let hasEFax = false;

        const efaxProduct = allItems.self.find(
            (item) => item.productCode === EAddOnProductCode.SELF_EFAX,
        );

        if (efaxProduct && !!efaxProduct.paymentStatus) {
            hasEFax = true;
        } else if (['1'].includes(addonUniprofileInfo?.addonService?.eFaxOption || '')) {
            hasEFax = false;
        }

        const eprescription = shoppingCartItems?.find((item) =>
            [
                EAddOnProductCode.SELF_EPRESCRIPTION,
                EAddOnProductCode.SELF_EPRESCRIPTION_EPCS,
            ].includes(item.productCode),
        );

        return !!eprescription && !hasEFax;
    }, [
        addonUniprofileInfo?.addonService?.eFaxOption,
        allItems.self,
        shoppingCartItems,
    ]);

    React.useEffect(() => {
        const all = allItems?.all || [];
        const map: Partial<Record<EAddOnProductCode, EhrAddOnItem>> = {};
        all.forEach((item) => {
            map[item.productCode] = item;
        });
        setProductCodeAddOnItemMap(map as Record<EAddOnProductCode, EhrAddOnItem>);
    }, [allItems]);

    const closeModal = () => {
        setShowModalMap({});
        setIsShowMoreMap({});
    };

    const showModal = (productCode: EAddOnProductCode, isMoreFlag?: boolean) => {
        const _showModalMap = { ...showModalMap };
        _showModalMap[productCode] = true;
        const _isShowMorMap = { ...isShowMoreMap };
        _isShowMorMap[productCode] = isMoreFlag;
        setShowModalMap(_showModalMap);
        setIsShowMoreMap(_isShowMorMap);
    };

    const clearAndResetData = async (productCode: EAddOnProductCode) => {
        removingFlagRef.current[productCode] = true;
        forceUpdate({});
        const result = await removeFromShoppingCart(productCode);
        if (!result.error) {
            if (productCode === EAddOnProductCode.ASSISTANT_SEAT) {
                setAssistantSeatsChangeFlag({});
            }
            if (productCode === EAddOnProductCode.COLLABORATING_PHYSICIAN_SEAT) {
                setCollaboratingPhysicianSeatsChangeFlag({});
            }
            clearFlagRef.current[productCode] = {};
            setResetEfaxModal({});
            refetchCart();
        }
        removingFlagRef.current[productCode] = false;
        forceUpdate({});
    };

    const handleRemove = (productCode: EAddOnProductCode) => {
        if (
            productCode === EAddOnProductCode.SELF_EFAX &&
            shouldShowWarningPopup
        ) {
            setShowEfaxWarning(true);
            return;
        }
        const cartAssistantEprescriptionEpcs = shoppingCartItems?.find((item) => item.productCode === EAddOnProductCode.ASSISTANT_EPRESCRIPTION_EPCS);
        const cartAssistantEprescription = shoppingCartItems?.find((item) => item.productCode === EAddOnProductCode.ASSISTANT_EPRESCRIPTION);
        const hasAssistantAddOn = (cartAssistantEprescriptionEpcs && cartAssistantEprescriptionEpcs.productQty > 0) || (cartAssistantEprescription && cartAssistantEprescription.productQty > 0);
        if (productCode === EAddOnProductCode.ASSISTANT_SEAT && hasAssistantAddOn) {
            modal.confirm({
                title: null,
                icon: null,
                className: s.confirmModal,
                width: '650px',
                okText: 'Remove',
                onOk: () => {
                    clearAndResetData(productCode);
                },
                content: (
                    <div className={s.confirmWrap}>
                        <div className={s.confirmIcon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M11.9995 9V11M11.9995 15H12.0095M5.07134 19H18.9277C20.4673 19 21.4296 17.3333 20.6598 16L13.7316 4C12.9618 2.66667 11.0373 2.66667 10.2675 4L3.33929 16C2.56949 17.3333 3.53174 19 5.07134 19Z" stroke="#DC2626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <div className={s.confirm}>
                            <div className={s.confirmTitle}>Attention</div>
                            <div className={s.confirmContent}>
                                Removing assistant seats will also remove the e-Prescription add-ons you added for them.
                                Are you sure you want to remove both?
                            </div>
                        </div>
                    </div>
                ),
            });
            return;
        }
        const cartCollaboratingPhysicianEprescriptionEpcs = shoppingCartItems?.find((item) => item.productCode === EAddOnProductCode.COLLABORATING_PHYSICIAN_EPRESCRIPTION_EPCS);
        const cartCollaboratingPhysicianEprescription = shoppingCartItems?.find((item) => item.productCode === EAddOnProductCode.COLLABORATING_PHYSICIAN_EPRESCRIPTION);
        const hasCollaboratingPhysicianAddOn = (cartCollaboratingPhysicianEprescriptionEpcs && cartCollaboratingPhysicianEprescriptionEpcs.productQty > 0) || (cartCollaboratingPhysicianEprescription && cartCollaboratingPhysicianEprescription.productQty > 0);
        if (productCode === EAddOnProductCode.COLLABORATING_PHYSICIAN_SEAT && hasCollaboratingPhysicianAddOn) {
            modal.confirm({
                title: null,
                icon: null,
                className: s.confirmModal,
                width: '650px',
                okText: 'Remove',
                onOk: () => {
                    clearAndResetData(productCode);
                },
                content: (
                    <div className={s.confirmWrap}>
                        <div className={s.confirmIcon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M11.9995 9V11M11.9995 15H12.0095M5.07134 19H18.9277C20.4673 19 21.4296 17.3333 20.6598 16L13.7316 4C12.9618 2.66667 11.0373 2.66667 10.2675 4L3.33929 16C2.56949 17.3333 3.53174 19 5.07134 19Z" stroke="#DC2626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <div className={s.confirm}>
                            <div className={s.confirmTitle}>Attention</div>
                            <div className={s.confirmContent}>
                                Removing collaborating physician seats will also remove the e-Prescription add-ons you added for them.
                                Are you sure you want to remove both?
                            </div>
                        </div>
                    </div>
                ),
            });
            return;
        }
        clearAndResetData(productCode);
    };

    const handleAddEFax = async () => {
        try {
            const addData = {
                addonService: {
                    eFaxOption: '1',
                },
            };
            setAddingFax(true);
            const result = await addToShoppingCart(
                EAddOnProductCode.SELF_EFAX,
                1,
                {
                    addOnService: addData.addonService,
                },
            );
            if (!result.error) {
                refetchCart();
                closeModal();
                // setPendingSelfEfax({
                //     addOnService: addData.addonService,
                // });
            }
        } catch (e) {

        }
        setAddingFax(false);
    };

    console.log({
        shoppingCart,
        pendingSelfEfax,
        pendingSelfEprescriptionEpcs,
        pendingSelfEprescription,
        pendingAssistantSeats,
        pendingAssistantEprescriptionEpcs,
        pendingAssistantEprescription,
        pendingCollaboratingPhysicianSeats,
        pendingCollaboratingPhysicianEprescription,
        pendingCollaboratingPhysicianEprescriptionEpcs,
    });

    const handleBackToHome = () => {
        navigate(`${PATH.DASHBOARD}/${PATH.EHR_HOME_PROVIDER}`);
    }

    const handleCheckNeedAddon = async () => {
        setCheckSettedAddonLoading(true);

        try {
            const res = await checkHasSetAddon();
            if (res && !res.error) {
                const needAddon = res.data?.data;

                if (!needAddon) {
                    setShowAddonUnSelectedTip(true);
                } else {
                    handleBackToHome();
                }
            } else {
                message.error(res.error)
            }
        } catch(e) {
            console.error(e);
        }

        setCheckSettedAddonLoading(false);
    }

    const handleSaveNoNeedAddon = async () => {
        setCheckSettedAddonLoading(true);

        try {
            const res = await submitNonCMDChannelSelected('HOMEPAGE_NEED_ADDON', 1);
            if (res && !res.error) {
                setShowAddonUnSelectedTip(false);
                handleBackToHome();
            } else {
                message.error(res.error)
            }
        } catch(e) {
            console.error(e);
        }

        setCheckSettedAddonLoading(false);
    }

    return (
        <Spin spinning={checkSettedAddonLoading}>
            <div className={s.wrap}>
                <Modal
                    width={780}
                    closable={false}
                    open={showAddonUnSelectedTip}
                    footer={
                        <div className={s.modalFooter}>
                            <Button onClick={() => {
                                handleSaveNoNeedAddon();
                            }}>I don’t need add-ons for now</Button>
                            <Button type="primary" onClick={() => {
                                setShowAddonUnSelectedTip(false);
                            }}>Review Add-ons</Button>
                        </div>
                    }
                >
                    <div className={s.warningModalWrap}>
                        <div className={s.tipIcon} />
                        <div className={s.content}>
                            <div className={s.title}>Important reminder before back to EHR</div>
                            <div className={s.descBox}>
                                <div className={s.desc}>You’re about to leave the Practice enablement add-ons page. If your practice requires medication prescribing or collaborating physicians, please ensure you’ve reviewed the add-ons carefully to enable these features in Kiwi EHR for launching your Klarity practice.</div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <div
                    onClick={handleCheckNeedAddon}
                    className={s.nav}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9.70711 16.7071C9.31659 17.0976 8.68342 17.0976 8.2929 16.7071L2.29289 10.7071C1.90237 10.3166 1.90237 9.68342 2.29289 9.29289L8.2929 3.29289C8.68342 2.90237 9.31659 2.90237 9.70712 3.29289C10.0976 3.68342 10.0976 4.31658 9.70712 4.70711L5.41422 9H17C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11L5.41422 11L9.70711 15.2929C10.0976 15.6834 10.0976 16.3166 9.70711 16.7071Z"
                            fill="currentColor"
                        />
                    </svg>
                    Back to EHR
                </div>
                <div className={s.header}>
                    <h3 className={s.title}>Practice enablement add-ons</h3>
                    <div className={s.btnWrap}>
                        <Button
                            onClick={() => {
                                window.open(`mailto:${Email.onboarding}`);
                            }}
                        >
                            Need help?
                        </Button>
                        <Button
                            onClick={() => {
                                navigate(`${PATH.DASHBOARD}/${PATH.EHR_CART}`);
                            }}
                            className={s.btn}
                            type="primary"
                            disabled={cartCount === 0}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                            >
                                <path
                                    d="M14.1667 10.8333H5.83333L4.5 4.16667H17.5L14.1667 10.8333Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M15.8333 15.8333C15.8333 16.7538 15.0871 17.5 14.1667 17.5C13.2462 17.5 12.5 16.7538 12.5 15.8333C12.5 14.9129 13.2462 14.1667 14.1667 14.1667C15.0871 14.1667 15.8333 14.9129 15.8333 15.8333Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M7.5 15.8333C7.5 16.7538 6.75381 17.5 5.83333 17.5C4.91286 17.5 4.16667 16.7538 4.16667 15.8333C4.16667 14.9129 4.91286 14.1667 5.83333 14.1667C6.75381 14.1667 7.5 14.9129 7.5 15.8333Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M2.5 2.5H4.16667L4.5 4.16667M5.83333 10.8333H14.1667L17.5 4.16667H4.5M5.83333 10.8333L4.5 4.16667M5.83333 10.8333L3.92259 12.7441C3.39762 13.269 3.76942 14.1667 4.51184 14.1667H14.1667M14.1667 14.1667C13.2462 14.1667 12.5 14.9129 12.5 15.8333C12.5 16.7538 13.2462 17.5 14.1667 17.5C15.0871 17.5 15.8333 16.7538 15.8333 15.8333C15.8333 14.9129 15.0871 14.1667 14.1667 14.1667ZM7.5 15.8333C7.5 16.7538 6.75381 17.5 5.83333 17.5C4.91286 17.5 4.16667 16.7538 4.16667 15.8333C4.16667 14.9129 4.91286 14.1667 5.83333 14.1667C6.75381 14.1667 7.5 14.9129 7.5 15.8333Z"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <span>Cart ({cartCount})</span>
                        </Button>
                    </div>
                </div>
                <div className={s.content}>
                    <div className={s.contentInner}>
                        <div className={s.tips}>
                            The following add-ons are optional. You are free to use your own
                            e-Fax, e-Prescription, and EPCS software as long as you document
                            your clinical decisions in the Kiwi EHR. However, our Practice
                            enablement add-ons are natively integrated and work seamlessly
                            with Kiwi EHR.
                        </div>
                    </div>
                    <Spin className={s.addOnWrap} spinning={loadingCart}>
                        <div className={s.addOnTitle}>Add-ons for yourself </div>
                        <div className={s.itemWrap}>
                            {selfItems.map((item: EhrAddOnItem) => {
                                let hasAdd = false;
                                let addedCount = 0;
                                const targetShoppingCartItem: TShoppingCartItem | undefined =
                                    shoppingCart?.items?.find(
                                        (c) => c.productCode === item.productCode,
                                    );
                                if (
                                    targetShoppingCartItem &&
                                    targetShoppingCartItem.productQty > 0
                                ) {
                                    hasAdd = true;
                                    addedCount = targetShoppingCartItem.productQty;
                                }
                                return (
                                    <div className={s.item} key={item.id}>
                                        { item.productCode === 'ADD-ON-P001' && ePrescriptionNEPCSShowRecommandTag && <EHRAvaRecommandLabel />}
                                        <div className={s.itemName}>{item.productName}</div>
                                        <div className={s.itemPrice}>${item.price}/month</div>
                                        <div className={s.itemDes}>{item.description}</div>
                                        <div className={s.btnWrap}>
                                            {item.activeStatus && (
                                                <div className={s.statusWrap}>
                                                    <div className={s.statusItem}>
                                                        <EhrActiveStatus status={item.activeStatus || ''} />
                                                    </div>
                                                    <div className={s.statusItem}>
                                                        <EhrPaymentStatus
                                                            status={item.paymentStatus || ''}
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                            {hasAdd && !item.activeStatus && (
                                                <>
                                                    <Button
                                                        className={s.btn}
                                                        icon={
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="16"
                                                                viewBox="0 0 16 16"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M13.3661 4.23431C13.6785 4.54673 13.6785 5.05327 13.3661 5.36569L6.96608 11.7657C6.65366 12.0781 6.14713 12.0781 5.83471 11.7657L2.63471 8.56569C2.32229 8.25327 2.32229 7.74673 2.63471 7.43431C2.94712 7.1219 3.45366 7.1219 3.76608 7.43431L6.40039 10.0686L12.2347 4.23431C12.5471 3.9219 13.0537 3.9219 13.3661 4.23431Z"
                                                                    fill="white"
                                                                />
                                                            </svg>
                                                        }
                                                        disabled
                                                    >
                                                        Added
                                                    </Button>
                                                    <Button
                                                        type="link"
                                                        loading={
                                                            removingFlagRef.current[item.productCode] || false
                                                        }
                                                        onClick={() => {
                                                            handleRemove(item.productCode);
                                                        }}
                                                        className={s.btnText}
                                                    >
                                                        Remove
                                                    </Button>
                                                </>
                                            )}
                                            {!hasAdd && !item.activeStatus && (
                                                <>
                                                    <Button
                                                        onClick={() => {
                                                            if (
                                                                item.productCode === EAddOnProductCode.SELF_EFAX
                                                            ) {
                                                                handleAddEFax();
                                                            } else {
                                                                showModal(item.productCode);
                                                            }
                                                        }}
                                                        loading={
                                                            addingFax &&
                                                            item.productCode === EAddOnProductCode.SELF_EFAX
                                                        }
                                                        className={s.btn}
                                                    >
                                                        Add
                                                    </Button>
                                                    <Button
                                                        className={s.btnText}
                                                        type="link"
                                                        onClick={() => {
                                                            showModal(item.productCode, true);
                                                        }}
                                                    >
                                                        Learn more
                                                    </Button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className={s.addOnTitle}>
                            Add-ons for your collaborating physician
                        </div>
                        <div className={s.itemWrap}>
                            {collaboratorItems.map((item: EhrAddOnItem) => {
                                let hasAdd = false;
                                let addedCount = 0;
                                const targetShoppingCartItem: TShoppingCartItem | undefined =
                                    shoppingCart?.items?.find(
                                        (c) => c.productCode === item.productCode,
                                    );
                                if (
                                    targetShoppingCartItem &&
                                    targetShoppingCartItem.productQty > 0
                                ) {
                                    hasAdd = true;
                                    addedCount = targetShoppingCartItem.productQty;
                                }

                                // if (item.productCode === EAddOnProductCode.COLLABORATING_PHYSICIAN_SEAT) {
                                //     addedCount = pendingCollaboratingPhysicianSeats?.length || 0;
                                //     hasAdd = addedCount > 0;
                                // }
                                // if (item.productCode === EAddOnProductCode.COLLABORATING_PHYSICIAN_EPRESCRIPTION_EPCS) {
                                //     addedCount = pendingCollaboratingPhysicianEprescriptionEpcs?.length || 0;
                                //     hasAdd = addedCount > 0;
                                // }
                                // if (item.productCode === EAddOnProductCode.COLLABORATING_PHYSICIAN_EPRESCRIPTION) {
                                //     addedCount = pendingCollaboratingPhysicianEprescription?.length || 0;
                                //     hasAdd = addedCount > 0;
                                // }
                                return (
                                    <div className={s.item} key={item.id}>
                                        { item.productCode === 'ADD-ON-P004' && collaboratingPhysicianSeatShowRecommandTag && <EHRAvaRecommandLabel />}
                                        <div className={s.itemName}>{item.productName}</div>
                                        <div className={s.itemPrice}>${item.price}/month</div>
                                        <div className={s.itemDes}>{item.description}</div>
                                        <div className={s.btnWrap}>
                                            {hasAdd && (
                                                <>
                                                    <span className={s.btnLabel}>{addedCount} seats</span>
                                                    <Button
                                                        className={s.btn}
                                                        icon={
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="16"
                                                                viewBox="0 0 16 16"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M13.3661 4.23431C13.6785 4.54673 13.6785 5.05327 13.3661 5.36569L6.96608 11.7657C6.65366 12.0781 6.14713 12.0781 5.83471 11.7657L2.63471 8.56569C2.32229 8.25327 2.32229 7.74673 2.63471 7.43431C2.94712 7.1219 3.45366 7.1219 3.76608 7.43431L6.40039 10.0686L12.2347 4.23431C12.5471 3.9219 13.0537 3.9219 13.3661 4.23431Z"
                                                                    fill="white"
                                                                />
                                                            </svg>
                                                        }
                                                        disabled
                                                    >
                                                        Added
                                                    </Button>
                                                    <Button
                                                        type="link"
                                                        loading={
                                                            removingFlagRef.current[item.productCode] || false
                                                        }
                                                        onClick={() => {
                                                            handleRemove(item.productCode);
                                                        }}
                                                        className={s.btnText}
                                                    >
                                                        Remove
                                                    </Button>
                                                </>
                                            )}
                                            {!hasAdd && (
                                                <Button
                                                    onClick={() => {
                                                        showModal(item.productCode);
                                                    }}
                                                    className={s.btn}
                                                >
                                                    Add
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className={s.addOnTitle}>Add-ons for your assistant</div>
                        <div className={s.itemWrap}>
                            {assistantItems.map((item: EhrAddOnItem) => {
                                let hasAdd = false;
                                let addedCount = 0;
                                const targetShoppingCartItem: TShoppingCartItem | undefined =
                                    shoppingCart?.items?.find(
                                        (c) => c.productCode === item.productCode,
                                    );
                                if (
                                    targetShoppingCartItem &&
                                    targetShoppingCartItem.productQty > 0
                                ) {
                                    hasAdd = true;
                                    addedCount = targetShoppingCartItem.productQty;
                                }

                                // if (item.productCode === EAddOnProductCode.ASSISTANT_SEAT) {
                                //     addedCount = pendingAssistantSeats?.length || 0;
                                //     hasAdd = addedCount > 0;
                                // }
                                // if (item.productCode === EAddOnProductCode.ASSISTANT_EPRESCRIPTION_EPCS) {
                                //     addedCount = pendingAssistantEprescriptionEpcs?.length || 0;
                                //     hasAdd = addedCount > 0;
                                // }
                                // if (item.productCode === EAddOnProductCode.ASSISTANT_EPRESCRIPTION) {
                                //     addedCount = pendingAssistantEprescription?.length || 0;
                                //     hasAdd = addedCount > 0;
                                // }
                                return (
                                    <div className={s.item} key={item.id}>
                                        <div className={s.itemName}>{item.productName}</div>
                                        <div className={s.itemPrice}>${item.price}/month</div>
                                        <div className={s.itemDes}>{item.description}</div>
                                        <div className={s.btnWrap}>
                                            {hasAdd && (
                                                <>
                                                    <span className={s.btnLabel}>{addedCount} seats</span>
                                                    <Button
                                                        className={s.btn}
                                                        icon={
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="16"
                                                                viewBox="0 0 16 16"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M13.3661 4.23431C13.6785 4.54673 13.6785 5.05327 13.3661 5.36569L6.96608 11.7657C6.65366 12.0781 6.14713 12.0781 5.83471 11.7657L2.63471 8.56569C2.32229 8.25327 2.32229 7.74673 2.63471 7.43431C2.94712 7.1219 3.45366 7.1219 3.76608 7.43431L6.40039 10.0686L12.2347 4.23431C12.5471 3.9219 13.0537 3.9219 13.3661 4.23431Z"
                                                                    fill="white"
                                                                />
                                                            </svg>
                                                        }
                                                        disabled
                                                    >
                                                        Added
                                                    </Button>
                                                    <Button
                                                        type="text"
                                                        loading={
                                                            removingFlagRef.current[item.productCode] || false
                                                        }
                                                        onClick={() => {
                                                            handleRemove(item.productCode);
                                                        }}
                                                        className={s.link}
                                                    >
                                                        Remove
                                                    </Button>
                                                </>
                                            )}
                                            {!hasAdd && (
                                                <Button
                                                    onClick={() => {
                                                        showModal(item.productCode);
                                                    }}
                                                    className={s.btn}
                                                >
                                                    Add
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </Spin>
                </div>
                <div className={s.modalWrap}>
                    <SelfEPrescriptionAndEpcsModal
                        item={
                            productCodeAddOnItemMap?.[
                            EAddOnProductCode.SELF_EPRESCRIPTION_EPCS
                            ]
                        }
                        open={!!showModalMap[EAddOnProductCode.SELF_EPRESCRIPTION_EPCS]}
                        onAdd={() => {
                            refetchCart();
                            closeModal();
                        }}
                        onClose={() => {
                            closeModal();
                        }}
                        clearFlag={
                            clearFlagRef.current[EAddOnProductCode.SELF_EPRESCRIPTION_EPCS]
                        }
                        isShowMore={
                            !!isShowMoreMap[EAddOnProductCode.SELF_EPRESCRIPTION_EPCS]
                        }
                    />
                    <SelfEPrescriptionModal
                        item={
                            productCodeAddOnItemMap?.[EAddOnProductCode.SELF_EPRESCRIPTION]
                        }
                        open={!!showModalMap[EAddOnProductCode.SELF_EPRESCRIPTION]}
                        onAdd={() => {
                            refetchCart();
                            closeModal();
                        }}
                        onClose={() => {
                            closeModal();
                        }}
                        clearFlag={
                            clearFlagRef.current[EAddOnProductCode.SELF_EPRESCRIPTION]
                        }
                        isShowMore={!!isShowMoreMap[EAddOnProductCode.SELF_EPRESCRIPTION]}
                    />
                    <SelfEFaxModal
                        item={productCodeAddOnItemMap?.[EAddOnProductCode.SELF_EFAX]}
                        open={!!showModalMap[EAddOnProductCode.SELF_EFAX]}
                        onAdd={() => {
                            handleAddEFax();
                            closeModal();
                        }}
                        onClose={() => {
                            closeModal();
                        }}
                        clearFlag={clearFlagRef.current[EAddOnProductCode.SELF_EFAX]}
                    />
                    <SelfEFaxWarning
                        clearFlag={resetEfaxModal}
                        open={showEfaxWarning}
                        onChange={({ isRemoveEFaxNumber }) => {
                            if (isRemoveEFaxNumber) {
                                clearAndResetData(EAddOnProductCode.SELF_EFAX);
                            } else {
                                refetchCart();
                            }
                        }}
                        onClose={() => {
                            setShowEfaxWarning(false);
                            setResetEfaxModal({});
                        }}
                    />

                    <CollaboratingPhysicianSeatModal
                        item={
                            productCodeAddOnItemMap?.[
                            EAddOnProductCode.COLLABORATING_PHYSICIAN_SEAT
                            ]
                        }
                        open={
                            !!showModalMap[EAddOnProductCode.COLLABORATING_PHYSICIAN_SEAT]
                        }
                        onAdd={(data: TCollaboratingPhysician[]) => {
                            console.log('on Add');
                            setPendingCollaboratingPhysicianSeats(data);
                            refetchCart();
                            setCollaboratingPhysicianSeatsChangeFlag({});
                            closeModal();
                        }}
                        onClose={() => {
                            closeModal();
                        }}
                        clearFlag={
                            clearFlagRef.current[
                            EAddOnProductCode.COLLABORATING_PHYSICIAN_SEAT
                            ]
                        }
                    />

                    <CollaboratingPhysicianEPrescriptionAndEpcsModal
                        item={
                            productCodeAddOnItemMap?.[
                            EAddOnProductCode.COLLABORATING_PHYSICIAN_EPRESCRIPTION_EPCS
                            ]
                        }
                        open={
                            !!showModalMap[
                            EAddOnProductCode.COLLABORATING_PHYSICIAN_EPRESCRIPTION_EPCS
                            ]
                        }
                        onAdd={(data?: TCollaboratingPhysician[]) => {
                            setPendingCollaboratingPhysicianEprescriptionEpcs(data);
                            refetchCart();
                            closeModal();
                        }}
                        onClose={() => {
                            closeModal();
                        }}
                        productLimitChangeFlag={collaboratingPhysicianSeatsChangeFlag}
                        clearFlag={
                            clearFlagRef.current[
                            EAddOnProductCode.COLLABORATING_PHYSICIAN_EPRESCRIPTION_EPCS
                            ]
                        }
                    />
                    <CollaboratingPhysicianEPrescriptionModal
                        item={
                            productCodeAddOnItemMap?.[
                            EAddOnProductCode.COLLABORATING_PHYSICIAN_EPRESCRIPTION
                            ]
                        }
                        open={
                            !!showModalMap[
                            EAddOnProductCode.COLLABORATING_PHYSICIAN_EPRESCRIPTION
                            ]
                        }
                        onAdd={(data?: TCollaboratingPhysician[]) => {
                            setPendingCollaboratingPhysicianEprescription(data);
                            refetchCart();
                            closeModal();
                        }}
                        productLimitChangeFlag={collaboratingPhysicianSeatsChangeFlag}
                        onClose={() => {
                            closeModal();
                        }}
                        clearFlag={
                            clearFlagRef.current[
                            EAddOnProductCode.COLLABORATING_PHYSICIAN_EPRESCRIPTION
                            ]
                        }
                    />

                    <AssistantSeatModal
                        item={productCodeAddOnItemMap?.[EAddOnProductCode.ASSISTANT_SEAT]}
                        open={!!showModalMap[EAddOnProductCode.ASSISTANT_SEAT]}
                        onAdd={(data: TAssistant[]) => {
                            setPendingAssistantSeats(data);
                            refetchCart();
                            closeModal();
                        }}
                        onClose={() => {
                            closeModal();
                        }}
                        clearFlag={clearFlagRef.current[EAddOnProductCode.ASSISTANT_SEAT]}
                    />
                    <AssistantEPrescriptionAndEpcsModal
                        item={
                            productCodeAddOnItemMap?.[
                            EAddOnProductCode.ASSISTANT_EPRESCRIPTION_EPCS
                            ]
                        }
                        productLimitChangeFlag={assistantSeatsChangeFlag}
                        open={
                            !!showModalMap[EAddOnProductCode.ASSISTANT_EPRESCRIPTION_EPCS]
                        }
                        onAdd={(data?: TAssistant[], close?: boolean) => {
                            setPendingAssistantEprescriptionEpcs(data);
                            refetchCart();
                            if (close) {
                                closeModal();
                            }
                        }}
                        onClose={() => {
                            closeModal();
                        }}
                        clearFlag={
                            clearFlagRef.current[
                            EAddOnProductCode.ASSISTANT_EPRESCRIPTION_EPCS
                            ]
                        }
                    />
                    <AssistantEPrescriptionModal
                        item={
                            productCodeAddOnItemMap?.[
                            EAddOnProductCode.ASSISTANT_EPRESCRIPTION
                            ]
                        }
                        productLimitChangeFlag={assistantSeatsChangeFlag}
                        open={!!showModalMap[EAddOnProductCode.ASSISTANT_EPRESCRIPTION]}
                        onAdd={(data?: TAssistant[], close?: boolean) => {
                            setPendingAssistantEprescription(data);
                            refetchCart();
                            if (close) {
                                closeModal();
                            }
                        }}
                        onClose={() => {
                            closeModal();
                        }}
                        clearFlag={
                            clearFlagRef.current[EAddOnProductCode.ASSISTANT_EPRESCRIPTION]
                        }
                    />
                    {contextHolder}
                </div>
            </div>
        </Spin>
    );
};

export default EhrProviderAddOnPage;
