import React from 'react';
import { Modal, Spin } from 'antd';
import s from './s.module.less';
import { Prospect } from 'types/common';
import useSurveyHistory from 'hooks/useSurveyHistory';
import dayjs from 'utils/dayjs';
import { getTimeStr } from 'utils/calendar';

type Props = {
    show:boolean,
    onCancel?: ()=>void,
    item?: Prospect,
};

const SurveyHistoryModal = (props: Props) => {
    const { show, onCancel, item } = props;
    const [data, loading] = useSurveyHistory(item?.id);

    return (
        <Modal
            title="Survey histroy"
            open={show}
            onCancel={onCancel}
            footer={null}
        >
            <div className={s.wrap}>
                <Spin spinning={loading}>
                    {
                        data?.map((i) => {
                            let text = 'Survey sent';
                            if (i.isAnswered) {
                                text = 'Survey is answered';
                            }
                            return (
                                <div className={s.historyItem} key={i.id}>
                                    <div className={s.step} />
                                    <div className={s.stepContent}>
                                        <div className={s.time}>{getTimeStr(dayjs(i?.sendAt), 'MMMM D, YYYY')}</div>
                                        <div className={s.type}>{text}</div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </Spin>
            </div>
        </Modal>
    );
};

export default SurveyHistoryModal;
