import React from 'react';
import { Button, Checkbox, Input, message, Modal, Space, Popconfirm } from 'antd';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { useNavigate } from 'react-router-dom';
import useMyPatientList from 'hooks/useMyPatientList';
import { Prospect } from 'types/common';
import { CheckboxChangeEvent, CheckboxOptionType } from 'antd/lib/checkbox';
import { ColumnsType } from 'antd/es/table';
import { SearchOutlined, PhoneOutlined, FilterOutlined } from '@ant-design/icons';
import CommonTable from 'components/CommonTable';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import useMyListedChannels from 'hooks/useMyListedChannels';
import { getDisableSurveyCapNotice, getHasShowSurveyTooltips, setHasShowSurveyTooltips } from 'utils/localstore';
import SendSurveyNoteModal from '../SendSurveyNoteModal';
import { reputationSendSurvey } from 'api/provider';
import PatientTableEmpty from 'components/EmptyHolder/PatientTableEmpty';
import FormValidationHelper from 'utils/validation';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields } from 'types/enumerationData';
import { getReputationPatientsList } from 'api/survey';
import { isValidEmail } from 'utils/form';
import DataUpdatedFlagStore from 'store/DataUpdatedFlag';

type Props = {
    show:boolean,
    onCannel?: ()=>void,
    onClickAddPatient?: ()=>void,
};

const SendSurveyModal = (props: Props) => {
    const { show, onClickAddPatient, onCannel } = props;
    const [showNote, setShowNote] = React.useState<boolean>(false);

    const [channels, loadingMychannel] = useMyListedChannels();
    const navigate = useNavigate();
    const [data, setData] = React.useState<Prospect[]>([]);
    const [selectKeys, setSelectKeys] = React.useState<React.Key[]>([]);

    const [showNameFilter, setShowNameFilter] = React.useState<boolean>(false);
    const [pendingNameFilter, setPendingNameFilter] = React.useState('');
    const [nameFilter, setNameFilter] = React.useState('');

    const [pendingEmailFilter, setPendingEmailFilter] = React.useState('');
    const [emailFilter, setEmailFilter] = React.useState('');

    //undefined: all
    const [showSurveySentFilter, setShowSurveySentFilter] = React.useState<boolean>(false);
    const [lastSurveySentFilter, setLastSurveySentFilter] = React.useState<string>();
    const [lastSendAnsweredFilter, setLastSendAnsweredFilter] = React.useState<boolean>();

    const [channelFilterCheckAll, setChannelFilterCheckAll] = React.useState<boolean>(false);
    const [channelFilter, setChannelFilter] = React.useState<number[]>([]);

    const disableSurveyCapNotice = getDisableSurveyCapNotice();
    const [showIntro, setShowIntro] = React.useState<number | undefined>();
    const [feedbackListPage, setFeedbackListPage] = React.useState(1);
    const [feedbackListPageSize, setFeedbackListPageSize] = React.useState(10);
    const [lastSurveySentOptionsData] = useGetSettingData(EnumFields.PATIENT_SURVEY_SEND_STATUS);
    const [loading, setLoading] = React.useState(false);
    const [, setDataUpdatedFlag] = DataUpdatedFlagStore.useStore();

    const [myPatientListData] = useMyPatientList();

    const fetchPatientsList = async () => {
        setLoading(true);
        try {
            //return all
            const res = await getReputationPatientsList({
                size: feedbackListPageSize,
                current: feedbackListPage,
            });

            if (res && !res.error && res.data?.data) {
                const record:Prospect[] = res.data?.data || [];

                setData(record || []);
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    React.useEffect(() => {
        fetchPatientsList();
    }, []);

    React.useEffect(() => {
        if (!show) {
            setChannelFilter([]);
            setNameFilter('');
            setEmailFilter('');
            setLastSurveySentFilter(undefined);
            setLastSendAnsweredFilter(undefined);
            setLoading(false);
            setSelectKeys([]);
        }
    }, [show]);

    React.useEffect(() => {
        fetchPatientsList();
    }, [myPatientListData]);

    React.useEffect(() => {
        if (!getHasShowSurveyTooltips() && show) {
            setTimeout(() => {
                setShowIntro(1);
            }, 500);
        }
    }, [show]);

    const handleSendSurvey = async () => {
        const patientData = data?.filter((item) => selectKeys.includes(item.id));

        const result = await reputationSendSurvey(selectKeys as number[]);
        if (!result.error) {
            message.success('Survey sent');
            setDataUpdatedFlag('patientListUpdated', {});
            setDataUpdatedFlag('surveyDataUpdated', {});
            setSelectKeys([]);
            fetchPatientsList();
        }
    };

    const handleClickSendSurvey = () => {
        if (!disableSurveyCapNotice) {
            //show confirm modal
            setShowNote(true);
        } else {
            handleSendSurvey();
        }
    };
    const lastSurveySentOptions:CheckboxOptionType[] = lastSurveySentOptionsData?.map((item:SettingEnum) => {
        return {
            label: item.content,
            value: item.dictKey,
        };
    }) || [];

    const lastSurveyRespondedOptions:CheckboxOptionType[] = [
        {
            label: 'No',
            value: false,
        },
        {
            label: 'Yes',
            value: true,
        },
    ];

    const channelFilterOptions:CheckboxOptionType[] = channels?.map((item) => {
        return {
            label: item.name,
            value: item.id,
        };
    }) || [];
    channelFilterOptions.push({
        label: 'Others',
        value: -1,
    });

    const handleChannelFilterCheckAll = (e:CheckboxChangeEvent) => {
        const check = !!e.target.checked;
        setChannelFilterCheckAll(e.target.checked);
        const checkedList = check ? channelFilterOptions.map((item) => item.value) : [];
        setChannelFilter(checkedList as number[]);
    };

    const handleChannelFilterChange = (values: CheckboxValueType[]) => {
        setChannelFilter(values as number[]);
        if (values?.length === channelFilterOptions?.length) {
            setChannelFilterCheckAll(true);
        } else {
            setChannelFilterCheckAll(false);
        }
    };

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectKeys(selectedRowKeys || []);
        },
        selectedRowKeys: selectKeys,
        getCheckboxProps: (record:Prospect) => {
            let disabled = true;
            const target = data.find((item) => item?.id === record?.id);
            if (target && target.email && isValidEmail(target.email)) {
                disabled = false;
            }
            return {
                disabled,
            };
        },
    };

    const getDataAfterFilter = () => {
        const retData:Prospect[] = data?.filter((item:Prospect) => {
            let ret = true;
            if (lastSurveySentFilter && ret) {
                ret = item.lastSurveySendStatus === lastSurveySentFilter;
            }
            if (lastSendAnsweredFilter !== undefined && ret) {
                ret = item.lastSendAnswered === lastSendAnsweredFilter;
            }
            if (nameFilter && ret) {
                ret = item?.firstName?.toLowerCase().includes(nameFilter) || item?.lastName?.toLowerCase().includes(nameFilter);
            }
            if (emailFilter !== undefined && ret) {
                ret = item?.email?.toLowerCase().includes(emailFilter);
            }
            // length > 0 means filter is on
            if (channelFilter && channelFilter.length > 0 && !channelFilterCheckAll && ret) {
                ret = !!item.channelId && channelFilter.includes(item.channelId as number);
            }
            return ret;
        });
        return retData;
    };

    const dataAfterFilter = getDataAfterFilter();

    const ToolTipsSearch = () => {
        return (
            <div className={s.toolTips}>
                <h4>Use the search functionality to quickly find a patient</h4>
                <div className={s.step}>
                    <span>Step 1 out of 2</span>
                    <Button
                        type="primary"
                        onClick={() => {
                            setShowIntro(2);
                        }}
                    >
                        Next
                    </Button>
                </div>
            </div>
        );
    };

    const ToolTipsFilter = () => {
        return (
            <div className={s.toolTips}>
                <h4>Use the filter functionality to change the available patients in the table.</h4>
                <div className={s.step}>
                    <span>Step 2 out of 2</span>
                    <Button
                        type="primary"
                        onClick={() => {
                            setShowIntro(undefined);
                            setHasShowSurveyTooltips('true');
                        }}
                    >
                        Done
                    </Button>
                </div>
            </div>
        );
    };

    const columns: ColumnsType<Prospect> = [
        {
            title: 'NAME',
            dataIndex: 'name',
            filterSearch: true,
            width: '220px',
            filterDropdownVisible: showNameFilter,
            onFilterDropdownVisibleChange: (visible) => {
                if (showIntro === 1) {
                    setShowNameFilter(false);
                    return;
                }
                setShowNameFilter(visible);
            },
            filterIcon: (
                <Popconfirm
                    placement="bottomLeft"
                    title={null}
                    description={<ToolTipsSearch />}
                    rootClassName={s.toolTipsWrap}
                    open={showIntro === 1}
                >
                    <SearchOutlined />
                </Popconfirm>
            ),
            filterDropdown: ({ close }) => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        value={pendingNameFilter}
                        onChange={(e) => {
                            setPendingNameFilter(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && pendingNameFilter) {
                                setNameFilter(pendingNameFilter);
                            }
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                setNameFilter(pendingNameFilter);
                                close();
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => {
                                setPendingNameFilter('');
                                setNameFilter('');
                                close();
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            render: (name:string, item:Prospect) => {
                return (
                    <div className={s.nameWrap}>
                        <div className={s.name}>
                            <span>{item.firstName} {item.lastName}</span>
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'EMAIL',
            dataIndex: 'email',
            filterSearch: true,
            filterIcon: <SearchOutlined />,
            filterDropdown: ({ close }) => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        value={pendingEmailFilter}
                        onChange={(e) => {
                            setPendingEmailFilter(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && pendingEmailFilter) {
                                setEmailFilter(pendingEmailFilter);
                            }
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                setEmailFilter(pendingEmailFilter);
                                close();
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => {
                                setPendingEmailFilter('');
                                setEmailFilter('');
                                close();
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            render: (name:string, item:Prospect) => {
                return (
                    <div className={s.nameWrap}>
                        <div className={s.nameRight}>
                            <div className={s.name}>{item.email}</div>
                            {
                                !loading && item.tel &&
                                <div className={s.itemWithIcon}>
                                    <PhoneOutlined />
                                    <p className={s.val}>{item.tel || 'No phone number provided'}</p>
                                </div>
                            }
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'CHANNEL',
            dataIndex: 'channel',
            width: '180px',
            filterDropdown: () => {
                return (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Space>
                            <Checkbox onChange={handleChannelFilterCheckAll} checked={channelFilterCheckAll}>
                                Check all
                            </Checkbox>
                            <Checkbox.Group options={channelFilterOptions} value={channelFilter} onChange={handleChannelFilterChange} />
                        </Space>
                    </div>
                );
            },
            render: (channel, item) => {
                return (
                    <div>{`${item.channelId}` === '-1' ? 'Others' : channel}</div>
                );
            },
        },
        {
            title: 'LAST SURVEY SENT',
            dataIndex: 'lastSurveySendStatus',
            width: '220px',
            filterDropdownVisible: showSurveySentFilter,
            onFilterDropdownVisibleChange: (visible) => {
                if (showIntro === 2) {
                    setShowSurveySentFilter(false);
                    return;
                }
                setShowSurveySentFilter(visible);
            },
            filterIcon: (
                <Popconfirm
                    placement="bottomRight"
                    title={null}
                    description={<ToolTipsFilter />}
                    rootClassName={s.toolTipsWrap}
                    open={showIntro === 2}
                >
                    <FilterOutlined />
                </Popconfirm>
            ),
            filterDropdown: ({ close }) => {
                return (
                    <div className={s.dropWrap}>
                        {
                            lastSurveySentOptions.map((item) => {
                                return (
                                    <div
                                        onClick={() => {
                                            setLastSurveySentFilter(item.value as string);
                                            close();
                                        }}
                                        className={s.dropItem}
                                        key={item.value as string}
                                    >
                                        {item.label}
                                    </div>
                                );
                            })
                        }
                        <div
                            onClick={() => {
                                setLastSurveySentFilter(undefined);
                                close();
                            }}
                            className={s.dropItem}
                        >All status
                        </div>
                    </div>
                );
            },
            render: (status) => {
                const item = lastSurveySentOptionsData?.find((op) => op.dictKey === status);
                const text = item ? item.content : status;
                return (
                    <div>{text || 'Never sent'}</div>
                );
            },
        },
        {
            title: 'LAST SURVEY RESPONDED',
            dataIndex: 'lastSendAnswered',
            width: '220px',
            filterDropdown: ({ close }) => {
                return (
                    <div className={s.dropWrap} onKeyDown={(e) => e.stopPropagation()}>
                        {
                            lastSurveyRespondedOptions.map((item) => {
                                return (
                                    <div
                                        onClick={() => {
                                            setLastSendAnsweredFilter(item.value as boolean);
                                            close();
                                        }}
                                        className={s.dropItem}
                                        key={item.value as string}
                                    >
                                        {item.label}
                                    </div>
                                );
                            })
                        }
                        <div
                            onClick={() => {
                                setLastSendAnsweredFilter(undefined);
                                close();
                            }}
                            className={s.dropItem}
                        >All status
                        </div>
                    </div>
                );
            },
            render: (status:boolean) => {
                return (
                    <div>{status ? 'Yes' : 'No'}</div>
                );
            },
        },
    ];
    return (
        <Modal
            title="Send survey"
            closable
            className={`${commonS.modalFixHeightWrap} ${commonS.modalFixSetedHeightWrap} ${s.modalWrap}`}
            width="1100px"
            onCancel={() => {
                setSelectKeys([]);
                onCannel?.();
            }}
            zIndex={90}
            onOk={handleClickSendSurvey}
            open={show}
            destroyOnClose
            okText="Send survey"
            okButtonProps={{
                disabled: !selectKeys.length,
            }}
            cancelButtonProps={{
                style: {
                    display: 'none',
                },
            }}
        >
            <div className={s.wrap}>
                <div className={s.header}>
                    <div className={s.left}>
                        {
                            selectKeys.length ?
                                <div className={s.item}>
                                    {selectKeys.length} patients selected
                                </div>
                                :
                                <div className={s.item}>Total {dataAfterFilter.length} patients</div>
                        }
                    </div>
                    <div className={s.right}>
                        <Button
                            onClick={onClickAddPatient}
                        >
                            Add patients
                        </Button>
                    </div>
                </div>
                <div className={s.tableWrap}>
                    <CommonTable
                        bordered
                        rowKey="id"
                        loading={loading}
                        columns={columns}
                        data={dataAfterFilter || []}
                        rowSelection={rowSelection}
                        locale={{
                            emptyText: <PatientTableEmpty />,
                        }}
                    />
                </div>
            </div>
            <SendSurveyNoteModal
                show={showNote}
                onCancel={() => {
                    setShowNote(false);
                }}
                onOk={() => {
                    handleSendSurvey();
                    setShowNote(false);
                }}
            />
        </Modal>
    );
};

export default SendSurveyModal;
