// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__cardItem--MI51U {
  width: 100%;
  border-radius: 16px;
  background: #FFF;
  padding: 16px 24px;
  box-sizing: border-box;
  height: 318px;
  overflow: auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/EhrProviderHomePage/components/SeatCard/s.module.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,sBAAA;EACA,aAAA;EACA,cAAA;AACF","sourcesContent":[".cardItem {\n  width: 100%;\n  border-radius: 16px;\n  background: #FFF;\n  padding: 16px 24px;\n  box-sizing: border-box;\n  height: 318px;\n  overflow: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardItem": `s-module__cardItem--MI51U`
};
export default ___CSS_LOADER_EXPORT___;
