import { message, Form, Input, Button, DatePicker } from 'antd';
import React from 'react';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { FormType } from './types';
import { License } from 'types/common';
import { SNAPSHOT_COLOR } from 'constants/common';
import StateSelect from 'components/form/StateSelect';
import FormValidationHelper from 'utils/validation';
import FileUpload from 'components/form/FileUpload';
import LicenseImg from 'components/LicenseImg';
import { renderChangUI } from 'types/changeHighLight';

type Props = {
    value: Partial<License>;
    onAdd: (item: License) => void;
    name: string;
    onRemove: () => void;
    onEditStatusChange: (status: boolean) => void;
};

const EditLicenses = (props: Props) => {
    const { name, value, onAdd, onRemove, onEditStatusChange } =
        props;
    const [form] = Form.useForm<FormType>();
    const [submiting, setSubmiting] = React.useState(false);
    const [isEdit, setIsEdit] = React.useState(!value.id);

    const reset = () => {
        form.resetFields();
    };

    React.useEffect(() => {
        onEditStatusChange(isEdit);
    }, [isEdit]);

    const handleOk = async () => {
        try {
            setSubmiting(true);
            const formValues = await form.validateFields();
            setIsEdit(false);
            onAdd?.({
                ...value,
                ...formValues,
            });
        } catch (e: any) {
            console.log(e);
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };

    React.useEffect(() => {
        if (value) {
            form.setFieldsValue({
                state: value.state,
                licenseNumber: value.licenseNumber,
                url: value.url,
                expireDate: value.expireDate,
            });
        }
    }, [value]);

    const onCancel = () => {
        if (value.state) {
            form.setFieldsValue({
                ...value,
                state: value.state,
                licenseNumber: value.licenseNumber,
                url: value.url,
                expireDate: value.expireDate,
            });
            setIsEdit(false);
        } else {
            onRemove();
        }
    };

    return (
        <div className={s.wrap}>
            <div className={s.title}>
                <div className={s.name}>{name}</div>
                <div className={s.action}>
                    {isEdit && (
                        <>
                            <Button className={s.btn} onClick={onCancel}>
                                Cancel
                            </Button>
                            <Button className={s.btn2} onClick={handleOk} type="primary">
                                Save
                            </Button>
                        </>
                    )}
                    {!isEdit && (
                        <>
                            <Button className={s.btn} onClick={onRemove}>
                                Remove
                            </Button>
                            <Button
                                className={s.btn}
                                onClick={() => {
                                    setIsEdit(true);
                                }}
                            >
                                Edit
                            </Button>
                        </>
                    )}
                </div>
            </div>
            {!isEdit && (
                <div className={s.formWrap}>
                    <div className={s.row}>
                        <div className={s.displayItem}>
                            <div className={s.label}>License state</div>
                            <div className={s.value}>{value.state}</div>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.displayItem}>
                            <div className={s.label}>DEA license number</div>
                            <div className={s.value}>{value.licenseNumber}</div>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.displayItem}>
                            <div className={s.label}>Expiration date</div>
                            <div className={s.value}>
                                {value?.expireDate?.format('MM-DD-YYYY')}
                            </div>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.displayItem}>
                            <div className={s.label}>Uploaded license</div>
                            <div className={`${s.value} ${s.imgWrap}`}>
                                <LicenseImg src={value.url} />
                            </div>
                            {renderChangUI('url', value, false, (val: string) => {
                                return (
                                    <div
                                        style={{
                                            border: `1px solid ${SNAPSHOT_COLOR}`,
                                            display: 'inline-block',
                                            marginTop: '16px',
                                        }}
                                    >
                                        <LicenseImg src={val} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
            {isEdit && (
                <div className={s.formWrap}>
                    <Form
                        form={form}
                        name="basic"
                        className={commonS.formStyle1}
                        initialValues={{}}
                        autoComplete="off"
                        layout="vertical"
                    >
                        <div className={s.row}>
                            <div className={s.rowItem} style={{ width: '100%' }}>
                                <Form.Item
                                    style={{ width: '100%' }}
                                    label="License state"
                                    name={['state']}
                                    validateFirst
                                    rules={[
                                        { required: true, message: 'License state is required' },
                                    ]}
                                >
                                    <StateSelect />
                                </Form.Item>
                            </div>
                        </div>
                        <div className={s.row}>
                            <div className={s.rowItem} style={{ width: '100%' }}>
                                <Form.Item
                                    style={{ width: '100%' }}
                                    label="DEA license number"
                                    name={['licenseNumber']}
                                    validateFirst
                                    rules={[
                                        {
                                            required: true,
                                            message: 'DEA license number is required',
                                        },
                                        FormValidationHelper.validateLicenseNumber(
                                            'License number must contain only letters or numbers',
                                        ),
                                    ]}
                                >
                                    <Input
                                        maxLength={25}
                                        placeholder="What is the DEA license number?  "
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className={s.row}>
                            <div className={s.rowItem} style={{ width: '100%' }}>
                                <Form.Item
                                    style={{ width: '100%' }}
                                    label="Expiration date"
                                    name={['expireDate']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Expiration date is required',
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        disabledDate={(date) => {
                                            const now = new Date().getTime();
                                            if (!date) {
                                                return false;
                                            }
                                            return date?.valueOf() < now;
                                        }}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className={s.row}>
                            <div className={s.rowItem} style={{ width: '100%' }}>
                                <Form.Item
                                    style={{ width: '500px' }}
                                    label="License copy"
                                    name={['url']}
                                    rules={[
                                        { required: true, message: 'License copy is required' },
                                    ]}
                                >
                                    <FileUpload />
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </div>
            )}
            {isEdit && (
                <div className={s.footerBtnWrap}>
                    <Button className={s.btn} onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button className={s.btn2} onClick={handleOk} type="primary">
                        Save
                    </Button>
                </div>
            )}
        </div>
    );
};

export default EditLicenses;
