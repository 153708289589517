// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-modules__title--Qvy9l {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 140% */
  letter-spacing: -0.5px;
  margin-bottom: 16px;
  margin-top: 48px;
}
.s-modules__item--Bm2AR {
  width: 910px;
  border-radius: 10px;
  border: 1px solid #EAECF0;
  background: #FFF;
  padding: 16px 36px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.s-modules__subTitle--CUiWq {
  color: var(--gray-900);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-right: 8px;
  letter-spacing: -0.16px;
}
.s-modules__subTitle--CUiWq .s-modules__step--QXvTZ {
  color: #00816B;
  margin-right: 8px;
}
.s-modules__description--eeaqX {
  color: #6B7280;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  margin-top: 16px;
  padding-left: 24px;
}
.s-modules__description--eeaqX li {
  list-style-type: disc;
  margin-bottom: 6px;
}
.s-modules__description--eeaqX li a {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/KlarityReviewsPage/components/IntroHomeView/components/Features/s.modules.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,SAAS;EACT,sBAAA;EACA,mBAAA;EACA,gBAAA;AACF;AAEA;EACE,YAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;EACA,kBAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AAAF;AAGA;EACE,sBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;EACA,uBAAA;AADF;AANA;EAUI,cAAA;EACA,iBAAA;AADJ;AAKA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EAHA,SAAS;EAKT,gBAAA;EACA,kBAAA;AAHF;AALA;EAWI,qBAAA;EACA,kBAAA;AAHJ;AATA;EAeM,0BAAA;AAHN","sourcesContent":[".title {\n  color: #000;\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 28px;\n  /* 140% */\n  letter-spacing: -0.5px;\n  margin-bottom: 16px;\n  margin-top: 48px;\n}\n\n.item {\n  width: 910px;\n  border-radius: 10px;\n  border: 1px solid #EAECF0;\n  background: #FFF;\n  padding: 16px 36px;\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 16px;\n}\n\n.subTitle {\n  color: var(--gray-900);\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 30px;\n  margin-right: 8px;\n  letter-spacing: -0.16px;\n\n  .step {\n    color: #00816B;\n    margin-right: 8px;\n  }\n}\n\n.description {\n  color: #6B7280;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 24px;\n  /* 150% */\n  margin-top: 16px;\n  padding-left: 24px;\n\n  li {\n    list-style-type: disc;\n    margin-bottom: 6px;\n\n    a {\n      text-decoration: underline;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `s-modules__title--Qvy9l`,
	"item": `s-modules__item--Bm2AR`,
	"subTitle": `s-modules__subTitle--CUiWq`,
	"step": `s-modules__step--QXvTZ`,
	"description": `s-modules__description--eeaqX`
};
export default ___CSS_LOADER_EXPORT___;
