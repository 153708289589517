import React from "react";
import s from './s.module.less';
import { calculateRemainingDays } from "utils/provider";
import { ClockCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

interface IProps {
    isCompleted?: boolean;
    completeTime?: string;
    startTime?: string;
}

const TimeRemaining = ({
    isCompleted,
    completeTime,
    startTime,
}: IProps) => {
    const remainingDays = calculateRemainingDays(startTime);

    if (isCompleted && completeTime) {
        return (
            <div className={s.timeLeft}>
                <div className={s.checkIcon}></div>
                <div className={s.timeText}>Completed on: {dayjs(completeTime).format('MM/DD/YYYY')}</div>
            </div>
        )
    } else if (typeof remainingDays !== 'number' && !remainingDays) {
        return (
            <div className={s.timeLeft}>
                <ClockCircleOutlined style={{color: '#9CA3AF'}} />
                <div className={s.timeText}>Time left: 3 days</div>
            </div>
        )
    } else if (remainingDays > -1) {
        return (
            <div className={s.timeLeft}>
                <div className={s.clockIcon}></div>
                <div className={s.timeText}>Time left: {remainingDays} days</div>
            </div>
        )
    } else {
        return (
            <div className={s.timeLeft}>
                <div className={s.warningIcon}></div>
                <div className={s.timeText}>Overdue by {Math.abs(remainingDays)} day</div>
            </div>
        )
    }

}

export default TimeRemaining;
