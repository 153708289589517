import React, { useEffect, useState } from 'react';
import s from './s.module.less';
import { Button, Form, Input } from 'antd';
import { Desktop } from 'assets/preview/Desktop';
import { Mobile } from 'assets/preview/Mobile';
import commonS from 'styles/common.module.less';
import os from 'assets/preview/OS.svg';
import surveyBanner from 'assets/survey/surveyBanner.png';
import dynamicIsland from 'assets/survey/dynamicIsland.png';
import RichText from 'components/RichText';
import debounce from 'lodash/debounce';
import { SurveyStepValue } from 'types/survey';
import { handleProviderNameReplacement } from 'utils/survey';
import { Provider } from 'types/provider';

type IFormData = {
    subject: string;
    content: string;
};

type IProps = {
    initFormValue: IFormData
    onNext: (data: IFormData) => void;
    providerData?: Provider;
};

const Invitation = ({
    initFormValue,
    providerData,
    onNext,
}: IProps) => {
    const [formInstance] = Form.useForm<IFormData>();
    const [isMobileMode, setIsMobileMode] = useState(false);
    const [email, setEmail] = useState('');

    useEffect(() => {
        if (initFormValue) {
            const subject = handleProviderNameReplacement(initFormValue.subject, providerData);
            const content = handleProviderNameReplacement(initFormValue.content, providerData);
            setEmail(content);
            formInstance.setFieldsValue({
                subject,
                content,
            });
        }
    }, [initFormValue, formInstance, providerData]);

    const handleFormValueChange = debounce((value: Partial<IFormData>, allValues: IFormData) => {
        setEmail(handleProviderNameReplacement(allValues.content, providerData));
    }, 500);

    return (
        <div className={s.wrap}>
            <div className={s.left}>
                <div className={s.title}>Survey invitation email template</div>
                <div className={s.subTitle}>Tailor your survey experience by editing the email subject and content.</div>
                <div className={s.body}>
                    <Form
                        id={SurveyStepValue.SURVEY_INVITATION}
                        form={formInstance}
                        name="surveyInvitation"
                        className={`${commonS.formStyle1} ${s.form}`}
                        autoComplete="off"
                        layout="vertical"
                        onValuesChange={handleFormValueChange}
                        onFinish={onNext}
                    >
                        <div className={s.contentLabel}>Subject</div>
                        <Form.Item
                        // label="Subject:"
                            name="subject"
                            rules={[
                                { required: true, message: 'Subject is a required field' },
                                { max: 300, message: 'Subject maximum character length of 300' },
                            ]}
                        >
                            <Input.TextArea showCount maxLength={100} className={s.textarea} style={{ resize: 'none' }} placeholder="From Dr. Smith, how was your healthcare visit with? Help us elevate your care experience!" />
                        </Form.Item>
                        <div className={s.contentLabel}>Email content</div>
                        <div className={s.emailBody}>
                            <div className={s.emailHeader}>Dear [Patient’s name],</div>
                            <Form.Item
                            // label="Email content"
                                name="content"
                                rules={[
                                    { required: true, message: 'Email content is a required field' },
                                    { max: 2000, message: 'Email content maximum character length of 2000' },
                                ]}
                            >
                                <RichText
                                    maxLength={2000}
                                    className={s.textarea}
                                />
                                {/* <Input.TextArea
                                className={s.textarea}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder={'Your experience with us matters. We\'re always striving to improve our service, and your feedback is instrumental in helping us achieve that goal.Please take a moment to share your thoughts by completing a short survey. It\'s quick, easy, and most importantly, your responses will directly contribute to making your future visits even better.'}
                            /> */}
                            </Form.Item>
                            <div className={s.emailFooter}>
                                <p>Warm regards,</p>
                                <p>{handleProviderNameReplacement('Kiwi Health in partnership with [Provider‘s Name]', providerData)}</p>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            <div className={s.preview}>
                <div className={s.modeWrap}>
                    <span
                        onClick={() => {
                            setIsMobileMode(false);
                        }}
                        className={isMobileMode ? '' : s.active}
                    >
                        <Desktop />
                    </span>
                    <span
                        onClick={() => {
                            setIsMobileMode(true);
                        }}
                        className={isMobileMode ? s.active : ''}
                    >
                        <Mobile />
                    </span>

                </div>
                <div
                    className={isMobileMode ? `${s.iframeWrap} ${s.iframeWrapMobile}` : s.iframeWrap}
                >
                    <div className={s.topBar}>
                        {
                            isMobileMode ?
                                <div className={s.mobileHeader}>
                                    <img className={s.island} src={dynamicIsland} />
                                </div>
                                : <img src={os} />
                        }
                    </div>
                    <div className={s.previewContent}>
                        <div className={s.scrollContent}>
                            <img src={surveyBanner} />
                            <div className={s.content}>
                                <div className={s.text}>
                                    <p>Dear [Patient Name],</p>
                                    <br />
                                    <div dangerouslySetInnerHTML={{ __html: email }} />
                                    <br />
                                    <Button type="primary" className={s.surveyButton}>Open survey</Button>
                                    <br />
                                    <br />
                                    <p>Warm regards,</p>
                                    <p>{handleProviderNameReplacement('Kiwi Health in partnership with [Provider\'s Name]', providerData)}</p>
                                    <br />
                                    <br />
                                    {/* <p className={s.tip}>Please note: This email is for the intended recipient only. Kindly delete if received in error. Your feedback remains anonymous unless you allow the provider to follow up.  Anticipate an automated follow-up for additional input. Do not reply to this email. </p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Invitation;
