import React from 'react';
import { HELP_EMAIL } from 'constants/common';
import s from './s.module.less';

type IProps = {
    title: string;
};

const HelpEmail: React.FC<IProps> = ({ title }) => {
    return (
        <div>
            <div className={s.helpTipsTitle}>{title}</div>
            <a href={`mailto:${HELP_EMAIL}`} className={s.link}>
                Contact Klarity onboarding team
            </a>
        </div>
    );
};

export default HelpEmail;
