import React from 'react';
import s from './s.module.less';
import { Prospect, ProspectNotesItem } from 'types/common';
import { getTimeStr } from 'utils/calendar';
import dayjs from 'utils/dayjs';
import { Button, Spin, message } from 'antd';
import { useRequest } from 'ahooks';
import { createProspectNote, getProspectNoteList } from 'api/prospect';
import TextArea from 'antd/lib/input/TextArea';

type Props = {
    prospect?: Prospect
};

const Page = (props:Props) => {
    const { prospect } = props;
    const [content, setContent] = React.useState('');
    const { data, loading, run } = useRequest(getProspectNoteList, {
        manual: true,
    });
    const prospectId = prospect?.id;

    React.useEffect(() => {
        if (prospectId) {
            run(prospectId);
        }
    }, [prospectId]);

    const list:ProspectNotesItem[] = data?.data?.data || [];
    list.sort((a, b) => {
        return new Date(b.createTime).getTime() - new Date(a.createTime).getTime();
    });

    const sendEmail = async () => {
        const result = await createProspectNote(prospectId!, content);
        if (result.error) {
            //message.error(result.error);
        } else {
            message.success('Noted added');
            setContent('');
            run(prospectId!);
        }
    };
    return (
        <div className={s.wrap}>
            <Spin spinning={loading} style={{ height: 48, width: '100%', position: 'absolute' }} />
            <div className={s.listWrap}>
                {
                    list.map((msg:ProspectNotesItem, inx) => {
                        return (
                            <div className={s.msg}>
                                <div className={s.msgHeader}>
                                    <div className={s.left}>
                                        <div className={s.title}>{inx + 1}</div>
                                    </div>
                                    <div className={s.right}>
                                        {getTimeStr(dayjs(msg.createTime), 'dddd MMM DD, [at] HH:mm A')}
                                    </div>
                                </div>
                                <div className={s.msgContent}>
                                    {msg.notes}
                                </div>
                            </div>
                        );
                    })
                }
            </div>
            <div className={s.replyWrap}>
                <h4>New notes</h4>
                <TextArea
                    onChange={(e) => {
                        setContent(e.target.value);
                    }}
                    rows={3}
                    value={content}
                />
                <Button
                    disabled={!content}
                    className={s.btn}
                    type="primary"
                    onClick={sendEmail}
                >Add
                </Button>
            </div>
        </div>
    );
};

export default Page;
