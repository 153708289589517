import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import s from './s.module.less';
// import Logo from 'assets/logo/kiwihealth.png';
import Logo from 'assets/campain/blackfriday2024/kiwiLogo.png';
import ProgressBar from './components/ProgressBar';
import { Button, Spin, message } from 'antd';
import PracticeInfo from './components/PracticeInfo';
import Availability from './components/Availability';
import TaxInfo from './components/TaxInfo';
import Lab from './components/Lab';
import Successfully from './components/Successfully';
import useLoadBasicData from 'hooks/useLoadBasicData';
import useFetchProvider from 'hooks/useFetchProvider';
import useGetStepInfo from 'hooks/useGetStepInfo';
import { ESTEP } from './type';
import { onboardingSubmit } from 'api/ehr';
import { getUserId } from 'utils/localstore';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import { Email } from 'constants/contact';
import { EStepTypes } from 'types/common';

const EHROnboardingPage = () => {
    const navigate = useNavigate();
    const formIds = useRef<string[]>([
        'practiceInfoForm',
        'availabilityForm',
        'taxInfoForm',
        'labForm',
    ]);
    const [loadBaiscData] = useLoadBasicData();
    const [providerData, providerLoading] = useFetchProvider();
    const [stepInfo, stepInfoLoading] = useGetStepInfo(EStepTypes.EHR_SET_UP);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState(ESTEP.PRACTICE_INFO);
    const [formData, setFormData] = useState({
        practiceInfoForm: {
            practiceName: '',
            practicePhone: '',
            practiceLogo: '',
        },
        taxInfoForm: {
            taxIdType: '',
            taxId: '',
            w9Form: '',
        },
        labForm: {
            setUpLapOption: '',
        },
    });

    const providerId = getUserId();

    useEffect(() => {
        loadBaiscData();
    }, [loadBaiscData]);

    useEffect(() => {
        if (stepInfo?.currentStep) {
            setCurrentStep(parseInt(stepInfo.currentStep, 10) + 1);
        }
    }, [stepInfo]);

    useEffect(() => {
        if (providerData && providerData.practice) {
            const { practice } = providerData;

            setFormData({
                practiceInfoForm: {
                    practiceName: practice?.practiceName || '',
                    practicePhone: practice?.practicePhone || '',
                    practiceLogo: practice?.practiceLogo || '',
                },
                taxInfoForm: {
                    taxIdType: practice?.taxIdType || '',
                    taxId: practice?.taxId || '',
                    w9Form: practice?.w9Form || '',
                },
                labForm: {
                    setUpLapOption: practice?.setUpLapOption || '',
                },
            });
        }
    }, [providerData]);

    const handleExit = useCallback(() => {
        navigate(`${PATH.DASHBOARD}/${PATH.EHR_HOME_PROVIDER}`, { replace: true });
    }, [navigate]);

    const handleSubmited = useCallback(async (data: any, field: string) => {
        if (field === 'availabilityForm') {
            setSubmitLoading(true);
            try {
                const params = {
                    ...data,
                    providerId,
                };
                const updateStepRes = await onboardingSubmit({
                    stepTypeEnum: 'EHR_SET_UP',
                    step: currentStep.toString(),
                    data: params,
                });
                if (updateStepRes && !updateStepRes.error) {
                    setCurrentStep(currentStep + 1);
                } else {
                    message.error(updateStepRes?.error);
                }
            } catch (e) {
                console.error(e);
            }
            setSubmitLoading(false);
        } else {
            const newFormData = {
                ...formData,
                [field]: data,
            };

            setFormData(newFormData);

            setSubmitLoading(true);
            try {
                const res = await onboardingSubmit({
                    stepTypeEnum: 'EHR_SET_UP',
                    step: currentStep.toString(),
                    data: {
                        id: providerId!,
                        ...newFormData.practiceInfoForm,
                        ...newFormData.taxInfoForm,
                        ...newFormData.labForm,
                    },
                });

                if (res && !res.error) {
                    setCurrentStep(currentStep + 1);
                } else {
                    message.error(res?.error);
                }
            } catch (e) {
                console.error(e);
            }
            setSubmitLoading(false);
        }
    }, [currentStep, formData, providerId]);

    const commonProps = useMemo(() => {
        return {
            handleSubmited,
        };
    }, [handleSubmited]);

    const contentRender = useMemo(() => {
        if (stepInfo?.endFlag || currentStep === ESTEP.SUCCESSFULLY) {
            return <Successfully />;
        }

        if (currentStep === ESTEP.PRACTICE_INFO) {
            return <PracticeInfo
                formId={formIds.current[0]}
                {...formData.practiceInfoForm}
                {...commonProps}
            />;
        }

        if (currentStep === ESTEP.AVAILABILITY) {
            return <Availability
                formId={formIds.current[1]}
                {...commonProps}
            />;
        }
        if (currentStep === ESTEP.TAX_INFO) {
            return <TaxInfo
                formId={formIds.current[2]}
                {...formData.taxInfoForm}
                {...commonProps}
            />;
        }
        if (currentStep === ESTEP.LAB) {
            return <Lab
                formId={formIds.current[3]}
                {...formData.labForm}
                {...commonProps}
            />;
        }

        return null;
    }, [formData, stepInfo, currentStep, commonProps]);

    if (providerLoading || stepInfoLoading) {
        return <div className={s.spin}><Spin /></div>;
    }

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <img className={s.logo} src={Logo} alt="Kiwi Health logo" />
                <a className={s.needHelp} href={`mailto:${Email.onboarding}`}>Need Help?</a>
            </div>
            <div className={s.body}>
                {contentRender}
            </div>
            {
                (!stepInfo?.endFlag && currentStep !== ESTEP.SUCCESSFULLY) && (
                    <>
                        <ProgressBar step={currentStep - 1} />
                        <div className={s.footer}>
                            <div className={s.leftPart}>
                                {
                                    currentStep === ESTEP.PRACTICE_INFO ? (
                                        ''
                                    ) : (
                                        <Button
                                            onClick={() => setCurrentStep(currentStep - 1 > 0 ? currentStep - 1 : 0)}
                                        >Back
                                        </Button>
                                    )
                                }
                            </div>
                            <div className={s.rightPart}>
                                <Button
                                    form={formIds.current[currentStep - 1]}
                                    type="primary"
                                    htmlType="submit"
                                    loading={submitLoading}
                                >Next
                                </Button>
                            </div>
                        </div>
                    </>
                )

            }
        </div>
    );
};

export default EHROnboardingPage;
