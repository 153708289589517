// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--WKaCZ {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--gray-700, #374151);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.s-module__wrap--WKaCZ .s-module__ava--ZszyF {
  height: 10px;
  margin-left: 4px;
  margin-right: 4px;
}
.s-module__wrap--WKaCZ .s-module__like--E9eCP {
  margin-left: 28px;
  margin-right: 16px;
  cursor: pointer;
}
.s-module__wrap--WKaCZ .s-module__dislike--E5jQe {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/ProspectsDetailPage/components/message/AIMessageFeedback/s.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,+BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACF;AARA;EAUI,YAAA;EACA,gBAAA;EACA,iBAAA;AACJ;AAbA;EAgBI,iBAAA;EACA,kBAAA;EACA,eAAA;AAAJ;AAlBA;EAsBI,eAAA;AADJ","sourcesContent":[".wrap {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  color: var(--gray-700, #374151);\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 20px;\n\n  .ava {\n    height: 10px;\n    margin-left: 4px;\n    margin-right: 4px;\n  }\n\n  .like {\n    margin-left: 28px;\n    margin-right: 16px;\n    cursor: pointer;\n  }\n\n  .dislike {\n    cursor: pointer;\n  }\n\n  .dislikeActive,\n  .likeActive {\n    //background: var(--primary-color);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--WKaCZ`,
	"ava": `s-module__ava--ZszyF`,
	"like": `s-module__like--E9eCP`,
	"dislike": `s-module__dislike--E5jQe`
};
export default ___CSS_LOADER_EXPORT___;
