import React, { useState } from 'react';
import cx from 'classnames';
import s from '../GrowYourBusiness/s.module.less';
import c from './s.module.less';
import type { TTaskItem } from 'types/home';
import { Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';

type IProps = {
    isKlarity: boolean;
    task: TTaskItem;
    index: number;
};

const Index: React.FC<IProps> = ({
    isKlarity,
    task,
    index,
}) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleHideModal = () => {
        setShowModal(false);
    };

    const handleRouteKiwi = () => {
        navigate('/dashboard/reputation-management');
    };

    const handleRouteKlarity = () => {
        navigate('/dashboard/klarity-reviews');
    };

    const handleTask = () => {
        if (task.completed) {
            return;
        }
        setShowModal(true);
    };

    return (
        <>
            <div
                className={cx(s.listItem, task.completed ? s.completed : '')}
                onClick={() => handleTask()}
            >
                <div className={s.listInner}>
                    <i className={s.num}>{index + 1}</i>
                    <div className={s.content}>
                        <div className={s.goalTitle}>{task.taskName}</div>
                        <div className={s.goalDescription}>{task.description}</div>
                    </div>
                    <div className={s.arrow} />
                </div>
                {task.completeThreshold === 1 && (
                    <div className={s.reviewsTips}>
                        You're on your 1st streak. Keep it up!
                    </div>
                )}
                {task.completeThreshold === 2 && (
                    <div className={s.reviewsTips}>
                        You're on your 2nd streak. Keep it up!
                    </div>
                )}
                {task.completeThreshold === 3 && (
                    <div className={s.reviewsStarTips}>
                        You've reached a 3-month streak! Great job!
                    </div>
                )}
            </div>
            <Modal
                title="Earn your 5-star reviews 🌟"
                centered
                open={showModal}
                onCancel={handleHideModal}
                width={534}
                footer={null}
            >
                {isKlarity ? (
                    <div className={s.modalContent}>
                        <p>Aim to earn the following for a solid 3-month streak:</p>
                        <ul>
                            <li>One 5-star review</li>
                            <li>Average 4-star reviews</li>
                        </ul>
                        <p>
                            Note: The system automatically recalculates data for the
                            previous 3 months (excluding the current month).
                        </p>
                        <p>
                            Start your journey towards excellence today by consistently
                            delivering outstanding patient care. Focus on every aspect of
                            the patient experience, from initial contact to follow-up care.
                            Actively encourage satisfied patients to share their positive
                            experiences online.
                        </p>
                        <div className={s.buttonWrap}>
                            <Button type="primary" onClick={() => handleRouteKlarity()}>
                                Manage my Klarity reviews
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className={s.modalContent}>
                        <p>
                            Send out your patient survey and aim to earn the following for a
                            solid 3-month streak:
                        </p>
                        <ul>
                            <li>One 5-star review/ month</li>
                            <li>Average 4-star reviews/ month</li>
                        </ul>
                        <p>
                            Note: The system automatically recalculates data for the
                            previous 3 months (excluding the current month).
                        </p>
                        <p>
                            Start your journey towards excellence today by consistently
                            delivering outstanding patient care. Focus on every aspect of
                            the patient experience, from initial contact to follow-up care.
                            Actively encourage satisfied patients to share their positive
                            experiences online.
                        </p>
                        <div className={s.buttonWrap}>
                            <Button type="primary" onClick={() => handleRouteKiwi()}>
                                Send out patient survey
                            </Button>
                        </div>
                    </div>
                )}
            </Modal>
        </>
    );
};

export default Index;
