// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__buttonWrap--aLAb0 {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.s-module__header--S9IoO {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.s-module__header--S9IoO .s-module__statusNtime--9ieye {
  display: flex;
  align-items: center;
  gap: 8px;
}
.s-module__h2--PpXHV {
  font-family: Inter;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  text-align: left;
}
.s-module__content--sJBJC {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--gray-500);
}
.s-module__content--sJBJC .s-module__paragraph--jmo0a {
  margin-bottom: 8px;
}
.s-module__content--sJBJC ul {
  margin-top: 16px;
  margin-bottom: 56px;
}
.s-module__content--sJBJC ul li {
  margin-bottom: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/ProviderHomePage/components/KlarityInitStep1/s.module.less"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,eAAA;EACA,SAAA;AAFF;AAKA;EACE,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAHF;AADA;EAOI,aAAA;EACA,mBAAA;EACA,QAAA;AAHJ;AAMA;EACE,kBAAA;EACA,0BAAA;EACA,2BAAA;EACA,4BAAA;EACA,gBAAA;AAJF;AAMA;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,sBAAA;AAJF;AAFA;EASI,kBAAA;AAJJ;AALA;EAaI,gBAAA;EACA,mBAAA;AALJ;AATA;EAiBM,mBAAA;AALN","sourcesContent":[".wrap {\n}\n\n.buttonWrap {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 16px;\n}\n\n.header {\n  margin-bottom: 30px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  .statusNtime {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n  }\n}\n.h2 {\n  font-family: Inter;\n  font-size: 24px !important;\n  font-weight: 600 !important;\n  line-height: 20px !important;\n  text-align: left;\n}\n.content {\n  font-family: Inter;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 20px;\n  text-align: left;\n  color: var(--gray-500);\n\n  .paragraph {\n    margin-bottom: 8px;\n  }\n\n  ul {\n    margin-top: 16px;\n    margin-bottom: 56px;\n\n    li {\n      margin-bottom: 16px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonWrap": `s-module__buttonWrap--aLAb0`,
	"header": `s-module__header--S9IoO`,
	"statusNtime": `s-module__statusNtime--9ieye`,
	"h2": `s-module__h2--PpXHV`,
	"content": `s-module__content--sJBJC`,
	"paragraph": `s-module__paragraph--jmo0a`
};
export default ___CSS_LOADER_EXPORT___;
