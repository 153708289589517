// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--Jy8Rc {
  color: #333;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 146px);
  overflow: auto;
}
.s-module__wrap--Jy8Rc .s-module__btnWrap--cXOt3 {
  margin-bottom: 48px;
  margin-top: 8px;
}
.s-module__wrap--Jy8Rc .s-module__appWrap--GYiJl {
  margin-bottom: 36px;
}
.s-module__wrap--Jy8Rc .s-module__appWrap--GYiJl h3 {
  font-weight: bold;
}
.s-module__wrap--Jy8Rc .s-module__appWrap--GYiJl .s-module__item--Kvek5 {
  border: 1px solid #eee;
  border-radius: 8px;
  margin-top: 24px;
}
.s-module__wrap--Jy8Rc .s-module__appWrap--GYiJl .s-module__itemHeader--or2yy {
  padding: 6px 16px;
  border-bottom: 1px solid #eee;
  line-height: 30px;
  min-height: 30px;
  font-weight: bold;
}
.s-module__wrap--Jy8Rc .s-module__appWrap--GYiJl .s-module__itemContent--_E3pN {
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.s-module__wrap--Jy8Rc .s-module__appWrap--GYiJl .s-module__itemContentItem--CtQ4K {
  width: 30%;
}
.s-module__wrap--Jy8Rc .s-module__appWrap--GYiJl .s-module__itemContentItemLabel--qNBvB {
  min-height: 24px;
  color: #6B7280;
  line-height: 24px;
}
.s-module__wrap--Jy8Rc .s-module__appWrap--GYiJl .s-module__itemContentItemValue--qXqL8 {
  font-weight: 500;
  min-height: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/ProspectsDetailPage/components/appointment/s.module.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,cAAA;AACF;AANA;EAOI,mBAAA;EACA,eAAA;AAEJ;AAVA;EAWI,mBAAA;AAEJ;AAbA;EAaM,iBAAA;AAGN;AAhBA;EAgBM,sBAAA;EACA,kBAAA;EACA,gBAAA;AAGN;AArBA;EAqBM,iBAAA;EACA,6BAAA;EACA,iBAAA;EACA,gBAAA;EACA,iBAAA;AAGN;AA5BA;EA4BM,aAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;AAGN;AAnCA;EAmCM,UAAA;AAGN;AAtCA;EAsCM,gBAAA;EACA,cAAA;EACA,iBAAA;AAGN;AA3CA;EA2CM,gBAAA;EACA,gBAAA;AAGN","sourcesContent":[".wrap{\n  color: #333;\n  display: flex;\n  flex-direction: column;\n  height: calc(100vh - 146px);\n  overflow: auto;\n  .btnWrap{\n    margin-bottom: 48px;\n    margin-top: 8px;\n  }\n  .appWrap{\n    margin-bottom: 36px;\n    h3 {\n      font-weight: bold;\n    }\n    .item{\n      border: 1px solid #eee;\n      border-radius: 8px;\n      margin-top: 24px;\n    }\n    .itemHeader{\n      padding: 6px 16px;\n      border-bottom: 1px solid #eee;\n      line-height: 30px;\n      min-height: 30px;\n      font-weight: bold;\n    }\n    .itemContent{\n      padding: 16px;\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n      flex-wrap: wrap;\n    }\n    .itemContentItem{\n      width: 30%;\n    }\n    .itemContentItemLabel{\n      min-height: 24px;\n      color: #6B7280;\n      line-height: 24px;\n    }\n    .itemContentItemValue{\n      font-weight: 500;\n      min-height: 24px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--Jy8Rc`,
	"btnWrap": `s-module__btnWrap--cXOt3`,
	"appWrap": `s-module__appWrap--GYiJl`,
	"item": `s-module__item--Kvek5`,
	"itemHeader": `s-module__itemHeader--or2yy`,
	"itemContent": `s-module__itemContent--_E3pN`,
	"itemContentItem": `s-module__itemContentItem--CtQ4K`,
	"itemContentItemLabel": `s-module__itemContentItemLabel--qNBvB`,
	"itemContentItemValue": `s-module__itemContentItemValue--qXqL8`
};
export default ___CSS_LOADER_EXPORT___;
