import React, { useCallback, useEffect, useMemo, useState } from 'react';
import s from './s.module.less';
import { ChannelPayPlanTypeEnum } from 'types/channel';
import { Button, Popover } from 'antd';

interface IProps {
    disable?: boolean;
    checked?: boolean;
    name?: string;
    logo?: string;
    intro?: string;
    channelType: ChannelPayPlanTypeEnum;
    onChange: (value: boolean) => void;
    locked?: boolean;
}

const ChannelCard = ({
    locked,
    channelType,
    disable = true,
    checked,
    name = 'Google',
    logo = 'https://saas-prod-us.s3.us-east-2.amazonaws.com/prod/a5363e29-0a52-45ac-a4a1-6aa02d551367.png',
    intro = 'A mental health platform that connects directly to Kiwi Health. It is the easiest platform to bring in a large volume of new patients with a strong care coordination and patient support team.',
    onChange,
}: IProps) => {
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        setIsChecked(!!checked);
    }, [checked]);

    const handleCheck = useCallback(() => {
        if (!disable) {
            const value = !isChecked;

            setIsChecked(value);
            onChange(value);
        }
    }, [disable, isChecked, onChange]);

    const tagRender = useMemo(() => {
        if (channelType === ChannelPayPlanTypeEnum.FREE) {
            return {
                cls: s.free,
                text: 'Free to start',
            };
        } else if (channelType === ChannelPayPlanTypeEnum.PAID) {
            return {
                cls: s.paid,
                text: 'In paid plan',
            };
        } else if (channelType === ChannelPayPlanTypeEnum.PREMIUM) {
            return {
                cls: s.premium,
                text: 'In premium',
            };
        }

        return null;
    }, [channelType]);

    return (
        <div className={`${s.card} ${disable && s.disable} ${isChecked && s.checked}`} onClick={handleCheck}>
            <div className={s.topBox}>
                <div className={s.header}>
                    <div className={s.nameBox}>
                        <img className={s.logo} src={logo} />
                        <div className={s.channelName}>{name}</div>
                    </div>
                    {
                        disable ?
                            <div className={s.disableIcon} /> :
                            <div className={`${s.checkbox} ${isChecked ? s.checked : ''}`} />
                    }
                </div>
                <div className={s.content}>{intro}</div>
            </div>
            { locked && (
                <Popover
                    placement="bottomLeft"
                    content={
                        <div style={{ maxWidth: '350px', padding: '26px 32px' }}>
                            <div className={s.popoverTitle}>Upgrade to unlock the channel</div>
                            <p className={s.popoverContent}>This channel is only available to Klarity Plus Members.</p>
                            <Button type="primary">Upgrade to unlock</Button>
                        </div>
                    }
                >
                    <div className={s.lockedMask}>
                        <div className={s.lockIcon} />
                    </div>
                    <div className={s.popoverEl} />
                </Popover>
            )}
            <div className={s.bottomBox}>
                {tagRender && <div className={`${s.tag} ${tagRender.cls}`}>{tagRender.text}</div> }
                <div className={s.priceBox}>
                    <div className={s.originalPrice}>Price: $30/month</div>
                    <div className={s.price}>Free in your plan!</div>
                </div>
            </div>
        </div>
    );
};

export default ChannelCard;
