// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--PML3M {
  border-radius: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/ProviderHomePage/components/KiwiExpandYourReach/s.module.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ","sourcesContent":[".wrap {\n    border-radius: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--PML3M`
};
export default ___CSS_LOADER_EXPORT___;
