import React from 'react';
import { Modal } from 'antd';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import PatientUploadSelectPanel from 'components/PatientUploadSelectPanel';

type Props = {
    show:boolean,
    onCannel?: ()=>void,
    onClickDirectUpload?: ()=>void,
    onClickManualInput?: ()=>void
};

const AddPatientsOverviewModal = (props: Props) => {
    const { show, onClickDirectUpload, onCannel, onClickManualInput } = props;

    return (
        <Modal
            title="Add patients"
            closable
            className={commonS.modalFixHeightWrap}
            width="1100px"
            onCancel={() => {
                onCannel?.();
            }}
            footer={null}
            open={show}
            destroyOnClose
        >
            <div className={s.wrap}>
                <PatientUploadSelectPanel
                    onClickDirectUpload={onClickDirectUpload}
                    onClickManualInput={onClickManualInput}
                />
            </div>
        </Modal>
    );
};

export default AddPatientsOverviewModal;
