import React, { useCallback, useState } from 'react';
import {
    IProfileFormSubmit,
} from 'types/channelApplicationProvider';
import s from './s.module.less';
import { StepValue } from 'pages/CreateUniProfilePage/types';
import { NoticeType } from 'antd/es/message/interface';
import StatusCard from '../StatusCard';
import { Tabs } from 'antd';
import Notes from '../Notes';
import Assignee from '../Assignee';
import { UniprofileFormData } from 'types/provider';
import UniprofilePreview from 'components/UniprofilePreview';

type ISubmitData = Partial<IProfileFormSubmit>;

interface IProps {
    providerId: number;
    channelId: number;
    id: number;
    formData: UniprofileFormData;
    onSubmit: (value: ISubmitData, step: StepValue) => void;
    refetch: () => void;
    onShowMessage: (type: NoticeType, content: string) => void;
}

const Detail = ({
    providerId,
    channelId,
    id,
    formData,
    onSubmit,
    refetch,
    onShowMessage,
}: IProps) => {
    const [notesIsEditing, setNotesIsEditing] = useState(false);
    const [showNotesError, setShowNotesError] = useState(false);
    const [tabActiveKey, setTabActiveKey] = useState('1');

    const { profile, assignee = '', notes = '' } = formData;

    const tabItems = [
        {
            key: '1',
            label: 'Status',
            children: <StatusCard id={id} channelId={channelId} providerId={profile?.id!} value={formData?.profile?.auditStatus === 'Removed' ? 1 : 0} refetch={refetch} />,
        },
        {
            key: '2',
            label: 'Notes',
            children: <Notes id={id} notes={notes} channelId={channelId} providerId={providerId} isEditing={notesIsEditing} showError={showNotesError} onClick={(stats: boolean) => setNotesIsEditing(stats)} refetch={refetch} />,
        },
        {
            key: '3',
            label: 'Assignee',
            children: <Assignee id={id} assignee={assignee} channelId={channelId} providerId={providerId} refetch={refetch} />,
        },
    ].filter(Boolean);

    const handleTabClick = useCallback((key: string) => {
        setTabActiveKey(key);
    }, []);

    return (
        <div className={s.detailBox}>
            {
                profile?.id &&
                <div className={`${s.block} ${s.opsCardBox}`}>
                    <div className={s.cardInner}>
                        <h3>Ops panel</h3>
                        <Tabs activeKey={tabActiveKey} items={tabItems as any} onTabClick={handleTabClick} />
                    </div>
                </div>
            }

            <UniprofilePreview
                providerId={providerId}
                channelId={channelId}
                formData={formData}
                onSubmit={onSubmit}
                refetch={refetch}
                onShowMessage={onShowMessage}
                supportEdit={false}
            />
        </div>
    );
};

export default Detail;
