// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--_u1_i {
  min-height: 100vh;
}
.s-module__wrap--_u1_i .s-module__header--rSZUO {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  margin-bottom: 16px;
}
.s-module__wrap--_u1_i .s-module__title--qKq37 {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
}
.s-module__wrap--_u1_i .s-module__name--RDp1H {
  color: #15191E;
  font-size: 14px;
}
.s-module__wrap--_u1_i .s-module__moveButton--Iqqc1:hover {
  background-color: transparent;
}
.s-module__wrap--_u1_i .s-module__moveColumn--afBu1 {
  transform: scale(1.143);
  margin-right: 8px;
  padding: 8px 0;
}
.s-module__wrap--_u1_i .ant-table-row,
.s-module__wrap--_u1_i .ant-table-placeholder {
  z-index: 10!important;
}
.s-module__tabWrap--_oFQz {
  margin-bottom: 20px;
}
.s-module__button--yxMqX {
  margin-right: 32px;
}
.s-module__button--yxMqX.s-module__active--MOZos,
.s-module__button--yxMqX:hover {
  border: 1px solid #00816B;
  background: #F0FCF6 !important;
}
.s-module__button--yxMqX.s-module__active--MOZos span,
.s-module__button--yxMqX:hover span {
  font-weight: 500;
  font-family: Inter;
  font-size: 14px;
  color: #00816B;
}
`, "",{"version":3,"sources":["webpack://./src/pages/operation/Configurations/components/FeaturedItem/s.module.less"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;AAFA;EAGM,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,YAAA;EACA,mBAAA;AAEN;AATA;EAUM,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAEN;AAfA;EAgBM,cAAA;EACA,eAAA;AAEN;AACM;EACE,6BAAA;AACR;AAtBA;EAyBM,uBAAA;EACA,iBAAA;EACA,cAAA;AAAN;AA3BA;;EA+BQ,qBAAA;AAAR;AAMA;EACE,mBAAA;AAJF;AAOA;EACE,kBAAA;AALF;AAME;;EACE,yBAAA;EACA,8BAAA;AAHJ;AACE;;EAII,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,cAAA;AADN","sourcesContent":[".wrap {\n    min-height: 100vh;\n    .header {\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n      height: 52px;\n      margin-bottom: 16px;\n    }\n    .title {\n      font-size: 20px;\n      font-weight: 500;\n      line-height: 28px;\n      text-align: left;\n    }\n    .name {\n      color: #15191E;\n      font-size: 14px;\n    }\n    .moveButton {\n      &:hover {\n        background-color: transparent;\n      }\n    }\n    .moveColumn {\n      transform: scale(1.143);\n      margin-right: 8px;\n      padding: 8px 0;\n    }\n    :global {\n      .ant-table-row, .ant-table-placeholder {\n        z-index: 10!important;\n      }\n    }\n\n}\n\n.tabWrap {\n  margin-bottom: 20px;\n}\n\n.button {\n  margin-right: 32px;\n  &.active, &:hover {\n    border: 1px solid #00816B;\n    background: #F0FCF6!important;\n    span {\n      font-weight: 500;\n      font-family: Inter;\n      font-size: 14px;\n      color: #00816B;\n\n    }\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--_u1_i`,
	"header": `s-module__header--rSZUO`,
	"title": `s-module__title--qKq37`,
	"name": `s-module__name--RDp1H`,
	"moveButton": `s-module__moveButton--Iqqc1`,
	"moveColumn": `s-module__moveColumn--afBu1`,
	"tabWrap": `s-module__tabWrap--_oFQz`,
	"button": `s-module__button--yxMqX`,
	"active": `s-module__active--MOZos`
};
export default ___CSS_LOADER_EXPORT___;
