import React from 'react';
import s from './s.module.less';
import { Button, Input, Space, Checkbox, Select, message, Modal, Switch } from 'antd';
import { SearchOutlined, PhoneOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { ColumnsType } from 'antd/es/table';
import { getProspectList, saveProspectSetting, updateProspectEhrByIds, updateProspectStatusByIds } from 'api/prospect';
import CommonTable from 'components/CommonTable';
import { PATH } from 'constants/path';
import AvaIcon from 'assets/common/ava.png';
import { useNavigate } from 'react-router-dom';
import { Channel, EStatus, EProspectType, Prospect } from 'types/common';
import { exportExcel } from 'utils/excel';
import { getTimeStr } from 'utils/calendar';
import dayjs from 'utils/dayjs';
import AddAndUpdateProspects from 'components/AddAndUpdateProspects';
import UpdateEmailModal from './UpdateEmailModal';
import Icons from './imgs';
import { getHasReadTermsForExport, setHasReadTermsForExport, getHasSetNotifyEmails, setHasSetNotifyEmails, setShowAvaSettingInProsepect, getShowAvaSettingInProsepect } from 'utils/localstore';
import { displayItem } from 'utils/common';
import SliderPanel from 'components/SliderPanel';
import icon from './imgs/icon.svg';
import useProviderNotifyEmails from 'hooks/useProviderNotifyEmails';
import type { CheckboxOptionType } from 'antd/lib/checkbox';
import SubscriptionStore from 'store/Subscription';
import useProviderServiceFlag from 'hooks/useProviderServiceFlag';
import { TSubscriptionType } from 'types/subscription';
import { Email } from 'constants/contact';
import { openKlaritySubscriptionModal } from 'utils/globalLayerControl';
import Frame from './imgs/frame.png';
import AvaConfigHome from './AvaConfigHome';
import useProspectSettingInfo from 'hooks/useProspectSettingInfo';

const CheckboxGroup = Checkbox.Group;

const allValue = 'All';

const Page = () => {
    const [page, setPage] = React.useState(1);
    const [show, setShow] = React.useState(false);

    const [getSubscription] = SubscriptionStore.useStore();
    const currentPlan = getSubscription('currentPlan');

    const navigate = useNavigate();
    const [pendingNameFilter, setPendingNameFilter] = React.useState('');
    const [nameFilter, setNameFilter] = React.useState('');
    const [statusFilter, setStatusFilter] = React.useState<string>('Active');
    const [typeFilter, setTypeFilter] = React.useState<string>(allValue);
    const [channelFilter, setChannelFilter] = React.useState<string>(allValue);
    const [showUpdateEmailModal, setShowUpdateEmailModal] = React.useState(false);

    const [ehrUpdating, setEhrUpdating] = React.useState(false);
    const [statusUpdating, setStatusUpdating] = React.useState(false);

    const { data, loading, run } = useRequest(getProspectList);
    const [selectKeys, setSelectKeys] = React.useState<React.Key[]>([]);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [checked, setChecked] = React.useState(false);

    const [showAvaModal, setShowAvaModal] = React.useState(!getShowAvaSettingInProsepect());
    const [showAvaHome, setShowAvaHome] = React.useState(false);

    const [notifyEmails, notifyEmailsLoading, fetchNotifyEmails] = useProviderNotifyEmails();
    const hasNotifyEmails = notifyEmails && notifyEmails.length > 0;

    const [collapse, setCollapse] = React.useState(!getHasSetNotifyEmails());
    const [hasInitCollapseStatus, setHasInitCollapseStatus] = React.useState(false);

    const [info, loadingInfo] = useProspectSettingInfo();
    const [isOpenAva, setIsOpenAva] = React.useState(false);
    const [showOpenAvaPannel, setShowOpenAvaPannel] = React.useState(false);
    const [switching, setSwitching] = React.useState(false);

    const [lastMessageStatusFilter, setLastMessageStatusFilter] =
        React.useState<string>();

    const [replyByFilter, setReplyByFilter] =
        React.useState<'PROVIDER' | 'AI'>();

    const listData: Prospect[] = data?.data?.data || [];

    let list: Prospect[] = listData;

    const [flag] = useProviderServiceFlag();
    const isKlarity = !!flag?.klarityServiceFlag;
    const isKlarityPro = currentPlan?.type === TSubscriptionType.ESSENTIAL;

    const lastMessageStatusOptions: CheckboxOptionType[] = [
        {
            label: 'Read',
            value: 1,
        },
        {
            label: 'Unread',
            value: 0,
        },
    ];

    const handleChangeAvaSwitch = async (check: boolean) => {
        const current = isOpenAva;
        setIsOpenAva(check);
        setSwitching(true);
        const result = await saveProspectSetting(null, check);
        if (!result.error) {
            message.success('Save successfully');
        } else {
            //roll back
            setIsOpenAva(current);
        }
        setSwitching(false);
    };

    // React.useEffect(() => {
    //     if (info) {
    //         setIsOpenAva(info.avaAutoReply);
    //         setShowOpenAvaPannel(!info.avaAutoReply)
    //     }
    // }, [info])

    React.useEffect(() => {
        if (notifyEmails !== undefined) {
            //loader from server
            if (notifyEmails.length === 0 && !hasInitCollapseStatus && collapse === false) {
                setCollapse(true);
                setHasInitCollapseStatus(true);
            }
        }
    }, [notifyEmails, hasInitCollapseStatus, collapse]);

    if (lastMessageStatusFilter !== undefined) {
        list = list.filter(
            (item: Prospect) =>
                `${item.isRead}` === `${lastMessageStatusFilter}`,
        );
    }

    const channelsFromData: Partial<Channel>[] = [];
    const addChannelIds: number[] = [];
    list.forEach((c: Prospect) => {
        if (!addChannelIds.includes(c.channelId as number)) {
            addChannelIds.push(c.channelId as number);
            channelsFromData.push({
                id: c.channelId as number,
                name: c.channel || 'Others',
            });
        }
    });

    if (
        nameFilter ||
        typeFilter !== allValue ||
        channelFilter !== allValue ||
        statusFilter !== allValue || replyByFilter
    ) {
        list = list.filter((item: Prospect) => {
            let flag = true;
            if (nameFilter) {
                flag = `${item.firstName} ${item.lastName} ${item.email}`
                    .toLowerCase()
                    .includes(nameFilter.toLowerCase());
            }

            if (typeFilter !== allValue && flag) {
                flag = item.type === typeFilter;
            }

            if (channelFilter !== allValue && flag) {
                flag = `${item.channelId}` === channelFilter;
            }

            if (statusFilter !== allValue && flag) {
                flag = item.status === statusFilter;
            }

            if (replyByFilter && flag) {
                flag = item.lastReplyBy?.toLowerCase() === replyByFilter.toLowerCase();
            }
            return flag;
        });
    }

    const channelsFromDataAfterFilter: Partial<Channel>[] = [];
    const addChannelIdsAfterFilter: number[] = [];
    list.forEach((c: Prospect) => {
        if (!addChannelIdsAfterFilter.includes(c.channelId as number)) {
            addChannelIdsAfterFilter.push(c.channelId as number);
            channelsFromDataAfterFilter.push({
                id: c.channelId as number,
                name: c.channel || 'Others',
            });
        }
    });

    React.useEffect(() => {
        setPage(1);
    }, [nameFilter, channelFilter, typeFilter]);

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectKeys(selectedRowKeys || []);
        },
        selectedRowKeys: selectKeys,
    };

    const selectItems = list.filter((item: Prospect) => selectKeys.includes(item.id));
    const hasSelect = selectItems.length > 0;
    let allActive = !!hasSelect;
    let allArchived = !!hasSelect;
    let allOnEhr = !!hasSelect;
    let allOffEhr = !!hasSelect;
    const showExport = !!hasSelect;
    selectItems?.forEach((item: Prospect) => {
        if (item.status === EStatus.Active) {
            allArchived = false;
        }
        if (item.status === EStatus.Archived) {
            allActive = false;
        }
        if (item.ehr === 'No') {
            allOnEhr = false;
        }
        if (item.ehr === 'Yes') {
            allOffEhr = false;
        }
    });

    const setEhr = async (val: 'Yes' | 'No') => {
        const result = await updateProspectEhrByIds(selectKeys as any, val);
        if (result.error) {
            //message.error(result.error);
        } else {
            const msg = val === 'Yes' ? 'Mark as on EHR successfully' : 'Removed from EHR successfully';
            message.success(msg);
            setSelectKeys([]);
            run();
        }
    };

    const setStatus = async (val: EStatus) => {
        const result = await updateProspectStatusByIds(selectKeys as any, val);
        if (result.error) {
            //message.error(result.error);
        } else {
            const msg = val === EStatus.Active ? 'Activate successfully' : 'Archived successfully';
            message.success(msg);
            setSelectKeys([]);
            run();
        }
    };

    const downloadExcel = () => {
        exportExcel({
            data: selectItems as any,
            excelName: 'Prospects list',
        });
        setSelectKeys([]);
    };

    const columns: ColumnsType<Prospect> = [
        {
            title: 'NAME',
            dataIndex: 'name',
            filterSearch: true,
            width: '200px',
            filterIcon: <SearchOutlined />,
            filterDropdown: () => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        value={pendingNameFilter}
                        onChange={(e) => {
                            setPendingNameFilter(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && pendingNameFilter) {
                                setNameFilter(pendingNameFilter);
                            }
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                setNameFilter(pendingNameFilter);
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => {
                                setPendingNameFilter('');
                                setNameFilter('');
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            render: (name: string, item: Prospect) => {
                return (
                    <div className={s.nameWrap}>
                        <div className={s.nameRight}>
                            <div className={s.name}>
                                {item.status === EStatus.Active && (
                                    <span className={s.activate} />
                                )}
                                {item.status === EStatus.Archived && (
                                    <span className={s.archive} />
                                )}
                                <span>
                                    {item.firstName} {item.lastName}
                                </span>
                            </div>
                            {/* <div className={s.itemWithIcon}>
                                <ClockCircleOutlined />
                                <a className={s.val} href={`mailto:${item.email}`}>{item.email}</a>
                            </div> */}
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'CONTACT',
            dataIndex: 'email',
            filterSearch: true,
            width: '250px',
            filterIcon: <SearchOutlined />,
            filterDropdown: () => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        value={pendingNameFilter}
                        onChange={(e) => {
                            setPendingNameFilter(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && pendingNameFilter) {
                                setNameFilter(pendingNameFilter);
                            }
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                setNameFilter(pendingNameFilter);
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => {
                                setPendingNameFilter('');
                                setNameFilter('');
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            render: (name: string, item: Prospect) => {
                return (
                    <div className={s.nameWrap}>
                        <div className={s.nameRight}>
                            <div className={s.name}>{item.email}</div>
                            {!loading && item.tel && (
                                <div className={s.itemWithIcon}>
                                    <PhoneOutlined />
                                    {/* <a className={s.val} href={`tel:${item.tel}`}>{item.tel}</a> */}
                                    <p className={s.val}>
                                        {displayItem(
                                            loading,
                                            'No phone number provided',
                                            item.tel,
                                        )}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'MESSAGE',
            dataIndex: 'isRead',
            width: '160px',
            filterDropdown: ({ close }) => {
                return (
                    <div className={s.dropWrap}>
                        {lastMessageStatusOptions.map((item) => {
                            return (
                                <div
                                    onClick={() => {
                                        setLastMessageStatusFilter(item.value as string);
                                        close();
                                    }}
                                    className={s.dropItem}
                                    key={item.value as string}
                                >
                                    {item.label}
                                </div>
                            );
                        })}
                        <div
                            onClick={() => {
                                setLastMessageStatusFilter(undefined);
                                close();
                            }}
                            className={s.dropItem}
                        >
                            All status
                        </div>
                    </div>
                );
            },
            render: (status) => {
                return <div>{status ? 'Read' : (<span className={s.unread}>Unread</span>)}</div>;
            },
        },
        // {
        //     title: 'CHANNEL',
        //     dataIndex: 'channel',
        //     width: '180px',
        //     //sorter: (a, b) => { return dayjs(a.createTime).valueOf() - dayjs(b.createTime).valueOf(); },
        //     render: (channel, item) => {
        //         return (
        //             <div>{`${item.channelId}` === '-1' ? 'Others' : channel}</div>
        //         );
        //     },
        // },
        {
            title: 'CREATE DATE',
            dataIndex: 'createTime',
            width: '160px',
            sorter: (a, b) => {
                return dayjs(a.createTime).valueOf() - dayjs(b.createTime).valueOf();
            },
            render: (createTime) => {
                return (
                    <div>
                        {createTime
                            ? getTimeStr(dayjs(createTime), 'MM/DD/YYYY')
                            : 'Nil'}
                    </div>
                );
            },
        },
        {
            title: 'LAST REPLIED',
            dataIndex: 'lastReplyAt',
            width: '160px',
            sorter: (a, b) => {
                if (a.lastReplyAt && !b.lastReplyAt) {
                    return 1;
                }
                if (!a.lastReplyAt && b.lastReplyAt) {
                    return -1;
                }
                if (!a.lastReplyAt && !b.lastReplyAt) {
                    return 0;
                }
                return dayjs(a.lastReplyAt).valueOf() - dayjs(b.lastReplyAt).valueOf();
            },
            render: (lastReplyAt) => {
                return (
                    <div>
                        {lastReplyAt
                            ? getTimeStr(dayjs(lastReplyAt), 'MM/DD/YYYY')
                            : '-'}
                    </div>
                );
            },
        },
        {
            title: 'REPLIED BY',
            dataIndex: 'lastReplyBy',
            width: '160px',
            filterDropdown: ({ close }) => {
                return (
                    <div className={s.dropWrap}>
                        <div
                            onClick={() => {
                                setReplyByFilter('PROVIDER');
                                close();
                            }}
                            className={s.dropItem}
                        >
                            You
                        </div>
                        <div
                            onClick={() => {
                                setReplyByFilter('AI');
                                close();
                            }}
                            className={s.dropItem}
                        >
                            Ava
                        </div>
                        <div
                            onClick={() => {
                                setReplyByFilter(undefined);
                                close();
                            }}
                            className={s.dropItem}
                        >
                            All
                        </div>
                    </div>
                );
            },
            render: (type: string) => {
                let label = '';
                if (type?.toLowerCase() === 'provider') {
                    label = 'You';
                }
                if (type?.toLowerCase() === 'ai') {
                    label = 'Ava';
                }
                return <div>{label}</div>;
            },
        },
        {
            title: 'TYPE',
            dataIndex: 'type',
            width: '150px',
            render: (type) => {
                return (
                    <>
                        {type === EProspectType.PATIENT ? (
                            <span className={`${s.statusP} ${s.statusPatient}`}>
                                <span className={s.icon} />
                                Patient
                            </span>
                        ) : (
                            <span className={s.statusP}>
                                <span className={s.icon} />
                                Prospect
                            </span>
                        )}
                    </>
                );
            },
        },
        {
            title: 'EHR',
            dataIndex: 'ehr',
            width: '100px',
            render: (ehr) => {
                return <div className={s.tag}>{ehr}</div>;
            },
        },
        {
            title: '',
            width: '120px',
            fixed: 'right',
            render: (item) => {
                return (
                    <div>
                        <Button
                            className={s.link}
                            onClick={() => {
                                navigate(
                                    `${PATH.DASHBOARD}/${PATH.PROSPECTS_DETAIL}/${item.id}`,
                                );
                            }}
                            type="text"
                        >
                            View
                        </Button>
                    </div>
                );
            },
        },
    ];

    const showKlarityPlanPannel = () => {
        openKlaritySubscriptionModal();
    };

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <div className={s.left}>
                    <h1>Prospects</h1>
                    <h3>{list?.length || ''} prospective patients</h3>
                </div>
                <div className={s.right}>
                    <Button
                        onClick={() => {
                            navigate(`${PATH.DASHBOARD}/${PATH.AVA_SETTING_PROSPECTS}`);
                        }}
                        style={{
                            marginRight: '16px',
                        }}
                    >
                        Settings
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            setShow(true);
                        }}
                    >
                        Create a new prospect
                    </Button>
                </div>
            </div>
            {
                isKlarity &&
                <div className={s.klarityNoteWrap}>
                    <div className={s.left}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M16.5 8C16.5 12.4183 12.9183 16 8.5 16C4.08172 16 0.5 12.4183 0.5 8C0.5 3.58172 4.08172 0 8.5 0C12.9183 0 16.5 3.58172 16.5 8ZM9.5 4C9.5 4.55228 9.05228 5 8.5 5C7.94772 5 7.5 4.55228 7.5 4C7.5 3.44772 7.94772 3 8.5 3C9.05228 3 9.5 3.44772 9.5 4ZM7.5 7C6.94772 7 6.5 7.44772 6.5 8C6.5 8.55229 6.94772 9 7.5 9V12C7.5 12.5523 7.94772 13 8.5 13H9.5C10.0523 13 10.5 12.5523 10.5 12C10.5 11.4477 10.0523 11 9.5 11V8C9.5 7.44772 9.05228 7 8.5 7H7.5Z" fill="#60A5FA" />
                        </svg>
                        <div className={s.info}>
                            {
                                isKlarityPro ?
                                    'All of your prospects are already handled by Kiwi’s virtual front desk team. You have 150 minutes call-time in your plan. To get more minutes, please contact Klarity support team.'
                                    :
                                    'All of your prospects will be handled by Kiwi’s virtual front desk team. You have 150 minutes call-time in total in your plan. To get more minutes, please explore other plans.'
                            }
                        </div>
                    </div>
                    <div className={s.right}>
                        {
                            !isKlarityPro &&
                            <span
                                onClick={showKlarityPlanPannel}
                            >Explore Kiwi plans
                            </span>
                        }
                        {
                            isKlarityPro &&
                            <span onClick={() => {
                                window.open(`mailto:${Email.support}`);
                            }}
                            >Contact us
                            </span>
                        }
                    </div>
                </div>
            }
            <div className={`${s.avaPannel} ${showOpenAvaPannel ? s.avaPannelShow : ''}`}>
                <div className={s.avaTitle}>
                    <div className={s.avaTitleLeft}>
                        Turn on
                        <img src={AvaIcon} className={s.avaIcon} alt="ava" />
                        auto-reply  and meet your new AI assistant 👋
                    </div>
                    <div className={s.avaTitleRight}>
                        <Switch
                            loading={switching}
                            checked={isOpenAva}
                            onChange={handleChangeAvaSwitch}
                        />
                    </div>
                </div>
                <div className={s.avaContent}>
                    Ava Auto-reply instantly responds to prospects, while Co-pilot mode drafts replies for your approval.
                    <br />
                    Check more customization in Setting.
                </div>
            </div>
            <div style={{ display: 'none' }}>
                <SliderPanel
                    showVal={collapse}
                    title={
                        <div className={s.notificationSettingInfo}>
                            Prospect email notification preferences <img src={icon} />
                        </div>
                    }
                    contentCls={s.notificationSetting}
                    defaultHide
                    onShowStatusChange={(showInfo: boolean) => {
                        if (!showInfo) {
                            setHasSetNotifyEmails('true');
                        }
                    }}
                >
                    <>
                        <div className={s.subTitle}>
                            {hasNotifyEmails
                                ? `Your prospect notifications will be send to the following email addresses: ${notifyEmails.join(
                                    ', ',
                                )}`
                                : "Stay informed and never miss a new client's important message! Add up to 5 email addresses to receive prospect notifications."}
                        </div>
                        <Button
                            onClick={() => {
                                setShowUpdateEmailModal(true);
                            }}
                            type="primary"
                            size="large"
                            className={s.btn}
                        >
                            {hasNotifyEmails ? 'Edit emails' : 'Add emails'}
                        </Button>
                    </>
                </SliderPanel>
            </div>
            <div className={s.filter}>
                <div className={s.left}>
                    <p className={s.activeStatus}>Active prospects</p>
                    <p className={s.archivedStatus}>Archived prospects</p>
                </div>
                <div className={s.right}>
                    <Select
                        value={statusFilter}
                        style={{ width: 160, marginRight: 16 }}
                        onChange={(val) => {
                            setStatusFilter(val);
                        }}
                        options={[
                            { value: allValue, label: 'All status' },
                            { value: 'Active', label: 'Active' },
                            { value: 'Archived', label: 'Archived' },
                        ]}
                    />
                    <Select
                        value={typeFilter}
                        style={{ width: 160, marginRight: 16 }}
                        onChange={(val) => {
                            setTypeFilter(val);
                        }}
                        options={[
                            { value: allValue, label: 'All types' },
                            { value: 'Prospect', label: 'Prospect' },
                            { value: 'Patient', label: 'Patient' },
                        ]}
                    />
                </div>
            </div>
            <div className={s.control}>
                <div className={s.left}>
                    {selectKeys?.length > 0 ? (
                        <span className={s.mr8}>
                            {`Selected ${selectKeys?.length} Prospects`}
                        </span>
                    ) : (
                        <div className={s.item}>
                            Total {list?.length || 0} prospective patients
                        </div>
                    )}
                    {allOffEhr && (
                        <Button
                            loading={ehrUpdating}
                            className={s.action}
                            onClick={() => {
                                setEhr('Yes');
                            }}
                        >
                            {Icons.EhrOnIcon}
                            <span className={s.label}>Mark as on EHR</span>
                        </Button>
                    )}
                    {allOnEhr && (
                        <Button
                            loading={ehrUpdating}
                            className={s.action}
                            onClick={() => {
                                setEhr('No');
                            }}
                        >
                            {Icons.EhrOffIcon}
                            <span className={s.label}>Removed from EHR</span>
                        </Button>
                    )}

                    {allArchived && (
                        <Button
                            loading={statusUpdating}
                            className={s.action}
                            onClick={() => {
                                setStatus(EStatus.Active);
                            }}
                        >
                            {Icons.CheckIcon}
                            <span className={s.label}>Activate</span>
                        </Button>
                    )}

                    {allActive && (
                        <Button
                            loading={statusUpdating}
                            className={s.action}
                            onClick={() => {
                                setStatus(EStatus.Archived);
                            }}
                        >
                            {Icons.UnCheckIcon}
                            <span className={s.label}>Archive</span>
                        </Button>
                    )}
                    {showExport && (
                        <div
                            onClick={() => {
                                if (getHasReadTermsForExport()) {
                                    downloadExcel();
                                } else {
                                    setShowConfirm(true);
                                }
                            }}
                            className={s.action}
                        >
                            {Icons.DownloadIcon}
                            <span className={s.label}>Export</span>
                        </div>
                    )}
                    <span />
                </div>
            </div>
            <div className={s.tableWrap}>
                <CommonTable
                    bordered
                    rowKey="id"
                    loading={loading}
                    columns={columns}
                    data={list || []}
                    scroll={{ x: 'max-content' }}
                    rowSelection={rowSelection}
                />
            </div>
            {show && (
                <AddAndUpdateProspects
                    show={show}
                    onSuccess={() => {
                        run();
                        setShow(false);
                    }}
                    onCannel={() => {
                        setShow(false);
                    }}
                />
            )}
            <UpdateEmailModal
                onCannel={() => {
                    setShowUpdateEmailModal(false);
                }}
                item={notifyEmails}
                onSuccess={() => {
                    setShowUpdateEmailModal(false);
                    fetchNotifyEmails();
                    setCollapse(false);
                    setHasSetNotifyEmails('true');
                }}
                show={showUpdateEmailModal}
            />
            <Modal
                title="Terms for export"
                open={showConfirm}
                onOk={() => {
                    downloadExcel();
                    setHasReadTermsForExport('true');
                    setShowConfirm(false);
                }}
                onCancel={() => {
                    setShowConfirm(false);
                }}
                okText="Export the CSV file"
                okButtonProps={{
                    disabled: !checked,
                }}
            >
                <div>
                    <div className={s.modalP}>
                        We want to remind you that patient information contained within
                        the .csv or .xlsx file you are about to download is private and
                        must be treated with utmost care. By downloading the file, you
                        agree to take full responsibility for safeguarding this
                        information, and to comply with all applicable laws and
                        regulations.
                    </div>
                    <div className={s.modalP}>
                        <Checkbox
                            onChange={(e) => {
                                setChecked(e.target.checked);
                            }}
                        >
                            I have read and understand the terms.
                        </Checkbox>
                    </div>
                </div>
            </Modal>
            <Modal
                open={showAvaModal}
                footer={null}
                width="960px"
                onCancel={() => {
                    setShowAvaModal(false);
                }}
            >
                <div className={s.avaWrap}>
                    <div className={s.left}>
                        <img src={Frame} />
                    </div>
                    <div className={s.right}>
                        <div className={s.header}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path d="M19.9996 39.13C30.565 39.13 39.13 30.565 39.13 19.9996C39.13 9.43415 30.565 0.869141 19.9996 0.869141C9.43415 0.869141 0.869141 9.43415 0.869141 19.9996C0.869141 30.565 9.43415 39.13 19.9996 39.13Z" fill="#F0FCF6" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M0 20C0 8.95432 8.95432 0 20 0C31.0457 0 40 8.95432 40 20C40 31.0457 31.0457 40 20 40C8.95432 40 0 31.0457 0 20ZM20 1.73913C9.91482 1.73913 1.73913 9.91482 1.73913 20C1.73913 30.0852 9.91482 38.2609 20 38.2609C30.0852 38.2609 38.2609 30.0852 38.2609 20C38.2609 9.91482 30.0852 1.73913 20 1.73913Z" fill="#00816B" />
                                <path d="M30.9316 24.0999C32.8882 24.0999 34.12 22.7668 34.12 20.8103C34.12 18.8537 32.8882 17.2676 30.9316 17.2676V20.9115V24.0999Z" fill="#A3CDC3" />
                                <path d="M9.52344 17.2673C7.56687 17.2673 6.33511 18.6003 6.33511 20.5569C6.33511 22.5135 7.56687 24.0996 9.52344 24.0996V20.4557V17.2673Z" fill="#A3CDC3" />
                                <path d="M8.9668 15.1323C8.9668 13.8517 10.005 12.8135 11.2856 12.8135H29.4223C30.7029 12.8135 31.7411 13.8517 31.7411 15.1323V26.8922C31.7411 28.1728 30.7029 29.211 29.4223 29.211H11.2856C10.005 29.211 8.9668 28.1728 8.9668 26.8922V15.1323Z" fill="#00816B" />
                                <path d="M19.0898 9.97949H21.8228V13.6234H19.0898V9.97949Z" fill="#00816B" />
                                <path d="M10.8906 16.853C10.8906 15.5724 11.9288 14.5342 13.2095 14.5342H27.7022C28.9829 14.5342 30.0211 15.5724 30.0211 16.853V24.969C30.0211 26.2496 28.9829 27.2878 27.7022 27.2878H13.2095C11.9288 27.2878 10.8906 26.2496 10.8906 24.969V16.853Z" fill="white" />
                                <path d="M24.1499 22.1256C24.5413 22.1256 24.8585 21.6498 24.8585 21.0628C24.8585 20.4758 24.5413 20 24.1499 20C23.7586 20 23.4414 20.4758 23.4414 21.0628C23.4414 21.6498 23.7586 22.1256 24.1499 22.1256Z" fill="#00816B" />
                                <path d="M18.4824 23.1885C19.1119 23.6082 19.8515 23.8321 20.608 23.8321C21.3646 23.8321 22.1042 23.6082 22.7336 23.1885" fill="#00816B" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M18.12 22.9475C18.2532 22.7478 18.5231 22.6938 18.7229 22.827C19.2809 23.199 19.9366 23.3976 20.6073 23.3976C21.278 23.3976 21.9337 23.199 22.4917 22.827C22.6915 22.6938 22.9615 22.7478 23.0947 22.9475C23.2279 23.1473 23.1739 23.4173 22.9741 23.5505C22.2732 24.0178 21.4497 24.2671 20.6073 24.2671C19.7649 24.2671 18.9414 24.0178 18.2405 23.5505C18.0407 23.4173 17.9867 23.1473 18.12 22.9475Z" fill="#00816B" />
                                <path d="M17.064 22.1256C17.4553 22.1256 17.7725 21.6498 17.7725 21.0628C17.7725 20.4758 17.4553 20 17.064 20C16.6727 20 16.3555 20.4758 16.3555 21.0628C16.3555 21.6498 16.6727 22.1256 17.064 22.1256Z" fill="#00816B" />
                                <path d="M23.6444 9.06804C23.6444 10.0743 22.2169 10.89 20.456 10.89C18.6951 10.89 17.2676 10.0743 17.2676 9.06804C17.2676 8.06181 18.6951 7.24609 20.456 7.24609C22.2169 7.24609 23.6444 8.06181 23.6444 9.06804Z" fill="#A3CDC3" />
                            </svg>
                            <div className={s.hRight}>
                                <div className={s.title}>Ava auto-reply</div>
                                <div className={s.des}>Created by Kiwi Health</div>
                            </div>
                        </div>
                        <span className={s.label}>
                            New feature
                        </span>
                        <div className={s.avaTitle}>
                            Meet <img className={s.ava} src={AvaIcon} />, your AI prospect assistant
                        </div>
                        <div className={s.avaDes}>
                            Ava helps you respond to prospects instantly, boosting your response rate and saving you time. Customize her tone, add your booking link, then you're all set. Let Ava handle the initial communication while you focus on what you do best – providing excellent care.
                        </div>
                        <div className={s.btnWrap}>
                            <Button
                                onClick={() => {
                                    setShowAvaHome(true);
                                }}
                                block
                                size="large"
                                className={s.btn}
                            >Learn more
                            </Button>
                            <Button
                                onClick={() => {
                                    setShowAvaSettingInProsepect('true');
                                    navigate(`${PATH.DASHBOARD}/${PATH.AVA_SETTING_PROSPECTS}`);
                                }}
                                block
                                size="large"
                                type="primary"
                                className={s.btn}
                            >Set up Ava in Settings
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
            <AvaConfigHome
                show={showAvaHome}
                onClickBack={() => {
                    setShowAvaHome(false);
                }}
                onClickSet={() => {
                    setShowAvaSettingInProsepect('true');
                    setShowAvaHome(false);
                    navigate(`${PATH.DASHBOARD}/${PATH.AVA_SETTING_PROSPECTS}`);
                }}
            />
        </div>
    );
};

export default Page;
