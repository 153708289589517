import React, { useState } from 'react';
import cx from 'classnames';
import s from '../GrowYourBusiness/s.module.less';
import c from './s.module.less';
import type { TTaskItem } from 'types/home';
import { Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';

type IProps = {
    isKlarity: boolean;
    task: TTaskItem;
    index: number;
};

const Index: React.FC<IProps> = ({
    isKlarity,
    task,
    index,
}) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleHideModal = () => {
        setShowModal(false);
    };

    const handleRoute = () => {
        navigate('/dashboard/ava-setting-prospects');
    };

    const handleTask = () => {
        if (task.completed) {
            return;
        }
        setShowModal(true);
    };

    return (
        <>
            <div
                className={cx(s.listItem, task.completed ? s.completed : '')}
                onClick={() => handleTask()}
            >
                <div className={s.listInner}>
                    <i className={s.num}>{index + 1}</i>
                    <div className={s.content}>
                        <div className={s.goalTitle}>{task.taskName}</div>
                        <div className={s.goalDescription}>{task.description}</div>
                    </div>
                    <div className={s.arrow} />
                </div>
            </div>
            <Modal
                title="Turn on Ava AI auto-reply 💌"
                centered
                open={showModal}
                onCancel={handleHideModal}
                width={534}
                footer={null}
            >
                <div className={s.modalContent}>
                    <p>
                        Enhance your responsiveness to potential patients by turning on
                        Ava AI auto-reply.
                    </p>
                    <p>
                        This powerful feature ensures that you respond to prospects
                        promptly, even when you're unavailable. Remember, in today's
                        fast-paced world, prompt responses are not just
                        appreciated—they're expected. Let Ava AI handle initial
                        interactions efficiently, allowing you to focus on providing
                        quality care while ensuring no prospect goes unattended.
                    </p>
                    <div className={s.buttonWrap}>
                        <Button type="primary" onClick={() => handleRoute()}>
                            Turn on Ava AI auto-reply
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Index;
