import React, { useCallback, useEffect } from 'react';
import s from './s.module.less';
import { Form, Input, message } from 'antd';
import FormValidationHelper from 'utils/validation';
import PhotoList from 'components/PhotoList';
import commonS from 'styles/common.module.less';

interface IProps {
    formId: string;
    practiceName?: string;
    practicePhone?: string;
    practiceLogo?: string;
    handleSubmited?: (data: any, field: string) => void;
}

const PracticeInfo = ({
    formId,
    practiceName,
    practicePhone,
    practiceLogo,
    handleSubmited,
}: IProps) => {
    const [formInstance] = Form.useForm();

    useEffect(() => {
        formInstance.setFieldsValue({
            practiceName,
            practicePhone,
            practiceLogo,
        });
    }, [formInstance, practiceLogo, practiceName, practicePhone]);

    const handleFinish = useCallback(async (values) => {
        handleSubmited?.(values, formId);
    }, [formId, handleSubmited]);

    const handleFinishFailed = useCallback((errors) => {
        if (errors?.errorFields.length > 0) {
            message.error(errors.errorFields[0].errors?.[0]);
        }
    }, []);

    return (
        <div className={s.wrap}>
            <div className={s.inner}>
                <div className={s.title}>Step 1 of 4: Confirm your practice information</div>
                <div className={s.subTitle}>By syncing with Kiwi EHR, your practice information will be seamlessly incorporated into patient forms, reinforcing your professional branding and improving patient experience.</div>
                <div className={s.form}>
                    <Form
                        id={formId}
                        form={formInstance}
                        className={commonS.formStyle1}
                        layout="vertical"
                        onFinish={handleFinish}
                        onFinishFailed={handleFinishFailed}
                    >
                        <div className={s.row}>
                            <div className={s.displayItem}>
                                <Form.Item
                                    className={s.formItem}
                                    name="practiceName"
                                    label="Practice name"
                                    rules={[
                                        { required: true, message: 'Practice Name is a required field' },
                                    ]}
                                >
                                    <Input maxLength={200} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className={s.row}>
                            <div className={s.displayItem}>
                                <div className={`${s.formItemLabel} ${s.required}`} style={{ width: '168px' }}>
                                    Practice phone number
                                </div>
                                <div className={s.subLabel}>
                                    Please enter a phone number where you can be reached. Pharmacies may use this number to verify prescriptions.
                                </div>
                                <Form.Item
                                    // label="Practice phone number"
                                    style={{ width: '40%' }}
                                    name="practicePhone"
                                    validateFirst
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Practice phone number is a required field',
                                        },
                                        FormValidationHelper.validatePhoneNumber('Phone number must contain only number or -'),
                                    ]}
                                >
                                    <Input maxLength={30} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className={s.row}>
                            <div className={s.displayItem}>
                                <div className={s.formItemLabel}>
                                    Practice logo
                                </div>
                                <div className={s.subLabel}>
                                    Your practice logo is displayed in automated emails that Kiwi EHR sends to your clients, at the top left of your and your clients’ Kiwi EHR account, and on various forms within the Kiwi EHR platform.
                                </div>
                                <div className={s.subLabel}>
                                    We recommend using an image with a transparent background and limiting the resolution to a width of 150 pixels and a height of 50 pixels.
                                </div>
                                <div className={s.uploadBox}>
                                    <Form.Item
                                        name="practiceLogo"
                                        valuePropName="data"
                                    >
                                        <PhotoList
                                            min={1}
                                            data={[]}
                                            enable
                                            responseType="string"
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default PracticeInfo;
