import React, { useCallback, useEffect, useMemo } from 'react';
import s from './s.module.less';
import LineBar from '../../../LineBar';
import { showBlackFriday2024EnhancementModal } from 'utils/globalLayerControl';
import CCCStore from 'store/CCC';
import Modal from 'antd/es/modal/Modal';
import { Checkbox, Col, message, Row } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { CCCTypeList } from 'constants/ccc';
import { pauseCCC } from 'api/ccc';

const Enhance = () => {
    const [getCCCStore, setCCCStore] = CCCStore.useStore();
    const cccList = getCCCStore('cccList');
    const [selectedCCCItems, setSelectedCCCItems] = React.useState<string[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [showPauseConfirm, setShowPauseConfirm] = React.useState<boolean>(false);

    const offeredCCC = useMemo(() => cccList?.filter((ccc) => ccc.offerStatus === 'A'), [cccList]);

    const hasOfferedCCC = offeredCCC && offeredCCC.length > 0;

    useEffect(() => {
        if (hasOfferedCCC) {
            setSelectedCCCItems(offeredCCC.map((ccc) => ccc.skuCode));
        }
    }, [hasOfferedCCC, offeredCCC]);

    const handleOfferShowCampainModal = useCallback(() => {
        showBlackFriday2024EnhancementModal();
    }, []);

    const handleSelectedCCCChange = useCallback((skuCodes: CheckboxValueType[]) => {
        setSelectedCCCItems(skuCodes as string[]);
    }, []);

    const handleConfirmPause = useCallback(async () => {
        if (!selectedCCCItems || selectedCCCItems.length === 0) return;

        setLoading(true);
        try {
            const res = await pauseCCC(selectedCCCItems);

            if (res && !res.error) {
                setSelectedCCCItems([]);
                setCCCStore('forceRefetchCCCList', {});
                setShowPauseConfirm(false);
                message.success('You’ve successfully paused offering Care Commitment Card.');
            } else {
                message.error(res.error);
            }
        } catch (error) {
            console.log('error', error);
        }
        setLoading(false);
    }, [selectedCCCItems]);

    const handleShowConfirmPauseModal = useCallback(() => {
        setShowPauseConfirm(true);
    }, []);

    if (!cccList || cccList.length === 0) {
        return null;
    }

    return (
        <div className={s.wrap}>
            <Modal
                title="Pause offering Care Commitment Cards?"
                open={showPauseConfirm}
                confirmLoading={loading}
                okButtonProps={{
                    danger: true,
                }}
                okText="Pause"
                onCancel={() => {
                    if (hasOfferedCCC) {
                        setSelectedCCCItems(offeredCCC.map((ccc) => ccc.skuCode));
                    }
                    setShowPauseConfirm(false);
                }}
                onOk={handleConfirmPause}
            >
                <div className={s.modalContent}>
                    <p className={s.confirmContent}>Pausing offering the cards will remove it from your Provider Profile page, making it unavailable to patients. You can easily resume it anytime to continue offering this benefit. </p>
                    <p className={s.confirmSecContent}>Select the cards you wish to pause below.</p>
                    {
                        hasOfferedCCC && (
                            <Checkbox.Group value={selectedCCCItems} onChange={handleSelectedCCCChange}>
                                <Row>
                                    {offeredCCC.map((ccc) => {
                                        const { skuCode } = ccc;
                                        const type = CCCTypeList.find((item) => item.skuCode === skuCode)?.type;
                                        return (
                                            <Col key={ccc.skuCode} span={24}>
                                                <Checkbox disabled={offeredCCC.length === 1} value={ccc.skuCode}>{type} Care Commitment Card</Checkbox>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </Checkbox.Group>
                        )
                    }
                </div>
            </Modal>
            <LineBar
                lineBarId="kiwi-100-1"
                icon="icon-8"
                title="Offer Klarity Care Commitment Cards to your patients"
                description="Enhance patient loyalty, secure upfront payments, and streamline care with the Klarity Care Commitment Card. "
                cta={hasOfferedCCC ? 'Pause offering' : 'Offer now'}
                details={`
                        <ul>
                            <li>Choose from three tiers—Plus ($600 for $800 value), Essential ($300 for $400 value), and Basic ($100 for $125 value) —to offer tailored benefits to your patients.</li>
                            <li>Enhance patient loyalty and secure future revenue by allowing patients to pay less upfront while receiving more credit to use on any of your services.</li>
                            <li>Patients can use the card on any of the services you offered.</li>
                        </ul>
                    `}
                onCta={hasOfferedCCC ? handleShowConfirmPauseModal : handleOfferShowCampainModal}
            />
        </div>
    );
};

export default Enhance;
