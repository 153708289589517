import React from 'react';
import s from './s.module.less';
import LandingIcon from 'assets/practiceFront/pfLandingImage.svg';
import InternalIcon from 'assets/practiceFront/globIcon.svg';
import PeopleGroupIcon from 'assets/practiceFront/peopleGroupIcon.svg';
import RiseIcon from 'assets/practiceFront/riseIcon.svg';

const Page = () => {
    return (
        <div className={s.wrap}>
            <div className={s.step}>Step 3 of 3: Create your Practice Front, the Kiwi Health profile page</div>
            <div className={s.desc}>{'Let\'s create your Kiwi Health profile page to showcase your practice and boost your online presence. This professional page will be optimized for search engines, attracting more potential patients to your Klarity provider profile page, where they can book appointments and learn more about your services.'}</div>
            <div className={s.content}>
                <div className={s.left}>
                    <div className={s.list}>
                        <div className={s.item}>
                            <img src={InternalIcon} />
                            <div className={s.info}>
                                <div className={s.itemTitle}>Increased visibility</div>
                                <div className={s.itemContent}>Increase your online presence by utilizing the best SEO techniques to make your website easily discoverable.</div>
                            </div>
                        </div>
                        <div className={s.item}>
                            <img src={PeopleGroupIcon} />
                            <div className={s.info}>
                                <div className={s.itemTitle}>Enhanced patient experience</div>
                                <div className={s.itemContent}>Provide patients with easy access to information such as hours, services offered, pricing, contact info, health tips, news, and updates and online scheduling.</div>
                            </div>
                        </div>
                        <div className={s.item}>
                            <img src={RiseIcon} />
                            <div className={s.info}>
                                <div className={s.itemTitle}>Increased revenue</div>
                                <div className={s.itemContent}>No more fees to third-party platforms. Handling your own online appointment booking and payment processing.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={s.right}>
                    <img src={LandingIcon} />
                </div>
            </div>
        </div>
    );
};

export default Page;
