import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Pagination, Spin } from 'antd';
import s from './s.module.less';
import Empty from './empty.svg';
import ProviderStore from 'store/Provider';
import ReplyReviewModal from '../ReplyReviewModal';
import { getKlarityReviewList } from 'api/survey';
import { EDisputeStatusType, EOpenType, ESortType, ETimeType, TReviewItem } from '../../types';
import ReviewItem from './components/ReviewItem';
import { IGetKlarityReviewListInput } from 'types/reputation';
import useGetKlarityReviewList from 'hooks/useGetKlarityReviewList';

type Props = {
    disputedList?: boolean;
    disputeFilter?: EDisputeStatusType;
    openFilter?: EOpenType;
    sortFilter?: ESortType;
    waitToReply?: boolean;
    onTotalUpdate?: (total: number) => void;
    scrollToTop?: () => void;
    onReplySuccess?: () => void;
    forceUpdate?: {};
    isShow?: boolean;
};

const WaitForReply = (prop: Props) => {
    const { disputeFilter, openFilter, sortFilter, waitToReply, onTotalUpdate, scrollToTop, onReplySuccess, forceUpdate, isShow, disputedList } = prop;
    const [getUser] = ProviderStore.useStore();
    const navigate = useNavigate();
    const [reviewData, setReviewData] = useState<TReviewItem[]>();

    const [showReplyModal, setShowReplyModal] = useState(false);
    const [currentReview, setCurrentReview] = React.useState<TReviewItem>();

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    // const [loading, setLoading] = React.useState(false);

    const latestFilter = React.useRef({ openFilter, sortFilter });

    const [reveiwList, totalReview, loading,,refetchReviewList] = useGetKlarityReviewList();

    const getReviewList = async () => {
        if (loading) {
            return;
        }
        const needSetPage = (latestFilter.current.openFilter !== openFilter || latestFilter.current.sortFilter !== sortFilter) && page !== 1;
        if (needSetPage) {
            setPage(1);
            return;
        }

        const data: IGetKlarityReviewListInput = {
            sortType: sortFilter!,
            publicOrPrivate: openFilter!,
            size: pageSize,
            current: page,
        };
        if (disputedList) {
            data.disputed = true;
        }

        if (disputeFilter && disputeFilter !== EDisputeStatusType.ALL) {
            data.disputeStatus = disputeFilter;
        }

        if (waitToReply) {
            data.waitToReply = true;
        }
        latestFilter.current = { openFilter, sortFilter };

        refetchReviewList(data);
    };

    useEffect(() => {
        setReviewData(reveiwList || []);
        setTotal(totalReview || 0);
        onTotalUpdate?.(totalReview);
        scrollToTop?.();
    }, [reveiwList, totalReview]);

    useEffect(() => {
        if (forceUpdate) {
            getReviewList();
        }
    }, [forceUpdate]);

    useEffect(() => {
        getReviewList();
    }, [page, pageSize, openFilter, sortFilter, disputeFilter]);

    const handlePaginationChange = (page: number, pageSize: number) => {
        setPage(page);
        setPageSize(pageSize);
    };

    const isEmpty = (reviewData && reviewData.length === 0);

    return (
        <div className={s.wrap}>
            <div className={s.reviewWrap}>
                <Spin className={s.reviewContent} spinning={loading}>
                    {
                        isEmpty && (
                            <div className={s.emtpyWrap}>
                                <div className={s.emptyImg}>
                                    <img src={Empty} alt="" />
                                </div>
                                <div className={s.emptyTitle}>
                                    {waitToReply ? 'No reviews yet' : 'No reviews yet'}
                                </div>
                            </div>
                        )
                    }
                    {
                        reviewData?.map((review: TReviewItem) => {
                            return (
                                <ReviewItem
                                    onClickReply={() => {
                                        setCurrentReview(review);
                                        setShowReplyModal(true);
                                    }}
                                    isShow={isShow}
                                    key={review.id}
                                    review={review}
                                />
                            );
                        })
                    }
                    <div className={s.paginationBox}>
                        <Pagination disabled={loading} hideOnSinglePage current={page} total={total} pageSize={pageSize} onChange={handlePaginationChange} />
                    </div>
                </Spin>

            </div>
            <ReplyReviewModal
                show={showReplyModal}
                item={currentReview}
                onSuccess={() => {
                    setShowReplyModal(false);
                    onReplySuccess?.();
                }}
                onCannel={() => {
                    setShowReplyModal(false);
                }}
            />
        </div>
    );
};

export default WaitForReply;
