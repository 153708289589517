// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./bg.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-modules__title--Cvcha {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  /* 140% */
  letter-spacing: -0.5px;
  margin-bottom: 24px;
  margin-top: 8px;
}
.s-modules__list--As2Ev {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px 48px;
  border-radius: 24px;
  background: rgba(240, 252, 246, 0.4);
}
.s-modules__list--As2Ev .s-modules__item--he0QF {
  width: 32%;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.s-modules__list--As2Ev .s-modules__item--he0QF .s-modules__icon--_noHv {
  height: 30px;
  width: 30px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center center no-repeat;
  background-size: contain;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}
.s-modules__list--As2Ev .s-modules__item--he0QF .s-modules__subTitle--rzAnv {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 8px;
}
.s-modules__list--As2Ev .s-modules__item--he0QF .s-modules__description--bwRxT {
  color: var(--Grey, #6B7280);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/ProspectsManagePage/AvaConfigHome/Benefits/s.modules.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,SAAS;EACT,sBAAA;EACA,mBAAA;EACA,eAAA;AACF;AAEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,uBAAA;EACA,kBAAA;EACA,mBAAA;EACA,oCAAA;AAAF;AAPA;EAUI,UAAA;EACA,eAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AAAJ;AAfA;EAkBM,YAAA;EACA,WAAA;EACA,2EAAA;EACA,wBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;AAAN;AA1BA;EA8BM,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AADN;AAjCA;EAsCM,2BAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAFN","sourcesContent":[".title {\n  color: #000;\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 34px;\n  /* 140% */\n  letter-spacing: -0.5px;\n  margin-bottom: 24px;\n  margin-top: 8px;\n}\n\n.list {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: flex-start;\n  padding: 24px 48px;\n  border-radius: 24px;\n  background: rgba(240, 252, 246, 0.40);\n\n  .item {\n    width: 32%;\n    padding: 0 24px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n\n    .icon {\n      height: 30px;\n      width: 30px;\n      background: url('./bg.svg') center center no-repeat;\n      background-size: contain;\n      border-radius: 15px;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      margin-bottom: 8px;\n    }\n\n    .subTitle {\n      font-size: 16px;\n      font-style: normal;\n      font-weight: 500;\n      line-height: 22px;\n      margin-bottom: 8px;\n    }\n\n    .description {\n      color: var(--Grey, #6B7280);\n      text-align: center;\n      font-size: 14px;\n      font-weight: 400;\n      line-height: 20px\n    }\n  }\n}\n\n.item {}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `s-modules__title--Cvcha`,
	"list": `s-modules__list--As2Ev`,
	"item": `s-modules__item--he0QF`,
	"icon": `s-modules__icon--_noHv`,
	"subTitle": `s-modules__subTitle--rzAnv`,
	"description": `s-modules__description--bwRxT`
};
export default ___CSS_LOADER_EXPORT___;
