import React, { useCallback, useMemo } from 'react';
import s from './s.module.less';
import LineBar from '../../../LineBar';
import { ICampaign } from 'types/campaign';
import dayjs from 'utils/dayjs';
import CampaignStore from 'store/Campaign';
import { showBlackFriday2024JoinCampaignModal, showBlackFriday2024LeaveCampaignModal } from 'utils/globalLayerControl';

interface IProps {
    campaign: ICampaign;
}

const Reach = ({
    campaign,
}: IProps) => {
    const [,setSelectedCampaign] = CampaignStore.useStore();
    const { campaignName, description, providerEndDateTime, providerStartDateTime, joined, iconUrl } = campaign;

    const handleJoin = useCallback(() => {
        setSelectedCampaign('selectedCampaign', campaign);
        showBlackFriday2024JoinCampaignModal();
    }, [campaign]);

    const handleLeaveGroup = useCallback(() => {
        setSelectedCampaign('selectedCampaign', campaign);
        showBlackFriday2024LeaveCampaignModal();
    }, [campaign]);

    const statusTag = useMemo(() => {
        return !providerStartDateTime && !providerEndDateTime ? '' : (
            <div className={s.statusTag}>{providerStartDateTime ? dayjs(providerStartDateTime || '').format('MMM DD') : ''} - {providerEndDateTime ? dayjs(providerEndDateTime || '').format('MMM DD') : ''}</div>
        );
    }, [providerEndDateTime, providerStartDateTime]);

    return (
        <div className={s.wrap}>
            <LineBar
                statusTag={statusTag}
                lineBarId="kiwi-2-1"
                icon="icon-7"
                backgroundImage={iconUrl}
                title={campaignName}
                description={description}
                cta={joined ? 'Leave the campaign' : 'Join now'}
                details=""
                onCta={joined ? handleLeaveGroup : handleJoin}
            />
        </div>
    );
};

export default Reach;
