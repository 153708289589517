import React, { useEffect, useState } from 'react';
import ProviderStore from 'store/Provider';
import Intro from './components/Intro';
import ReputationDashboardPage from 'pages/dashboard/ReputationDashboradPage';
import { getReputationStatus } from 'api/survey';
import { Spin, message } from 'antd';
import s from './s.module.less';
import useGetProviderReputationStatus from 'hooks/useGetProviderReputationStatus';

const Page = () => {
    const [getUser] = ProviderStore.useStore();
    const [hasConfig, loading] = useGetProviderReputationStatus();

    // const fetch = async () => {
    //     try {
    //         const res = await getReputationStatus();

    //         if (res && !res.error) {
    //             setHasConfig(!!(res.data.data));
    //         } else {
    //             message.error(res.error);
    //         }
    //     } catch (e) {
    //         console.error(e);
    //     }
    // };

    // useEffect(() => {
    //     fetch();
    // }, []);

    // if (hasConfig === null) {
    //     return (
    //         <Spin spinning>
    //             <div className={s.wrap} />
    //         </Spin>
    //     );
    // }
    if (loading) {
        return <Spin><div className={s.wrap} /></Spin>;
    }

    return hasConfig ?
        (
            <ReputationDashboardPage />
        )
        : (
            <Intro />
        );
};

export default Page;
