import { message, Form, Input, Button } from 'antd';
import React from 'react';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { FormType } from './types';
import { TAssistant } from 'types/common';
import { REQUIRED_FIELD } from 'constants/common';

type Props = {
    value: Partial<TAssistant>;
    onAdd: (item: TAssistant) => void;
    name: string;
    existEmails: string[];
    onRemove: () => void;
    onEditStatusChange: (status: boolean) => void;
};

const EditAssistant = (props: Props) => {
    const { name, value, onAdd, existEmails, onRemove, onEditStatusChange } = props;
    const [form] = Form.useForm<FormType>();
    const [submiting, setSubmiting] = React.useState(false);
    const [isEdit, setIsEdit] = React.useState(true);

    const reset = () => {
        form.resetFields();
    };

    React.useEffect(() => {
        onEditStatusChange(isEdit);
    }, [isEdit]);

    const handleOk = async () => {
        try {
            setSubmiting(true);
            const formValues = await form.validateFields();

            let emailExist = false;
            if (existEmails.includes(formValues.assistantEmail) && value?.assistantEmail !== formValues.assistantEmail) {
                emailExist = true;
            }
            if (emailExist) {
                form.setFields([{
                    name: 'email',
                    errors: ['Assistant already exists.'],
                }]);
                return;
            }

            setIsEdit(false);
            onAdd?.({
                ...formValues,
            });
        } catch (e: any) {
            console.log(e);
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };

    React.useEffect(() => {
        if (value) {
            form.setFieldsValue({
                assistantName: value.assistantName,
                assistantEmail: value.assistantEmail,
            });
        }
    }, [value]);

    const onCancel = () => {
        if (value.assistantEmail) {
            form.setFieldsValue({
                assistantName: value.assistantName,
                assistantEmail: value.assistantEmail,
            });
            setIsEdit(false);
        } else {
            onRemove();
        }
    };

    return (
        <div className={s.wrap}>
            <div className={s.title}>
                <div className={s.name}>{name}</div>
                <div className={s.action}>
                    {
                        isEdit && (
                            <>
                                <Button className={s.btn} onClick={onCancel}>Cancel</Button>
                                <Button className={s.btn2} onClick={handleOk} type="primary">Save</Button>
                            </>
                        )
                    }
                    {
                        !isEdit && (
                            <>
                                <Button className={s.btn} onClick={onRemove}>Remove</Button>
                                <Button
                                    className={s.btn}
                                    onClick={() => {
                                        setIsEdit(true);
                                    }}
                                >Edit
                                </Button>
                            </>
                        )
                    }
                </div>
            </div>
            {
                !isEdit && (
                    <div className={s.formWrap}>
                        <div className={s.row}>
                            <div
                                className={s.displayItem}
                            >
                                <div className={s.label}>Name</div>
                                <div className={s.value}>{value.assistantName}</div>
                            </div>
                        </div>
                        <div className={s.row}>
                            <div
                                className={s.displayItem}
                            >
                                <div className={s.label}>Email</div>
                                <div className={s.value}>{value.assistantEmail}</div>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                isEdit && (
                    <div className={s.formWrap}>
                        <Form
                            form={form}
                            name="basic"
                            className={commonS.formStyle1}
                            initialValues={{}}
                            autoComplete="off"
                            layout="vertical"
                        >
                            <div className={s.row}>
                                <div className={s.rowItem} style={{ width: '100%' }}>
                                    <Form.Item
                                        label="Name"
                                        name={['assistantName']}
                                        rules={[{ required: true, message: REQUIRED_FIELD }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.rowItem} style={{ width: '100%' }}>
                                    <Form.Item
                                        label="Email"
                                        name={['assistantEmail']}
                                        rules={[
                                            { required: true, message: REQUIRED_FIELD },
                                            { type: 'email', message: 'Email is invalid' },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                    </div>
                )
            }
            {isEdit && (
                <div className={s.footerBtnWrap}>
                    <Button className={s.btn} onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button className={s.btn2} onClick={handleOk} type="primary">
                        Save
                    </Button>
                </div>
            )}

        </div>
    );
};

export default EditAssistant;
