// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__timeLeft--P2jc3 {
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--gray-500);
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/ProviderHomePage/components/TimeRemaining/s.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,QAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,sBAAA;AACJ","sourcesContent":[".timeLeft {\n    display: flex;\n    align-items: center;\n    gap: 4px;\n    font-family: Inter;\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 20px;\n    text-align: left;\n    color: var(--gray-500);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timeLeft": `s-module__timeLeft--P2jc3`
};
export default ___CSS_LOADER_EXPORT___;
