import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import s from './s.module.less';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from './form';
import { STRIPE_PUBLIC_KEY } from 'constants/stripe';
import { loadStripe } from '@stripe/stripe-js';
import { Button, Spin, message } from 'antd';
import useShoppingCart from 'hooks/useShoppingCart';
import { TShoppingCartItem, EAddOnProductCode, IEHRCheckoutInput } from 'types/ehr';
import { get2Decimal } from 'utils/common';
import useGetStripePaymentInfo from 'hooks/useGetStripePaymentInfo';
import { removeFromShoppingCart, shoppingCartCheckout } from 'api/ehr';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import SelfEFaxWarning from '../EhrProviderAddOnPage/components/SelfEFaxWarning';
import useAddOnProduct from 'hooks/useAddOnProduct';
import useGetEHRAddOnUniprofileInfo from 'hooks/useGetEHRAddOnUniprofileInfo';

type TCardDataItem = {
    self: Partial<Record<EAddOnProductCode, TShoppingCartItem[]>>,
    collaboratingPhysician: Partial<Record<EAddOnProductCode, TShoppingCartItem[]>>,
    assistant: Partial<Record<EAddOnProductCode, TShoppingCartItem[]>>,
};
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
const selfCodes = [EAddOnProductCode.SELF_EFAX, EAddOnProductCode.SELF_EPRESCRIPTION, EAddOnProductCode.SELF_EPRESCRIPTION_EPCS];
const collaboratingPhysicianCodes = [EAddOnProductCode.COLLABORATING_PHYSICIAN_EPRESCRIPTION, EAddOnProductCode.COLLABORATING_PHYSICIAN_EPRESCRIPTION_EPCS, EAddOnProductCode.COLLABORATING_PHYSICIAN_SEAT];
const assistantCodes = [EAddOnProductCode.ASSISTANT_EPRESCRIPTION, EAddOnProductCode.ASSISTANT_EPRESCRIPTION_EPCS, EAddOnProductCode.ASSISTANT_SEAT];

const EHRCart = () => {
    const navigate = useNavigate();
    const [shoppingCart, loadingCart, refetchCart] = useShoppingCart();
    const [stripePaymentInfo, loadingPaymentInfo] = useGetStripePaymentInfo();
    const [addonUniprofileInfo, loadingUniprofileInfo] = useGetEHRAddOnUniprofileInfo();
    const [totalPrice, setTotalPrice] = useState(0);
    const [removeLoading, setRemoveLoading] = useState(false);
    const [showErrorMessageBox, setShowErrorMessageBox] = useState(false);
    const defaultErrorMessage = 'Unfortunately, your payment could not be processed. Please check your payment details and try again, or contact your bank for more information.';
    const [mainErrorMessage, setMainErrorMessage] = useState(defaultErrorMessage);
    const [cartData, setCardData] = useState<TCardDataItem>({
        self: {},
        collaboratingPhysician: {},
        assistant: {},
    });
    const [showStripeForm, setShowStripeForm] = useState(true);
    const [fastPaymentLoading, setFastPaymentLoading] = useState(false);
    const [showEfaxWarning, setShowEfaxWarning] = useState(false);
    const [efaxProductCode, setEfaxProductCode] = useState<EAddOnProductCode>();
    const [resetEfaxModal, setResetEfaxModal] = useState<Record<any, any>>();
    const [currentPaymentMethodId, setCurrentPaymentMethodId] = useState<string | undefined>();
    const nowDate = useRef(dayjs().format('MMM DD, YYYY'));
    const [allItems, loadingProduct, refetchProduct] = useAddOnProduct();

    const removeCartItem = useCallback(async (productCode?: EAddOnProductCode) => {
        if (productCode) {
            setRemoveLoading(true);

            try {
                const res = await removeFromShoppingCart(productCode);

                if (res && !res.error) {
                    setShowEfaxWarning(false);
                    setResetEfaxModal({});
                    refetchCart();
                } else {
                    message.error(res?.error);
                }
            } catch (e) {
                console.error(e);
            }

            setRemoveLoading(false);
        }
    }, [refetchCart]);

    const handleUseOtherCard = useCallback(() => {
        setShowStripeForm(true);
    }, []);

    const items = shoppingCart?.items;

    const getTotalPrice = useCallback((data: TShoppingCartItem[]) => {
        return data.reduce((acc, item) => acc + item.price * item.productQty, 0);
    }, []);

    const groupedData = useCallback((data: TShoppingCartItem[]) => {
        if (data && data.length > 0) {
            return data.reduce((acc, item) => {
                if (selfCodes.includes(item.productCode)) {
                    if (!acc.self[item.productCode]) {
                        acc.self[item.productCode] = [];
                    }
                    acc.self[item.productCode]?.push(item);
                }

                if (collaboratingPhysicianCodes.includes(item.productCode)) {
                    if (!acc.collaboratingPhysician[item.productCode]) {
                        acc.collaboratingPhysician[item.productCode] = [];
                    }
                    acc.collaboratingPhysician[item.productCode]?.push(item);
                }

                if (assistantCodes.includes(item.productCode)) {
                    if (!acc.assistant[item.productCode]) {
                        acc.assistant[item.productCode] = [];
                    }
                    acc.assistant[item.productCode]?.push(item);
                }

                return acc;
            }, {
                self: {},
                collaboratingPhysician: {},
                assistant: {},
            } as TCardDataItem);
        }

        return {
            self: {},
            collaboratingPhysician: {},
            assistant: {},
        };
    }, []);

    useEffect(() => {
        if (items) {
            const data = groupedData(items);

            setCardData(data);

            const total = getTotalPrice(items);
            setTotalPrice(total);
        }
    }, [getTotalPrice, groupedData, items]);

    useEffect(() => {
        if (stripePaymentInfo) {
            setShowStripeForm(stripePaymentInfo.length === 0);
        }
    }, [stripePaymentInfo]);

    const checkoutCartItems = useMemo(() => {
        const data = shoppingCart?.items || [];
        return data.map((item) => {
            return {
                productCode: item.productCode,
                productQty: item.productQty,
            };
        });
    }, [shoppingCart]);

    const back = useCallback(() => {
        navigate(`${PATH.DASHBOARD}/${PATH.EHR_ADD_ON_PROVIDER}`);
    }, [navigate]);

    const paidSucceess = useCallback(() => {
        message.success('Your payment was successful!', () => {
            navigate(`${PATH.DASHBOARD}/${PATH.EHR_ADD_ON_PROVIDER}`, { replace: true });
        });
    }, [navigate]);

    const fastPay = useCallback(async (paymentMethodId: string) => {
        if (paymentMethodId && !fastPaymentLoading) {
            setCurrentPaymentMethodId(paymentMethodId);
            setFastPaymentLoading(true);
            const paymentSubmitData:IEHRCheckoutInput = {
                paymentMethodId,
                newCustomerOption: false,
                ehrAddonShoppingCartReqList: checkoutCartItems,
            };
            try {
                const resp = await shoppingCartCheckout(paymentSubmitData);

                if (resp && !resp.error) {
                    paidSucceess();
                } else {
                    setMainErrorMessage(resp.error || defaultErrorMessage);
                    setShowErrorMessageBox(true);
                    setFastPaymentLoading(false);
                }
            } catch (e) {
                console.error(e);
                setFastPaymentLoading(false);
            }

            setCurrentPaymentMethodId(undefined);
        }
    }, [checkoutCartItems, fastPaymentLoading, paidSucceess]);

    const shouldShowWarningPopup = useMemo(() => {
        let hasEFax = false;

        const efaxProduct = allItems.self.find((item) => item.productCode === EAddOnProductCode.SELF_EFAX);

        if (efaxProduct && !!efaxProduct.paymentStatus) {
            hasEFax = true;
        } else if (
            ['1'].includes(
                addonUniprofileInfo?.addonService?.eFaxOption || '',
            )
        ) {
            hasEFax = false;
        }

        const eprescription = items?.find((item) =>
            [
                EAddOnProductCode.SELF_EPRESCRIPTION,
                EAddOnProductCode.SELF_EPRESCRIPTION_EPCS,
            ].includes(item.productCode),
        );

        return !!eprescription && !hasEFax;
    }, [addonUniprofileInfo?.addonService?.eFaxOption, allItems.self, items]);

    if (loadingCart || loadingPaymentInfo || loadingProduct) {
        return <div className={s.spin}><Spin /></div>;
    }

    return (
        <div className={s.wrap}>
            <Spin spinning={removeLoading || loadingCart}>
                <SelfEFaxWarning
                    clearFlag={resetEfaxModal}
                    open={showEfaxWarning}
                    onChange={({ isRemoveEFaxNumber }) => {
                        if (isRemoveEFaxNumber) {
                            removeCartItem(efaxProductCode);
                        } else {
                            refetchCart();
                        }
                    }}
                    onClose={() => {
                        setShowEfaxWarning(false);
                        setResetEfaxModal({});
                    }}
                />
                <div className={s.header}>
                    <div className={s.nav} onClick={back}>
                        <div className={s.backIcon} />
                        <div className={s.backText}>Practice enablement add-ons</div>
                    </div>
                    <div className={s.title}>Cart</div>
                </div>
                {items && items.length > 0 ? (
                    <div className={s.body}>
                        <div className={s.cartInfo}>
                            <div className={s.summaryPrice}>
                                <div className={s.item}>
                                    <div className={s.labelBox}>
                                        <div className={s.label}>Due today</div>
                                    </div>
                                    <div className={s.price}>$0.00</div>
                                </div>
                                <div className={`${s.item} ${s.alignTop}`}>
                                    <div className={s.labelBox}>
                                        <div className={s.label}>Starting {nowDate.current}</div>
                                        <div className={s.subLabel}>
                                            Add-ons for yourself, your collaborating physician, and
                                            your assistant (billed on {nowDate.current}, then
                                            monthly)
                                        </div>
                                    </div>
                                    <div className={s.price}>${get2Decimal(totalPrice)}</div>
                                </div>
                            </div>
                            <div className={s.summaryDetail}>
                                <div className={s.header}>
                                    <div className={s.label}>Subtotal</div>
                                    <div className={s.price}>${get2Decimal(totalPrice)}</div>
                                </div>
                                <div className={s.details}>
                                    <div className={s.title}>Selected add-ons</div>
                                    {
                                        (
                                            (cartData.self?.[
                                                EAddOnProductCode.SELF_EPRESCRIPTION
                                            ] && cartData.self[
                                                EAddOnProductCode.SELF_EPRESCRIPTION
                                            ].length > 0) ||
                                            (cartData.self?.[
                                                EAddOnProductCode.SELF_EPRESCRIPTION_EPCS
                                            ] && cartData.self[
                                                EAddOnProductCode.SELF_EPRESCRIPTION_EPCS
                                            ].length > 0) ||
                                            (cartData.self?.[
                                                EAddOnProductCode.SELF_EFAX
                                            ] && cartData.self[
                                                EAddOnProductCode.SELF_EFAX
                                            ].length > 0)
                                        ) && (
                                            <div className={s.listBlock}>
                                                <div className={s.tag}>For yourself</div>
                                                <div className={s.list}>
                                                    {cartData.self[
                                                        EAddOnProductCode.SELF_EPRESCRIPTION
                                                    ]?.map((item) => {
                                                        const {
                                                            productCode,
                                                            price,
                                                            productQty,
                                                            ehrAddonShoppingCartId,
                                                            productName,
                                                        } = item;
                                                        return (
                                                            <div
                                                                key={ehrAddonShoppingCartId}
                                                                className={s.listItem}
                                                            >
                                                                <div className={s.label}>{productName}</div>
                                                                <div className={s.price}>
                                                                    ${get2Decimal(productQty * price)}
                                                                </div>
                                                                <div
                                                                    className={s.action}
                                                                    onClick={() => removeCartItem(productCode)}
                                                                >
                                                                    remove
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                    {cartData.self[
                                                        EAddOnProductCode.SELF_EPRESCRIPTION_EPCS
                                                    ]?.map((item) => {
                                                        const {
                                                            productCode,
                                                            price,
                                                            productQty,
                                                            ehrAddonShoppingCartId,
                                                            productName,
                                                        } = item;
                                                        return (
                                                            <div
                                                                key={ehrAddonShoppingCartId}
                                                                className={s.listItem}
                                                            >
                                                                <div className={s.label}>{productName}</div>
                                                                <div className={s.price}>
                                                                    ${get2Decimal(productQty * price)}
                                                                </div>
                                                                <div
                                                                    className={s.action}
                                                                    onClick={() => removeCartItem(productCode)}
                                                                >
                                                                    remove
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                    {cartData.self[EAddOnProductCode.SELF_EFAX]?.map(
                                                        (item) => {
                                                            const {
                                                                productCode,
                                                                price,
                                                                productQty,
                                                                ehrAddonShoppingCartId,
                                                                productName,
                                                            } = item;
                                                            return (
                                                                <div
                                                                    key={ehrAddonShoppingCartId}
                                                                    className={s.listItem}
                                                                >
                                                                    <div className={s.label}>{productName}</div>
                                                                    <div className={s.price}>
                                                                        ${get2Decimal(productQty * price)}
                                                                    </div>
                                                                    <div
                                                                        className={s.action}
                                                                        onClick={() => {
                                                                            if (shouldShowWarningPopup) {
                                                                                setEfaxProductCode(productCode);
                                                                                setShowEfaxWarning(true);
                                                                            } else {
                                                                                removeCartItem(productCode);
                                                                            }
                                                                        }}
                                                                    >
                                                                        remove
                                                                    </div>
                                                                </div>
                                                            );
                                                        },
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        (
                                            (cartData.collaboratingPhysician?.[
                                                EAddOnProductCode.COLLABORATING_PHYSICIAN_EPRESCRIPTION
                                            ] && cartData.collaboratingPhysician[
                                                EAddOnProductCode.COLLABORATING_PHYSICIAN_EPRESCRIPTION
                                            ].length > 0) ||
                                            (cartData.collaboratingPhysician?.[
                                                EAddOnProductCode.COLLABORATING_PHYSICIAN_EPRESCRIPTION_EPCS
                                            ] && cartData.collaboratingPhysician[
                                                EAddOnProductCode.COLLABORATING_PHYSICIAN_EPRESCRIPTION_EPCS
                                            ].length > 0) ||
                                            (cartData.collaboratingPhysician?.[
                                                EAddOnProductCode.COLLABORATING_PHYSICIAN_SEAT
                                            ] && cartData.collaboratingPhysician[
                                                EAddOnProductCode.COLLABORATING_PHYSICIAN_SEAT
                                            ].length > 0)
                                        ) && (
                                            <div className={s.listBlock}>
                                                <div className={s.tag}>
                                                    For your collaborating physician
                                                </div>
                                                <div className={s.list}>
                                                    {cartData.collaboratingPhysician[
                                                        EAddOnProductCode
                                                            .COLLABORATING_PHYSICIAN_EPRESCRIPTION
                                                    ]?.map((item) => {
                                                        const {
                                                            productCode,
                                                            price,
                                                            productQty,
                                                            ehrAddonShoppingCartId,
                                                            productName,
                                                        } = item;
                                                        return (
                                                            <div
                                                                key={ehrAddonShoppingCartId}
                                                                className={s.listItem}
                                                            >
                                                                <div className={s.label}>{productName}</div>
                                                                <div className={s.quantity}>x{productQty}</div>
                                                                <div className={s.price}>
                                                                    ${get2Decimal(productQty * price)}
                                                                </div>
                                                                <div
                                                                    className={s.action}
                                                                    onClick={() => removeCartItem(productCode)}
                                                                >
                                                                    remove
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                    {cartData.collaboratingPhysician[
                                                        EAddOnProductCode
                                                            .COLLABORATING_PHYSICIAN_EPRESCRIPTION_EPCS
                                                    ]?.map((item) => {
                                                        const {
                                                            productCode,
                                                            price,
                                                            productQty,
                                                            ehrAddonShoppingCartId,
                                                            productName,
                                                        } = item;
                                                        return (
                                                            <div
                                                                key={ehrAddonShoppingCartId}
                                                                className={s.listItem}
                                                            >
                                                                <div className={s.label}>{productName}</div>
                                                                <div className={s.quantity}>x{productQty}</div>
                                                                <div className={s.price}>
                                                                    ${get2Decimal(productQty * price)}
                                                                </div>
                                                                <div
                                                                    className={s.action}
                                                                    onClick={() => removeCartItem(productCode)}
                                                                >
                                                                    remove
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                    {cartData.collaboratingPhysician[
                                                        EAddOnProductCode.COLLABORATING_PHYSICIAN_SEAT
                                                    ]?.map((item) => {
                                                        const {
                                                            productCode,
                                                            price,
                                                            productQty,
                                                            ehrAddonShoppingCartId,
                                                            productName,
                                                        } = item;
                                                        return (
                                                            <div
                                                                key={ehrAddonShoppingCartId}
                                                                className={s.listItem}
                                                            >
                                                                <div className={s.label}>{productName}</div>
                                                                <div className={s.quantity}>x{productQty}</div>
                                                                <div className={s.price}>
                                                                    ${get2Decimal(productQty * price)}
                                                                </div>
                                                                <div
                                                                    className={s.action}
                                                                    onClick={() => removeCartItem(productCode)}
                                                                >
                                                                    remove
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        (
                                            (cartData.assistant?.[
                                                EAddOnProductCode.ASSISTANT_EPRESCRIPTION
                                            ] && cartData.assistant[
                                                EAddOnProductCode.ASSISTANT_EPRESCRIPTION
                                            ].length > 0) ||
                                            (cartData.assistant?.[
                                                EAddOnProductCode.ASSISTANT_EPRESCRIPTION_EPCS
                                            ] && cartData.assistant[
                                                EAddOnProductCode.ASSISTANT_EPRESCRIPTION_EPCS
                                            ].length > 0) ||
                                            (cartData.assistant?.[
                                                EAddOnProductCode.ASSISTANT_SEAT
                                            ] && cartData.assistant[
                                                EAddOnProductCode.ASSISTANT_SEAT
                                            ].length > 0)
                                        ) && (
                                            <div className={s.listBlock}>
                                                <div className={s.tag}>For your assistant</div>
                                                <div className={s.list}>
                                                    {cartData.assistant[
                                                        EAddOnProductCode.ASSISTANT_EPRESCRIPTION
                                                    ]?.map((item) => {
                                                        const {
                                                            productCode,
                                                            price,
                                                            productQty,
                                                            ehrAddonShoppingCartId,
                                                            productName,
                                                        } = item;
                                                        return (
                                                            <div
                                                                key={ehrAddonShoppingCartId}
                                                                className={s.listItem}
                                                            >
                                                                <div className={s.label}>{productName}</div>
                                                                <div className={s.quantity}>x{productQty}</div>
                                                                <div className={s.price}>
                                                                    ${get2Decimal(productQty * price)}
                                                                </div>
                                                                <div
                                                                    className={s.action}
                                                                    onClick={() => removeCartItem(productCode)}
                                                                >
                                                                    remove
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                    {cartData.assistant[
                                                        EAddOnProductCode.ASSISTANT_EPRESCRIPTION_EPCS
                                                    ]?.map((item) => {
                                                        const {
                                                            productCode,
                                                            price,
                                                            productQty,
                                                            ehrAddonShoppingCartId,
                                                            productName,
                                                        } = item;
                                                        return (
                                                            <div
                                                                key={ehrAddonShoppingCartId}
                                                                className={s.listItem}
                                                            >
                                                                <div className={s.label}>{productName}</div>
                                                                <div className={s.quantity}>x{productQty}</div>
                                                                <div className={s.price}>
                                                                    ${get2Decimal(productQty * price)}
                                                                </div>
                                                                <div
                                                                    className={s.action}
                                                                    onClick={() => removeCartItem(productCode)}
                                                                >
                                                                    remove
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                    {cartData.assistant[
                                                        EAddOnProductCode.ASSISTANT_SEAT
                                                    ]?.map((item) => {
                                                        const {
                                                            productCode,
                                                            price,
                                                            productQty,
                                                            ehrAddonShoppingCartId,
                                                            productName,
                                                        } = item;
                                                        return (
                                                            <div
                                                                key={ehrAddonShoppingCartId}
                                                                className={s.listItem}
                                                            >
                                                                <div className={s.label}>{productName}</div>
                                                                <div className={s.quantity}>x{productQty}</div>
                                                                <div className={s.price}>
                                                                    ${get2Decimal(productQty * price)}
                                                                </div>
                                                                <div
                                                                    className={s.action}
                                                                    onClick={() => removeCartItem(productCode)}
                                                                >
                                                                    remove
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )

                                    }
                                </div>
                            </div>
                        </div>
                        <div className={s.strpe}>
                            <div className={s.paymentFormWrap}>
                                <div className={s.titleBox}>
                                    <div className={s.paymentTitle}>Payment detail</div>
                                    <div className={s.paymentSubtitle}>
                                        <div className={s.safeIcon} />
                                        <div className={s.paymentSubtitleText}>
                                            Guaranteed safe & secure checkout
                                        </div>
                                        <div className={s.stripeBrand} />
                                    </div>
                                </div>
                                {showErrorMessageBox && (
                                    <div className={s.errorMessageBox}>
                                        <div className={s.errorMessageInner}>
                                            <div className={s.wrongIcon} />
                                            <div className={s.errorText}>{mainErrorMessage}</div>
                                            <div
                                                className={s.closeIcon}
                                                onClick={() => setShowErrorMessageBox(false)}
                                            />
                                        </div>
                                    </div>
                                )}
                                {showStripeForm ? (
                                    <Elements stripe={stripePromise}>
                                        <PaymentForm
                                            defaultErrorMessage={defaultErrorMessage}
                                            setMainErrorMessage={setMainErrorMessage}
                                            setShowErrorMessageBox={setShowErrorMessageBox}
                                            cartItems={checkoutCartItems}
                                            onNext={paidSucceess}
                                        />
                                    </Elements>
                                ) : (
                                    <div className={s.fastPayment}>
                                        <div className={s.fastPaymentTitle}>
                                            List of used cards
                                        </div>
                                        <div className={s.list}>
                                            {stripePaymentInfo?.map((item, index) => {
                                                const brand = item?.card?.brand?.toLowerCase() || '';
                                                const last4 = item?.card?.last4?.toLowerCase();
                                                return (
                                                    <div key={index} className={s.item}>
                                                        <div className={`${s.brand} ${s[brand]}`} />
                                                        <div className={s.cardNumber}>
                                                            ********{last4}
                                                        </div>
                                                        <Button
                                                            className={s.fastPaymentButton}
                                                            type="primary"
                                                            onClick={() => fastPay(item.id)}
                                                            loading={
                                                                currentPaymentMethodId === item.id &&
                                  fastPaymentLoading
                                                            }
                                                        >
                                                            Pay with this card
                                                        </Button>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div
                                            className={s.useOtherCard}
                                            onClick={handleUseOtherCard}
                                        >
                                            Use a different card
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={s.emptyCart}>
                        <div className={s.emptyImage} />
                        <div className={s.title}>Your cart is empty</div>
                        <Button type="primary" onClick={back}>
                            Browse add-ons
                        </Button>
                    </div>
                )}
            </Spin>
        </div>
    );
};

export default EHRCart;
