import { Modal, Checkbox, DatePicker, Input } from 'antd';
import React from 'react';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { TCollaboratingPhysician } from 'types/common';
import useCollaboratingPhysician from 'hooks/useCollaboratingPhysician';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { formatDate } from 'utils/common';
import dayjs from 'dayjs';
import { REQUIRED_FIELD } from 'constants/common';
import { EhrAddOnItem } from 'types/ehr';
import { addToShoppingCart } from 'api/ehr';

type Props = {
    clearFlag?: {};
    open:boolean;
    onAdd:(item?:TCollaboratingPhysician[], close?:boolean)=>void;
    onClose: ()=>void;
    item?: EhrAddOnItem;
    productLimitChangeFlag?:{};
};

type AddInfo = {
    name: string,
    email: string,
    birthdayDate?: string,
    deaNumber?: string,
    needAddBirthdayDate: boolean,
    needAddDeaNumber: boolean,
    showBirthdayDateError?: boolean,
    showDeaNumberError?: boolean,
};

const CollaboratingPhysicianEPrescriptionAndEpcsModal = (props:Props) => {
    const { clearFlag, open, onAdd, onClose, productLimitChangeFlag } = props;
    const product:EhrAddOnItem | undefined = props.item;
    const [allCollaboratingPhysician, loading, refetch] = useCollaboratingPhysician(product?.productCode as any);
    const [submiting, setSubmiting] = React.useState(false);

    const [emailOptions, setEmailOptions] = React.useState<string[]>([]);
    const [purchasedEmails, setPurchasedEmails] = React.useState<string[]>([]); //emails

    const [pendingSelected, setPendingSelected] = React.useState<string[]>(); //emails
    const pendingRef = React.useRef<string[]>([]);

    const needInfoItem = React.useRef<AddInfo[]>();
    const [,forceUpdate] = React.useState({});

    const PRICE_PER = product?.price || 0;

    const handleCheckboxChange = (checkedValues: CheckboxValueType[]) => {
        const values = checkedValues as string[];
        pendingRef.current = [...values];
        setPendingSelected([...values]);
    };

    const reset = () => {
        pendingRef.current = [];
        needInfoItem.current = [];
        setPendingSelected([]);
        //refetch();
        onAdd?.(undefined, true);
    };

    React.useEffect(() => {
        if (clearFlag) {
            reset();
        }
    }, [clearFlag]);

    React.useEffect(() => {
        if (open) {
            //refetch and add default
            refetch();
        }
    }, [open]);

    React.useEffect(() => {
        refetch();
    }, [productLimitChangeFlag]);

    React.useEffect(() => {
        if (pendingSelected && pendingSelected.length > 0) {
            needInfoItem.current = [];
            const allData:TCollaboratingPhysician[] = [...allCollaboratingPhysician];
            pendingSelected?.forEach((email:string) => {
                const target = allData.find((item) => item.email === email);
                if (target) {
                    if (!target.deaNumber || !target.birthdayDate) {
                        needInfoItem.current!.push({
                            name: target.name,
                            email: target.email,
                            birthdayDate: target.birthdayDate,
                            deaNumber: target.deaNumber,
                            needAddBirthdayDate: !target.birthdayDate,
                            needAddDeaNumber: !target.deaNumber,
                        });
                    }
                }
            });
        } else {
            needInfoItem.current = [];
        }
        forceUpdate({});
    }, [allCollaboratingPhysician, pendingSelected]);

    // React.useEffect(() => {
    //     if (pendingRef.current.length === 0) {
    //         return;
    //     }
    //     //reset when data change
    //     pendingRef.current = pendingRef.current?.filter((email:string) => {
    //         return emailOptions.includes(email);
    //     });
    //     setPendingSelected([...pendingRef.current]);

    //     const addData:TCollaboratingPhysician[] = [];
    //     const allData:TCollaboratingPhysician[] = [...allCollaboratingPhysician];
    //     pendingRef.current?.forEach((email:string) => {
    //         const target = allData.find((item) => item.email === email);
    //         if (target) {
    //             addData.push(target);
    //         }
    //     });
    // }, [emailOptions]);

    React.useEffect(() => {
        const _emailOptions:string[] = [];
        const _purchasedEmails:string[] = [];
        if (allCollaboratingPhysician) {
            allCollaboratingPhysician.forEach((item) => {
                //only add email if they limit productPurchased
                if (item.email && !_purchasedEmails.includes(item.email) && item.productPurchased) {
                    _purchasedEmails.push(item.email!);
                }
                if (item.email && !_emailOptions.includes(item.email!) && item.productLimitAdded)
                {
                    _emailOptions.push(item.email!);
                }
            });
        }
        setEmailOptions(_emailOptions);
        setPurchasedEmails(_purchasedEmails);
        const currentPendingSelect = pendingRef.current;
        const _pendingSelect = currentPendingSelect?.filter((item) => _emailOptions.includes(item));
        pendingRef.current = _pendingSelect;
        setPendingSelected(_pendingSelect);
    }, [allCollaboratingPhysician]);

    const handleOk = async () => {
        if (needInfoItem.current && needInfoItem.current.length > 0) {
            let hasError = false;
            needInfoItem.current.forEach((item) => {
                if (!item.birthdayDate) {
                    item.showBirthdayDateError = true;
                    hasError = true;
                }
                if (!item.deaNumber) {
                    item.showDeaNumberError = true;
                    hasError = true;
                }
            });
            if (hasError) {
                forceUpdate({});
                return;
            }
        }

        //const allData:TCollaboratingPhysician[] = [...allCollaboratingPhysician, ...(pendingCollaboratingPhysicianSeat || [])];
        const addData:TCollaboratingPhysician[] = [];
        const physicianIdList: number[] = [];
        const physicianList: TCollaboratingPhysician[] = [];
        pendingSelected?.forEach((email:string) => {
            const target = allCollaboratingPhysician?.find((item) => item.email === email);
            if (target) {
                //means has id
                const addInfo:AddInfo | undefined = needInfoItem.current?.find((item) => item.email === email);
                const addNewData:TCollaboratingPhysician = { ...target };
                if (addInfo) {
                    addNewData.birthdayDate = addInfo.birthdayDate;
                    addNewData.deaNumber = addInfo.deaNumber;
                }
                physicianList.push(addNewData);
                physicianIdList.push(target.id!);
                addData.push(addNewData);
            } else {
                //can remove this logic?
                //const target2 = pendingCollaboratingPhysicianSeat?.find((item) => item.email === email);
            }
        });
        //call api
        setSubmiting(true);
        const result = await addToShoppingCart(product?.productCode!, physicianIdList.length, {
            physicianIdList,
            physicianList,
        });
        setSubmiting(false);
        if (!result.error) {
            onAdd?.(addData, true);
        }
    };

    const needInfo = needInfoItem.current && needInfoItem.current.length > 0;

    const isEmpty = emailOptions && emailOptions.length === 0;
    const hasSelect = (pendingSelected?.length || 0) > 0;
    return (
        <Modal
            title="e-Prescription + EPCS (Prescribing role)"
            open={open}
            destroyOnClose
            className={commonS.modalFixHeightWrap}
            width="700px"
            okText={isEmpty ? 'Close' : 'Add'}
            onCancel={() => {
                onClose();
            }}
            onOk={() => {
                if (isEmpty) {
                    onClose();
                } else {
                    handleOk();
                }
            }}
            okButtonProps={{
                loading: submiting,
                disabled: !isEmpty && pendingSelected?.length === 0,
            }}
            cancelButtonProps={{
                style: {
                    display: isEmpty ? 'none' : 'inline-block',
                },
            }}
        >
            <div className={s.wrap}>
                <div className={hasSelect ? `${s.title} ${s.titleShow}` : s.title}>You&apos;ve added {pendingSelected?.length || 0} e-Prescription + EPCS (Prescribing role) for your collaborating physicians, it will be ${PRICE_PER * (pendingSelected?.length || 0)} monthly.</div>
                <div className={s.content}>
                    <div className={s.cContent}>Choose the collaborating physicians from your Kiwi EHR who need e-Prescription + EPCS</div>
                    {isEmpty
                        && <div className={s.empty}>You don’t have any collaborating physicians, <br />please add collaborating physicians seats</div>
                    }
                    <Checkbox.Group
                        style={{ width: '100%', display: 'block' }}
                        onChange={handleCheckboxChange}

                    >
                        {emailOptions.map((item) => {
                            const target = allCollaboratingPhysician?.find((as) => as.email === item);
                            const checked = pendingSelected?.find((i) => i === item);
                            return (
                                <div key={item} className={s.item}>
                                    <Checkbox
                                        value={item}
                                        disabled={purchasedEmails.includes(item)}
                                        checked={!!checked}
                                    >{target?.name} - {item}
                                    </Checkbox>
                                </div>
                            );
                        })}
                    </Checkbox.Group>
                </div>
                {
                    needInfo &&
                    <div className={s.content}>
                        <div className={s.title}>Information required  </div>
                        {
                            needInfoItem?.current?.map((item, index) => {
                                return (
                                    <div className={s.addOnItem} key={item.email}>
                                        <div className={s.row}>
                                            <div
                                                style={{
                                                    width: '48%',
                                                }}
                                                className={s.displayItem}
                                            >
                                                <div className={s.label}>Name</div>
                                                <div className={s.value}>{item.name}</div>
                                            </div>
                                            <div
                                                style={{
                                                    width: '48%',
                                                }}
                                                className={s.displayItem}
                                            >
                                                <div className={s.label}>Email</div>
                                                <div className={s.value}>{item.email}</div>
                                            </div>
                                        </div>
                                        <div className={s.row}>
                                            <div
                                                style={{
                                                    width: '48%',
                                                }}
                                                className={s.displayItem}
                                            >
                                                <div className={s.label}>Date of Birth <span className={s.need}>*</span></div>
                                                <div className={s.value}>
                                                    {
                                                        item.needAddBirthdayDate &&
                                                        <DatePicker
                                                            clearIcon={null}
                                                            onChange={(val) => {
                                                                if (val) {
                                                                    const date = formatDate(val);
                                                                    item.birthdayDate = date;
                                                                    item.showBirthdayDateError = false;
                                                                    forceUpdate({});
                                                                }
                                                            }}
                                                            disabledDate={(current) => {
                                                                const day18Ago = dayjs().subtract(18, 'year');
                                                                return current.valueOf() > day18Ago.valueOf();
                                                            }}
                                                            defaultPickerValue={dayjs().subtract(18, 'year')}
                                                            style={{ width: '100%' }}
                                                        />
                                                    }
                                                    {
                                                        !item.needAddBirthdayDate &&
                                                        formatDate(dayjs(item.birthdayDate))
                                                    }
                                                </div>
                                                <div className={`${s.error} ${item.showBirthdayDateError ? s.errorShow : ''}`}>
                                                    {REQUIRED_FIELD}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={s.row}>
                                            <div
                                                style={{
                                                    width: '75%',
                                                }}
                                                className={s.displayItem}
                                            >
                                                <div className={s.label}>DEA license number <span className={s.need}>*</span></div>
                                                <div className={s.value}>
                                                    {
                                                        item.needAddDeaNumber &&
                                                        <Input
                                                            onChange={(e) => {
                                                                item.deaNumber = e.target.value;
                                                                item.showDeaNumberError = !e.target.value;
                                                                forceUpdate({});
                                                            }}
                                                            style={{ width: '100%' }}
                                                        />
                                                    }
                                                    {
                                                        !item.needAddDeaNumber &&
                                                        item.birthdayDate
                                                    }
                                                </div>
                                                <div className={`${s.error} ${item.showDeaNumberError ? s.errorShow : ''}`}>
                                                    {REQUIRED_FIELD}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                }
            </div>
        </Modal>
    );
};

export default CollaboratingPhysicianEPrescriptionAndEpcsModal;
