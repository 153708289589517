export const TITLE = 'New to Kiwi EHR? Watch our quick video tutorial to learn how to get the most out of Kiwi EHR and deliver exceptional patient care.';

export const CONFIG = {
    title: 'In the tutorial, you will learn: ',
    list: [
        'How to setup your avilability',
        'How to manage your appointment',
        'How to join video calls for your appointment',
        'How to manage your patients',
    ],
};

export default CONFIG;
