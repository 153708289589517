import React, { useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { Input } from 'antd';

interface IProps {
    itemBoxClass?: string;
    title: string;
    label:string[]
    value?: { userName: string; password: string; };
    onChange?: (value: { userName: string; password: string; }) => void;
}

const FormItem = ({
    itemBoxClass = '',
    title,
    value,
    label,
    onChange,
}: IProps) => {
    const [val, setVal] = useState({
        userName: '',
        password: '',
    });

    useEffect(() => {
        if (value) {
            setVal(value);
        }
    }, [value]);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, key: string) => {
        const newValue = {
            ...val,
            [key]: e.target.value,
        };
        setVal(newValue);
        onChange?.(newValue);
    }, [onChange, val]);

    return (
        <div className={s.formItem}>
            <div className={s.itemTitle}>{title} <span className={s.tag}>Privacy</span></div>
            <div className={`${s.itemBox} ${itemBoxClass}`}>
                <div className={s.label}>{label[0]}</div>
                <Input maxLength={255} value={val.userName} onChange={(e) => handleChange(e, 'userName')} />
            </div>
            <div className={`${s.itemBox} ${itemBoxClass}`}>
                <div className={s.label}>{label[1]}</div>
                <Input.Password maxLength={255} visibilityToggle value={val.password} onChange={(e) => handleChange(e, 'password')} />
            </div>
        </div>
    );
};

export default FormItem;
