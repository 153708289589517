import React, { useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { Desktop } from 'assets/preview/Desktop';
import { Mobile } from 'assets/preview/Mobile';
import PracticeFrontPreviewStore from 'store/PracticeFrontPreview';
import os from 'assets/preview/OS.svg';
import { message } from 'antd';
import { getUrlParam } from 'utils/common';
import { getHelloKlairtyProfileLink } from 'api/operation';
import { IHelloKlarityViewPostMessageData } from 'types/operation';
import { prereleaseOriginReplacement } from 'utils/helloklarity';
import { PRE_RELEASE_ORIGIN } from 'constants/common';
// import { isProductionEnv } from 'utils/common';
// import { EPracticeFrontPreviewPage } from 'types/channel';
// import { getUserId } from 'utils/localstore';

type Props = {
    // currentLink:string;
    state?: string;
    city?: string;
    slug: string;
    providerId?: number
    setIframeEl?: (iframe: HTMLIFrameElement) => void;
    onLoad?: (status: boolean) => void;
    handlePlatformChange?: (platform: string) => void;
    postMessageData: IHelloKlarityViewPostMessageData;
};

const type = {
    getHeight: 'getHeight',
    refresh: 'refresh',
    returnHeight: 'returnHeight',
};

const Page = (prop: Props) => {
    const {
        onLoad,
        setIframeEl,
        handlePlatformChange,
        providerId,
        postMessageData,
    } = prop;
    // const [pageHeight, setPageHeight] = React.useState(2100);
    const [get, set] = PracticeFrontPreviewStore.useStore();
    const flag = get('flag');

    const isMobileMode = get('isMobile');
    const talbeRowId = getUrlParam('id');
    const [currentLink, setCurrentLink] = useState('');
    // const host = isProductionEnv() ? 'https://provider.kiwihealth.com' : 'https://dev.d31frxe1u41op.amplifyapp.com';
    // const host = isProductionEnv() ? 'https://provider.kiwihealth.com' : 'http://localhost:3010';

    const getProfileUrl = useCallback(async (id: number) => {
        try {
            const res = await getHelloKlairtyProfileLink(id);

            if (res && res.data?.data && res.data?.data?.length > 0 && !res.error) {
                const linkUrl = res.data.data[0];
                const url = new URL(linkUrl);
                const providerIdSearch = providerId ? `&id=${providerId}` : '';
                const newUrl = prereleaseOriginReplacement(linkUrl, PRE_RELEASE_ORIGIN);

                if (url.search) {
                    setCurrentLink(`${newUrl}&isPreview=true${providerIdSearch}`);
                } else {
                    setCurrentLink(`${newUrl}?isPreview=true${providerIdSearch}`);
                }
                // setCurrentLink('http://localhost:3010/provider/georgia/atlanta/benjamin-gatliff?isPreview=true');
            } else {
                message.error(res.error);
            }
        } catch (e) {
            console.error(e);
        }
    }, [providerId]);

    useEffect(() => {
        if (talbeRowId) {
            getProfileUrl(Number(talbeRowId));
        }
    }, [getProfileUrl, talbeRowId]);

    let editAreaHeight:number = 0;
    const leftDom = document.getElementById('pf-left');
    if (leftDom) {
        editAreaHeight = leftDom.clientHeight;
    }

    // const sendHeight = () => {
    //     try {
    //         const pfIframe:any = document.getElementById('pf-preview');
    //         const iframeWin = pfIframe.contentWindow;
    //         const message = {
    //             type: type.getHeight,
    //             ...postMessageData,
    //         };
    //         iframeWin.postMessage(message, '*');
    //     } catch (e) {
    //         console.error(e);
    //     }
    // };

    // const sendRefresh = () => {
    //     try {
    //         const pfIframe:any = document.getElementById('pf-preview');
    //         const iframeWin = pfIframe.contentWindow;
    //         const message = {
    //             type: type.refresh,
    //             ...postMessageData,
    //         };
    //         iframeWin.postMessage(message, '*');
    //         setTimeout(() => {
    //             sendHeight();
    //         }, 5000);
    //     } catch (e) {
    //         console.error(e);
    //     }
    // };

    React.useEffect(() => {
        const pfIframe:any = document.getElementById('pf-preview');
        // eslint-disable-next-line func-names
        pfIframe.onload = function () {
            //获取 iframe 内容的高度
            // setTimeout(() => {
            //     onLoad?.(true);
            //     // sendHeight();
            // }, 5000);
        };

        setIframeEl?.(pfIframe as HTMLIFrameElement);
    }, []);

    // React.useEffect(() => {
    //     window.addEventListener('message', (event) => {
    //         if (event?.data?.type === type.returnHeight) {
    //             const msg = event.data;
    //             const { height } = msg;
    //             setPageHeight(height);
    //         }
    //     });
    // }, []);

    // React.useEffect(() => {
    //     if (flag) {
    //         sendRefresh();
    //     }
    // }, [flag]);

    // React.useEffect(() => {
    //     setTimeout(() => {
    //         sendHeight();
    //     }, 5000);
    // }, []);

    const handleReloadIframe = useCallback(() => {
        const pfIframe:any = document.getElementById('pf-preview');

        if (pfIframe) {
            pfIframe.src += '';
        }
    }, []);

    return (
        <div className={s.wrap}>
            <div className={s.modeWrap}>
                <span
                    onClick={() => {
                        set('isMobile', false);
                        handleReloadIframe();
                        handlePlatformChange?.('pc');
                    }}
                    className={isMobileMode ? '' : s.active}
                >
                    <Desktop />
                </span>
                <span
                    onClick={() => {
                        set('isMobile', true);
                        handleReloadIframe();
                        handlePlatformChange?.('mobile');
                    }}
                    className={isMobileMode ? s.active : ''}
                >
                    <Mobile />
                </span>

            </div>
            <div
                className={isMobileMode ? `${s.iframeWrap} ${s.iframeWrapMobile}` : s.iframeWrap}
            >
                <div className={s.topBar}>
                    <img src={os} />
                </div>
                <iframe
                    style={{
                        // height: `${pageHeight}px`,
                    }}
                    onLoad={() => {
                    }}
                    src={currentLink}
                    // src={`http://localhost:3010/provider/zhengkai?state=al&city=fs&isPreview=true&id=${providerId}`}
                    // scrolling="auto"
                    id="pf-preview"
                    title="preview"
                />
                {/* <div
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    className={s.mask}
                /> */}
            </div>
        </div>
    );
};

export default Page;
