import React from 'react';
import { Form, Button, Input, Modal, message } from 'antd';
import { updateProviderEmailNotif } from 'api/provider';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { FormEmails } from './types';
// import useMyListedChannels from 'hooks/useMyListedChannels';

type Props = {
    show:boolean,
    item?:string[],
    onCannel?: (needRefresh?:boolean)=>void,
    onSuccess?: ()=>void
};

const UpdateEmailModal = (props: Props) => {
    const { show, item, onCannel, onSuccess } = props;
    const [submiting, setSubmiting] = React.useState(false);
    const [form] = Form.useForm<FormEmails>();
    const isEdit = item && item.length > 0;

    // const [myChannels] = useMyListedChannels();
    const onSubmit = async () => {
        try {
            setSubmiting(true);
            const formValues = await form.validateFields();

            const data:{ email:string }[] = formValues.list;

            const result = await updateProviderEmailNotif(data ? data.map((i) => i.email).join(',') : '');
            if (result.error) {
                //message.error(result.error);

            } else {
                message.success('Notify emails has updated');
                if (onSuccess) {
                    onSuccess();
                }
            }
        } catch (e:any) {
            if (e.errorFields) {
                //form error
                return;
            }
            //const msg = e?.toString?.() || 'data error';
            //message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };

    React.useEffect(() => {
        if (item) {
            form.setFieldsValue({
                list: item.map((i) => { return { email: i }; }),
            });
        } else {
            form.resetFields();
        }
    }, [item, show, form]);

    return (
        <Modal
            title={isEdit ? 'Edit emails for prospect notifications' : 'Add emails for prospect notifications'}
            closable
            className={commonS.modalFixHeightWrap}
            width="520px"
            onCancel={() => {
                onCannel?.();
            }}
            okText="Save"
            open={show}
            onOk={onSubmit}
            destroyOnClose
            okButtonProps={{ loading: submiting }}
        >
            <Form
                form={form}
                name="basic"
                className={commonS.formStyle1}
                autoComplete="off"
                layout="vertical"
                scrollToFirstError
            >
                <Form.List name="list">
                    {
                        (fields, { add, remove }) => {
                            return (
                                <>
                                    {
                                        fields.map(({ key, name, ...restField }, index) => {
                                            const label = `Email address ${index + 1}`;

                                            return (
                                                <div className={s.formBody}>
                                                    <div className={s.row}>
                                                        <div
                                                            className={s.displayItem}
                                                        >
                                                            <Form.Item
                                                                {...restField}
                                                                label={
                                                                    <div className={s.labelWrap}>
                                                                        <span>{label}</span>
                                                                        <Button size="small" onClick={() => remove(index)}>Remove</Button>
                                                                    </div>
                                                                }
                                                                rules={[
                                                                    { required: true, message: 'Email address is required' },
                                                                    { type: 'email', message: 'Please input correct email format' },
                                                                ]}
                                                                name={[name, 'email']}

                                                            >
                                                                <Input style={{ width: '460px' }} />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                    {
                                        fields?.length < 5 &&
                                        <Form.Item>
                                            <div className={s.add} onClick={() => add()}>+ Add {fields?.length > 0 ? 'another' : 'an'} email address</div>
                                        </Form.Item>
                                    }
                                </>
                            );
                        }
                    }
                </Form.List>

            </Form>
        </Modal>
    );
};

export default UpdateEmailModal;
