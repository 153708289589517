import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import s from './s.module.less';
import { Button, message, Input, Space } from 'antd';
import { SearchOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { ColumnsType } from 'antd/es/table';
import { getConfigFeaturedItems, updateConfigFeaturedItemsSeq } from 'api/operation';
import type { ServerMeta } from 'types/common';
import AddModal from '../AddModal';
import CommonTable from 'components/CommonTable';

interface RowContextProps {
    setActivatorNodeRef?: (element: HTMLElement | null) => void;
    listeners?: SyntheticListenerMap;
}

const RowContext = React.createContext<RowContextProps>({});

type IProps = {
    ind: number;
    len: number;
};

const DragHandle: React.FC<IProps> = ({ ind, len }) => {
    const { setActivatorNodeRef, listeners } = useContext(RowContext);
    const isFirst = useMemo(() => ind === 0, [ind]);
    return (
        <Button
            type="text"
            icon={
                <>
                    <ArrowUpOutlined
                        style={isFirst ? { color: '#C1C1C1' } : { color: '#00816B' }}
                    />
                    <ArrowDownOutlined
                        style={
                            ind === len - 1 ? { color: '#C1C1C1' } : { color: '#00816B' }
                        }
                    />
                </>
            }
            style={{ cursor: 'move' }}
            ref={setActivatorNodeRef}
            className={s.moveButton}
            {...listeners}
        />
    );
};

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string;
}

const Row: React.FC<RowProps> = (props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: props['data-row-key'] });

    const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Translate.toString(transform),
        transition,
        ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    };

    const contextValue = useMemo<RowContextProps>(
        () => ({ setActivatorNodeRef, listeners }),
        [setActivatorNodeRef, listeners],
    );

    return (
        <RowContext.Provider value={contextValue}>
            <tr {...props} ref={setNodeRef} style={style} {...attributes} />
        </RowContext.Provider>
    );
};

const Page: React.FC = () => {
    const [conditionsData, setConditionsData] = React.useState<ServerMeta[]>([]);
    const [stateData, setStateData] = React.useState<ServerMeta[]>([]);
    const [openConditionModal, setOpenConditionModal] = React.useState(false);
    const [openStateModal, setOpenStateModal] = React.useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [pendingNameFilter, setPendingNameFilter] = useState('');
    const [pendingNameStateFilter, setPendingNameStateFilter] = useState('');
    const [activeTab, setActiveTab] = useState('CONDITION');

    const list = useMemo(() => {
        if (
            pendingNameFilter
        ) {
            return conditionsData.filter((oj: ServerMeta) => {
                const nameFit = oj.content
                    ?.toLowerCase()
                    .includes(pendingNameFilter.toLowerCase());
                return nameFit;
            });
        }
        return conditionsData;
    }, [conditionsData, pendingNameFilter]);

    const stateList = useMemo(() => {
        if (pendingNameStateFilter) {
            return stateData.filter((oj: ServerMeta) => {
                const nameFit = oj.content
                    ?.toLowerCase()
                    .includes(pendingNameStateFilter.toLowerCase());
                return nameFit;
            });
        }
        return stateData;
    }, [stateData, pendingNameStateFilter]);

    const getData = async () => {
        setIsLoading(true);
        try {
            const { data } = await getConfigFeaturedItems();
            setConditionsData(
                (data?.data || [])
                    .filter((cond: ServerMeta) => cond.type === 'CONDITION')
                    .sort((a: ServerMeta, b: ServerMeta) => a.seq - b.seq),
            );
            setStateData(
                (data?.data || [])
                    .filter((cond: ServerMeta) => cond.type === 'STATE')
                    .sort((a: ServerMeta, b: ServerMeta) => a.seq - b.seq),
            );
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }
    };

    const exchangeData = async (type: string, fromInd: number, overInd: number) => {
        const currentData =
          type === 'CONDITION' ? conditionsData : stateData;

        if (type === 'CONDITION') {
            setConditionsData((prevState) => {
                return arrayMove(prevState, fromInd, overInd);
            });
        } else {
            setStateData((prevState) => {
                return arrayMove(prevState, fromInd, overInd);
            });
        }

        const fromData = currentData[fromInd];
        const toData = currentData[overInd];

        const { data } = await updateConfigFeaturedItemsSeq([
            {
                ...fromData,
                seq: toData.seq,
            },
            {
                ...toData,
                seq: fromData.seq,
            },
        ]);
        getData();
    };

    const handleUp = async (type: string, ind: number) => {
        if (ind === 0) {
            return;
        }
        setIsLoading(true);
        const overInd = ind - 1;
        exchangeData(type, ind, overInd);
    };

    const handleNext = (type: string, ind: number, len: number) => {
        if (ind === len - 1) {
            return;
        }
        setIsLoading(true);
        const overInd = ind + 1;
        exchangeData(type, ind, overInd);
    };

    const columns: ColumnsType<ServerMeta> = useMemo(
        () => [
            {
                key: 'sort',
                align: 'center',
                width: 80,
                dataIndex: 'seq',
                render: (ind) => (
                    <>
                        <ArrowUpOutlined
                            className={s.moveColumn}
                            style={ind === 0 ? { color: '#C1C1C1' } : { color: '#00816B' }}
                            onClick={() => handleUp('CONDITION', ind)}
                        />
                        <ArrowDownOutlined
                            className={s.moveColumn}
                            style={
                                ind === list.length - 1
                                    ? { color: '#C1C1C1' }
                                    : { color: '#00816B' }
                            }
                            onClick={() => handleNext('CONDITION', ind, list.length)}
                        />
                    </>
                    // <DragHandle
                    //     ind={ind}
                    //     len={list.length}
                    // />
                ),
            },
            {
                title: 'CONDITIONS',
                dataIndex: 'content',
                filterSearch: true,
                filterIcon: <SearchOutlined />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Input
                            value={pendingNameFilter}
                            onChange={(e) => {
                                setPendingNameFilter(e.target.value);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && pendingNameFilter) {
                                    setPendingNameFilter(pendingNameFilter);
                                }
                            }}
                            style={{ marginBottom: 8, display: 'block' }}
                        />
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => {
                                    setPendingNameFilter(pendingNameFilter);
                                }}
                                icon={<SearchOutlined />}
                                size="small"
                                style={{ width: 90 }}
                            >
                                Search
                            </Button>
                            <Button
                                onClick={() => {
                                    setPendingNameFilter('');
                                }}
                                size="small"
                                style={{ width: 90 }}
                            >
                                Reset
                            </Button>
                        </Space>
                    </div>
                ),
                render: (text) => <div className={s.name}>{text}</div>,
            },
        ],
        [pendingNameFilter, list],
    );

    const stateColumns: ColumnsType<ServerMeta> = useMemo(
        () => [
            {
                key: 'sort',
                align: 'center',
                width: 80,
                dataIndex: 'seq',
                // render: (ind) => <DragHandle ind={ind} len={stateList.length} />,
                render: (ind) => (
                    <>
                        <ArrowUpOutlined
                            className={s.moveColumn}
                            style={ind === 0 ? { color: '#C1C1C1' } : { color: '#00816B' }}
                            onClick={() => handleUp('STATE', ind)}
                        />
                        <ArrowDownOutlined
                            className={s.moveColumn}
                            style={
                                ind === stateList.length - 1
                                    ? { color: '#C1C1C1' }
                                    : { color: '#00816B' }
                            }
                            onClick={() => handleNext('STATE', ind, stateList.length)}
                        />
                    </>
                ),
            },
            {
                title: 'STATES',
                dataIndex: 'content',
                filterSearch: true,
                filterIcon: <SearchOutlined />,
                filterDropdown: () => (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Input
                            value={pendingNameStateFilter}
                            onChange={(e) => {
                                setPendingNameStateFilter(e.target.value);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && pendingNameStateFilter) {
                                    setPendingNameStateFilter(pendingNameStateFilter);
                                }
                            }}
                            style={{ marginBottom: 8, display: 'block' }}
                        />
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => {
                                    setPendingNameStateFilter(pendingNameStateFilter);
                                }}
                                icon={<SearchOutlined />}
                                size="small"
                                style={{ width: 90 }}
                            >
                                Search
                            </Button>
                            <Button
                                onClick={() => {
                                    setPendingNameStateFilter('');
                                }}
                                size="small"
                                style={{ width: 90 }}
                            >
                                Reset
                            </Button>
                        </Space>
                    </div>
                ),
                render: (text) => <div className={s.name}>{text}</div>,
            },
        ],
        [pendingNameStateFilter, stateList],
    );

    const onDragEnd = async ({ active, over }: DragEndEvent) => {
        setIsLoading(true);
        if (active.id !== over?.id && !!over) {
            const currentData =
              activeTab === 'CONDITION' ? conditionsData : stateData;

            if (activeTab === 'CONDITION') {
                setConditionsData((prevState) => {
                    const activeIndex = prevState.findIndex(
                        (record) => record.id === active?.id,
                    );
                    const overIndex = prevState.findIndex(
                        (record) => record.id === over?.id,
                    );
                    return arrayMove(prevState, activeIndex, overIndex);
                });
            } else {
                setStateData((prevState) => {
                    const activeIndex = prevState.findIndex(
                        (record) => record.id === active?.id,
                    );
                    const overIndex = prevState.findIndex(
                        (record) => record.id === over?.id,
                    );
                    return arrayMove(prevState, activeIndex, overIndex);
                });
            }

            const fromData =
              currentData.find(
                  (item: ServerMeta) => item.id === active.id,
              ) || ({} as ServerMeta);
            const toData = currentData.find(
                (item: ServerMeta) => item.id === over.id,
            ) || {} as ServerMeta;

            const { data } = await updateConfigFeaturedItemsSeq([
                {
                    ...fromData,
                    seq: toData.seq,
                },
                {
                    ...toData,
                    seq: fromData.seq,
                },
            ]);
            getData();
        }
    };

    const onSuccess = () => {
        setOpenConditionModal(false);
        setOpenStateModal(false);
        getData();
        message.success('Updated successfully');
    };

    const onConditionEdit = () => {
        setOpenConditionModal(true);
    };

    const onStateEdit = () => {
        setOpenStateModal(true);
    };

    const changeTab = (type) => {
        setActiveTab(type);
    };

    useEffect(() => {
        getData();
    }, []);

    const renderTable = ({
        title,
        editText,
        sourceData,
        tableColumn,
        editCallback,
    }: {
        title: string;
        editText: string;
        sourceData: ServerMeta[];
        tableColumn: ColumnsType<ServerMeta>;
        editCallback: () => void;
    }) => (
        <div className={s.wrap}>
            <div className={s.header}>
                <h4 className={s.title}>{title}</h4>
                <Button onClick={() => editCallback()}>{editText}</Button>
            </div>
            <div className={s.content}>
                <DndContext
                    modifiers={[restrictToVerticalAxis]}
                    onDragEnd={onDragEnd}
                >
                    <SortableContext
                        items={sourceData.map((i) => i.id)}
                        strategy={verticalListSortingStrategy}
                    >
                        <CommonTable
                            components={{ body: { row: Row } }}
                            rowKey="id"
                            bordered
                            loading={isLoading}
                            columns={tableColumn}
                            data={sourceData}
                            pagination={false}
                        />
                    </SortableContext>
                </DndContext>
            </div>
        </div>
    );
    return (
        <>
            <div>
                <div className={s.tabWrap}>
                    <Button
                        type="text"
                        className={`${s.button} ${
                            activeTab === 'CONDITION' ? s.active : ''
                        }`}
                        onClick={() => changeTab('CONDITION')}
                    >
                        Conditions
                    </Button>
                    <Button
                        type="text"
                        className={`${s.button} ${activeTab === 'STATE' ? s.active : ''}`}
                        onClick={() => changeTab('STATE')}
                    >
                        States
                    </Button>
                </div>
                {activeTab === 'CONDITION' && renderTable({
                    title: 'Top conditions',
                    editText: 'Edit conditions',
                    sourceData: list,
                    tableColumn: columns,
                    editCallback: () => {
                        onConditionEdit();
                    },
                })}

                {activeTab === 'STATE' && renderTable({
                    title: 'Top states',
                    editText: 'Edit states',
                    sourceData: stateList,
                    tableColumn: stateColumns,
                    editCallback: () => {
                        onStateEdit();
                    },
                })}
            </div>
            {openConditionModal && (
                <AddModal
                    isOps
                    popupType="CONDITION"
                    list={conditionsData}
                    onCancel={() => setOpenConditionModal(false)}
                    onSuccess={onSuccess}
                />
            )}
            {openStateModal && (
                <AddModal
                    isOps
                    popupType="STATE"
                    list={stateData}
                    onCancel={() => setOpenStateModal(false)}
                    onSuccess={onSuccess}
                />
            )}
        </>
    );
};

export default Page;
