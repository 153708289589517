import React from 'react';
import s from './s.modules.less';
import { TITLE, CONFIG } from './constants';

const Features: React.FC = () => {
    return (
        <div className={s.wrap}>
            <h2 className={s.title}>{TITLE}</h2>
            <div className={s.list}>
                {CONFIG.map((configItem, inx) => {
                    return (
                        <div className={s.item}>
                            <div className={s.left}>
                                <h3 className={s.subTitle}>
                                    <span className={s.step}>Step {inx + 1}</span>
                                    {configItem.title}</h3>
                                <ul className={s.description}>
                                    {configItem.tips.map((val: string, inx) => {
                                        return (
                                            <li key={inx}>
                                                <div dangerouslySetInnerHTML={{ __html: val }} ></div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                            <div className={s.right}>
                                {configItem.image}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Features;
