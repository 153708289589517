import React, { useEffect, useState } from 'react';
import s from './s.module.less';
import { Button } from 'antd';
import { Desktop } from 'assets/preview/Desktop';
import { Mobile } from 'assets/preview/Mobile';
import os from 'assets/preview/OS.svg';
import surveyBanner from 'assets/survey/surveyBanner.png';
import dynamicIsland from 'assets/survey/dynamicIsland.png';
import { Provider } from 'types/provider';
import { handleCredentialReplacement, handleProviderNameReplacement } from 'utils/survey';
import { getProviderNameDisplay } from 'utils/provider';

const emailContent = `Thank you for taking the time to respond to my recent survey. I truly appreciate your valuable input. If you could spare a moment, I would be grateful if you could share your experience by leaving a review.
<div><br></div>
Click on the button below to leave a review on the platform of your choice.
<div><br></div>
To protect your privacy, please remember that you should not feel the need to share your full or real name when you post a review. Please use the provided link. Your responses remain confidential unless you've chosen to share them.
<div><br></div>
Thank you once again for choosing me for your healthcare needs.`;

const emailTemplate = `
Dear [Patient Name],
<div><br></div>
${emailContent}
<div><br></div>
<div>Best wishes,</div>
<div>[Provider name]</div>
<div>[Credentials]</div>
`;

type IProps = {
    providerData?: Provider;
};

const Invitation = ({
    providerData,
}: IProps) => {
    const [emailData, setEmailData] = useState(emailTemplate);
    const [isMobileMode, setIsMobileMode] = useState(false);

    useEffect(() => {
        if (providerData) {
            setEmailData(handleProviderNameReplacement(handleCredentialReplacement(emailTemplate, providerData), providerData));
        }
    }, [providerData]);

    return (
        <div className={s.wrap}>
            <div className={s.left}>
                <div className={s.title}>Automated review invitation email template</div>
                <div className={s.subTitle}>Review and confirm the content for your review invitation</div>
                <div className={s.intro}>This email is non-editable</div>
                <div className={s.body}>
                    <div className={s.form}>
                        <div className={s.formItem}>
                            <div className={s.contentLabel}>Subject</div>
                            <div className={s.contentValue}>Thanks for responding to survey! Please help us by leaving a review.</div>
                        </div>
                        <div className={s.formItem}>
                            <div className={s.contentLabel}>Email content</div>
                            <div
                                className={s.emailBody}
                                dangerouslySetInnerHTML={{ __html: emailData }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={s.preview}>
                <div className={s.modeWrap}>
                    <span
                        onClick={() => {
                            setIsMobileMode(false);
                        }}
                        className={isMobileMode ? '' : s.active}
                    >
                        <Desktop />
                    </span>
                    <span
                        onClick={() => {
                            setIsMobileMode(true);
                        }}
                        className={isMobileMode ? s.active : ''}
                    >
                        <Mobile />
                    </span>

                </div>
                <div
                    className={isMobileMode ? `${s.iframeWrap} ${s.iframeWrapMobile}` : s.iframeWrap}
                >
                    <div className={s.topBar}>
                        {
                            isMobileMode ?
                                <div className={s.mobileHeader}>
                                    <img className={s.island} src={dynamicIsland} />
                                </div>
                                : <img src={os} />
                        }
                    </div>
                    <div className={s.previewContent}>
                        <div className={s.scrollContent}>
                            <img src={surveyBanner} />
                            <div className={s.content}>
                                <div className={s.text}>
                                    <p>Dear [Patient Name],</p>
                                    <br />
                                    <div dangerouslySetInnerHTML={{ __html: emailContent }} />
                                    <br />
                                    <Button type="primary" className={s.surveyButton}>Open review link</Button>
                                    <br />
                                    <br />
                                    <p>Regards,</p>
                                    <p>{getProviderNameDisplay(providerData)}</p>
                                    <p>{providerData?.credential || ''}</p>
                                    <br />
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Invitation;
