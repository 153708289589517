import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import s from './s.module.less';
import { Spin, message } from 'antd';
import Introduction from './components/Introduction';
import SurveyFormSetup from './components/Configration/Survey/Form/Form';
import SurveyInvitation from './components/Configration/Survey/Invitation/Invitation';
import SurveyIntro from './components/Configration/Survey/Intro/Intro';
import PatientIntro from './components/Configration/Patient/Intro/Intro';
import PatientImprot from './components/Configration/Patient/Import/Import';
import ReviewIntro from './components/Configration/Review/Intro/Intro';
import ChannelSelection from './components/Configration/Review/ChannelSelection/ChannelSelection';
import ReviewInvitation from './components/Configration/Review/Invitation/Invitation';
import { ESurveyStep, SurveyNonForm, SurveySteps } from 'types/survey';
import useGetProviderReputationStatus from 'hooks/useGetProviderReputationStatus';

import { reputationProviderData } from 'data/mockData';
import Footer from './components/Footer';
import { saveReviewTrigger, saveSurveyEmail, saveSurveyForm } from 'api/survey';
import useGetSurveyEmailTemplate from 'hooks/survey/useGetSurveyEmailTemplate';
import useGetSurveyForm from 'hooks/survey/useGetSurveyForm';
import useProviderFetch from 'hooks/useProviderFetch';
import useGetReviewChannelList from 'hooks/survey/useGetReviewChannelList';
import useGetReviewTrigger from 'hooks/survey/useGetReviewTrigger';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import { getIsStartEditReview, getIsStartEditSurveyForm, getReputationSetupStep, setImportedPatients, setReputationSetupStep, setStartEditReview, setStartEditSurveyForm } from 'utils/localstore';
import { isFreeUser } from 'utils/provider';
import SetProviderInfoModal from './components/SetProviderInfoModal';
import useLoadBasicData from 'hooks/useLoadBasicData';
import { reputationAddProfile } from 'api/provider';
import { IAddProfile } from 'types/reputation';

const ReputationConfigPage = () => {
    const navigate = useNavigate();
    const surveyFormSetupRef = useRef();
    const [currentStep, setCurrentStep] = useState(0);
    const [formId, setFormId] = useState(SurveySteps[currentStep]);
    const [emaillTeplateData, emailTeplateLoading,,refetchEmailTeplateData] = useGetSurveyEmailTemplate();
    const [surveyFormData, surveyDataLoading,, refetchSurveyData] = useGetSurveyForm();
    const [reviewChannelList, reviewChannelListLoading] = useGetReviewChannelList();
    const [reviewTrigger, reviewTriggerLoading,, refetchRreviewTriggerList] = useGetReviewTrigger();
    const [providerData, providerLoading,,refetchProvider] = useProviderFetch();
    const [saveLoading, setSaveLoading] = useState(false);
    const [footerBackClickCallback, setFooterBackClickCallback] = useState<(data?: any) => void | undefined>();
    const [footerNextClickCallback, setFooterNextClickCallback] = useState<(data?: any) => void | undefined>();
    const isFree = isFreeUser();
    const [hasConfig] = useGetProviderReputationStatus();
    const isSurverEdit = getIsStartEditSurveyForm();
    const isReviewEdit = getIsStartEditReview();
    const [loadSettingData] = useLoadBasicData();

    const [surveyFormSwiperIndex, setSurveyFormSwiperIndex] = useState(0);
    const [isReputationEdit, setIsReputationEdit] = useState(false);
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [addProfileLoading, setAddProfileLoading] = useState(false);

    const { invitationEmail } = reputationProviderData;

    const currentStepInStepBar = useRef<Record<number, number>>({
        [ESurveyStep.INTRODUCTION]: -1,
        [ESurveyStep.SURVEY_INTRO]: -1,
        [ESurveyStep.SURVEY_INVITATION]: 1,
        [ESurveyStep.SURVEY_FORM]: 2,
        [ESurveyStep.PATIENT_INTRO]: 4,
        [ESurveyStep.PATIENT_IMPORT]: 5,
        [ESurveyStep.REVIEW_INTRO]: 5,
        [ESurveyStep.CHANNEL_SELECTION]: 6,
        [ESurveyStep.REVIEW_INVITATION]: 7,
    });

    const [currentStepBarIndex, setCurrentStepBarIndex] = useState(-1);

    useEffect(() => {
        setImportedPatients('');
    }, []);

    useEffect(() => {
        const stepbars = document.querySelectorAll('[data-name="stepbar"]');

        for (let i = 0; i < stepbars.length; i++) {
            stepbars[i].classList.remove(s.actived);
            if (i < currentStepBarIndex) {
                stepbars[i].classList.add(s.actived);
            }
        }
    }, [currentStepBarIndex]);

    useEffect(() => {
        if (currentStep < ESurveyStep.SURVEY_INVITATION) {
            setCurrentStepBarIndex(-1);
        } else {
            const index = currentStepInStepBar.current[currentStep];

            if (index) {
                if (currentStep < ESurveyStep.SURVEY_INVITATION) {
                    setCurrentStepBarIndex(-1);
                }
                setCurrentStepBarIndex(index);
            }
        }
    }, [currentStep]);

    useEffect(() => {
        return () => {
            setStartEditSurveyForm();
            setStartEditReview();
        };
    }, []);

    useEffect(() => {
        if (!isReputationEdit && !hasConfig) {
            const step = getReputationSetupStep();

            if (step) {
                setCurrentStep(step ? Number(step) : 0);
            }
        }
    }, [hasConfig, isReputationEdit]);

    useEffect(() => {
        if (isSurverEdit) {
            setCurrentStep(ESurveyStep.SURVEY_INTRO);
        } else if (isReviewEdit) {
            setCurrentStep(ESurveyStep.REVIEW_INTRO);
        }

        setIsReputationEdit(!!isSurverEdit || !!isReviewEdit);
    }, [isReviewEdit, isSurverEdit]);

    useEffect(() => {
        if (!isReputationEdit && !hasConfig) {
            setReputationSetupStep(currentStep.toString());
        }
    }, [isReputationEdit, currentStep, hasConfig]);

    useEffect(() => {
        loadSettingData();
        return () => {
            if (isReputationEdit) {
                setReputationSetupStep();
            }
        };
    }, [isReputationEdit, loadSettingData]);

    const onNext = useCallback(async (formData) => {
        setSaveLoading(true);
        let refetCallback;
        let res = {
            error: '',
        };
        let backToDashboard = false;
        if (currentStep === ESurveyStep.SURVEY_INVITATION) {
            res = await saveSurveyEmail(formData);
            refetCallback = refetchEmailTeplateData;
        } else if (currentStep === ESurveyStep.SURVEY_FORM) {
            const submitData = {
                elements: formData,
            };

            res = await saveSurveyForm(submitData);
            // const isSurverEdit = getIsStartEditSurveyForm();
            backToDashboard = !!isSurverEdit;
            refetCallback = refetchSurveyData;
        } else if (currentStep === ESurveyStep.CHANNEL_SELECTION) {
            res = await saveReviewTrigger(formData);
            refetCallback = refetchRreviewTriggerList;
        }

        setSaveLoading(false);

        if (!res.error) {
            refetCallback?.();
            if (backToDashboard) {
                navigate(`${PATH.DASHBOARD}/${PATH.REPUTATION_DASHBORAD}`);
            } else {
                const step = currentStep + 1;
                setCurrentStep(step);
            }
        }
    }, [currentStep, isSurverEdit, navigate, refetchEmailTeplateData, refetchRreviewTriggerList, refetchSurveyData]);

    const handleNextPageWithoutForm = useCallback(() => {
        return () => {
            if (!isReputationEdit && currentStep === ESurveyStep.SURVEY_INTRO && (!providerData || !providerData.firstName)) {
                setShowProfileModal(true);
            } else {
                const step = currentStep + 1;
                setCurrentStep(step);
            }
        };
    }, [currentStep, isReputationEdit, providerData]);

    const handleSwipeWhenClickNext = useCallback(() => {
        return () => {
            surveyFormSetupRef.current?.slideNext();
            const swiperIndex = surveyFormSetupRef.current?.getCurrentSwiperIndex();

            if (swiperIndex === 0) {
                setCurrentStepBarIndex(2);
            } else if (swiperIndex === 1) {
                setCurrentStepBarIndex(3);
            } else {
                setCurrentStepBarIndex(4);
            }
        };
    }, []);

    const backToPreviousPage = useCallback(() => {
        const step = currentStep - 1;
        setCurrentStep(step < 0 ? 0 : step);
    }, [currentStep]);

    const handleBackToPreviousPage = useCallback(() => {
        return backToPreviousPage;
    }, [backToPreviousPage]);

    const handleBackToPreviousSwiper = useCallback(() => {
        return () => {
            surveyFormSetupRef.current?.slidePrev();
            const swiperIndex = surveyFormSetupRef.current?.getCurrentSwiperIndex();

            if (swiperIndex === 0) {
                setCurrentStepBarIndex(2);
            } else if (swiperIndex === 1) {
                setCurrentStepBarIndex(3);
            } else {
                setCurrentStepBarIndex(4);
            }
        };
    }, []);

    const handleConfigSuccess = useCallback(() => {
        return () => {
            if (!isFreeUser()) {
                setReputationSetupStep();
            }
            navigate(`/${PATH.REPUTATION_CONFIG_SUCCESS}`);
        };
    }, [navigate]);

    const handleBackToDashboardSuccess = useCallback(() => {
        return () => navigate(`${PATH.DASHBOARD}/${PATH.REPUTATION_DASHBORAD}`);
    }, [navigate]);

    const handleSkipButtonClick = useCallback(() => {
        setCurrentStep(6);
    }, []);

    const handleSubmitProfile = useCallback(async (data: IAddProfile) => {
        if (providerData && providerData.id) {
            setAddProfileLoading(true);
            try {
                const res = await reputationAddProfile(providerData.id, data);

                if (res && !res.error) {
                    setShowProfileModal(false);
                    await refetchProvider();

                    const step = currentStep + 1;
                    setCurrentStep(step);
                }
            } catch (e) {
                console.error(e);
            }
            setAddProfileLoading(false);
        }
    }, [currentStep, providerData, refetchProvider]);

    useEffect(() => {
        const id = SurveySteps[currentStep];

        // 有3种next方式，
        // 1， 无任何表单，step + 1
        // 2，有form，通过button submit成功后step + 1,所以不需要button click事件
        // 3. survey表单内next是swiper + 1； 需要调用swiper next()
        // 有2种 back
        // 1. 自然step - 1, 2. swiper -1
        // survey 表单比较特殊，siwper-1 如果back，需要step-1, 最后一个swiper next需要form通过submit成功后step+1. 其他都是swiper ±1
        if (SurveyNonForm.includes(currentStep)) {
            if (currentStep === ESurveyStep.REVIEW_INVITATION) {
                // const isReviewEdit = getIsStartEditReview();
                if (isReviewEdit) {
                    setFooterNextClickCallback(handleBackToDashboardSuccess);
                } else {
                    setFooterNextClickCallback(handleConfigSuccess);
                }
                setFooterBackClickCallback(handleBackToPreviousPage);
            } else if (currentStep === ESurveyStep.REVIEW_INTRO && isFree) {
                setFooterNextClickCallback(handleConfigSuccess);
            } else {
                setFooterNextClickCallback(handleNextPageWithoutForm);
            }
            setFooterBackClickCallback(handleBackToPreviousPage);
        } else if (currentStep === ESurveyStep.SURVEY_FORM) {
            if (surveyFormSwiperIndex === 0) {
                setFooterNextClickCallback(handleSwipeWhenClickNext);
                setFooterBackClickCallback(handleBackToPreviousPage);
            } else if (surveyFormData && surveyFormSwiperIndex === surveyFormData.length - 1) {
                setFooterNextClickCallback(undefined);
                setFooterBackClickCallback(handleBackToPreviousSwiper);
            } else {
                setFooterNextClickCallback(handleSwipeWhenClickNext);
                setFooterBackClickCallback(handleBackToPreviousSwiper);
            }
        } else {
            setFooterNextClickCallback(undefined);
            setFooterBackClickCallback(handleBackToPreviousPage);
        }

        setFormId(id);
    }, [backToPreviousPage, currentStep, handleBackToPreviousPage, handleBackToPreviousSwiper, handleNextPageWithoutForm, handleSwipeWhenClickNext, surveyFormData, surveyFormSwiperIndex, handleConfigSuccess, handleBackToDashboardSuccess, isFree, isReviewEdit]);

    const pageRender = useMemo(() => {
        let content = null;

        const commonProps = {
            hideIndex: isReputationEdit,
        };

        switch (currentStep) {
            case ESurveyStep.INTRODUCTION:
                content = <Introduction />;
                break;
            case ESurveyStep.SURVEY_INTRO:
                content = <SurveyIntro {...commonProps} />;
                break;
            case ESurveyStep.SURVEY_INVITATION:
                content = <SurveyInvitation providerData={providerData} initFormValue={emaillTeplateData || invitationEmail} onNext={onNext} />;
                break;
            case ESurveyStep.SURVEY_FORM:
                content = <SurveyFormSetup ref={surveyFormSetupRef} onNext={onNext} providerData={providerData} initFormValue={surveyFormData} onSiwperChange={setSurveyFormSwiperIndex} />;
                break;
            case ESurveyStep.PATIENT_INTRO:
                content = <PatientIntro {...commonProps} />;
                break;
            case ESurveyStep.PATIENT_IMPORT:
                content = <PatientImprot />;
                break;
            case ESurveyStep.REVIEW_INTRO:
                content = <ReviewIntro {...commonProps} />;
                break;
            case ESurveyStep.CHANNEL_SELECTION:
                content = <ChannelSelection channels={reviewChannelList} onNext={onNext} initFormValue={reviewTrigger} providerData={providerData} />;
                break;
            case ESurveyStep.REVIEW_INVITATION:
                content = <ReviewInvitation providerData={providerData} />;
                break;
            default: content = null;
        }

        return content;
    }, [currentStep, emaillTeplateData, invitationEmail, isReputationEdit, onNext, providerData, reviewChannelList, reviewTrigger, surveyFormData]);

    const spinning = !!(emailTeplateLoading || surveyDataLoading || reviewChannelListLoading || reviewTriggerLoading || providerLoading);

    const showBack = useMemo(() => {
        // const isSurverEdit = getIsStartEditSurveyForm();
        // const isReviewEdit = getIsStartEditReview();

        if (isSurverEdit) {
            if (currentStep === ESurveyStep.SURVEY_INTRO) {
                return false;
            }
        }

        if (isReviewEdit) {
            if (currentStep === ESurveyStep.REVIEW_INTRO) {
                return false;
            }
        }

        return currentStep !== ESurveyStep.INTRODUCTION;
    }, [currentStep, isReviewEdit, isSurverEdit]);

    const showSkipButton = useMemo(() => {
        return currentStep === ESurveyStep.PATIENT_INTRO || currentStep === ESurveyStep.PATIENT_IMPORT;
    }, [currentStep]);

    // 临时方案
    const stepBarRender = useMemo(() => {
        if (isSurverEdit) {
            return (
                <div className={s.step}>
                    <div className={`${s.stepBar} ${s.wrapBar}`}>
                        <div data-name="stepbar" className={`${s.stepBar}`} />
                    </div>
                    <div className={`${s.stepBar} ${s.wrapBar}`}>
                        <div data-name="stepbar" className={s.stepBar} />
                    </div>
                    <div className={`${s.stepBar} ${s.wrapBar}`}>
                        <div data-name="stepbar" className={s.stepBar} />
                    </div>
                    <div className={`${s.stepBar} ${s.wrapBar}`}>
                        <div data-name="stepbar" className={s.stepBar} />
                    </div>
                </div>
            );
        }
        if (isReviewEdit) {
            return (
                <div className={s.step}>
                    <div className={`${s.stepBar} ${s.wrapBar}`} style={{ display: 'none' }}>
                        <div data-name="stepbar" className={`${s.stepBar}`} />
                        <div data-name="stepbar" className={`${s.stepBar}`} />
                        <div data-name="stepbar" className={`${s.stepBar}`} />
                        <div data-name="stepbar" className={s.stepBar} />
                    </div>
                    <div className={`${s.stepBar} ${s.wrapBar}`} style={{ display: 'none' }}>
                        <div data-name="stepbar" className={s.stepBar} />
                    </div>
                    <div className={`${s.stepBar} ${s.wrapBar}`}>
                        <div data-name="stepbar" className={s.stepBar} />
                    </div>
                    <div className={`${s.stepBar} ${s.wrapBar}`}>
                        <div data-name="stepbar" className={s.stepBar} />
                    </div>
                </div>
            );
        }

        if (currentStep !== ESurveyStep.INTRODUCTION) {
            return (
                <div className={s.step}>
                    <div className={`${s.stepBar} ${s.wrapBar}`}>
                        <div data-name="stepbar" className={`${s.stepBar}`} />
                        <div data-name="stepbar" className={`${s.stepBar}`} />
                        <div data-name="stepbar" className={`${s.stepBar}`} />
                        <div data-name="stepbar" className={s.stepBar} />
                    </div>
                    <div className={`${s.stepBar} ${s.wrapBar}`}>
                        <div data-name="stepbar" className={s.stepBar} />
                    </div>
                    <div className={`${s.stepBar} ${s.wrapBar}`}>
                        <div data-name="stepbar" className={s.stepBar} />
                        <div data-name="stepbar" className={s.stepBar} />
                    </div>
                </div>
            );
        }

        return null;
    }, [currentStep, isReviewEdit, isSurverEdit]);

    return (
        <div className={s.wrap}>
            <Spin spinning={spinning}>
                <SetProviderInfoModal
                    loading={addProfileLoading}
                    show={showProfileModal}
                    onOk={handleSubmitProfile}
                    onCannel={() => setShowProfileModal(false)}
                />
                {pageRender}
                {stepBarRender}
                <Footer
                    showSkipButton={showSkipButton}
                    showBack={showBack}
                    formId={formId}
                    loading={saveLoading}
                    onBackClick={footerBackClickCallback}
                    onNextClick={footerNextClickCallback}
                    onSkipClick={handleSkipButtonClick}
                />
            </Spin>
        </div>
    );
};

export default ReputationConfigPage;
