import React from 'react';
import s from './s.module.less';
import { TITLE, CONFIG } from './constants';

const Partnerchannels = () => {
    return (
        <div className={s.wrap}>
            <div className={s.title}>{TITLE}</div>
            <div className={s.list}>
                {
                    CONFIG.map((configItem) => {
                        return (
                            <div className={s.item}>
                                <img className={s.logo} src={configItem} />
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default Partnerchannels;
