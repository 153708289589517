import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Pagination, MenuProps, Dropdown, Space, Spin } from 'antd';
import s from './s.module.less';
import dayjs from 'utils/dayjs';
import ProviderStore from 'store/Provider';
import { DownOutlined } from '@ant-design/icons';
import { PatientsFeedback, EScroeType } from 'types/common';
import { getTimeStr } from 'utils/calendar';
import ScoreLabel from 'components/ScoreLabel';
import ReplyFeedbackModal from '../ReplyFeedbackModal';
import { getReputationFeedbackList, getReputationFeedbackOverviewData } from 'api/survey';
import { IFeedbackOverviewData } from 'types/reputation';
import DataUpdatedFlagStore from 'store/DataUpdatedFlag';
import { isFreeUser } from 'utils/provider';
import { openSubscriptionModal } from 'utils/globalLayerControl';

enum EFeedbackFilter {
    OLDEST = 1,
    NEWEST = 0,
}

enum EFeedbackType {
    NORMAL = 1,
    ANONYMOUS = 2,
    REPLYED = 3,
}

const Feedback = () => {
    const navigate = useNavigate();
    const [getUser] = ProviderStore.useStore();
    const [showReply, setShowReply] = React.useState<boolean>(false);
    const [currentFeedback, setCurrentFeedback] = React.useState<PatientsFeedback>();
    const [currentFeedbackType, setCurrentFeedbackType] = React.useState<EFeedbackType>(EFeedbackType.NORMAL);
    const [feedbackData, setFeedbackData] = useState<PatientsFeedback[]>([]);
    const [anonymousFeedbackData, setAnonymousFeedbackData] = useState<PatientsFeedback[]>([]);
    const [repliedFeedbackData, setRepliedFeedbackData] = useState<PatientsFeedback[]>([]);
    const [overviewData, setOverviewData] = useState<IFeedbackOverviewData>({
        averageSurveyRating: 0,
        feedbackReceived: 0,
        invitationSend: 0,
        surveyDeliverySend: 0,
        surveySendThisWeek: 0,
        surveySendTotal: 0,
    });
    const [feedbackFilter, setFeedbackFilter] = React.useState<EFeedbackFilter>(EFeedbackFilter.NEWEST);
    const [feedbackListPage, setFeedbackListPage] = useState(1);
    const [feedbackListPageSize, setFeedbackListPageSize] = useState(10);
    const [totalFeedback, setTotalFeedback] = useState(0);

    const [anonymousFeedbackFilter, setAnonymousFeedbackFilter] = React.useState<EFeedbackFilter>(EFeedbackFilter.NEWEST);
    const [anonymousFeedbackListPage, setAnonymousFeedbackListPage] = useState(1);
    const [anonymousFeedbackListPageSize, setAnonymousFeedbackListPageSize] = useState(10);
    const [anonymousTotalFeedback, setAnonymousTotalFeedback] = useState(0);

    const [repliedFeedbackFilter, setRepliedFeedbackFilter] = React.useState<EFeedbackFilter>(EFeedbackFilter.NEWEST);
    const [repliedFeedbackListPage, setRepliedFeedbackListPage] = useState(1);
    const [repliedFeedbackListPageSize, setRepliedFeedbackListPageSize] = useState(10);
    const [repliedTotalFeedback, setRepliedTotalFeedback] = useState(0);
    const [loading, setLoading] = React.useState(false);

    const [getDataUpdatedFlag] = DataUpdatedFlagStore.useStore();
    const patientFeedbackListUpdatedFlag = getDataUpdatedFlag('patientFeedbackListUpdated');

    const isFree = isFreeUser();
    const user = getUser('data');
    const hasLoaded = !!user?.email;

    const showUpgrade = !!isFree && hasLoaded;
    const showChannelStore = !isFree && !user?.gmail && hasLoaded;

    const canReply = !showUpgrade && !showChannelStore;

    const fetchOverviewData = async () => {
        try {
            const res = await getReputationFeedbackOverviewData();

            if (res && !res.error && res.data?.data) {
                setOverviewData(res.data.data);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getFeedbackList = async (
        { type, current, size, order, replied }:
        { type: EFeedbackType, current?: number, size?:number, order: EFeedbackFilter, replied?: boolean },
    ) => {
        try {
            let _size = size || feedbackListPageSize;
            let _current = current || feedbackListPage;
            if (type === EFeedbackType.ANONYMOUS) {
                _size = size || anonymousFeedbackListPageSize;
                _current = current || anonymousFeedbackListPage;
            }
            if (type === EFeedbackType.REPLYED) {
                _size = size || repliedFeedbackListPageSize;
                _current = current || repliedFeedbackListPage;
            }
            setLoading(true);
            const res = await getReputationFeedbackList({
                isAnonymous: type === EFeedbackType.ANONYMOUS,
                orderAsc: order === EFeedbackFilter.OLDEST,
                size: _size,
                current: _current,
                replied: replied || false,
            });
            setLoading(false);

            if (res && !res.error) {
                const { records = [], total = 0 } = res.data?.data || {};
                if (type === EFeedbackType.NORMAL) {
                    setFeedbackData(records);
                    setTotalFeedback(total);
                } else if (replied) {
                    setRepliedFeedbackData(records);
                    setRepliedTotalFeedback(total);
                } else {
                    setAnonymousFeedbackData(records);
                    setAnonymousTotalFeedback(total);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        fetchOverviewData();
        getFeedbackList({ type: EFeedbackType.NORMAL, order: EFeedbackFilter.NEWEST });
        getFeedbackList({ type: EFeedbackType.ANONYMOUS, order: EFeedbackFilter.NEWEST });
        getFeedbackList({ type: EFeedbackType.REPLYED, replied: true, order: EFeedbackFilter.NEWEST });
    }, []);

    const handleFeedbackTypeChange = (type: EFeedbackType) => {
        const data:any = { type, current: 1, replied: type === EFeedbackType.REPLYED };
        if (currentFeedbackType === EFeedbackType.NORMAL) {
            data.order = feedbackFilter === EFeedbackFilter.OLDEST;
        }
        if (currentFeedbackType === EFeedbackType.ANONYMOUS) {
            data.order = anonymousFeedbackFilter === EFeedbackFilter.OLDEST;
        }
        if (currentFeedbackType === EFeedbackType.REPLYED) {
            data.order = repliedFeedbackFilter === EFeedbackFilter.OLDEST;
        }
        getFeedbackList(data);
    };

    const handlePaginationChange = (page: number, pageSize: number) => {
        const data:any = { type: currentFeedbackType, current: page, size: pageSize, replied: currentFeedbackType === EFeedbackType.REPLYED };
        if (currentFeedbackType === EFeedbackType.NORMAL) {
            setFeedbackListPage(page);
            setFeedbackListPageSize(pageSize);
            data.order = feedbackFilter === EFeedbackFilter.OLDEST;
        }
        if (currentFeedbackType === EFeedbackType.ANONYMOUS) {
            setAnonymousFeedbackListPage(page);
            setAnonymousFeedbackListPageSize(pageSize);
            data.order = anonymousFeedbackFilter === EFeedbackFilter.OLDEST;
        }
        if (currentFeedbackType === EFeedbackType.REPLYED) {
            setRepliedFeedbackListPage(page);
            setRepliedFeedbackListPageSize(pageSize);
            data.order = repliedFeedbackFilter === EFeedbackFilter.OLDEST;
        }

        getFeedbackList(data);
    };

    useEffect(() => {
        if (patientFeedbackListUpdatedFlag) {
            fetchOverviewData();
            getFeedbackList({ type: EFeedbackType.NORMAL, order: feedbackFilter });
            getFeedbackList({ type: EFeedbackType.ANONYMOUS, order: anonymousFeedbackFilter });
            getFeedbackList({ type: EFeedbackType.REPLYED, order: repliedFeedbackFilter, replied: true });
        }
    }, [patientFeedbackListUpdatedFlag]);

    const feedbackFilterItems: MenuProps['items'] = [
        {
            label: <span
                className={s.dropItem}
                onClick={() => {
                    setFeedbackFilter(EFeedbackFilter.NEWEST);
                    setFeedbackListPage(1);
                    getFeedbackList({ type: EFeedbackType.NORMAL, order: EFeedbackFilter.NEWEST, current: 1 });
                }}
            >Newest</span>,
            key: EFeedbackFilter.NEWEST,
        },
        {
            label: <span
                className={s.dropItem}
                onClick={() => {
                    setFeedbackFilter(EFeedbackFilter.OLDEST);
                    setFeedbackListPage(1);
                    getFeedbackList({ type: EFeedbackType.NORMAL, order: EFeedbackFilter.OLDEST, current: 1 });
                }}
            >Oldest</span>,
            key: EFeedbackFilter.OLDEST,
        },
    ];

    let feedbackFilterLabel = '';
    if (feedbackFilter === EFeedbackFilter.NEWEST) {
        feedbackFilterLabel = 'Newest';
    }
    if (feedbackFilter === EFeedbackFilter.OLDEST) {
        feedbackFilterLabel = 'Oldest';
    }

    const anonymousFeedbackFilterItems: MenuProps['items'] = [
        {
            label: <span
                className={s.dropItem}
                onClick={() => {
                    setAnonymousFeedbackFilter(EFeedbackFilter.NEWEST);
                    setAnonymousFeedbackListPage(1);
                    getFeedbackList({ type: EFeedbackType.ANONYMOUS, order: EFeedbackFilter.NEWEST, current: 1 });
                }}
            >Newest</span>,
            key: EFeedbackFilter.NEWEST,
        },
        {
            label: <span
                className={s.dropItem}
                onClick={() => {
                    setAnonymousFeedbackFilter(EFeedbackFilter.OLDEST);
                    setAnonymousFeedbackListPage(1);
                    getFeedbackList({ type: EFeedbackType.ANONYMOUS, order: EFeedbackFilter.OLDEST, current: 1 });
                }}
            >Oldest</span>,
            key: EFeedbackFilter.OLDEST,
        },
    ];

    let anonymousFeedbackFilterLabel = '';
    if (anonymousFeedbackFilter === EFeedbackFilter.NEWEST) {
        anonymousFeedbackFilterLabel = 'Newest';
    }
    if (anonymousFeedbackFilter === EFeedbackFilter.OLDEST) {
        anonymousFeedbackFilterLabel = 'Oldest';
    }

    const repliedFeedbackFilterItems: MenuProps['items'] = [
        {
            label: <span
                className={s.dropItem}
                onClick={() => {
                    setRepliedFeedbackFilter(EFeedbackFilter.NEWEST);
                    setRepliedFeedbackListPage(1);
                    getFeedbackList({ type: EFeedbackType.REPLYED, order: EFeedbackFilter.NEWEST, current: 1, replied: true });
                }}
            >Newest</span>,
            key: EFeedbackFilter.NEWEST,
        },
        {
            label: <span
                className={s.dropItem}
                onClick={() => {
                    setRepliedFeedbackFilter(EFeedbackFilter.OLDEST);
                    setRepliedFeedbackListPage(1);
                    getFeedbackList({ type: EFeedbackType.REPLYED, order: EFeedbackFilter.OLDEST, current: 1, replied: true });
                }}
            >Oldest</span>,
            key: EFeedbackFilter.OLDEST,
        },
    ];

    let repliedFeedbackFilterLabel = '';
    if (repliedFeedbackFilter === EFeedbackFilter.NEWEST) {
        repliedFeedbackFilterLabel = 'Newest';
    }
    if (repliedFeedbackFilter === EFeedbackFilter.OLDEST) {
        repliedFeedbackFilterLabel = 'Oldest';
    }

    const {
        averageSurveyRating,
        feedbackReceived,
    } = overviewData;

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <div className={s.rowWrap}>
                    <div className={s.rowIn}>
                        <div className={s.item}>
                            <div className={s.top}>
                                <div className={s.label}>Total feedback received</div>
                            </div>
                            <div className={s.bottom}>
                                <span className={s.val}>{feedbackReceived || 0}</span>
                            </div>
                        </div>
                        <div className={s.item}>
                            <div className={s.top}>
                                <div className={s.label}>Average survey rating</div>
                            </div>
                            <div className={s.bottom}>
                                <span className={s.val}>{averageSurveyRating || 0}</span> <span className={s.val2}> out of 5</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className={s.feedback}>
                <div className={s.feedbackHeader}>
                    <h2>Feedback received</h2>
                </div>
                <div className={s.feedbackType}>
                    <div
                        onClick={() => {
                            // setFeedbackListPage(1);
                            // setAnonymousFeedbackListPage(1);
                            // setRepliedFeedbackListPage(1);
                            // handleFeedbackTypeChange(EFeedbackType.NORMAL);
                            setCurrentFeedbackType(EFeedbackType.NORMAL);
                        }}
                        className={currentFeedbackType === EFeedbackType.NORMAL ? `${s.typeItem} ${s.typeItemActive}` : s.typeItem}
                    >
                        Feedback needs reply ({totalFeedback})
                    </div>
                    <div
                        onClick={() => {
                            // setFeedbackListPage(1);
                            // setAnonymousFeedbackListPage(1);
                            // setRepliedFeedbackListPage(1);
                            // handleFeedbackTypeChange(EFeedbackType.ANONYMOUS);
                            setCurrentFeedbackType(EFeedbackType.ANONYMOUS);
                        }}
                        className={currentFeedbackType === EFeedbackType.ANONYMOUS ? `${s.typeItem} ${s.typeItemActive}` : s.typeItem}
                    >
                        Anonymous feedback ({anonymousTotalFeedback})
                    </div>
                    <div
                        onClick={() => {
                            // setFeedbackListPage(1);
                            // setAnonymousFeedbackListPage(1);
                            // setRepliedFeedbackListPage(1);
                            // handleFeedbackTypeChange(EFeedbackType.REPLYED);
                            setCurrentFeedbackType(EFeedbackType.REPLYED);
                        }}
                        className={currentFeedbackType === EFeedbackType.REPLYED ? `${s.typeItem} ${s.typeItemActive}` : s.typeItem}
                    >
                        Responded feedback ({repliedTotalFeedback})
                    </div>
                </div>
                <div className={s.tips}>
                    {
                        showUpgrade && (
                            <div className={s.upgrade}>
                                <div className={s.nodeLeft}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5 2C5.55228 2 6 2.44772 6 3V4H7C7.55228 4 8 4.44772 8 5C8 5.55228 7.55228 6 7 6H6V7C6 7.55228 5.55228 8 5 8C4.44772 8 4 7.55228 4 7V6H3C2.44772 6 2 5.55228 2 5C2 4.44772 2.44772 4 3 4H4V3C4 2.44772 4.44772 2 5 2ZM5 12C5.55228 12 6 12.4477 6 13V14H7C7.55228 14 8 14.4477 8 15C8 15.5523 7.55228 16 7 16H6V17C6 17.5523 5.55228 18 5 18C4.44772 18 4 17.5523 4 17V16H3C2.44772 16 2 15.5523 2 15C2 14.4477 2.44772 14 3 14H4V13C4 12.4477 4.44772 12 5 12Z" fill="#FBBF24" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M11.9999 2C12.4537 2 12.8505 2.30548 12.9667 2.74411L14.1459 7.19893L17.4997 9.13381C17.8092 9.31241 17.9999 9.64262 17.9999 10C17.9999 10.3574 17.8092 10.6876 17.4997 10.8662L14.1459 12.8011L12.9667 17.2559C12.8505 17.6945 12.4537 18 11.9999 18C11.5462 18 11.1493 17.6945 11.0332 17.2559L9.85402 12.8011L6.50027 10.8662C6.19072 10.6876 6 10.3574 6 10C6 9.64262 6.19072 9.31241 6.50027 9.13382L9.85402 7.19893L11.0332 2.74411C11.1493 2.30548 11.5462 2 11.9999 2Z" fill="#FBBF24" />
                                    </svg>
                                </div>
                                <div className={s.nodeRight}>
                                    <div className={s.text}>
                                        Elevate patient communication with our &apos;Reply Feedback&apos; tool. As a premium feature, it enables timely responses to patient feedback, building stronger trust and engagement. Enhance your practice&apos;s responsiveness and patient care by upgrading your plan now.
                                    </div>
                                    <div
                                        onClick={() => {
                                            openSubscriptionModal();
                                        }}
                                        className={s.upText}
                                    >
                                        Upgrade plan
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        showChannelStore && (
                            <div className={s.upgrade}>
                                <div className={s.nodeLeft}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5 2C5.55228 2 6 2.44772 6 3V4H7C7.55228 4 8 4.44772 8 5C8 5.55228 7.55228 6 7 6H6V7C6 7.55228 5.55228 8 5 8C4.44772 8 4 7.55228 4 7V6H3C2.44772 6 2 5.55228 2 5C2 4.44772 2.44772 4 3 4H4V3C4 2.44772 4.44772 2 5 2ZM5 12C5.55228 12 6 12.4477 6 13V14H7C7.55228 14 8 14.4477 8 15C8 15.5523 7.55228 16 7 16H6V17C6 17.5523 5.55228 18 5 18C4.44772 18 4 17.5523 4 17V16H3C2.44772 16 2 15.5523 2 15C2 14.4477 2.44772 14 3 14H4V13C4 12.4477 4.44772 12 5 12Z" fill="#FBBF24" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M11.9999 2C12.4537 2 12.8505 2.30548 12.9667 2.74411L14.1459 7.19893L17.4997 9.13381C17.8092 9.31241 17.9999 9.64262 17.9999 10C17.9999 10.3574 17.8092 10.6876 17.4997 10.8662L14.1459 12.8011L12.9667 17.2559C12.8505 17.6945 12.4537 18 11.9999 18C11.5462 18 11.1493 17.6945 11.0332 17.2559L9.85402 12.8011L6.50027 10.8662C6.19072 10.6876 6 10.3574 6 10C6 9.64262 6.19072 9.31241 6.50027 9.13382L9.85402 7.19893L11.0332 2.74411C11.1493 2.30548 11.5462 2 11.9999 2Z" fill="#FBBF24" />
                                    </svg>
                                </div>
                                <div className={s.nodeRight}>
                                    <div className={s.text}>
                                        To reply to feedback in Reputation Management, you must be listed on at least one channel. Visit the Channel Store to select and add your preferred channels.
                                    </div>
                                    <div
                                        onClick={() => {
                                            navigate('/dashboard/channel');
                                        }}
                                        className={s.upText}
                                    >
                                        Browse channel store
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <Spin className={s.feedbackContent} spinning={loading}>
                    {
                        currentFeedbackType === EFeedbackType.NORMAL &&
                        <>
                            <div className={s.feedbackFilter}>
                                <Dropdown menu={{ items: feedbackFilterItems }} trigger={['click']}>
                                    <a onClick={(e) => e.preventDefault()}>
                                        <Space>
                                            <span className={s.filterLabel}>{feedbackFilterLabel}</span>
                                            <DownOutlined />
                                        </Space>
                                    </a>
                                </Dropdown>
                            </div>
                            <div className={s.feedbackContentIn}>
                                {
                                    feedbackData?.map((feedback:PatientsFeedback) => {
                                        return (
                                            <div className={s.feedbackItem} key={`${feedback.id}`}>
                                                <div className={s.left}>
                                                    <div className={s.nameAndScore}>
                                                        <div className={s.name}>{`${feedback.firstName} ${feedback.lastName}`} </div>
                                                        <div className={s.score}><ScoreLabel value={feedback.overallScore} /> </div>
                                                    </div>
                                                    <div className={s.contentAndTime}>
                                                        <div className={s.content}>{feedback.feedbackContent}</div>
                                                        <div className={s.time}>{getTimeStr(dayjs(feedback?.answerAt), 'MMMM D, YYYY')}</div>
                                                    </div>
                                                </div>
                                                <div className={s.right}>
                                                    <Button
                                                        onClick={() => {
                                                            setCurrentFeedback(feedback);
                                                            setShowReply(true);
                                                        }}
                                                        className={`${canReply ? s.btn : s.btnDisabled}`}
                                                        disabled={!canReply}
                                                    >Reply
                                                    </Button>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            <div className={s.paginationBox}>
                                <Pagination current={feedbackListPage} total={totalFeedback} pageSize={feedbackListPageSize} onChange={handlePaginationChange} />
                            </div>

                        </>
                    }

                    {
                        currentFeedbackType === EFeedbackType.ANONYMOUS &&
                        <>
                            <div className={s.feedbackFilter}>
                                <Dropdown menu={{ items: anonymousFeedbackFilterItems }} trigger={['click']}>
                                    <a onClick={(e) => e.preventDefault()}>
                                        <Space>
                                            <span className={s.filterLabel}>{anonymousFeedbackFilterLabel}</span>
                                            <DownOutlined />
                                        </Space>
                                    </a>
                                </Dropdown>
                            </div>
                            <div className={s.feedbackContentIn}>
                                {
                                    anonymousFeedbackData?.map((feedback:PatientsFeedback) => {
                                        return (
                                            <div className={s.feedbackItem} key={feedback.id}>
                                                <div className={s.left}>
                                                    <div className={s.nameAndScore}>
                                                        <div className={s.name}>{getTimeStr(dayjs(feedback?.answerAt), 'MMMM D, YYYY')}</div>
                                                        <div className={s.score}><ScoreLabel value={feedback.overallScore} /> </div>
                                                    </div>
                                                    <div className={s.contentAndTime}>
                                                        <div className={s.content} style={{ marginRight: '120px' }}>{feedback.feedbackContent}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            <div className={s.paginationBox}>
                                <Pagination current={anonymousFeedbackListPage} total={anonymousTotalFeedback} pageSize={anonymousFeedbackListPageSize} onChange={handlePaginationChange} />
                            </div>
                        </>
                    }

                    {
                        currentFeedbackType === EFeedbackType.REPLYED &&
                        <>
                            <div className={s.feedbackFilter}>
                                <Dropdown menu={{ items: repliedFeedbackFilterItems }} trigger={['click']}>
                                    <a onClick={(e) => e.preventDefault()}>
                                        <Space>
                                            <span className={s.filterLabel}>{repliedFeedbackFilterLabel}</span>
                                            <DownOutlined />
                                        </Space>
                                    </a>
                                </Dropdown>
                            </div>
                            <div className={s.feedbackContentIn}>
                                {
                                    repliedFeedbackData?.map((feedback:PatientsFeedback) => {
                                        return (
                                            <div className={s.feedbackItem} key={feedback.id}>
                                                <div className={s.left}>
                                                    <div className={s.nameAndScore}>
                                                        <div className={s.name}>{`${feedback.firstName} ${feedback.lastName}`} </div>
                                                        <div className={s.score}><ScoreLabel value={feedback.overallScore} /> </div>
                                                    </div>
                                                    <div className={s.contentAndTime}>
                                                        <div className={s.content}>{feedback.feedbackContent}</div>
                                                        <div className={s.time}>{getTimeStr(dayjs(feedback?.answerAt), 'MMMM D, YYYY')}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            <div className={s.paginationBox}>
                                <Pagination current={repliedFeedbackListPage} total={repliedTotalFeedback} pageSize={repliedFeedbackListPageSize} onChange={handlePaginationChange} />
                            </div>
                        </>
                    }

                </Spin>
            </div>
            <ReplyFeedbackModal
                show={showReply}
                item={currentFeedback}
                onSuccess={() => {
                    setShowReply(false);
                }}
                onCannel={() => {
                    setShowReply(false);
                }}
            />
        </div>
    );
};

export default Feedback;
