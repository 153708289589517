import React from 'react';
import PatientIntroPic from 'assets/survey/importIntroPic.svg';
import s from './s.module.less';

interface IProps {
    hideIndex: boolean;
}

const PatientIntro = ({
    hideIndex,
}: IProps) => {
    return (
        <div className={s.introWrap}>
            <div className={s.left}>
                { !hideIndex && <div className={s.stepText}>Step 2</div> }
                <div className={s.title}>Add your patient list</div>
                <div className={s.desc}>Our system helps you target the right audience for desired feedback.</div>
            </div>
            <div className={s.right}>
                <img src={PatientIntroPic} />
            </div>
        </div>
    );
};

export default PatientIntro;
