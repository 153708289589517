import React from 'react';
import s from './s.module.less';
import { useNavigate } from 'react-router-dom';
import { TProfileData } from 'types/home';

type IProps = {
    profileData: TProfileData
};

const Index: React.FC<IProps> = ({ profileData }) => {
    const navigate = useNavigate();
    if (!profileData.waitReplyReviewCount) {
        return null;
    }
    return (
        <div className={s.wrap}>
            <p className={s.description}>
                You have {profileData.waitReplyReviewCount} patient reviews that need
                your response.
            </p>
            <div
                className={s.applyButton}
                onClick={() => {
                    navigate('/dashboard/klarity-reviews');
                }}
            >
                Reply to reviews
            </div>
        </div>
    );
};

export default Index;
