import { Button, message, Radio, Space } from 'antd';
import React, { useCallback, useState } from 'react';
import commonS from 'styles/common.module.less';
import s from './s.module.less';
import { updateOpsChannelWithdrawStatus } from 'api/operation';

interface IProps {
    channelId: number;
    providerId: number;
    id: number;
    refetch: () => void;
    value: 0 | 1;
}

const StatusCard = ({
    channelId,
    providerId,
    id,
    refetch,
    value,
}: IProps) => {
    const [currentValue, setCurrentValue] = useState<0 | 1>(value);
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);

    const handleSave = async () => {
        setLoading(true);
        const res = await updateOpsChannelWithdrawStatus({
            channelId,
            providerId,
            id,
            passed: currentValue === 1,
        });

        if (!res?.error) {
            refetch();
        } else {
            messageApi.error(res.error);
        }
        setLoading(false);
    };

    React.useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    return (
        <div className={s.wrap}>
            {contextHolder}
            <div className={s.title}>Has the provider&apos;s profile been removed from the channel?</div>
            <div className={s.websiteBox}>
                <Radio.Group
                    onChange={(e) => {
                        setCurrentValue(e.target.value);
                    }}
                    value={currentValue}
                >
                    <Space direction="vertical">
                        <Radio value={0}>No</Radio>
                        <Radio value={1}>Yes</Radio>
                    </Space>
                </Radio.Group>
            </div>
            <div className={s.btnWrap}>
                <Button
                    onClick={handleSave}
                    loading={loading}
                    className={commonS.lightBtn}
                >
                    Update the status
                </Button>
            </div>
        </div>
    );
};

export default StatusCard;
