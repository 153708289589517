// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--MLnle .s-module__title--Tdssx {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 140% */
  letter-spacing: -0.5px;
  margin-bottom: 16px;
  margin-top: 24px;
}
.s-module__wrap--MLnle .s-module__list--O3hHb {
  padding: 16px;
  border-radius: 24px;
  background-color: #F8FAFC;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 16px;
  -moz-column-gap: 23px;
       column-gap: 23px;
}
.s-module__wrap--MLnle .s-module__list--O3hHb .s-module__item--rZ0PA {
  border-radius: 14px;
  padding: 20px 29px;
  background-color: var(--white);
}
.s-module__wrap--MLnle .s-module__list--O3hHb .s-module__item--rZ0PA .s-module__logo--x60fq {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/KlarityProcessPages/KiwiKnowledgePage/components/Partnerchannels/s.module.less"],"names":[],"mappings":"AAAA;EAEQ,WAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EAAN,SAAS;EACH,sBAAA;EACA,mBAAA;EACA,gBAAA;AACR;AAVA;EAaQ,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,aAAA;EACA,qCAAA;EACA,aAAA;EACA,qBAAA;OAAA,gBAAA;AAAR;AAnBA;EAsBY,mBAAA;EACA,kBAAA;EACA,8BAAA;AAAZ;AAxBA;EA0BgB,WAAA;EACA,oBAAA;KAAA,iBAAA;AAChB","sourcesContent":[".wrap {\n    .title {\n        color: #000;\n        font-size: 20px;\n        font-style: normal;\n        font-weight: 500;\n        line-height: 28px; /* 140% */\n        letter-spacing: -0.5px;\n        margin-bottom: 16px;\n        margin-top: 24px;\n    }\n\n    .list {\n        padding: 16px;\n        border-radius: 24px;\n        background-color: #F8FAFC;\n        display: grid;\n        grid-template-columns: repeat(5, 1fr);\n        row-gap: 16px;\n        column-gap: 23px;\n\n        .item {\n            border-radius: 14px;\n            padding: 20px 29px;\n            background-color: var(--white);\n            .logo {\n                width: 100%;\n                object-fit: cover;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--MLnle`,
	"title": `s-module__title--Tdssx`,
	"list": `s-module__list--O3hHb`,
	"item": `s-module__item--rZ0PA`,
	"logo": `s-module__logo--x60fq`
};
export default ___CSS_LOADER_EXPORT___;
