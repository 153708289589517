import React from 'react';
import s from './s.module.less';
import LineBar from '../LineBar';
import { useNavigate } from 'react-router-dom';

const Index: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div className={s.wrap}>
            <LineBar
                lineBarId="kiwi-3-1"
                icon="icon-2"
                title="Improve your services and pricing design"
                description="Conduct a comprehensive pricing check and adjust accordingly."
                details={`<ul>
                            <li>Price smartly: research diverse sources to provide affordable care (recommend to look into Psychology Today and Zocdoc, or email <a target="_blank" href="mailto:support@kiwihealth.com">support@kiwihealth.com</a> for advice)</li>
                        </ul>`}
                cta="Review my services"
                onCta={() => {
                    navigate(
                        '/create-uni-profile?type=uniprofile&from=home&step=6',
                    );
                }}
            />
            <LineBar
                lineBarId="kiwi-3-2"
                icon="icon-3"
                title="Grow your service offerings"
                description="Unlock new markets by adding extra licenses to your UniProfile."
                details={`<ul>
                        <li>Expand your practice: add licenses and diversify patient care</li>
                        </ul>`}
                cta="Upload new license"
                onCta={() => {
                    navigate(
                        '/create-uni-profile?type=uniprofile&from=home&step=5',
                    );
                }}
            />
            <LineBar
                lineBarId="kiwi-3-3"
                icon="icon-6"
                title="Actively collect patient feedback"
                description="Leverage Reputation Management tools."
                details={`<ul>
                        <li>Collect patient reviews from multiple channels</li>
                        <li>Respond to reviews to enhance patient satisfaction</li>
                    </ul>`}
                cta="Manage reviews"
                onCta={() => {
                    navigate('/dashboard/reputation-management');
                }}
            />
            <LineBar
                lineBarId="kiwi-3-4"
                icon="icon-5"
                title="Promote your services to build a patient base"
                description="Share your Practice Front to patients and social networks."
                details={`<ul>
                        <li>Insert your online scheduling link in Practice Front</li>
                        <li>Share Practice Front with your existing patients to promote your full service menu</li>
                        <li>Increase visibility: share Practice Front on social media</li>
                        </ul>`}
                cta="Open Practice Front"
                onCta={() => {
                    navigate('/dashboard/practice-front');
                }}
            />
        </div>
    );
};

export default Index;
