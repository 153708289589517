// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__modalWrap--b9Q7g {
  background: #fff;
  padding-bottom: 12px;
}
.s-module__modalWrap--b9Q7g h3 {
  color: var(--gray-900, #111827);
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 8px;
  margin-top: 16px;
}
.s-module__modalWrap--b9Q7g .s-module__des--Cku_V {
  color: var(--gray-500, #6B7280);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 12px;
}
.s-module__display--BtJg9 {
  height: auto;
}
.s-module__row--GQ0cb {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 16px;
}
.s-module__row--GQ0cb .s-module__displayItem--qwJev {
  margin-bottom: 12px;
}
.s-module__row--GQ0cb .s-module__displayItem--qwJev .s-module__label--jkACD {
  color: #6B7280;
  font-size: 14px;
  line-height: 20px;
}
.s-module__rowItem--T8gEb {
  margin-right: 24px;
}
.s-module__colItem--g26ml {
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/pages/operation/AiConfigPage/components/AddAndUpdateAiConfigModule/s.module.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,oBAAA;AACF;AAHA;EAKI,+BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;AACJ;AAXA;EAcI,+BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AAAJ;AAIA;EACE,YAAA;AAFF;AAKA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AAHF;AADA;EAaI,mBAAA;AATJ;AAJA;EAQM,cAAA;EACA,eAAA;EACA,iBAAA;AADN;AAQA;EACE,kBAAA;AANF;AASA;EACE,aAAA;EACA,sBAAA;AAPF","sourcesContent":[".modalWrap {\n  background: #fff;\n  padding-bottom: 12px;\n\n  h3 {\n    color: var(--gray-900, #111827);\n    font-size: 18px;\n    font-weight: 500;\n    line-height: 24px;\n    margin-bottom: 8px;\n    margin-top: 16px;\n  }\n\n  .des {\n    color: var(--gray-500, #6B7280);\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 20px;\n    margin-bottom: 12px;\n  }\n}\n\n.display {\n  height: auto;\n}\n\n.row {\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  margin-top: 16px;\n\n  .displayItem {\n    .label {\n      color: #6B7280;\n      font-size: 14px;\n      line-height: 20px;\n    }\n\n    margin-bottom: 12px;\n  }\n}\n\n.rowItem {\n  margin-right: 24px;\n}\n\n.colItem {\n  display: flex;\n  flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalWrap": `s-module__modalWrap--b9Q7g`,
	"des": `s-module__des--Cku_V`,
	"display": `s-module__display--BtJg9`,
	"row": `s-module__row--GQ0cb`,
	"displayItem": `s-module__displayItem--qwJev`,
	"label": `s-module__label--jkACD`,
	"rowItem": `s-module__rowItem--T8gEb`,
	"colItem": `s-module__colItem--g26ml`
};
export default ___CSS_LOADER_EXPORT___;
