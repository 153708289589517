import React, { useCallback } from 'react';
import s from './s.module.less';
import { Form, Input, Modal, Select, Tooltip } from 'antd';
import commonS from 'styles/common.module.less';
import { ProfileFormFieldEnum } from 'pages/CreateUniProfilePage/types';
import CredentialTip from 'assets/channel/credentialTip.jpg';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields } from 'types/enumerationData';
import { IAddProfile } from 'types/reputation';

interface IProps {
    loading?: boolean;
    show?: boolean;
    onOk: (data: IAddProfile) => void;
    onCannel?:() => void;
}

const SetProviderInfoModal = ({
    loading,
    show,
    onOk,
    onCannel,
}: IProps) => {
    const [formInstance] = Form.useForm();
    const [credentialTypes] = useGetSettingData(EnumFields.CREDENTIAL_TYPE);

    const handleSubmit = useCallback(async () => {
        try {
            const formValues = await formInstance.validateFields();

            onOk?.({
                ...formValues,
                credential: formValues?.credential?.join(','),
            });
        } catch (e) {
            console.error(e);
        }
    }, [formInstance, onOk]);
    return (
        <Modal
            title="Enter your name and credentials"
            closable
            // className={`${commonS.modalFixHeightWrap} ${commonS.modalFixSetedHeightWrap}`}
            width="543px"
            onCancel={() => {
                onCannel?.();
            }}
            onOk={handleSubmit}
            okText="Confirm"
            open={!!show}
            confirmLoading={loading}
            destroyOnClose
        >
            <div className={s.tip}>Your name and credentials are required to set up the survey</div>
            <Form
                form={formInstance}
                name="profileForm"
                className={commonS.formStyle1}
                // initialValues={{ remember: true }}
                autoComplete="off"
                layout="vertical"
                scrollToFirstError
            >
                <div className={s.wrap}>
                    <div className={s.row}>
                        <div
                            className={s.displayItem}
                            style={{ marginRight: '5px' }}
                        >
                            <Form.Item
                                label="First name"
                                name={ProfileFormFieldEnum.FIRST_NAME}
                                validateFirst
                                rules={[
                                    { required: true, message: 'First name is a required field' },
                                    { max: 50, message: 'Exceeded maximum character length of 50' },
                                ]}
                            >
                                <Input type="text" />
                            </Form.Item>
                        </div>
                        <div
                            className={s.displayItem}
                            style={{ marginLeft: '5px' }}
                        >
                            <Form.Item
                                label="Last name"
                                name={ProfileFormFieldEnum.LAST_NAME}
                                validateFirst
                                rules={[
                                    { required: true, message: 'Last name is a required field' },
                                    { max: 50, message: 'Exceeded maximum character length of 50' },
                                ]}
                            >
                                <Input type="text" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div
                            className={s.displayItem}
                        >
                            <Form.Item
                                label={
                                    <div className={s.formLabel}>Credentials
                                        <Tooltip overlayClassName={s.tipCard} overlayInnerStyle={{ padding: '24px' }} color="#FFFFFF" title={<div className={s.tipText}>Credentials will appear right next to your name.<img src={CredentialTip} /></div>}>
                                            <div className={s.questionMarkIcon} />
                                        </Tooltip>
                                    </div>
                                }
                                name={ProfileFormFieldEnum.CREDENTIAL}
                                validateFirst
                                rules={[
                                    { required: true, message: 'Credentials is a required field' },
                                ]}
                            >
                                <Select placeholder="search and select your credentials" mode="multiple" fieldNames={{ label: 'content', value: 'dictKey' }} options={credentialTypes} />
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export default SetProviderInfoModal;
