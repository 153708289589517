import Logo1 from 'assets/company/long_Allcounseling.png';
import Logo2 from 'assets/company/long_Bing.png';
import Logo3 from 'assets/company/long_Doctor.png';
import Logo4 from 'assets/company/long_Good therapy.png';
import Logo5 from 'assets/company/long_Healthgrades.png';
import Logo6 from 'assets/company/long_Nextdoor.png';
import Logo7 from 'assets/company/long_Vitals.png';
import Logo8 from 'assets/company/long_Webmd.png';
import Logo9 from 'assets/company/long_Zoom.png';
import Logo10 from 'assets/company/long_google.png';
import Logo11 from 'assets/company/long_psychology.png';
import Logo12 from 'assets/company/long_yelp.png';

export const TITLE = 'Maximize your practice with Kiwi Health';

export const CONFIG = [
    Logo10,
    Logo2,
    Logo8,
    Logo11,
    Logo12,
    Logo3,
    Logo7,
    Logo4,
    Logo1,
    Logo9,
    Logo5,
    Logo6,
];
