// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/practiceFront/practiceFrontCreateSuccess.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--WspJN {
  padding: 57px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.s-module__wrap--WspJN .s-module__successImage--D9L8m {
  margin-bottom: 27px;
  width: 360px;
  height: 360px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center no-repeat;
  background-size: contain;
}
.s-module__wrap--WspJN .s-module__title--S97GE {
  margin-bottom: 20px;
  color: var(--gray-900);
  text-align: center;
  font-family: Inter;
  font-size: 44px;
  font-style: normal;
  font-weight: 800;
  line-height: 60px;
}
.s-module__wrap--WspJN .s-module__desc--hsDH9 {
  width: 55%;
  margin-bottom: 27px;
  color: var(--gray-500);
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 37px;
  letter-spacing: 0.24px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/ReputationConfigSuccessfully/s.module.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AALA;EAOQ,mBAAA;EACA,YAAA;EACA,aAAA;EACA,oEAAA;EACA,wBAAA;AACR;AAZA;EAeQ,mBAAA;EACA,sBAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAAR;AAtBA;EA0BQ,UAAA;EACA,mBAAA;EACA,sBAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;AADR","sourcesContent":[".wrap {\n    padding: 57px 80px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    .successImage {\n        margin-bottom: 27px;\n        width: 360px;\n        height: 360px;\n        background: url('assets/practiceFront/practiceFrontCreateSuccess.svg') center no-repeat;\n        background-size: contain;\n    }\n\n    .title {\n        margin-bottom: 20px;\n        color: var(--gray-900);\n        text-align: center;\n        font-family: Inter;\n        font-size: 44px;\n        font-style: normal;\n        font-weight: 800;\n        line-height: 60px;\n    }\n\n    .desc {\n        width: 55%;\n        margin-bottom: 27px;\n        color: var(--gray-500);\n        text-align: center;\n        font-family: Inter;\n        font-size: 24px;\n        font-style: normal;\n        font-weight: 400;\n        line-height: 37px;\n        letter-spacing: 0.24px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--WspJN`,
	"successImage": `s-module__successImage--D9L8m`,
	"title": `s-module__title--S97GE`,
	"desc": `s-module__desc--hsDH9`
};
export default ___CSS_LOADER_EXPORT___;
