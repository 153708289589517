// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--Tn51M {
  padding: 24px;
}
.s-module__maxTips--MdLLV {
  color: #6B7280;
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  width: 700px;
}
.s-module__feedbackItem--Ci0HX {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #D1D5DB;
}
.s-module__feedbackItem--Ci0HX .s-module__left--p1wYV {
  flex-grow: 1;
}
.s-module__feedbackItem--Ci0HX .s-module__right--devcn {
  flex-shrink: 0;
  margin-left: 40px;
}
.s-module__feedbackItem--Ci0HX .s-module__right--devcn .s-module__btn--MeZha {
  background-color: #fff;
}
.s-module__feedbackItem--Ci0HX .s-module__nameAndScore--BlMuy,
.s-module__feedbackItem--Ci0HX .s-module__contentAndTime--CJH1J {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.s-module__feedbackItem--Ci0HX .s-module__name--qEYbq {
  color: #111827;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.s-module__feedbackItem--Ci0HX .s-module__time--_4OOS {
  flex-shrink: 0;
  margin-left: 24px;
  color: #6B7280;
  font-size: 14px;
}
.s-module__feedbackItem--Ci0HX .s-module__content--EUfxO {
  color: #6B7280;
  font-size: 14px;
  line-height: 20px;
}
.s-module__row--G0eBT {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 16px;
}
.s-module__row--G0eBT .s-module__displayItem--EocdT {
  margin-bottom: 12px;
}
.s-module__row--G0eBT .s-module__displayItem--EocdT .s-module__label--ic88d {
  color: #6B7280;
  font-size: 14px;
  line-height: 20px;
}
.s-module__rowItem--La1PI {
  margin-right: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/ReputationDashboradPage/components/ReplyFeedbackModal/s.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAEA;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;AAAF;AAGA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,gCAAA;AADF;AAJA;EAQI,YAAA;AADJ;AAPA;EAYI,cAAA;EACA,iBAAA;AAFJ;AAXA;EAgBM,sBAAA;AAFN;AAdA;;EAsBI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,kBAAA;AAJJ;AAtBA;EA8BI,cAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AALJ;AA5BA;EAqCI,cAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;AANJ;AAlCA;EA4CI,cAAA;EACA,eAAA;EACA,iBAAA;AAPJ;AAWA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AATF;AAKA;EAaI,mBAAA;AAfJ;AAEA;EAQM,cAAA;EACA,eAAA;EACA,iBAAA;AAPN;AAcA;EACE,kBAAA;AAZF","sourcesContent":[".wrap {\n  padding: 24px;\n}\n\n.maxTips{\n  color: #6B7280;\n  font-size: 14px;\n  font-weight: 500;\n  text-align: right;\n  width: 700px;\n}\n\n.feedbackItem {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding: 12px 0;\n  border-bottom: 1px solid #D1D5DB;\n\n  .left {\n    flex-grow: 1;\n  }\n\n  .right {\n    flex-shrink: 0;\n    margin-left: 40px;\n\n    .btn {\n      background-color: #fff;\n    }\n  }\n\n  .nameAndScore,\n  .contentAndTime {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 8px;\n  }\n\n  .name {\n    color: #111827;\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 20px;\n  }\n\n  .time {\n    flex-shrink: 0;\n    margin-left: 24px;\n    color: #6B7280;\n    font-size: 14px;\n  }\n\n  .content {\n    color: #6B7280;\n    font-size: 14px;\n    line-height: 20px;\n  }\n}\n\n.row {\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  margin-top: 16px;\n\n  .displayItem {\n    .label {\n      color: #6B7280;\n      font-size: 14px;\n      line-height: 20px;\n    }\n\n    margin-bottom: 12px;\n  }\n}\n\n.rowItem {\n  margin-right: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--Tn51M`,
	"maxTips": `s-module__maxTips--MdLLV`,
	"feedbackItem": `s-module__feedbackItem--Ci0HX`,
	"left": `s-module__left--p1wYV`,
	"right": `s-module__right--devcn`,
	"btn": `s-module__btn--MeZha`,
	"nameAndScore": `s-module__nameAndScore--BlMuy`,
	"contentAndTime": `s-module__contentAndTime--CJH1J`,
	"name": `s-module__name--qEYbq`,
	"time": `s-module__time--_4OOS`,
	"content": `s-module__content--EUfxO`,
	"row": `s-module__row--G0eBT`,
	"displayItem": `s-module__displayItem--EocdT`,
	"label": `s-module__label--ic88d`,
	"rowItem": `s-module__rowItem--La1PI`
};
export default ___CSS_LOADER_EXPORT___;
