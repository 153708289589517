import React, { useMemo } from 'react';
import { Button, Descriptions, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import useCollaboratingPhysician from 'hooks/useCollaboratingPhysician';
import useAssistant from 'hooks/useAssistant';
import { EAddOnProductCode } from 'types/ehr';
import HelpEmail from '../HelpEmail';
import s from './s.module.less';

const SeatCard: React.FC = () => {
    const navigate = useNavigate();
    const [allCollaboratingPhysician, loading, refetch] =
      useCollaboratingPhysician(EAddOnProductCode.COLLABORATING_PHYSICIAN_SEAT);
    const [allAssistants, loadingAssistant, refetchAssistant] = useAssistant(
        EAddOnProductCode.ASSISTANT_SEAT,
    );
    const collaboratingPhysicianPurchased = useMemo(() => {
        return allCollaboratingPhysician.filter((item) => item.productPurchased);
    }, [allCollaboratingPhysician]);

    const assistantsPurchased = useMemo(() => {
        return allAssistants.filter((item) => item.productPurchased);
    }, [allAssistants]);
    return (
        <div className={s.cardItem}>
            <Descriptions
                title="Additional members"
                layout="vertical"
                extra={
                    <Button
                        onClick={() => {
                            navigate(`${PATH.DASHBOARD}/${PATH.EHR_ADD_ON_PROVIDER}`);
                        }}
                    >
                        Add
                    </Button>
                }
                column={1}
                colon={false}
                size="small"
                contentStyle={{
                    marginBottom: '16px',
                }}
            >
                <Descriptions.Item label="Collaborating physician ">
                    {collaboratingPhysicianPurchased.length === 0 ? (
                        'No seat added'
                    ) : (
                        <div className={s.detail}>
                            <div>{collaboratingPhysicianPurchased?.[0].email}</div>
                            {collaboratingPhysicianPurchased.length > 1 && (
                                <Tooltip
                                    color="#fff"
                                    overlayInnerStyle={{
                                        color: '#6B7280',
                                        padding: 8,
                                        fontSize: '12px',
                                    }}
                                    title={
                                        <div className={s.emailContent}>
                                            {collaboratingPhysicianPurchased
                                                .slice(1)
                                                .map((item) => {
                                                    return <div>{item.email}</div>;
                                                })}
                                        </div>
                                    }
                                >
                                    <div className={s.more}>
                                        {collaboratingPhysicianPurchased.length - 1} more
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    )}
                </Descriptions.Item>
                <Descriptions.Item label="Assistant">
                    {assistantsPurchased.length === 0 ? (
                        'No seat added'
                    ) : (
                        <div className={s.detail}>
                            <div>{assistantsPurchased?.[0].assistantEmail}</div>
                            {assistantsPurchased.length > 1 && (
                                <Tooltip
                                    color="#fff"
                                    overlayInnerStyle={{
                                        color: '#6B7280',
                                        padding: 8,
                                        fontSize: '12px',
                                    }}
                                    title={
                                        <div className={s.emailContent}>
                                            {assistantsPurchased.slice(1).map((item) => {
                                                return <div>{item.assistantEmail}</div>;
                                            })}
                                        </div>
                                    }
                                >
                                    <div className={s.more}>
                                        {assistantsPurchased.length - 1} more
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    )}
                </Descriptions.Item>
                <Descriptions.Item label="">
                    <HelpEmail title="Need to manage existing members?" />
                </Descriptions.Item>
            </Descriptions>
        </div>
    );
};

export default SeatCard;
