import React, { useCallback } from 'react';
import s from './s.module.less';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';

const Successfully = () => {
    const navigate = useNavigate();

    const handleExploreAddons = useCallback(() => {
        navigate(`${PATH.DASHBOARD}/${PATH.EHR_ADD_ON_PROVIDER}`, { replace: true });
    }, [navigate]);

    const handleBackToEHRHome = useCallback(() => {
        navigate(`${PATH.DASHBOARD}/${PATH.EHR_HOME_PROVIDER}`, { replace: true });
    }, [navigate]);

    return (
        <div className={s.wrap}>
            <div className={s.box}>
                <div className={s.successImage} />
                <p className={s.desc}>Your EHR account is activated</p>
                <p className={s.exp}>If you need to add members such as collaborating physicians or assistants to Kiwi EHR, or if you require additional practice enablement features like e-Prescription and e-Fax, you can explore the Kiwi EHR add-ons.</p>
                <Button type="primary" onClick={handleExploreAddons}>Explore add-ons</Button>
                <a className={s.link} onClick={handleBackToEHRHome}>Back to EHR home page</a>
            </div>
        </div>
    );
};

export default Successfully;
