import React, { useCallback, useEffect } from 'react';
import s from './s.module.less';
// import Logo from 'assets/logo/kiwihealth.png';
import Logo from 'assets/campain/blackfriday2024/kiwiLogo.png';
import { Button, message } from 'antd';
import KlaritySubscription from 'components/KlaritySubscription';
import { TSubscriptionItem } from 'types/subscription';
import { getPayLink } from 'api/subscription';
import { setPendingSubscriptionPlan } from 'utils/localstore';
import { openKlarityPlanAdvertisingModal } from 'utils/globalLayerControl';
import { PATH } from 'constants/path';
import { useNavigate } from 'react-router-dom';
import useLoadBasicData from 'hooks/useLoadBasicData';
import { UNLOCK_YOURY_KIWI_BENEFITS } from 'constants/common';

const KlarityPlanSubscribePage = () => {
    const navigate = useNavigate();
    const [plan, setPlan] = React.useState<TSubscriptionItem>();
    const [planId, setPlanId] = React.useState<number>();
    const [loading, setLoading] = React.useState(false);
    const [loadBaiscData] = useLoadBasicData();

    useEffect(() => {
        loadBaiscData();
    }, [loadBaiscData]);
    // const onPlanSelect = useCallback((p:TSubscriptionItem, pid: number) => {
    //     if (pid === planId) {
    //         setPlan(undefined);
    //         setPlanId(undefined);
    //     } else {
    //         setPlan(p);
    //         setPlanId(pid);
    //     }
    // }, [planId]);
    // console.info(plan);

    useEffect(() => {
        // removed on 08/28
        // setTimeout(() => {
        //     openKlarityPlanAdvertisingModal(`/${PATH.KLARITY_AGREEMENT}`);
        // }, 100);
    }, []);

    const handleNext = useCallback(async () => {
        localStorage.setItem(UNLOCK_YOURY_KIWI_BENEFITS, 'true');
        navigate(`/${PATH.KLARITY_PLAN_PROCESS}`);
    }, [navigate]);
    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <img className={s.logo} src={Logo} alt="Kiwi Health logo" />
            </div>
            <KlaritySubscription />
            <div className={s.footer}>
                <Button type="primary" loading={loading} onClick={handleNext}>Next</Button>
            </div>
        </div>
    );
};

export default KlarityPlanSubscribePage;
