// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--PY0ve .s-module__row--qCRkZ {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 16px;
}
.s-module__wrap--PY0ve .s-module__row--qCRkZ .s-module__displayItem--DVBfy {
  margin-bottom: 12px;
}
.s-module__wrap--PY0ve .s-module__row--qCRkZ .s-module__displayItem--DVBfy .s-module__label--BZqhB {
  color: #6B7280;
  font-size: 14px;
  line-height: 20px;
}
.s-module__wrap--PY0ve .s-module__rowItem--RHa0y {
  margin-right: 36px;
}
.s-module__wrap--PY0ve .s-module__colItem--XkAJh {
  display: flex;
  flex-direction: column;
}
.s-module__note--OLvcd .s-module__noteItem--LStJB {
  margin-bottom: 16px;
}
.s-module__note--OLvcd .s-module__noteTitle--HksRU {
  color: var(--gray-900);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  /* 250% */
  margin-bottom: 8px;
}
.s-module__note--OLvcd .s-module__noteDesc--eJPjx {
  color: #6B7280;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
}
.s-module__note--OLvcd .s-module__noteDesc--eJPjx span {
  font-weight: 600;
}
.s-module__note--OLvcd .s-module__noteDesc--eJPjx ul {
  padding-left: 30px;
}
.s-module__note--OLvcd .s-module__noteDesc--eJPjx ul li {
  list-style: disc;
}
.s-module__note--OLvcd .s-module__noteDesc--eJPjx .s-module__noteOlItem--cvevK {
  list-style: auto;
  margin-bottom: 4px;
}
.s-module__note--OLvcd .s-module__noteOl--szK6J {
  padding-left: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/EhrProviderAddOnPage/components/SelfEFax/s.module.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AAAJ;AALA;EAcM,mBAAA;AANN;AARA;EASQ,cAAA;EACA,eAAA;EACA,iBAAA;AAER;AAbA;EAmBI,kBAAA;AAHJ;AAhBA;EAuBI,aAAA;EACA,sBAAA;AAJJ;AAQA;EAEI,mBAAA;AAPJ;AAKA;EAMI,sBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EARF,SAAS;EASP,kBAAA;AAPJ;AAJA;EAcI,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EAPF,aAAa;AACf;AAZA;EAoBM,gBAAA;AALN;AAfA;EAuBM,kBAAA;AALN;AAlBA;EA0BM,gBAAA;AALN;AArBA;EA6BM,gBAAA;EACA,kBAAA;AALN;AAzBA;EAkCI,kBAAA;AANJ","sourcesContent":[".wrap {\n  .row {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n    margin-top: 16px;\n\n    .displayItem {\n      .label {\n        color: #6B7280;\n        font-size: 14px;\n        line-height: 20px;\n      }\n\n      margin-bottom: 12px;\n    }\n  }\n\n  .rowItem {\n    margin-right: 36px;\n  }\n\n  .colItem {\n    display: flex;\n    flex-direction: column;\n  }\n}\n\n.note {\n  .noteItem {\n    margin-bottom: 16px;\n  }\n\n  .noteTitle {\n    color: var(--gray-900);\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 40px; /* 250% */\n    margin-bottom: 8px;\n  }\n  .noteDesc {\n    color: #6B7280;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 24px; /* 171.429% */\n    span {\n      font-weight: 600;\n    }\n    ul {\n      padding-left: 30px;\n    }\n    ul li {\n      list-style: disc;\n    }\n    .noteOlItem {\n      list-style: auto;\n      margin-bottom: 4px;\n    }\n  }\n  .noteOl {\n    padding-left: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--PY0ve`,
	"row": `s-module__row--qCRkZ`,
	"displayItem": `s-module__displayItem--DVBfy`,
	"label": `s-module__label--BZqhB`,
	"rowItem": `s-module__rowItem--RHa0y`,
	"colItem": `s-module__colItem--XkAJh`,
	"note": `s-module__note--OLvcd`,
	"noteItem": `s-module__noteItem--LStJB`,
	"noteTitle": `s-module__noteTitle--HksRU`,
	"noteDesc": `s-module__noteDesc--eJPjx`,
	"noteOlItem": `s-module__noteOlItem--cvevK`,
	"noteOl": `s-module__noteOl--szK6J`
};
export default ___CSS_LOADER_EXPORT___;
