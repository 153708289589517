import React, { useMemo } from 'react';
import s from './s.module.less';
import { Tabs } from 'antd';
import Table from './Table';
import isEmpty from 'lodash/isEmpty';
import { IEditBookingLink } from '../../type';

interface IProps {
    data?: Record<string, IEditBookingLink[]>;
    onEdit: (v: IEditBookingLink) => void;
    onRemove: (v: IEditBookingLink) => void;
    onCopyLink: (v: IEditBookingLink) => void;
}

const BookingLinkTable = ({
    data,
    onEdit,
    onRemove,
    onCopyLink,
}: IProps) => {
    const items = useMemo(() => {
        if (!isEmpty(data)) {
            return Object.entries(data).map(([k, v]: [string, IEditBookingLink[]]) => {
                const sortedData = v
                    .sort((a, b) => {
                        if (a.state < b.state) {
                            return -1;
                        }
                        if (a.state > b.state) {
                            return 1;
                        }
                        return 0;
                    });
                    // .map((e) => ({
                    //     ...e,
                    //     allCondition: true,
                    // }));

                return ({
                    key: k,
                    label: k,
                    children: (
                        <Table
                            title={k}
                            data={sortedData}
                            onEdit={onEdit}
                            onRemove={onRemove}
                            onCopyLink={onCopyLink}
                        />
                    ),
                });
            });
        }
    }, [data, onCopyLink, onEdit, onRemove]);
    return (
        <div className={s.wrap}>
            <Tabs items={items} />
        </div>
    );
};

export default BookingLinkTable;
