// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--hCey3 .s-module__title--yKSjT {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  /* 140% */
  letter-spacing: -0.5px;
  margin-bottom: 16px;
  margin-top: 24px;
}
.s-module__wrap--hCey3 .s-module__section1--lCyYx {
  padding: 24px;
  border-radius: 10px;
  border: 1px solid #EAECF0;
  padding-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.s-module__wrap--hCey3 .s-module__section1--lCyYx .s-module__left--YKHAi {
  width: 48%;
}
.s-module__wrap--hCey3 .s-module__section1--lCyYx .s-module__right--_EPUX {
  width: 48%;
}
.s-module__wrap--hCey3 .s-module__title--yKSjT {
  color: var(--gray-900, #111827);
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 100% */
  letter-spacing: -0.22px;
  margin-bottom: 24px;
}
.s-module__wrap--hCey3 .s-module__item1--qUni6 {
  display: flex;
  flex-direction: row;
}
.s-module__wrap--hCey3 .s-module__item1--qUni6 svg {
  flex-shrink: 0;
  margin-right: 12px;
  margin-top: 4px;
}
.s-module__wrap--hCey3 .s-module__item1--qUni6 .s-module__text--kwA3z {
  color: #6B7280;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 16px;
}
.s-module__wrap--hCey3 .s-module__item1--qUni6 .s-module__hight--z9Omg {
  color: var(--primary-color);
  font-weight: 700;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/ProspectsManagePage/AvaConfigHome/ProvenSuccess/s.module.less"],"names":[],"mappings":"AAAA;EAEI,WAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EAAF,SAAS;EAEP,sBAAA;EACA,mBAAA;EACA,gBAAA;AAAJ;AAVA;EAcI,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AADJ;AAnBA;EAuBM,UAAA;AADN;AAtBA;EA2BM,UAAA;AAFN;AAzBA;EAgCI,+BAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EAJF,SAAS;EAMP,uBAAA;EACA,mBAAA;AAJJ;AApCA;EA4CI,aAAA;EACA,mBAAA;AALJ;AAxCA;EAgDM,cAAA;EACA,kBAAA;EACA,eAAA;AALN;AA7CA;EAsDM,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AANN;AArDA;EA+DM,2BAAA;EACA,gBAAA;AAPN","sourcesContent":[".wrap {\n  .title {\n    color: #000;\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 34px;\n    /* 140% */\n    letter-spacing: -0.5px;\n    margin-bottom: 16px;\n    margin-top: 24px;\n  }\n\n  .section1 {\n    padding: 24px;\n    border-radius: 10px;\n    border: 1px solid #EAECF0;\n    padding-bottom: 0;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n\n    .left {\n      width: 48%;\n    }\n\n    .right {\n      width: 48%;\n    }\n  }\n\n  .title {\n    color: var(--gray-900, #111827);\n    font-family: Inter;\n    font-size: 22px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 22px;\n    /* 100% */\n    letter-spacing: -0.22px;\n    margin-bottom: 24px;\n  }\n\n  .item1 {\n    display: flex;\n    flex-direction: row;\n\n    svg {\n      flex-shrink: 0;\n      margin-right: 12px;\n      margin-top: 4px;\n    }\n\n    .text {\n      color: #6B7280;\n      font-size: 14px;\n      font-style: normal;\n      font-weight: 400;\n      line-height: 20px;\n      margin-bottom: 16px;\n    }\n\n    .hight {\n      color: var(--primary-color);\n      font-weight: 700;\n\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--hCey3`,
	"title": `s-module__title--yKSjT`,
	"section1": `s-module__section1--lCyYx`,
	"left": `s-module__left--YKHAi`,
	"right": `s-module__right--_EPUX`,
	"item1": `s-module__item1--qUni6`,
	"text": `s-module__text--kwA3z`,
	"hight": `s-module__hight--z9Omg`
};
export default ___CSS_LOADER_EXPORT___;
