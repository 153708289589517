import SolutionsImg1 from '../../images/SolutionsImg1.png';
import SolutionsImg2 from '../../images/SolutionsImg2.png';
import SolutionsImg3 from '../../images/SolutionsImg3.png';

export const TITLE = 'Maximize your practice with Kiwi Health';

export const CONFIG = [
    {
        title: 'Save up to $700 monthly on marketing and front desk costs',
        list: [
            'Get listed on 25+ directories, saving you hundreds in marketing costs.',
            'Get premium visibility on top-tier platforms like Psychology Today and Mental Health Match at no extra cost.',
            'Enjoy up to 8.5 hours of virtual front desk support each month, freeing you to focus on your patients.',
        ],
        imgUrl: SolutionsImg1,
    },
    {
        title: 'Get more patients with Kiwi Health Profile page and Kiwi’s own SEO technology',
        list: [
            'Create an engaging online presence with channel listings and our professional Kiwi Health profile page.',
            'Attract more patients by expanding your Kiwi Health Profile to multiple locations.',
            'Enhance search engine visibility with medical search engine optimization .',
        ],
        imgUrl: SolutionsImg2,
    },
    {
        title: 'Integrating with Klarity and Klarity EHR for seamless data management',
        list: [
            'Simplify your practice management with an all-in-one platform.',
            'Streamline data management and patient intake with our HIPAA-compliant virtual front desk, integrated with Klarity and Klarity EHR.',
            'Manage your Klarity practice, patient communication, and EHR in one convenient platform.',
        ],
        imgUrl: SolutionsImg3,
    },
];

export default CONFIG;
