import React from 'react';
import s from './s.modules.less';
import { TITLE, CONFIG, SUB_TITLE, SUB_DES } from './constants';
import Frame1 from './imgs/Frame1.png'
import Frame2 from './imgs/Frame2.png'

const HowItWork = () => {
    return (
        <div className={s.wrap}>
            <h2 className={s.title}>{TITLE}</h2>
            <div className={s.section1}>
                <div className={s.left}>
                    {
                        CONFIG.map((item, inx) => {
                            return (
                                <div className={s.item} key={item.title}>
                                    <div className={s.itemLeft}>
                                        {item.icon}
                                    </div>
                                    <div className={s.itemRight}>
                                        <div className={s.title}>
                                            {item.title}
                                        </div>
                                        <div className={s.des}>
                                            {item.description}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className={s.right}>
                    <img src={Frame1} alt={TITLE} />
                </div>
            </div>
            <div className={s.section2}>
                <div className={s.left}>
                    <img src={Frame2} alt={TITLE} />
                </div>
                <div className={s.right}>
                    <div className={s.title}>
                        {SUB_TITLE}
                    </div>
                    <div className={s.des}>
                        {SUB_DES}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowItWork;
