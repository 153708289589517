import React from 'react';
import { Form, Button, Input, Modal, message, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import FullScreenPopLayout from 'components/FullScreenPopLayout';
import s from './s.module.less';
import Benefits from './Benefits';
import ProvenSuccess from './ProvenSuccess';
import HowItWork from './HowItWork';
import Frame from './imgs/frame.png';
import AvaIcon from 'assets/common/ava.png';
import useProspectSettingInfo from 'hooks/useProspectSettingInfo';

type Props = {
    show: boolean,
    onClickBack: () => void,
    onClickSet: () => void,
};

enum ESection {
    BENEFITS = 'benefits',
    PROVEN_SUCCESS = 'provenSuccess',
    HOW_IT_WORKS = 'howItWorks'
}

const AvaConfigHome = (props: Props) => {
    const { show, onClickBack, onClickSet } = props;
    const [activeId, setActiveId] = React.useState(ESection.BENEFITS);
    const [isScrolling, setIsScrolling] = React.useState(false);
    const guideItems: TabsProps['items'] = [
        {
            key: ESection.BENEFITS,
            label: <div>Benefits</div>,
            children: <div />,
        },
        {
            key: ESection.PROVEN_SUCCESS,
            label: <div>Proven success</div>,
            children: <div />,
        },
        {
            key: ESection.HOW_IT_WORKS,
            label: <div>How it works</div>,
            children: <div />,
        },
    ];

    const handleChange = (activeKey: string) => {
        setIsScrolling(true);
        setActiveId(activeKey as ESection);
        const element = document.getElementById(activeKey as string);
        const wrapElement = document.getElementById('contentWrap');
        wrapElement?.scrollTo({
            top: (element as HTMLBaseElement).offsetTop - 150,
            behavior: 'smooth',
        });
        setIsScrolling(false);
    };

    React.useEffect(() => {

        const wrap = document.getElementById('contentWrap');
        const first = document.getElementById(ESection.BENEFITS);
        const second = document.getElementById(ESection.PROVEN_SUCCESS);
        const third = document.getElementById(ESection.HOW_IT_WORKS);
        const handle = () => {
            const top = wrap?.scrollTop;
            const slug = 160;
            if (third && top && (top + slug) > third?.offsetTop) {
                setActiveId(ESection.HOW_IT_WORKS)
            } else if (second && top && (top + slug) > second?.offsetTop) {
                setActiveId(ESection.PROVEN_SUCCESS)
            } else {
                setActiveId(ESection.BENEFITS)
            }
        }
        if (show) {
            wrap?.addEventListener("scroll", handle);
        }
        return () => {
            wrap?.removeEventListener('scroll', handle);
        }
    }, [show])

    if (!show) {
        return null
    }


    return (
        <FullScreenPopLayout
            addHeader
            footerButton={
                <div className={s.footer}>
                    <Button onClick={onClickBack}>Back</Button>
                    <Button onClick={onClickSet} type='primary'>Set up Ava in Settings</Button>
                </div>
            }
            wrapClass={s.wrapFull}
            contentClass={s.contentFull}
            contentId="contentWrap"
        >
            <div
                className={s.wrap}>
                <div className={s.header}>
                    <div className={s.left}>
                        <div className={s.title}>
                            Introducing AI-powered prospect auto-reply: transforming your new patient conversion with  <img className={s.ava} src={AvaIcon} />
                        </div>
                        <div className={s.des}>
                            Are you ready to revolutionize your new patient conversion? Meet <img className={s.ava} src={AvaIcon} />, your AI-powered assistant designed to respond to new patient inquiries instantly.
                        </div>
                    </div>
                    <div className={s.right}>
                        <img src={Frame} />
                    </div>
                </div>
                <div className={s.tabs}>
                    <Tabs
                        activeKey={activeId}
                        items={guideItems}
                        onChange={(activeKey) => handleChange(activeKey)}
                    />
                </div>
                <div className={s.content} id={ESection.BENEFITS}>
                    <Benefits />
                </div>
                <div className={s.content} id={ESection.PROVEN_SUCCESS}>
                    <ProvenSuccess />
                </div>
                <div className={s.content} id={ESection.HOW_IT_WORKS}>
                    <HowItWork />
                </div>
            </div>
        </FullScreenPopLayout>
    );
};

export default AvaConfigHome;
