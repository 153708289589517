// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__cardItem--P_rOK {
  width: 100%;
  border-radius: 16px;
  background: #FFF;
  box-sizing: border-box;
  height: 318px;
  overflow: hidden;
  padding-bottom: 80px;
}
.s-module__cardItem--P_rOK .ant-descriptions-header {
  padding: 16px 24px;
}
.s-module__cardItem--P_rOK .ant-descriptions-view {
  padding: 0 24px;
}
.s-module__descTitle--aK8_M {
  width: 100%;
  display: flex;
  flex-direction: row;
  color: var(--gray-900);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}
.s-module__descContent--UH_R6 {
  text-align: left;
  color: var(--gray-500);
  font-weight: 500;
  margin-bottom: 16px;
}
.s-module__helpTipsTitle--VGIAH {
  color: var(--gray-500);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}
.s-module__descEmail--EZmOQ {
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  margin-top: 10px;
}
.s-module__scrollContent--D5fKM {
  height: calc(100% - 60px);
  overflow: auto;
  display: flex;
  flex-direction: row;
}
.s-module__scrollContent--D5fKM .s-module__listItem--qxagd {
  width: 100%;
}
.s-module__cardContent--T_uwv .ant-descriptions-view {
  height: 190px;
  overflow: auto;
}
.s-module__helpEmail--Y8Uv8 {
  padding: 6px 16px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/EhrProviderHomePage/components/PayCard/s.module.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,mBAAA;EACA,gBAAA;EACA,sBAAA;EACA,aAAA;EACA,gBAAA;EACA,oBAAA;AACF;AARA;EAUM,kBAAA;AACN;AAXA;EAaM,eAAA;AACN;AAIA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EAFA,aAAa;AACf;AAIA;EACE,gBAAA;EACA,sBAAA;EACA,gBAAA;EACA,mBAAA;AAFF;AAKA;EACE,sBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EAHA,aAAa;AACf;AAKA;EACE,0BAAA;EACA,eAAA;EACA,gBAAA;AAHF;AAMA;EACE,yBAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;AAJF;AAAA;EAMI,WAAA;AAHJ;AAOA;EAGM,aAAA;EACA,cAAA;AAPN;AAYA;EACE,iBAAA;AAVF","sourcesContent":[".cardItem {\n  width: 100%;\n  border-radius: 16px;\n  background: #FFF;\n  box-sizing: border-box;\n  height: 318px;\n  overflow: hidden;\n  padding-bottom: 80px;\n  :global {\n    .ant-descriptions-header {\n      padding: 16px 24px;\n    }\n    .ant-descriptions-view {\n      padding: 0 24px;\n    }\n  }\n}\n\n.descTitle {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  color: var(--gray-900);\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 20px; /* 142.857% */\n}\n\n.descContent {\n  text-align: left;\n  color: var(--gray-500);\n  font-weight: 500;\n  margin-bottom: 16px;\n}\n\n.helpTipsTitle {\n  color: var(--gray-500);\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 20px; /* 142.857% */\n}\n\n.descEmail {\n  color: rgba(0, 0, 0, 0.88);\n  font-size: 14px;\n  margin-top: 10px;\n}\n\n.scrollContent {\n  height: calc(100% - 60px);\n  overflow: auto;\n  display: flex;\n  flex-direction: row;\n  .listItem {\n    width: 100%;\n  }\n}\n\n.cardContent {\n  :global {\n    .ant-descriptions-view {\n      height: 190px;\n      overflow: auto;\n    }\n  }\n}\n\n.helpEmail {\n  padding: 6px 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardItem": `s-module__cardItem--P_rOK`,
	"descTitle": `s-module__descTitle--aK8_M`,
	"descContent": `s-module__descContent--UH_R6`,
	"helpTipsTitle": `s-module__helpTipsTitle--VGIAH`,
	"descEmail": `s-module__descEmail--EZmOQ`,
	"scrollContent": `s-module__scrollContent--D5fKM`,
	"listItem": `s-module__listItem--qxagd`,
	"cardContent": `s-module__cardContent--T_uwv`,
	"helpEmail": `s-module__helpEmail--Y8Uv8`
};
export default ___CSS_LOADER_EXPORT___;
