export const TITLE = 'Explore the benefits of Kiwi Health’s EHR';

export const CONFIG = [
    {
        title: 'All-in-one solution with advanced features',
        description: 'Advanced charting, streamlined forms, efficient scheduling, and instant chat.',
    },
    {
        title: 'Telehealth: virtual-first care experience',
        description: 'Conduct video calls with chart access and HIPAA-compliant Zoom on any device, no extra fees. Your office goes wherever you do.',
    },
    {
        title: 'Building long-term client relationships',
        description: 'Web and mobile accessible, streamlining patient interactions with messaging, content, and reports.',
    },
    {
        title: 'Fully integrated with the Klarity and Kiwi ecosystem',
        description: 'Connect with the entire Kiwi ecosystem, including various marketing channels, Practice Front, and reputation management to boost practice growth.',
    },
    {
        title: 'Personalized service packages',
        description: 'Customize services from single visits to bundles, tailored to your practice\'s unique brand.',
    },
    {
        title: 'Flexible payment options',
        description: 'Supports various payment methods like cash, insurance, Superbill, and HSA/FSA cards to meet all clients needs.',
    },

];
