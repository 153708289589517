import { IHelloKlarityBookingLink } from 'types/operation';

export enum EBookingListAction {
    ADD = 'add',
    DELETE = 'delete',
    EDIT = 'edit',
}

export interface IEditBookingLink extends IHelloKlarityBookingLink {
    isEditing?: boolean;
    errorMessage?: string;
    status?: EBookingListAction;
    isManuallyLink?: boolean;
    uuid?: string;
    conditions?: string[];
    allCondition: boolean;
}

export type TOfferingItem = {
    archived: boolean;
    billing_frequency: string;
    can_be_gifted: boolean;
    charge_immediately: boolean;
    created_at: string;
    currency: string;
    description: string;
    id: string;
    immediate_purchase_url: string;
    initial_payment_amount: string;
    initial_price_with_taxes: string;
    name: string;
    offering_includes: {
        appointment_type: {
            available_contact_types: string[];
            clients_can_book: boolean;
            id: string;
            length: number;
            name: string;
        };
        appointment_type_id: string;
        required_appointment_type: boolean;
    }[];
    price: string;
    visibility_status: string;
};

export interface IFormData {
    bookingList: IEditBookingLink[]
}
