import React from 'react';
import dayjs from 'dayjs';
import s from './s.module.less';
import type { ColumnsType } from 'antd/es/table';
import { Button, Checkbox, Input, Space, Spin } from 'antd';
import CommonTable from 'components/CommonTable';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import useUpcommingChannels from 'hooks/useUpcommingChannels';
import useLiveChannels from 'hooks/useLiveChannels';
import { SearchOutlined } from '@ant-design/icons';
import useInterestChannels from 'hooks/useInterestChannels';
import { getTimeStr } from 'utils/calendar';
import DownloadIcon from 'assets/common/download.svg';
import { ChannelItemBasic, EChannelActiveStatus } from 'types/common';
import { exportExcel } from 'utils/excel';
import { EnumFields, SettingEnum } from 'types/enumerationData';
import useGetSettingData from 'hooks/useGetSettingData';
import { ChannelPayPlanTypeEnum } from 'types/channel';
import { NOT_SET } from 'constants/common';

const allValue = 'All';

const Page = () => {
    const navigate = useNavigate();
    const [upcomingChannels, upcomingChannelsLoading] = useUpcommingChannels();
    const [liveChannels, liveChannelsLoading] = useLiveChannels(true);
    const [interestChannels, interestChannelsLoading] = useInterestChannels();

    const [liveSortKey, setLiveSortKey] = React.useState<'id' | 'updateTime' | 'listedCount' | 'unProcessedCount' | 'activeStatus' | 'isShow' | 'payPlanType'>('id');
    const [liveSortType, setLiveSortType] = React.useState(true);

    const [upSortKey, setUpSortKey] = React.useState<'id' | 'updateTime' | 'waitingCount'>('id');
    const [upSortType, setUpSortType] = React.useState(true);

    const [channelPayTypeData] = useGetSettingData(EnumFields.CHANNEL_PAY_PLAN_TYPE);
    const channelPayTypeKeyContentMap:Partial<Record<ChannelPayPlanTypeEnum, string>> = {};
    channelPayTypeData?.forEach((item:SettingEnum) => {
        channelPayTypeKeyContentMap[item.dictKey as ChannelPayPlanTypeEnum] = item.content;
    });

    const feature = [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
    ];

    const [livePage, setLivePage] = React.useState(1);
    const [liveSize, setLiveSize] = React.useState(20);
    const [liveNameFilter, setLiveNameFilter] = React.useState('');
    const [liveFeaturedList, setLiveFeaturedList] = React.useState<Array<CheckboxValueType>>([]);

    const [upPage, setUpPage] = React.useState(1);
    const [upSize, setUpSize] = React.useState(20);
    const [upNameFilter, setUpNameFilter] = React.useState('');
    const [upFeaturedList, setUpFeaturedList] = React.useState<Array<CheckboxValueType>>([]);

    let liveList:ChannelItemBasic[] = liveChannels?.sort((a:ChannelItemBasic, b:ChannelItemBasic) => {
        let ret = b.id - a.id;
        if (liveSortKey === 'id') {
            ret = liveSortType ? b.id - a.id : a.id - b.id;
        }
        if (liveSortKey === 'listedCount') {
            ret = liveSortType ? b.listedCount - a.listedCount : a.listedCount - b.listedCount;
        }
        if (liveSortKey === 'unProcessedCount') {
            ret = liveSortType ? b.unProcessedCount - a.unProcessedCount : a.unProcessedCount - b.unProcessedCount;
        }
        if (liveSortKey === 'updateTime') {
            ret = liveSortType ? new Date(b.updateTime).getTime() - new Date(a.updateTime).getTime() : new Date(a.updateTime).getTime() - new Date(b.updateTime).getTime();
        }
        if (liveSortKey === 'activeStatus') {
            const valueA = a.activeStatus || '';
            const valueB = b.activeStatus || '';
            ret = liveSortType ? valueB.localeCompare(valueA) : valueA.localeCompare(valueB);
        }

        if (liveSortKey === 'isShow') {
            const valueA = a.isShow || '';
            const valueB = b.isShow || '';
            ret = liveSortType ? valueB.toString().localeCompare(valueA.toString()) : valueA.toString().localeCompare(valueB.toString());
        }

        if (liveSortKey === 'payPlanType') {
            const valueA = a.payPlanType || '';
            const valueB = b.payPlanType || '';
            ret = liveSortType ? valueB.localeCompare(valueA) : valueA.localeCompare(valueB);
        }
        return ret;
    }) || [];

    let upcomingList:ChannelItemBasic[] = upcomingChannels?.sort((a:ChannelItemBasic, b:ChannelItemBasic) => {
        let ret = b.id - a.id;
        if (upSortKey === 'id') {
            ret = upSortType ? b.id - a.id : a.id - b.id;
        }
        if (upSortKey === 'waitingCount') {
            ret = upSortType ? b.waitingCount - a.waitingCount : a.waitingCount - b.waitingCount;
        }
        if (upSortKey === 'updateTime') {
            ret = upSortType ? new Date(b.updateTime).getTime() - new Date(a.updateTime).getTime() : new Date(a.updateTime).getTime() - new Date(b.updateTime).getTime();
        }
        if (liveSortKey === 'activeStatus') {
            const valueA = a.activeStatus || '';
            const valueB = b.activeStatus || '';
            ret = liveSortType ? valueB.localeCompare(valueA) : valueA.localeCompare(valueB);
        }

        if (liveSortKey === 'isShow') {
            const valueA = a.isShow || '';
            const valueB = b.isShow || '';
            ret = liveSortType ? valueB.toString().localeCompare(valueA.toString()) : valueA.toString().localeCompare(valueB.toString());
        }

        if (liveSortKey === 'payPlanType') {
            const valueA = a.payPlanType || '';
            const valueB = b.payPlanType || '';
            ret = liveSortType ? valueB.localeCompare(valueA) : valueA.localeCompare(valueB);
        }
        return ret;
    }) || [];

    if (liveNameFilter || liveFeaturedList.length === 1) {
        liveList = liveList.filter((item) => {
            const nameFit = !liveNameFilter || item.name?.toLowerCase().includes(liveNameFilter.toLowerCase());
            let featuredFit = true;
            if (liveFeaturedList.length === 1) {
                const liveFeaturedListFilter = liveFeaturedList[0];
                featuredFit = liveFeaturedListFilter === 'yes' ? item.top : !item.top;
            }

            return nameFit && featuredFit;
        });
    }

    if (upNameFilter || upFeaturedList.length === 1) {
        upcomingList = upcomingList.filter((item) => {
            const nameFit = !upNameFilter || item.name?.toLowerCase().includes(upNameFilter.toLowerCase());
            let featuredFit = true;
            if (upFeaturedList.length === 1) {
                const upFeaturedListFilter = upFeaturedList[0];
                featuredFit = upFeaturedListFilter === 'yes' ? item.top : !item.top;
            }

            return nameFit && featuredFit;
        });
    }

    const handleLiveFeaturedChange = (featuredList: CheckboxValueType[]) => {
        setLiveFeaturedList(featuredList);
    };

    const handleLiveTableChange = (paginationData?:any, filtersData?:any, sorter?:any, action?:any) => {
        if (action?.action === 'paginate') {
            setLivePage(paginationData.current!);
            setLiveSize(paginationData.pageSize!);
            return;
        }

        if (action?.action === 'sort') {
            const orderType = sorter.order !== 'ascend';
            setLiveSortKey(sorter.column?.dataIndex);
            setLiveSortType(orderType);
        }
    };

    const handleUpFeaturedChange = (featuredList: CheckboxValueType[]) => {
        setUpFeaturedList(featuredList);
    };

    const handleUpTableChange = (paginationData?:any, filtersData?:any, sorter?:any, action?:any) => {
        if (action?.action === 'paginate') {
            setUpPage(paginationData.current!);
            setUpSize(paginationData.pageSize!);
            return;
        }

        if (action?.action === 'sort') {
            const orderType = sorter.order !== 'ascend';
            setUpSortKey(sorter.column?.dataIndex);
            setUpSortType(orderType);
        }
    };

    React.useEffect(() => {
        setLivePage(1);
    }, [liveNameFilter, liveFeaturedList]);

    React.useEffect(() => {
        setUpPage(1);
    }, [upNameFilter, upFeaturedList]);

    const liveColumns: ColumnsType<ChannelItemBasic> = [
        {
            title: 'NAME',
            dataIndex: 'name',
            filterSearch: true,
            width: '360px',
            filterIcon: <SearchOutlined />,
            fixed: 'left',
            filterDropdown: () => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        value={liveNameFilter}
                        onChange={(e) => {
                            const filterName = e.target.value;
                            setLiveNameFilter(filterName);
                        }}
                        allowClear
                    />
                </div>
            ),
            render: (name:string, item:ChannelItemBasic) => {
                return (
                    <div className={s.nameWrap}>
                        <div className={s.nameLeft}>
                            <img src={item.logo} />
                        </div>
                        <div className={s.nameRight}>
                            <div className={s.name}>
                                <span>{item.name}</span>
                            </div>
                            <div className={s.webside}>{item.website}</div>
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'STATUS',
            width: '120px',
            dataIndex: 'activeStatus',
            sortDirections: ['ascend', 'descend'],
            sorter: true,
            render: (activeStatus) => {
                return (
                    <div>{activeStatus === EChannelActiveStatus.ACTIVE ? 'Active' : 'Inactive'}</div>
                );
            },
        },
        {
            title: 'VISIBILITY',
            width: '120px',
            dataIndex: 'isShow',
            sortDirections: ['ascend', 'descend'],
            sorter: true,
            render: (isShow) => {
                return (
                    <div>{isShow ? 'Show' : 'Hide'}</div>
                );
            },
        },
        {
            title: 'TYPE',
            width: '120px',
            dataIndex: 'payPlanType',
            sortDirections: ['ascend', 'descend'],
            sorter: true,
            render: (payPlanType, item) => {
                const payTypeContent = item.payPlanType ? channelPayTypeKeyContentMap[item.payPlanType!] : NOT_SET;
                let typeClass = s.paid;
                if (item.payPlanType === ChannelPayPlanTypeEnum.FREE) {
                    typeClass = s.free;
                } else if (item.payPlanType === ChannelPayPlanTypeEnum.PAID) {
                    typeClass = s.paid;
                } else if (item.payPlanType === ChannelPayPlanTypeEnum.PREMIUM) {
                    typeClass = s.premium;
                }
                return (
                    <span className={`${s.tag} ${typeClass}`}>{payTypeContent}</span>
                );
            },
        },
        {
            title: 'DATE MODIFIED',
            width: '180px',
            dataIndex: 'updateTime',
            sortDirections: ['ascend', 'descend'],
            sorter: true,
            render: (updateTime) => {
                return (
                    <div>{updateTime ? getTimeStr(dayjs(updateTime), 'YYYY/MM/DD') : 'Nil'}</div>
                );
            },
        },
        {
            title: 'LISTED PROVIDERS',
            dataIndex: 'listedCount',
            align: 'center',
            width: '180px',
            sortDirections: ['ascend', 'descend'],
            sorter: true,
            render: (listedCount) => {
                return (
                    <div>{ listedCount || '0'}</div>
                );
            },
        },
        {
            title: 'UNPROCESSED APPLICATION',
            dataIndex: 'unProcessedCount',
            width: '250px',
            align: 'center',
            sortDirections: ['ascend', 'descend'],
            sorter: true,
            render: (unProcessedCount) => {
                return (
                    <div>{ unProcessedCount || '0'}</div>
                );
            },
        },
        {
            title: 'FEATURED',
            dataIndex: 'top',
            width: '80px',
            align: 'center',
            filterDropdown: () => {
                return (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Space>
                            <Checkbox.Group value={liveFeaturedList} options={feature} onChange={handleLiveFeaturedChange} />
                        </Space>
                    </div>
                );
            },
            render: (top) => {
                return (
                    <div>{top ? 'Yes' : 'No'}</div>
                );
            },
        },
        {
            title: '',
            width: '80px',
            fixed: 'right',
            render: (item) => {
                return (
                    <div>
                        <Button
                            className={s.link}
                            onClick={() => {
                                navigate(`${PATH.OPERATION}/${PATH.CHANNEL_UPDATE}/${item.id}`);
                            }}
                            type="text"
                        >View
                        </Button>
                    </div>
                );
            },
        },
    ];

    const upcomingColumns: ColumnsType<ChannelItemBasic> = [
        {
            title: 'NAME',
            dataIndex: 'name',
            filterSearch: true,
            width: '360px',
            filterIcon: <SearchOutlined />,
            fixed: 'left',
            filterDropdown: () => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        value={upNameFilter}
                        onChange={(e) => {
                            const filterName = e.target.value;
                            setUpNameFilter(filterName);
                        }}
                        allowClear
                    />
                </div>
            ),
            render: (name:string, item:ChannelItemBasic) => {
                return (
                    <div className={s.nameWrap}>
                        <div className={s.nameLeft}>
                            <img src={item.logo} />
                        </div>
                        <div className={s.nameRight}>
                            <div className={s.name}>
                                <span>{item.name}</span>
                            </div>
                            <div className={s.webside}>{item.website}</div>
                        </div>
                    </div>
                );
            },
        },
        // {
        //     title: 'STATUS',
        //     width: '120px',
        //     dataIndex: 'activeStatus',
        //     sortDirections: ['ascend', 'descend'],
        //     sorter: true,
        //     render: (activeStatus) => {
        //         return (
        //             <div>{activeStatus === EChannelActiveStatus.ACTIVE ? 'Active' : 'Inactive'}</div>
        //         );
        //     },
        // },
        // {
        //     title: 'VISIBILITY',
        //     width: '120px',
        //     dataIndex: 'isShow',
        //     sortDirections: ['ascend', 'descend'],
        //     sorter: true,
        //     render: (isShow) => {
        //         return (
        //             <div>{isShow ? 'Show' : 'Hide'}</div>
        //         );
        //     },
        // },
        {
            title: 'TYPE',
            width: '120px',
            dataIndex: 'payPlanType',
            sortDirections: ['ascend', 'descend'],
            sorter: true,
            render: (payPlanType, item) => {
                const payTypeContent = item.payPlanType ? channelPayTypeKeyContentMap[item.payPlanType!] : NOT_SET;
                let typeClass = s.paid;
                if (item.payPlanType === ChannelPayPlanTypeEnum.FREE) {
                    typeClass = s.free;
                } else if (item.payPlanType === ChannelPayPlanTypeEnum.PAID) {
                    typeClass = s.paid;
                } else if (item.payPlanType === ChannelPayPlanTypeEnum.PREMIUM) {
                    typeClass = s.premium;
                }
                return (
                    <span className={`${s.tag} ${typeClass}`}>{payTypeContent}</span>
                );
            },
        },
        {
            title: 'DATE MODIFIED',
            width: '180px',
            dataIndex: 'updateTime',
            sortDirections: ['ascend', 'descend'],
            sorter: true,
            render: (updateTime) => {
                return (
                    <div>{updateTime ? getTimeStr(dayjs(updateTime), 'YYYY/MM/DD') : 'Nil'}</div>
                );
            },
        },
        {
            title: 'ON WAITING LIST',
            dataIndex: 'waitingCount',
            sortDirections: ['ascend', 'descend'],
            sorter: true,
            width: '180px',
            align: 'center',
            render: (waitingCount) => {
                return (
                    <div>{ waitingCount || '0'}</div>
                );
            },
        },
        {
            title: 'CHANNEL INFORMATION',
            dataIndex: 'status',
            width: '250px',
            align: 'center',
            render: (status) => {
                return (
                    <div>{ status }</div>
                );
            },
        },
        {
            title: '',
            width: '80px',
            fixed: 'right',
            render: (item) => {
                return (
                    <div>
                        <Button
                            className={s.link}
                            onClick={() => {
                                navigate(`${PATH.OPERATION}/${PATH.CHANNEL_UPDATE}/${item.id}`);
                            }}
                            type="text"
                        >View
                        </Button>
                    </div>
                );
            },
        },
    ];
    return (
        <div className={s.wrap}>
            <div className={s.channelTable}>
                <h2>Explore Channels</h2>
                <div className={s.tableWrap}>
                    <CommonTable
                        bordered
                        rowKey="id"
                        loading={liveChannelsLoading}
                        columns={liveColumns}
                        data={liveList || []}
                        pagination={false}
                        onChange={handleLiveTableChange}
                        scroll={{ x: '1200' }}
                    />
                </div>
            </div>
            <div className={s.channelTable}>
                <div className={s.channelHeader}>
                    <h2>Upcoming channels</h2>
                    <Button
                        onClick={() => {
                            navigate(`${PATH.OPERATION}/${PATH.CHANNEL_UPDATE}`);
                        }}
                    >
                        Add a channel
                    </Button>
                </div>
                <div className={s.tableWrap}>
                    <CommonTable
                        bordered
                        rowKey="id"
                        loading={upcomingChannelsLoading}
                        columns={upcomingColumns}
                        data={upcomingList || []}
                        pagination={false}
                        onChange={handleUpTableChange}
                        scroll={{ x: '1200' }}
                    />
                </div>
            </div>
            <Spin spinning={interestChannelsLoading}>
                <div className={s.channelTable}>
                    <div className={s.channelHeader}>
                        <h2>Channels of interest to providers</h2>
                    </div>
                    <div
                        onClick={() => {
                            const data:any[] = interestChannels.map((channel:ChannelItemBasic) => {
                                return {
                                    Name: channel.name,
                                    Link: channel.website,
                                };
                            });
                            exportExcel({
                                data,
                                excelName: 'Channels of interest',
                            });
                        }}
                        className={s.csvDownloadWrap}
                    >
                        <span className={s.downloadIcon}>
                            Channels of interest to provider.csv
                            <img src={DownloadIcon} />
                        </span>
                    </div>
                </div>
            </Spin>
        </div>
    );
};

export default Page;
