import React, { useMemo } from 'react';
import s from './s.module.less';
import { Checkbox, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import ApplicationTable from './components/ApplicationTable';
import ChannelManagement from './components/ChannelManagement';
import ChannelWithdrawals from './components/ChannelWithdrawals';
import { insertUrlParam, getUrlParam } from 'utils/common';
import AccessStore from 'store/Access/permission';
import AccessDeniedTabView from 'components/AccessDeniedTabView';
import { PermissionModuleKeys } from 'constants/access';

enum EType {
    APPLICATION = 'application',
    OFFBOARDING = 'offboarding',
    MANAGEMENT = 'management',
}

const Page = () => {
    const [getAccessStore] = AccessStore.useStore();
    const access = getAccessStore('data');

    const channelListingComponent = useMemo(() => {
        if (!access) {
            return null;
        }

        return access?.[PermissionModuleKeys.CHANNEL_LISTING] ? <ApplicationTable /> : <AccessDeniedTabView />;
    }, [access]);

    const channelWithdrawalsComponent = useMemo(() => {
        if (!access) {
            return null;
        }

        return access?.[PermissionModuleKeys.CHANNEL_LISTING] ? <ChannelWithdrawals /> : <AccessDeniedTabView />;
    }, [access]);

    const channelStoreManagmentComponent = useMemo(() => {
        if (!access) {
            return null;
        }
        return access?.[PermissionModuleKeys.CHANNEL_STORE_MANAGEMENT] ? <ChannelManagement /> : <AccessDeniedTabView />;
    }, [access]);

    const items = useMemo<TabsProps['items']>(() => [
        {
            key: EType.APPLICATION,
            label: <p className={s.tabTitle}>Channel listing</p>,
            children: channelListingComponent,
            forceRender: true,
        },
        {
            key: EType.OFFBOARDING,
            label: <p className={s.tabTitle}>Channel offboarding</p>,
            children: channelWithdrawalsComponent,
            forceRender: true,
        },
        {
            key: EType.MANAGEMENT,
            label: <p className={s.tabTitle}>Channel store management</p>,
            children: channelStoreManagmentComponent,
            forceRender: true,
        },
    ], [channelListingComponent, channelStoreManagmentComponent, channelWithdrawalsComponent]);
    const [type, setType] = React.useState(getUrlParam('type') || EType.APPLICATION);
    const navigate = useNavigate();
    const desText = type === EType.APPLICATION ? '' : 'Here is the page to manage providers’ channel integration in Kiwi Health';

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <h1>Channels</h1>
                {/* <div className={s.des}>
                    {desText}
                </div> */}
            </div>
            <div className={s.tableWrap}>
                <Tabs
                    activeKey={type}
                    defaultActiveKey={type}
                    items={items}
                    onChange={(changeType) => {
                        setType(changeType as EType);
                        insertUrlParam('type', changeType);
                    }}
                />
            </div>

        </div>
    );
};

export default Page;
