import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import ChannelStatus from './components/Status';
import s from './s.module.less';
import useGetCorrectChannelStatus from 'hooks/useGetCorrectChannelStatus';
import { Channel } from 'types/common';

interface IProps {
    item: Channel;
}

const ChannelCard = ({
    item,
}: IProps) => {
    const {
        id,
        name,
        logo,
        tags,
        // website,
        intro,
        listStatus,
        // avgMonthGainPatient,
        // estimatedApplicationTime,
    } = item;
    const navigate = useNavigate();
    const statusText = useGetCorrectChannelStatus(listStatus);

    const handleDetailPage = useCallback(() => {
        navigate(`/dashboard/channel-detail?channelId=${id}`);
    }, [id, navigate]);

    const tagsArray = typeof tags === 'string' ? JSON.parse(tags) : tags;
    return (
        <div className={s.channel} onClick={handleDetailPage}>
            <div className={s.channelInfo}>
                <div className={s.channelCardHeader}>
                    <div className={s.channelTitle}>
                        <div className={s.logo} style={{ backgroundImage: `url(${logo})` }} />
                        <div className={s.name}>{name}</div>
                    </div>
                </div>
                <div className={s.tagList}>
                    {
                        tagsArray?.map((tag: string) => <div key={tag} className={s.tag}>{tag}</div>)
                    }
                </div>
                <div className={s.intro}>{intro}</div>
                {/* <div className={s.listInfo}>🔥 97% providers are listed on Yelp</div> */}
            </div>
        </div>
    );
};

export default ChannelCard;
