import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import s from './s.module.less';
import { PATH } from 'constants/path';
import { getPFUrl } from 'utils/localstore';
import { getUrlParam } from 'utils/common';
import { PRACTICE_FRONT_FROM } from 'constants/common';

const PracticefrontCreateSuccessPage = () => {
    const navigate = useNavigate();
    const [website, setWebsite] = useState('');
    const from = getUrlParam('from');

    const handleBack = useCallback(() => {
        if (from === PRACTICE_FRONT_FROM) {
            navigate(`${PATH.DASHBOARD}/${PATH.PRACTICE_FRONT}`, { replace: true });
        } else {
            navigate(`${PATH.DASHBOARD}/${PATH.CHANNEL}`, { replace: true });
        }
    }, [from, navigate]);

    useEffect(() => {
        setWebsite(getPFUrl());
    }, []);

    return (
        <div className={s.wrap}>
            <div className={s.box}>
                <div className={s.successImage} />
                <p className={s.desc}>Your Practice Front has launched successfully.</p>
                <p className={s.exp}>You could make updates to the information on your Practice Front whenever necessary. To see your live website, please click the link below: </p>
                { website && <a className={s.link} href={website} target="_blank" rel="noreferrer">{website}</a> }
                <Button type="primary" onClick={handleBack}>{`Back to ${from === PRACTICE_FRONT_FROM ? 'Practice Front' : 'Channels'} page`}</Button>
            </div>
        </div>
    );
};

export default PracticefrontCreateSuccessPage;
