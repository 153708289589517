import React from 'react';

const EhrOn = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.33341 3.33333H4.00008C3.2637 3.33333 2.66675 3.93029 2.66675 4.66667V12.6667C2.66675 13.403 3.2637 14 4.00008 14H10.6667C11.4031 14 12.0001 13.403 12.0001 12.6667V12M5.33341 3.33333C5.33341 4.06971 5.93037 4.66667 6.66675 4.66667H8.00008C8.73646 4.66667 9.33341 4.06971 9.33341 3.33333M5.33341 3.33333C5.33341 2.59695 5.93037 2 6.66675 2H8.00008C8.73646 2 9.33341 2.59695 9.33341 3.33333M9.33341 3.33333H10.6667C11.4031 3.33333 12.0001 3.93029 12.0001 4.66667V6.66667M13.3334 9.33333H6.66675M6.66675 9.33333L8.66675 7.33333M6.66675 9.33333L8.66675 11.3333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

);

export default EhrOn;
