import React from 'react';
import { Form, Input, Modal, Checkbox, message, Button } from 'antd';
import s from './s.module.less';
import dayjs from 'utils/dayjs';
import commonS from 'styles/common.module.less';
import { FormReply } from './types';
import { getTimeStr } from 'utils/calendar';
import DataUpdatedFlagStore from 'store/DataUpdatedFlag';
import { TReviewItem } from '../../types';
import StarReview from 'components/StarReview';
import ProviderStore from 'store/Provider';
import TextContentInput from 'components/form/TextContentInput';
import { getProviderNameDisplay } from 'utils/provider';
import { addKlarityReviewReply } from 'api/survey';
import { TKlarityReviewReplyInput } from 'types/survey';

type Props = {
    show: boolean,
    item?: TReviewItem,
    onCannel?: () => void,
    onSuccess?: () => void
};

const ReplyReviewModal = (props: Props) => {
    const { show, item, onCannel, onSuccess } = props;
    const [submiting, setSubmiting] = React.useState(false);
    const [getUser] = ProviderStore.useStore();
    const user = getUser('data');
    const [form] = Form.useForm<FormReply>();
    const [showOn, setShowOn] = React.useState(true)
    const [, setDataUpdatedFlag] = DataUpdatedFlagStore.useStore();
    const [showPhiLeakageModal, setShowPhiLeakageModal] = React.useState(false);
    const [showInappropriateContentModal, setInappropriateContentModal] = React.useState(false);

    const verified = item?.source !== 'PROVIDER_IMPORT';

    React.useEffect(() => {
        if (!show) {
            form.resetFields();
            setShowOn(true);
        }
    }, [show]);

    const onSubmit = async (isConfirmPhiLeakage?: boolean, forcePrivate?: boolean) => {
        setSubmiting(true);
        try {
            const formValues = await form.validateFields();

            const data: TKlarityReviewReplyInput = {
                ...formValues,
                reviewId: item?.id!,
                isPublic: showOn,
                isConfirmPhiLeakage: !!isConfirmPhiLeakage,
            };
            if (forcePrivate) {
                data.isPublic = false;
            }
            //todo
            const result = await addKlarityReviewReply(data);
            if (!result.error) {
                message.success('Reply success');
                if (onSuccess) {
                    onSuccess();
                }
            } else {
                if (result.errorCode + '' === '90001') {
                    setShowPhiLeakageModal(true)
                } else if (result.errorCode + '' === '90002') {
                    setInappropriateContentModal(true)
                } else {
                    message.error(result?.error || '')
                }
            }
        } catch (e: any) {
            if (e.errorFields) {
                //form error
                return;
            }
            //const msg = e?.toString?.() || 'data error';
            //message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };

    const hasTag = !!item?.tags?.trim();

    return (
        <>
            <Modal
                title="Reply review"
                closable
                className={commonS.modalFixHeightWrap}
                width="1200px"
                style={{
                    maxWidth: '94%'
                }}
                onCancel={() => {
                    onCannel?.();
                }}
                okText="Send"
                open={show}
                onOk={() => {
                    onSubmit();
                }}
                destroyOnClose
                okButtonProps={{ loading: submiting }}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <div className={s.wrap}>
                    <div className={s.left}>
                        <div className={s.feedbackItem} key={`${item?.id}`}>
                            {!verified && <div className={s.name}>Unverified patient</div>}
                            {verified &&
                                <div className={s.name}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                        <path d="M15.8955 4.44316L9.71001 1.75086C9.63829 1.71938 9.56081 1.70313 9.48249 1.70312C9.40416 1.70313 9.32669 1.71938 9.25496 1.75086L3.06951 4.44316C2.96764 4.48735 2.88091 4.56034 2.81998 4.65317C2.75905 4.74601 2.72658 4.85462 2.72656 4.96566V9.96266C2.72656 15.0262 9.03931 17.499 9.30816 17.6016C9.37371 17.6263 9.44306 17.6396 9.51146 17.6396C9.58271 17.6396 9.65301 17.6263 9.72046 17.5997C9.98646 17.4952 16.2365 14.9739 16.2365 9.96266V4.96566C16.2375 4.73956 16.1035 4.53341 15.8955 4.44316Z" fill="#54EFA5" />
                                        <path d="M16.2371 7.70121C15.8742 7.65656 15.5037 7.63281 15.1285 7.63281C10.4288 7.63281 6.57563 11.2552 6.20703 15.8598C7.72038 16.978 9.18148 17.5499 9.30783 17.5983C9.37338 17.623 9.44273 17.6363 9.51113 17.6363C9.58238 17.6363 9.65268 17.623 9.72013 17.5964C9.98613 17.4919 16.2362 14.9706 16.2362 9.95936V7.70121H16.2371Z" fill="#42E59B" />
                                        <path d="M11.3668 2.47191L9.71001 1.75086C9.63829 1.71938 9.56081 1.70313 9.48249 1.70312C9.40416 1.70313 9.32669 1.71938 9.25496 1.75086L3.06951 4.44316C2.96764 4.48735 2.88091 4.56034 2.81998 4.65317C2.75905 4.74601 2.72658 4.85462 2.72656 4.96566V9.07726C6.85906 9.07726 10.3361 6.27761 11.3668 2.47191Z" fill="#5FFFA7" />
                                        <path d="M8.92374 11.9836C8.84889 11.9837 8.77476 11.969 8.70563 11.9403C8.6365 11.9116 8.57373 11.8695 8.52094 11.8164L6.24094 9.53641C6.01864 9.31411 6.01864 8.95311 6.24094 8.72986C6.34782 8.62312 6.49269 8.56316 6.64374 8.56316C6.79479 8.56316 6.93967 8.62312 7.04654 8.72986L8.91804 10.6014L12.1043 7.32861C12.3238 7.10346 12.6848 7.09776 12.9099 7.31816C13.0181 7.42365 13.0799 7.56774 13.0819 7.7188C13.0838 7.86985 13.0258 8.01551 12.9204 8.12376L9.33034 11.8107C9.27767 11.8648 9.21476 11.9078 9.14528 11.9374C9.0758 11.9669 9.00114 11.9823 8.92564 11.9827C8.92659 11.9836 8.92564 11.9836 8.92374 11.9836Z" fill="white" />
                                    </svg>
                                    Verified patient
                                </div>
                            }
                            <div className={s.scoreInfo}>
                                <StarReview width={120} editable={false} value={item?.rating} />
                                <div className={s.time}>{getTimeStr(dayjs(item?.submitAt), 'MMMM D, YYYY')}</div>
                                {
                                    !item?.isPublic && <span className={s.private}>Private</span>
                                }
                                {
                                    item?.isPublic && <span className={s.public}>Public</span>
                                }
                            </div>
                            {
                                hasTag &&
                                <div className={s.tagList}>
                                    {
                                        item?.tags?.split(',').map((tag: string) => {
                                            return (
                                                <div key={tag} className={s.tag}>{tag}</div>
                                            )
                                        })
                                    }
                                </div>
                            }
                            <div className={s.content}>
                                {item?.comment?.content}
                            </div>
                        </div>
                        <Form
                            form={form}
                            name="basic"
                            className={commonS.formStyle1}
                            initialValues={{ subject: `Message from ${getProviderNameDisplay(user)} regarding your feedback on Klarity` }}
                            autoComplete="off"
                            layout="vertical"
                            scrollToFirstError
                        >
                            <div className={s.modalWrap}>
                                <div className={s.row}>
                                    <Form.Item
                                        style={{ width: '100%', maxWidth: '700px' }}
                                        label="Subject"
                                        name="subject"
                                        rules={[
                                            { required: true, message: 'Subject is required' },
                                            { max: 100, message: 'Subject maximum character length of 100' },
                                        ]}
                                    >
                                        <Input maxLength={100} placeholder="" size='large' />
                                    </Form.Item>
                                </div>
                                {/* 
                            <div className={s.maxTips}>Max. 3000 characters</div> */}
                                {/* <div className={s.row} style={{ marginTop: '-24px' }}> */}
                                <div className={s.row}>
                                    <Form.Item
                                        style={{ width: '100%', maxWidth: '700px' }}
                                        label="Reply"
                                        name="content"
                                        rules={[
                                            { required: true, message: 'Reply is required' },
                                            { max: 3000, message: 'Reply maximum character length of 3000' },
                                        ]}
                                    >
                                        <TextContentInput
                                            maxLength={3000}
                                            header={<div>Hello,</div>}
                                            showCount={{
                                                formatter: ({ value, count, maxLength }) => {
                                                    return <div className={s.count}>{count}/{maxLength} characters</div>
                                                }
                                            }}
                                            footer={
                                                <div className={s.nameFoot}>
                                                    <div>Warm regards</div>
                                                    <div>{getProviderNameDisplay(user)}</div>
                                                </div>}
                                        />
                                    </Form.Item>
                                </div>
                                <div className={s.checkWrap}>
                                    <Checkbox
                                        onChange={(e) => {
                                            setShowOn(e.target.checked)
                                        }}
                                        checked={showOn}
                                    >Show my reply on helloklarity.com</Checkbox>
                                </div>
                                <div className={`${s.checkTips} ${showOn ? s.checkTipsHighLight : ''}`}>
                                    For public reviews, you can choose to make your reply visible on Klarity. Please refrain from including any personal or health information about the patient in your reply.
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className={s.right}>
                        <div className={s.tips}>
                            <div className={s.title}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM11 6C11 6.55228 10.5523 7 10 7C9.44772 7 9 6.55228 9 6C9 5.44772 9.44772 5 10 5C10.5523 5 11 5.44772 11 6ZM9 9C8.44772 9 8 9.44772 8 10C8 10.5523 8.44772 11 9 11V14C9 14.5523 9.44772 15 10 15H11C11.5523 15 12 14.5523 12 14C12 13.4477 11.5523 13 11 13V10C11 9.44772 10.5523 9 10 9H9Z" fill="#60A5FA" />
                                </svg>
                                Tips for replying
                            </div>
                            <ul>
                                <li>
                                    <b>Personalize your response:</b> Address the patient by name and reference specific details from their feedback. This shows you've read and considered their comments.
                                </li>
                                <li>
                                    <b>Express empathy:</b> Acknowledge their feelings, whether positive or negative. For negative reviews, start with a statement like "I'm sorry to hear you had a negative experience."
                                </li>
                                <li>
                                    <b>Thank them for their feedback:</b> Always express gratitude for the patient taking the time to share their thoughts.
                                </li>
                                <li>
                                    <b>Offer solutions:</b> If there was a problem, offer a solution or a path to resolution. This could be as simple as inviting them to contact you directly to discuss the matter further.
                                </li>
                                <li>
                                    <b>Highlight positives:</b> For positive reviews, reiterate the aspects of your care that they appreciated. This reinforces your strengths and encourages more positive feedback.
                                </li>
                                <li>
                                    <b>Keep it professional:</b> Maintain a professional and respectful tone, even when responding to negative feedback.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                open={showPhiLeakageModal}
                width="660px"
                onCancel={() => {
                    setShowPhiLeakageModal(false)
                }}
                footer={
                    <div className={s.footerWrap}>
                        <Button
                            onClick={() => {
                                setShowPhiLeakageModal(false)
                                onSubmit(true, true)
                            }}
                            size="large"
                            danger type='primary' className={s.btn}>Send privately</Button>
                        <Button
                            onClick={() => {
                                setShowPhiLeakageModal(false)
                            }}
                            size="large"
                            type='primary' className={s.btn}>Back to edit</Button>
                    </div>
                }
            >
                <div className={s.phiWrap}>
                    <div className={s.phiLeft}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M11.9995 9V11M11.9995 15H12.0095M5.07134 19H18.9277C20.4673 19 21.4296 17.3333 20.6598 16L13.7316 4C12.9618 2.66667 11.0373 2.66667 10.2675 4L3.33929 16C2.56949 17.3333 3.53174 19 5.07134 19Z" stroke="#DC2626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    <div className={s.phiRight}>
                        <div className={s.phiTitle}>Privacy reminder</div>
                        <div className={s.phiContent}>
                            Your reply may contain protected health information (PHI). To protect patient privacy, please remove any personal details (e.g., names, dates, contact info) before posting publicly on helloklarity.com. You may also choose to keep your reply private. Please check your reply carefully. Sharing protected health information (PHI) is a violation of our Terms of Service and could result in your account being suspended.
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                open={showInappropriateContentModal}
                width="660px"
                onCancel={() => {
                    setInappropriateContentModal(false)
                }}
                footer={
                    <div className={s.footerWrap}>
                        <Button
                            onClick={() => {
                                setInappropriateContentModal(false)
                            }}
                            size="large"
                            type='primary' className={s.btn}>Back to edit</Button>
                    </div>
                }
            >
                <div className={s.phiWrap}>
                    <div className={s.phiLeft}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M11.9995 9V11M11.9995 15H12.0095M5.07134 19H18.9277C20.4673 19 21.4296 17.3333 20.6598 16L13.7316 4C12.9618 2.66667 11.0373 2.66667 10.2675 4L3.33929 16C2.56949 17.3333 3.53174 19 5.07134 19Z" stroke="#DC2626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    <div className={s.phiRight}>
                        <div className={s.phiTitle}>Inappropriate content</div>
                        <div className={s.phiContent}>
                            Your response may contain unprofessional or inappropriate language. Please review and edit your response to ensure it adheres to our guidelines. Maintaining a professional and respectful communication environment is important for patient trust and quality care.
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ReplyReviewModal;
