import { TFaqInfo } from "types/operation";

export const FAQ: TFaqInfo[] = [
  {
    id: 1,
    pageId: 1,
    seq: 1,
    question: "How does Klarity reviews work?",
    answer: `
      <ul>
        <li><b>Effortless reviews collection</b>: We automatically send review requests to patients 2 hours after their initial visit and first follow-up session. If no response is received, a reminder will be sent within 48 hours.</li>
        <li><b>Rating & comments</b>: Patients rate their experience and share comments.</li>
        <li><b>Sharing success</b>: 
        We encourage patients who rate their experience 3 stars or higher to share reviews on Trustpilot and Reviews.io. After their first follow-up session, we also guide patients with positive experiences to share on Google, WebMD, and Healthgrades. (Klarity providers can have 12 channels for free, check out in our <a href="/dashboard/channel">Channels</a>)
        </li>
        <li><b>Addressing concerns</b>: You can check for reviews that are waiting for your reply in Klarity reviews and click 'Reply' to communicate with patients.</li>
      </ul>
    `,
  },
  {
    id: 2,
    pageId: 2,
    seq: 2,
    question: "How does my Klarity rating impact my visibility and ranking?",
    answer: `
      <ul>
        <li><b>Higher rankings</b>: Positive rating contribute to a higher ranking in Klarity's search results, making your profile more visible to potential patients.</li>
        <li><b>Increased visibility</b>: A strong online reputation with high ratings and positive reviews attracts more patients to your profile.</li>
        <li><b>Traffic boost</b>: 
        At Klarity, we prioritize exceptional patient experiences. Providers with outstanding ratings will earn higher positions in our provider rankings, while those with consistently low ratings (below 3 stars) will face restrictions on patient traffic until they demonstrate significant improvement.
        </li>
      </ul>
      `,
  },
  {
    id: 3,
    pageId: 3,
    seq: 3,
    question: "How can I use patient feedback to improve my practice?",
    answer: `
      <ul>
        <li><b>Reputation dashboard</b>: Access a personalized dashboard to track your ratings and reviews over time. Gain insights into patient satisfaction trends.</li>
        <li><b>Respond to reviews</b>: Engage with patients by responding to both positive and negative reviews. Show appreciation for positive reviews and address concerns professionally.</li>
        <li><b>Helpful tips</b>: 
        Get guidance on crafting effective and appropriate responses to all types of reviews.
        </li>
        <li><b>Patient-guided growth</b>: 
        Analyze patient reviews to identify areas for improvement, refine your approach, and deliver even better care.
        </li>
      </ul>
      `,
  },
  {
    id: 4,
    pageId: 4,
    seq: 4,
    question: "How will Klarity reviews help me to grow my practice with positive reviews?",
    answer: `
      <ul>
        <li><b>Amplify your success</b>: We encourage satisfied patients to share their positive experiences on relevant platforms:
          <ul>
            <li>Initial visits: Trustpilot and Review.io</li>
            <li>First follow-up sessions: Google, WebMD, and Healthgrades (if you're listed)</li>
          </ul>
        </li>
        <li><b>Boost your ranking</b>: Positive reviews improve your visibility in Klarity search results, making you more discoverable to potential patients.</li>
        <li><b>Build trust</b>: 
        A strong online reputation with positive reviews demonstrates your commitment to quality care and helps attract new patients.
        </li>
        <li><b>High standards</b>: 
        We may limit patient volume for providers with consistently low ratings to ensure a high-quality experience for all patients.
        </li>
      </ul>
      `,
  },
  {
    id: 5,
    pageId: 5,
    seq: 5,
    question: "What happens if I get negative reviews?",
    answer: `
      <ul>
        <li><b>Public vs. private reviews</b>: Patients can choose to make their reviews public (visible on Klarity) or private (visible only to you). We encourage patients to leave negative feedback privately, allowing you to address their concerns directly.</li>
        <li><b>Required reply</b>: Patients can request a reply from you when they submit a review. You'll see a "Waiting for Reply" label on reviews that require your attention.</li>
        <li><b>Respond promptly</b>: 
        We recommend responding to negative reviews as soon as possible to prevent them from escalating into formal complaints. Your timely response demonstrates your commitment to patient satisfaction.
        </li>
        <li><b>Reply visibility (public reviews)</b>: 
        If a patient makes their review public and requests a reply, you can choose to make your reply public as well (visible on Klarity) or keep it private between you and the patient. Make sure you won’t expose patient’s person information in a public review.
        </li>
        <li><b>Complaint option</b>: 
        If a patient has a serious concern, they can choose to file a formal complaint, which will be reviewed by our team.
        </li>
      </ul>
      `,
  },
]