import React from 'react';
import s from './s.module.less';
import { TITLE, CONFIG } from './constants';
import Youtube from './youtube.png'
import { Button } from 'antd';

const Tutorial: React.FC = () => {
    return (
        <div className={s.wrap}>
            <div className={s.list}>
                <div className={s.item}>
                    <div className={s.left}>
                        <h2 className={s.title}>{TITLE}</h2>
                        <h3 className={s.subTitle}>{CONFIG.title}</h3>
                        <ul className={s.detailList}>
                            {CONFIG.list.map((listItem) => (
                                <li className={s.detailItem}>{listItem}</li>
                            ))}
                        </ul>
                    </div>
                    <div className={s.right}>
                        <img
                            onClick={() => {
                                window.open('https://www.youtube.com/watch?v=WkbuPb9VfxU&list=PLBr6KvIG41IQVZh6XY6FlQT9Z1crFXkB_&index=1', '_blank')
                            }}
                            src={Youtube} alt="youtube" />
                        <Button size='large' className={s.btn} type='primary'>Watch tutorial on YouTube</Button>
                        {/* <iframe
                            className={s.videoWrap}
                            width="560"
                            height="315"
                            src="https://www.youtube.com/embed/videoseries?si=EH3cSS1DidpW_vCC&amp;list=PLBr6KvIG41IQVZh6XY6FlQT9Z1crFXkB_"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowfullscreen
                        /> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Tutorial;
