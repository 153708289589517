import { useCallback, useEffect, useState } from 'react';
import { getProviderChannelwithdrawDetail } from 'api/operation';
import { transferProviderToFormData } from 'utils/channel';
import { initApplicationFormData } from 'store/ChannelApplicationProvider';

interface IParams {
    id: number;
    channelId: number;
    providerId: number;
}

const useChannelWithdrawDetailData = ({ id, channelId, providerId }: IParams) => {
    const [data, setData] = useState(initApplicationFormData);
    const [loading, setLoading] = useState(false);

    const fetchData = useCallback(async () => {
        setLoading(true);
        let res:any = {
            error: '',
        };
        res = await getProviderChannelwithdrawDetail({
            id,
            channelId,
            providerId,
        });
        if (!res?.error) {
            setData(transferProviderToFormData(res.data?.data));
        } else {
            console.error(res);
        }
        setLoading(false);
    }, [channelId, providerId]);

    useEffect(() => {
        fetchData();
    }, []);

    return { data, refetch: fetchData, loading };
};

export default useChannelWithdrawDetailData;
