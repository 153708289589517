import React, { useState, useEffect } from 'react';
import { Button, Descriptions } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import EhrActiveStatus from 'components/EhrActiveStatus';
import EhrPaymentStatus from 'components/EhrPaymentStatus';
import { EAddOnProductCode, EhrAddOnItem } from 'types/ehr';
import { getPayList } from 'api/ehr';
import HelpEmail from '../HelpEmail';
import s from './s.module.less';

type TObj = {
    productCode: EAddOnProductCode;
    productName: string;
    list: Partial<EhrAddOnItem>[];
};

const PayCard: React.FC = () => {
    const navigate = useNavigate();
    const [isShowAddOns, setIsShowAddOns] = useState(false);
    const [payList, setPayList] = useState([] as TObj[]);

    const handleGetPayList = async () => {
        const { data } = await getPayList();
        const obj = [] as TObj[];
        (data?.data || []).map((item: EhrAddOnItem) => {
            const { productCode, productName, ...rest } = item;
            if (!obj.find((objItem) => objItem.productCode === productCode)) {
                obj.push({ productCode, productName, list: [] });
            }
            const target = obj.find(
                (objItem) => objItem.productCode === productCode,
            );
            target?.list.push(rest);
            return item;
        });
        setIsShowAddOns(data?.data && data?.data.length > 0);
        setPayList(obj);
    };

    useEffect(() => {
        handleGetPayList();
    }, []);
    return (
        <div className={s.cardItem}>
            <div className={s.cardContent}>
                <Descriptions
                    title="Practice enablement add-ons"
                    layout="vertical"
                    extra={
                        <Button
                            onClick={() => {
                                navigate(`${PATH.DASHBOARD}/${PATH.EHR_ADD_ON_PROVIDER}`);
                            }}
                        >
                            Add
                        </Button>
                    }
                    column={1}
                    colon={false}
                    size="small"
                    contentStyle={{
                        marginBottom: '16px',
                    }}
                >
                    {isShowAddOns ? (
                        <>
                            {payList.map((item) => {
                                if (item.list.length === 0) {
                                    return null;
                                }
                                return (
                                    <Descriptions.Item label="" key={item.productCode}>
                                        <div>
                                            <div className={s.descTitle}>
                                                {item.productName}{' '}
                                                {item.list.length > 1 ? `x ${item.list.length}` : ''}
                                            </div>
                                            {item.list.map((payListItem) => {
                                                return (
                                                    <div className={s.descContent} key={payListItem.id}>
                                                        {payListItem.email && (
                                                            <div className={s.descEmail}>
                                                                {payListItem.email}
                                                            </div>
                                                        )}
                                                        <div className={s.descContentItem}>
                                                            <EhrActiveStatus
                                                                status={payListItem?.activeStatus || ''}
                                                            />
                                                        </div>
                                                        <div className={s.descContentItem}>
                                                            <EhrPaymentStatus
                                                                status={payListItem?.paymentStatus || ''}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </Descriptions.Item>
                                );
                            })}
                        </>
                    ) : (
                        <Descriptions.Item label="">
                            <div className={s.helpTipsTitle}>
                                Elevate your practice with Kiwi EHR add-ons. Explore powerful
                                features like e-Prescribing, and more to streamline your
                                workflow and enhance patient care. Click “Add” to get started.
                            </div>
                        </Descriptions.Item>
                    )}
                </Descriptions>
            </div>
            {isShowAddOns && (
                <div className={s.helpEmail}>
                    <HelpEmail title="Need to manage add-ons?" />
                </div>
            )}
        </div>
    );
};

export default PayCard;
