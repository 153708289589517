import React, { useCallback } from 'react';
import s from './s.module.less';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';

const ConfigSuccessfullyPage = () => {
    const navigate = useNavigate();

    const handleClick = useCallback(() => {
        navigate(`${PATH.DASHBOARD}/${PATH.REPUTATION_MANAGEMENT}`);
    }, [navigate]);

    return (
        <div className={s.wrap}>
            <div className={s.successImage} />
            <div className={s.title}>{'You\'re all set!'}</div>
            <div className={s.desc}>{'Great job! You\'ve successfully set up your Reputation Management system. Start sending surveys to your patients to gather their feedback or unlock more channels for incorporating 5-star reviews into your profile.'}</div>
            <Button type="primary" onClick={handleClick}>Back to Reputation Management</Button>
        </div>
    );
};

export default ConfigSuccessfullyPage;
