import { Modal, Checkbox } from 'antd';
import React from 'react';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { TAssistant } from 'types/common';
import useAssistant from 'hooks/useAssistant';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { EhrAddOnItem } from 'types/ehr';
import { addToShoppingCart } from 'api/ehr';

type Props = {
    clearFlag?: {};
    open:boolean;
    onAdd:(item?:TAssistant[], close?:boolean)=>void;
    onClose: ()=>void;
    item?: EhrAddOnItem;
    productLimitChangeFlag?:{};
};

const AssistantEPrescription = (props:Props) => {
    const { clearFlag, open, onAdd, onClose, productLimitChangeFlag } = props;
    const product:EhrAddOnItem | undefined = props.item;
    const [allAssistants, loading, refetch] = useAssistant(product?.productCode as any);
    const [submiting, setSubmiting] = React.useState(false);

    const [emailOptions, setEmailOptions] = React.useState<string[]>([]);
    const [purchasedEmails, setPurchasedEmails] = React.useState<string[]>([]); //emails

    const [pendingSelected, setPendingSelected] = React.useState<string[]>(); //emails
    const pendingRef = React.useRef<string[]>([]);

    const PRICE_PER = product?.price || 0;

    const handleCheckboxChange = (checkedValues: CheckboxValueType[]) => {
        const values = checkedValues as string[];
        pendingRef.current = [...values];
        setPendingSelected([...values]);
    };

    const reset = () => {
        pendingRef.current = [];
        setPendingSelected([]);
        refetch();
        onAdd?.(undefined, true);
    };

    React.useEffect(() => {
        if (clearFlag) {
            reset();
        }
    }, [clearFlag]);

    React.useEffect(() => {
        if (open) {
            //refetch and add default
            refetch();
        }
    }, [open]);

    React.useEffect(() => {
        refetch();
    }, [productLimitChangeFlag]);

    // React.useEffect(() => {
    //     if (pendingRef.current.length === 0) {
    //         return;
    //     }
    //     //reset when data change
    //     pendingRef.current = pendingRef.current?.filter((email:string) => {
    //         return emailOptions.includes(email);
    //     });
    //     setPendingSelected([...pendingRef.current]);

    //     const addData:TAssistant[] = [];
    //     const allData:TAssistant[] = [...allAssistants];
    //     pendingRef.current?.forEach((email:string) => {
    //         const target = allData.find((item) => item.assistantEmail === email);
    //         if (target) {
    //             addData.push(target);
    //         }
    //     });
    //     onAdd?.(addData, false);
    // }, [emailOptions]);

    React.useEffect(() => {
        const _emailOptions:string[] = [];
        const _purchasedEmails:string[] = [];
        if (allAssistants) {
            allAssistants.forEach((item) => {
                if (item.assistantEmail && !_emailOptions.includes(item.assistantEmail!) && item.productPurchased)
                {
                    _purchasedEmails.push(item.assistantEmail!);
                }
                if (item.assistantEmail && !_emailOptions.includes(item.assistantEmail!) && item.productLimitAdded)
                {
                    _emailOptions.push(item.assistantEmail!);
                }
            });
        }
        setEmailOptions(_emailOptions);
        setPurchasedEmails(_purchasedEmails);
        const currentPendingSelect = pendingRef.current;
        const _pendingSelect = currentPendingSelect?.filter((item) => _emailOptions.includes(item));
        pendingRef.current = _pendingSelect;
        setPendingSelected(_pendingSelect);
    }, [allAssistants]);

    const handleOk = async () => {
        const addData:TAssistant[] = [];
        const assistantIdList: number[] = [];
        const assistantList: TAssistant[] = [];
        pendingSelected?.forEach((email:string) => {
            const target = allAssistants?.find((item) => item.assistantEmail === email);
            if (target) {
                const addNewData:TAssistant = { ...target };
                assistantList.push(addNewData);
                assistantIdList.push(target.id!);
                addData.push(addNewData);
            } else {
                //can remove this logic?
                //const target2 = pendingCollaboratingPhysicianSeat?.find((item) => item.email === email);
            }
        });
        //call api
        setSubmiting(true);
        const result = await addToShoppingCart(product?.productCode!, assistantIdList.length, {
            assistantIdList,
        });
        setSubmiting(false);
        if (!result.error) {
            onAdd?.(addData, true);
        }
    };

    const isEmpty = emailOptions && emailOptions.length === 0;
    const hasSelect = (pendingSelected?.length || 0) > 0;
    return (
        <Modal
            title="e-Prescription (Non-prescriber role)"
            open={open}
            destroyOnClose
            className={commonS.modalFixHeightWrap}
            width="700px"
            okText={isEmpty ? 'Close' : 'Add'}
            onCancel={() => {
                onClose();
            }}
            onOk={() => {
                if (isEmpty) {
                    onClose();
                } else {
                    handleOk();
                }
            }}
            okButtonProps={{
                loading: submiting,
                disabled: !isEmpty && pendingSelected?.length === 0,
            }}
            cancelButtonProps={{
                style: {
                    display: isEmpty ? 'none' : 'inline-block',
                },
            }}
        >
            <div className={s.wrap}>
                <div className={hasSelect ? `${s.title} ${s.titleShow}` : s.title}>You&apos;ve added {pendingSelected?.length || 0} e-prescription(Non-prescriber role) for your assistants, it will be ${PRICE_PER * (pendingSelected?.length || 0)} monthly.</div>
                <div className={s.content}>
                    <div className={s.cContent}>Choose the assistant from your Kiwi EHR who need e-Prescription</div>
                    {isEmpty
                        && <div className={s.empty}>You don’t have any assistants, <br />please add assistants seats</div>
                    }
                    <Checkbox.Group
                        style={{ width: '100%', display: 'block' }}
                        onChange={handleCheckboxChange}

                    >
                        {emailOptions.map((item) => {
                            const target = allAssistants?.find((as) => as.assistantEmail === item);
                            const checked = pendingSelected?.find((i) => i === item);
                            return (
                                <div key={item} className={s.item}>
                                    <Checkbox
                                        disabled={purchasedEmails.includes(item)}
                                        value={item}
                                        checked={!!checked}
                                    >{target?.assistantName} - {item}
                                    </Checkbox>
                                </div>
                            );
                        })}
                    </Checkbox.Group>
                </div>
            </div>
        </Modal>
    );
};

export default AssistantEPrescription;
