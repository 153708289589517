import { Modal } from 'antd';
import React, { useEffect } from 'react';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { EhrAddOnItem } from 'types/ehr';

type Props = {
    clearFlag?: {};
    open: boolean;
    onAdd: () => void;
    onClose: () => void;
    item?: EhrAddOnItem;
};

const SelfEFaxModal = (props:Props) => {
    const { clearFlag, open, onAdd, onClose } = props;
    const reset = () => {

    };

    const handleOk = async () => {
        onAdd?.();
    };

    useEffect(() => {
        if (clearFlag) {
            reset();
        }
    }, [clearFlag]);

    return (
        <Modal
            title="e-Fax"
            open={open}
            destroyOnClose
            className={commonS.modalFixHeightWrap}
            width="700px"
            okText="Add"
            onCancel={() => {
                onClose();
            }}
            onOk={() => {
                handleOk();
            }}
        >
            <div className={s.wrap}>
                <div className={s.note}>
                    <div className={s.noteItem}>
                        <div className={s.noteTitle}>
                            What is e-Fax service in the Kiwi EHR?
                        </div>
                        <div className={s.noteDesc}>
                            Kiwi EHR's e-Fax service allows you to securely send and receive
                            faxes directly within your EHR. This eliminates the need for a
                            separate fax machine or service, streamlining your workflow and
                            ensuring important documents are easily accessible alongside
                            patient records.
                        </div>
                    </div>
                    <div className={s.noteItem}>
                        <div className={s.noteTitle}>Why you need an e-Fax number?</div>
                        <ol className={`${s.noteDesc} ${s.noteOl}`}>
                            <li className={s.noteOlItem}>
                                <span>Receive Critical Documents:</span>any healthcare
                                organizations still rely on fax for sending referrals, lab
                                results, and other vital information. An e-fax number ensures
                                you can receive these documents seamlessly within Kiwi EHR.
                            </li>
                            <li className={s.noteOlItem}>
                                <span>Maintain Professionalism:</span>Having a dedicated fax
                                number presents a more professional image to your colleagues
                                and patients.
                            </li>
                            <li className={s.noteOlItem}>
                                <span>Simplified Communication:</span>Easily send faxes to
                                other healthcare providers, pharmacies, and insurance
                                companies directly from your EHR.
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default SelfEFaxModal;
