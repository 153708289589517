// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--umZN2 {
  background: #f9fafc;
  padding: 40px;
  min-height: 100vh;
}
.s-module__wrap--umZN2.s-module__unsetWrap--y1SKe .s-module__header--seg8n {
  flex-direction: column !important;
  justify-content: flex-start !important;
}
.s-module__wrap--umZN2 .s-module__header--seg8n {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.s-module__wrap--umZN2 .s-module__header--seg8n h1 {
  font-size: 30px;
  line-height: 36px;
  font-weight: bolder;
  color: #111827;
}
.s-module__wrap--umZN2 .s-module__header--seg8n .s-module__btnWrap--hMTSu {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.s-module__wrap--umZN2 .s-module__header--seg8n .s-module__btnWrap--hMTSu .s-module__btnNormal--mJi01 {
  margin-left: 8px;
  background-color: #fff;
}
.s-module__wrap--umZN2 .s-module__header--seg8n .s-module__btnWrap--hMTSu .s-module__btnPrimary--Z6tcR {
  margin-left: 8px;
}
.s-module__wrap--umZN2 .s-module__content--PHr2p {
  margin-top: 24px;
}
.s-module__wrap--umZN2 .s-module__content--PHr2p .ant-tabs {
  width: 100%;
}
.s-module__wrap--umZN2 .s-module__content--PHr2p .ant-tabs-tab {
  font-weight: 500;
  color: var(--gray-500);
  padding-left: 12px;
  padding-right: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/ReputationDashboradPage/s.module.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,aAAA;EACA,iBAAA;AACF;AACE;EAEI,iCAAA;EACA,sCAAA;AAAN;AARA;EAaI,aAAA;EACA,mBAAA;EACA,8BAAA;AAFJ;AAbA;EAiBM,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,cAAA;AADN;AAnBA;EAuBM,cAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;AADN;AAzBA;EA4BQ,gBAAA;EACA,sBAAA;AAAR;AA7BA;EAgCQ,gBAAA;AAAR;AAhCA;EAuCI,gBAAA;AAJJ;AAnCA;EA0CM,WAAA;AAJN;AAtCA;EAkDM,gBAAA;EACA,sBAAA;EAIA,kBAAA;EACA,mBAAA;AAdN","sourcesContent":[".wrap {\n  background: #f9fafc;\n  padding: 40px;\n  min-height: 100vh;\n\n  &.unsetWrap {\n    .header {\n      flex-direction: column !important;\n      justify-content: flex-start !important;\n    }\n  }\n\n  .header{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    h1 {\n      font-size: 30px;\n      line-height: 36px;\n      font-weight: bolder;\n      color: #111827;\n    }\n    .btnWrap{\n      flex-shrink: 0;\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n      .btnNormal {\n        margin-left: 8px;\n        background-color: #fff;\n      }\n      .btnPrimary{\n        margin-left: 8px;\n      }\n    }\n  \n  }\n\n  .content {\n    margin-top: 24px;\n\n    :global .ant-tabs{\n      width: 100%;\n    }\n\n    :global .ant-tabs-nav-list {\n\n    }\n\n    :global .ant-tabs-tab {\n      font-weight: 500;\n      color: var(--gray-500);\n    }\n\n    :global .ant-tabs-tab {\n      padding-left: 12px;\n      padding-right: 12px;\n     \n      // width: 80px;\n      // text-align: center;\n      // display: flex;\n      // justify-content: center;\n      // align-items: center;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--umZN2`,
	"unsetWrap": `s-module__unsetWrap--y1SKe`,
	"header": `s-module__header--seg8n`,
	"btnWrap": `s-module__btnWrap--hMTSu`,
	"btnNormal": `s-module__btnNormal--mJi01`,
	"btnPrimary": `s-module__btnPrimary--Z6tcR`,
	"content": `s-module__content--PHr2p`
};
export default ___CSS_LOADER_EXPORT___;
