import React, { useState } from 'react';
import { Modal, Form, Button, Input, Descriptions, Spin } from 'antd';
import commonS from 'styles/common.module.less';
import s from './s.module.less';
import { getSalesInfo, updateSalesInfo } from 'api/operation';
import { getUserId } from 'utils/localstore';
import type { TSalesInfo } from 'types/contractLink';
import FormValidationHelper from 'utils/validation';

type Props = {
    isAdd: boolean;
    onCancel?: () => void;
    onSuccess?: () => void;
};

const UpdateContact = ({ isAdd, onCancel, onSuccess }: Props) => {
    const [submiting, setSubmiting] = useState(false);
    const [form] = Form.useForm<TSalesInfo & { active: boolean }>();
    const [info, setInfo] = useState<TSalesInfo>({} as TSalesInfo);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onSave = async () => {
        setSubmiting(true);
        try {
            const formValues = await form.validateFields();
            const id = info?.id;

            const params: Partial<TSalesInfo> = {
                title: formValues.title,
                tel: formValues.tel,
                bookingLinkUrl: formValues.bookingLinkUrl,
            };

            const { data } = await updateSalesInfo(id, params);
            if (data.code === 200) {
                onSuccess?.();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setSubmiting(false);
        }
    };

    const getInfo = async () => {
        setIsLoading(true);
        const { data } = await getSalesInfo(getUserId());
        setInfo(data?.data);
        setIsLoading(false);
        if (data?.data?.title) {
            const { title, bookingLinkUrl, tel } = data.data;
            form.setFieldsValue({
                title,
                bookingLinkUrl,
                tel,
            });
        } else {
            form.resetFields();
        }
    };

    React.useEffect(() => {
        getInfo();
    }, []);

    return (
        <Modal
            title={isAdd ? 'Add your contact information' : 'Update sales contact info'}
            closable
            className={commonS.modalFixHeightWrap}
            width="520px"
            maskClosable
            destroyOnClose
            onCancel={onCancel}
            okText={isAdd ? 'Add' : 'Update'}
            open
            footer={
                <div>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button loading={submiting} type="primary" onClick={onSave}>
                        {isAdd ? 'Add' : 'Update'}
                    </Button>
                </div>
            }
        >
            <div className={s.wrap}>
                <Spin spinning={isLoading}>
                    <div className={s.tips}>
                        <div className={s.left}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="14"
                                viewBox="0 0 16 14"
                                fill="none"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M6.25706 1.09858C7.02167 -0.260724 8.97875 -0.260725 9.74336 1.09858L15.3237 11.0191C16.0736 12.3523 15.1102 13.9996 13.5805 13.9996H2.4199C0.890251 13.9996 -0.0731769 12.3523 0.676753 11.0191L6.25706 1.09858ZM9.00012 10.9998C9.00012 11.552 8.55241 11.9998 8.00012 11.9998C7.44784 11.9998 7.00012 11.552 7.00012 10.9998C7.00012 10.4475 7.44784 9.99976 8.00012 9.99976C8.55241 9.99976 9.00012 10.4475 9.00012 10.9998ZM8.00012 2.99976C7.44784 2.99976 7.00012 3.44747 7.00012 3.99976V6.99976C7.00012 7.55204 7.44784 7.99976 8.00012 7.99976C8.55241 7.99976 9.00012 7.55204 9.00012 6.99976V3.99976C9.00012 3.44747 8.55241 2.99976 8.00012 2.99976Z"
                                    fill="#FBBF24"
                                />
                            </svg>
                        </div>
                        <div className={s.right}>
                            <div className={s.title}>
                                {
                                    isAdd ? 'To ensure clients can reach you, please confirm or update your contact details below. This contact information will be used in all your generated links.' : 'Please note: Updated contact information will be applied to all future links you generate. Ensure accuracy as clients will use this information to reach you.'
                                }
                            </div>
                        </div>
                    </div>
                    <Descriptions layout="vertical" column={4} colon={false}>
                        <Descriptions.Item label="First name" span={2}>
                            {info?.firstName}
                        </Descriptions.Item>
                        <Descriptions.Item label="Last name" span={2}>
                            {info?.lastName}
                        </Descriptions.Item>
                        <Descriptions.Item label="Kiwi email" span={4}>
                            {info?.gmail}
                        </Descriptions.Item>
                    </Descriptions>
                    <Form
                        form={form}
                        className={commonS.formStyle1}
                        initialValues={{ active: true }}
                        autoComplete="off"
                        layout="vertical"
                        scrollToFirstError
                    >
                        <>
                            <div className={s.row}>
                                <div className={s.rowItem}>
                                    <Form.Item
                                        style={{ width: '320px' }}
                                        label="Title"
                                        name="title"
                                        validateFirst
                                        rules={[{ required: true, message: 'Title is required' }]}
                                    >
                                        <Input maxLength={100} />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.rowItem}>
                                    <Form.Item
                                        style={{ width: '320px' }}
                                        label="Phone number"
                                        name="tel"
                                        validateFirst
                                        rules={[
                                            { required: true, message: 'Phone number is required' },
                                            FormValidationHelper.validateUSPhoneNumber(
                                                'Please enter a 10-digit phone number',
                                            ),
                                        ]}
                                    >
                                        <Input maxLength={20} />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.rowItem}>
                                    <Form.Item
                                        style={{ width: '320px' }}
                                        label="Scheduling link"
                                        name="bookingLinkUrl"
                                        validateFirst
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Scheduling link is required',
                                            },
                                            FormValidationHelper.validateLink(
                                                'Please enter a valid link, starting with http:// or https://',
                                            ),
                                        ]}
                                    >
                                        <Input maxLength={500} minLength={40} />
                                    </Form.Item>
                                </div>
                            </div>
                        </>
                    </Form>
                </Spin>
            </div>
        </Modal>
    );
};

export default UpdateContact;
