import { Button, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import React, { useEffect, useState } from 'react';
import s from './s.module.less';
import { useNavigate } from 'react-router-dom';
import HeaderImage from './images/img1.png';
import Benefits from './components/Benefits';
import Features from './components/Features';
import useProviderHomeInfo from 'hooks/useProviderHomeInfo';
import { setHasViewKiwiEhrPage } from 'utils/localstore';
import FullScreenPopLayout from 'components/FullScreenPopLayout';
import FrequencyQuestionSecion from 'components/FrequencyQuestionSecion';
import { FAQ } from './constant';
import { Email } from 'constants/contact';

type Props = {
    notifyHasConfig?: () => void;
}

const IntroHomeView = (props: Props) => {
    const { notifyHasConfig } = props;
    const navigate = useNavigate();
    const [activeId, setActiveId] = useState('solutions');
    const [data, loadingHome] = useProviderHomeInfo();
    const [isScrolling, setIsScrolling] = useState(false);

    const [showGuide, setShowGuide] = useState(false);

    const uniprofileApproveCompleted = !!data?.uniprofileApproveCompleted;
    const ehrSetUpCompleted = !!data?.ehrSetUpCompleted;

    const showTips = !uniprofileApproveCompleted || !ehrSetUpCompleted;
    //const showTips = true;

    const guideItems: TabsProps['items'] = [
        {
            key: 'benefits',
            label: <div>Benefits</div>,
            children: <div />,
        },
        {
            key: 'features',
            label: <div>How does it work</div>,
            children: <div />,
        },
    ];

    const handleChange = (activeKey: string) => {
        setIsScrolling(true);
        setActiveId(activeKey);
        const element = document.getElementById(activeKey as string);
        const bodyElement = document.getElementById('siteLayout');
        const ehrHomeTab = document.getElementById('ehrHomeTab')?.offsetHeight || 0;
        bodyElement?.scrollTo({
            top: (element as HTMLBaseElement).offsetTop - ehrHomeTab - 10,
            behavior: 'smooth',
        });
        setIsScrolling(false);
    };

    useEffect(() => {
        const els = document.querySelectorAll('.ant-tabs-tab');
        const activeClassName = 'ant-tabs-tab-active';
        if (isScrolling) {
            return;
        }
        els.forEach((el) => {
            const classList = Array.from(el.classList).filter(
                (i) => i !== activeClassName,
            );
            if (el.getAttribute('data-node-key') === `${activeId}`) {
                classList.push(activeClassName);
                el.className = classList.join(' ');
            } else {
                el.className = 'ant-tabs-tab';
            }
        });
    }, [activeId]);

    useEffect(() => {
        setHasViewKiwiEhrPage('true');
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setActiveId(entry.target.id);
                    } else if (entry.target.id === 'features') {
                        setActiveId('benefits')
                    }
                });
            },
            {
                threshold: 0.1,
            },
        );
        const watchingItems = Array.from(
            document.getElementsByClassName('watchingContent'),
        );
        watchingItems.forEach((item) => observer.observe(item));
        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <div className={s.wrapper}>
            <div className={s.wrapperIn}>
                <div className={s.header}>
                    <div className={s.headerLeft}>
                        <h1 className={s.headerTitle}>
                            Unlock the power of patient feedback with
                            <br />
                            <span>Klarity reviews</span>
                        </h1>
                        <p className={s.headerInfo}>
                            Turn patient experiences into your most powerful marketing tool. Our automated system makes it easy to collect and leverage reviews to help you build trust, attract new patients, and grow your practice.
                        </p>
                        {
                            !showTips &&
                            <div className={s.headerBtnWrap}>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        setShowGuide(true)
                                    }}
                                    size="large"
                                    className={s.startedButton}
                                >
                                    Learn more
                                </Button>
                            </div>
                        }
                        {
                            showTips &&
                            <div className={s.tips}>
                                <div className={s.tipIcon} />
                                <div className={s.tipContent}>
                                    <div className={s.tipDesc}>
                                        Before using this feature, you'll need to complete UniProfile submission and EHR set-up.
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={s.headerRight}>
                        <img
                            src={HeaderImage}
                            className={s.headerImage}
                            alt="Klarity reviews"
                        />
                    </div>
                </div>
                <div className={s.contentWrap}>
                    <div className={s.tabs} id="ehrHomeTab">
                        <Tabs
                            defaultActiveKey="1"
                            activeKey={activeId}
                            items={guideItems}
                            onChange={(activeKey) => handleChange(activeKey)}
                        />
                    </div>
                    <div className={`${s.content} watchingContent`} id="benefits">
                        <Benefits />
                    </div>
                    <div className={`${s.content} watchingContent`} id="features">
                        <Features />
                    </div>
                </div>
                {
                    showGuide &&
                    <FullScreenPopLayout
                        footerButton={
                            <div className={s.btnWrap}>
                                <div className={s.helpWrap}>
                                    Need help? Contact <a href={`mailto:${Email.provider_support}`}>providersupport@helloklarity.com</a> for more information.
                                </div>
                                <Button
                                    onClick={() => {
                                        if (notifyHasConfig) {
                                            notifyHasConfig();
                                        }
                                    }}
                                    type='primary'>
                                    Manage your reputation
                                </Button>
                            </div>

                        }
                    >
                        <div className={s.popWrap}>
                            <div className={s.title}>Your ultimate guide for the Klarity Reviews</div>
                            <div className={s.des}>Klarity reviews is designed to empower you to build trust, attract new patients, and grow your practice. We've made it effortlessly to collect valuable patient feedback, showcase your success, and foster stronger patient relationships.</div>
                            <div className={s.faqWrap}>
                                <div className={s.line} />
                                <FrequencyQuestionSecion
                                    data={FAQ}
                                    largerFontsize
                                />
                            </div>

                        </div>
                    </FullScreenPopLayout>
                }
            </div>
        </div>
    );
};

export default IntroHomeView;
