import SolutionsImg1 from 'assets/ehr-home/s-1.png';
import SolutionsImg2 from 'assets/ehr-home/s-2.png';
import SolutionsImg3 from 'assets/ehr-home/s-3.png';
import SolutionsImg4 from 'assets/ehr-home/s-4.png';
import SolutionsImg5 from 'assets/ehr-home/s-5.png';
import SolutionsImg6 from 'assets/ehr-home/s-6.png';

export const TITLE = 'Discover advanced solutions with Kiwi Health’s EHR';

export const CONFIG = [
    {
        title: 'Enhance engagement with HIPAA-compliant web & mobile portal',
        list: [
            'Offers a HIPAA-compliant web and mobile communication portal',
            'Secure messaging system for private client-provider chat',
            'Telehealth options for secure virtual client-provider meetings',
        ],
        imgUrl: SolutionsImg1,
    },
    {
        title: 'Boost new patient acquisition with Kiwi ecosystem',
        list: [
            'Extends reach through diverse marketing channels',
            'Enhances visibility with Kiwi\'s HelloKlarity.com marketplace',
            'Boosts online reputation with reputation management tools',
        ],
        imgUrl: SolutionsImg2,
    },
    {
        title: 'Improve efficiency with forms, charting, and templates',
        list: [
            'Supplies a wide range of templates for forms and policies',
            'Supports detailed and customizable client charting',
            'Streamlines appointment scheduling and management',
        ],
        imgUrl: SolutionsImg3,
    },
    {
        title: 'Lab integration and medication management',
        list: [
            'Manages controlled medications efficiently',
            'Simplifies charting with pre-fill options and templates',
            'Facilitates easy lab requests and direct result retrieval',
        ],
        imgUrl: SolutionsImg4,
    },
    {
        title: 'Streamline billing and payment processes',
        list: [
            'Saves time with automated billing and subscriptions',
            'Supports diverse payment methods for client convenience',
            'Includes cash, insurance, Superbill, and HSA/FSA cards options',
        ],
        imgUrl: SolutionsImg5,
    },
    {
        title: 'Foster lasting client connections with wellness programs',
        list: [
            'Craft personalized wellness programs',
            'Use tools and progress tracking to engage clients',
            'Establish long-term relationships through educational support',
        ],
        imgUrl: SolutionsImg6,
    },

];

export default CONFIG;
