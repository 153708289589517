// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--Tf5Sr .s-module__editItem--Xuc4C {
  margin-bottom: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/operation/CustomizeHelloKlarityPPP/components/About/s.module.less"],"names":[],"mappings":"AAAA;EAEQ,mBAAA;AAAR","sourcesContent":[".wrap {\n    .editItem {\n        margin-bottom: 24px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--Tf5Sr`,
	"editItem": `s-module__editItem--Xuc4C`
};
export default ___CSS_LOADER_EXPORT___;
