// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--ohNeR .s-module__row--HhILi {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 16px;
}
.s-module__wrap--ohNeR .s-module__row--HhILi .s-module__displayItem--nE7LZ {
  margin-bottom: 12px;
}
.s-module__wrap--ohNeR .s-module__row--HhILi .s-module__displayItem--nE7LZ .s-module__label--ig5ci {
  color: #6B7280;
  font-size: 14px;
  line-height: 20px;
}
.s-module__wrap--ohNeR .s-module__rowItem--waa5y {
  margin-right: 36px;
}
.s-module__wrap--ohNeR .s-module__colItem--GISzj {
  display: flex;
  flex-direction: column;
}
.s-module__wrap--ohNeR .s-module__title--pGSdp {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.s-module__wrap--ohNeR .s-module__title--pGSdp .s-module__name--MulCd {
  color: #6B7280;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.s-module__wrap--ohNeR .s-module__title--pGSdp .s-module__btn--MSyAQ {
  background: #fff;
  margin-left: 8px;
}
.s-module__wrap--ohNeR .s-module__title--pGSdp .s-module__btn2--OKwUz {
  margin-left: 8px;
}
.s-module__wrap--ohNeR .s-module__formWrap--UZvFC {
  padding: 24px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #DAD8D8;
  margin-top: 12px;
  padding-top: 0;
}
.s-module__footerBtnWrap--aBWTW {
  margin-top: 18px;
}
.s-module__footerBtnWrap--aBWTW .s-module__btn2--OKwUz {
  margin-left: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/EhrProviderAddOnPage/components/AssistantSeat/components/EditAssistant/s.module.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AAAJ;AALA;EAcM,mBAAA;AANN;AARA;EASQ,cAAA;EACA,eAAA;EACA,iBAAA;AAER;AAbA;EAmBI,kBAAA;AAHJ;AAhBA;EAuBI,aAAA;EACA,sBAAA;AAJJ;AApBA;EA4BI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;AALJ;AA1BA;EAkCM,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AALN;AAhCA;EAyCM,gBAAA;EACA,gBAAA;AANN;AApCA;EA6CM,gBAAA;AANN;AAvCA;EAkDI,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;EACA,cAAA;AARJ;AAYA;EACE,gBAAA;AAVF;AASA;EAGI,gBAAA;AATJ","sourcesContent":[".wrap {\n  .row {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n    margin-top: 16px;\n\n    .displayItem {\n      .label {\n        color: #6B7280;\n        font-size: 14px;\n        line-height: 20px;\n      }\n\n      margin-bottom: 12px;\n    }\n  }\n\n  .rowItem {\n    margin-right: 36px;\n  }\n\n  .colItem {\n    display: flex;\n    flex-direction: column;\n  }\n\n  .title {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n\n    .name {\n      color: #6B7280;\n      font-size: 14px;\n      font-style: normal;\n      font-weight: 500;\n    }\n\n    .btn {\n      background: #fff;\n      margin-left: 8px;\n    }\n    .btn2{\n      margin-left: 8px;\n    }\n  }\n\n  .formWrap {\n    padding: 24px;\n    background: #fff;\n    border-radius: 10px;\n    border: 1px solid #DAD8D8;\n    margin-top: 12px;\n    padding-top: 0;\n  }\n}\n\n.footerBtnWrap {\n  margin-top: 18px;\n  .btn2 {\n    margin-left: 8px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--ohNeR`,
	"row": `s-module__row--HhILi`,
	"displayItem": `s-module__displayItem--nE7LZ`,
	"label": `s-module__label--ig5ci`,
	"rowItem": `s-module__rowItem--waa5y`,
	"colItem": `s-module__colItem--GISzj`,
	"title": `s-module__title--pGSdp`,
	"name": `s-module__name--MulCd`,
	"btn": `s-module__btn--MSyAQ`,
	"btn2": `s-module__btn2--OKwUz`,
	"formWrap": `s-module__formWrap--UZvFC`,
	"footerBtnWrap": `s-module__footerBtnWrap--aBWTW`
};
export default ___CSS_LOADER_EXPORT___;
