// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__link--Qv9jL {
  text-decoration: underline;
}
.s-module__link--Qv9jL:hover {
  color: var(--main-green);
}
.s-module__helpTipsTitle--3OYzx {
  color: var(--gray-500);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/EhrProviderHomePage/components/HelpEmail/s.module.less"],"names":[],"mappings":"AAAA;EACE,0BAAA;AACF;AAAE;EACE,wBAAA;AAEJ;AAEA;EACE,sBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EAAA,aAAa;AACf","sourcesContent":[".link {\n  text-decoration: underline;\n  &:hover {\n    color: var(--main-green);\n  }\n}\n\n.helpTipsTitle {\n  color: var(--gray-500);\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 20px; /* 142.857% */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `s-module__link--Qv9jL`,
	"helpTipsTitle": `s-module__helpTipsTitle--3OYzx`
};
export default ___CSS_LOADER_EXPORT___;
