import { message, Modal, Form, Input, Radio, DatePicker, Space } from 'antd';
import React, { useEffect, useState, useMemo } from 'react';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { FormType } from './types';
import { EhrAddOnItem, EAddOnProductCode } from 'types/ehr';
import { formatDate, removeHMSInUTCTime } from 'utils/common';
import { getAddOnUniprofileInfo, addToShoppingCart } from 'api/ehr/index';
import dayjs from 'dayjs';
import useAddOnProduct from 'hooks/useAddOnProduct';

type Props = {
    clearFlag?: {};
    open: boolean;
    isShowMore: boolean;
    onAdd: () => void;
    onClose: () => void;
    item?: EhrAddOnItem;
};

const SelfEPrescriptionModal = (props: Props) => {
    const { clearFlag, open, item, isShowMore, onAdd, onClose } = props;
    const product: EhrAddOnItem | undefined = props.item;
    const [form] = Form.useForm<FormType>();
    const [submiting, setSubmiting] = useState(false);
    const [allItems, loading, refetch] = useAddOnProduct();
    const selfItems = allItems.self;
    const isHasEfaxNumber = useMemo(() => {
        const eFaxNumber = selfItems.find(
            (eFaxItem) => eFaxItem.productCode === EAddOnProductCode.SELF_EFAX,
        );
        return !!eFaxNumber?.paymentStatus || eFaxNumber?.inEhrAddonShoppingCart;
    }, [selfItems]);
    const reset = () => {
        form.resetFields();
    };

    const handleOk = async () => {
        try {
            setSubmiting(true);
            const formValues = await form.validateFields();
            const addData = {
                birthDate: formValues.birthDate
                    ? formatDate(formValues.birthDate)
                    : '',
                addonService: {
                    doseSpotOption: formValues.doseSpotOption,
                    doseSpotName: formValues.doseSpotName || '',
                    eFaxOption: formValues.eFaxOption,
                    eFax: formValues.eFax,
                    addEFax: isHasEfaxNumber
                        ? false
                        : formValues.eFaxOption === '1',
                },
            };
            setSubmiting(true);
            const result = await addToShoppingCart(product?.productCode!, 1, {
                addOnService: addData.addonService,
                birthDate: addData.birthDate,
            });
            if (!result.error) {
                onAdd?.();
            }
            setSubmiting(false);
        } catch (e: any) {
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };

    const getData = async () => {
        try {
            const { data } = await getAddOnUniprofileInfo();
            const { birthDate, addonService } =
                data?.data as FormType;
            form.setFieldsValue({
                birthDate: birthDate
                    ? dayjs(removeHMSInUTCTime(birthDate))
                    : undefined,
                doseSpotOption: addonService?.doseSpotOption || 'N',
                eFaxOption: addonService?.eFaxOption || '1',
                eFax: addonService?.eFax || '',
            });
        } catch (e) {

        }
    };

    useEffect(() => {
        if (clearFlag) {
            reset();
        }
    }, [clearFlag]);

    useEffect(() => {
        if (open) {
            refetch();
            getData();
        }
    }, [open]);

    const currentBitrhday = form.getFieldValue('birthDate');
    return (
        <Modal
            title="e-Prescription"
            open={open}
            destroyOnClose
            className={commonS.modalFixHeightWrap}
            width="700px"
            okText="Add"
            onCancel={() => {
                onClose();
            }}
            onOk={() => {
                handleOk();
            }}
            okButtonProps={{
                loading: submiting,
            }}
        >
            <Form
                form={form}
                name="basic"
                className={commonS.formStyle1}
                initialValues={{ remember: true }}
                autoComplete="off"
                layout="vertical"
            >
                <div className={s.wrap}>
                    {isShowMore && (
                        <div className={s.note}>
                            <div className={s.noteItem}>
                                <div className={s.noteTitle}>
                                    What is e-Prescription service in the Kiwi EHR?
                                </div>
                                <div className={s.noteDesc}>
                                    Kiwi EHR's e-Prescription service, powered by DoseSpot,
                                    allows you to electronically prescribe medications directly
                                    to pharmacies, streamlining the process and reducing the
                                    risk of errors. With e-Prescription, you can easily send
                                    prescriptions, check for drug interactions, track refills,
                                    and enhance patient safety.
                                </div>
                            </div>
                            <div className={s.noteItem}>
                                <div className={s.noteTitle}>How does it work?</div>
                                <ol className={`${s.noteDesc} ${s.noteOl}`}>
                                    <li className={s.noteOlItem}>
                                        <span>Purchase:</span> Purchase e-Prescription for
                                        $40/month. Your card won't be charged until the service is
                                        activated and ready to use.
                                    </li>
                                    <li className={s.noteOlItem}>
                                        <span>Activation & Billing:</span> After approval, your
                                        e-Prescription service will be activated within 2-4
                                        business days. Your first monthly charge will occur 30
                                        days after your initial purchase to ensure everything is
                                        working smoothly before billing begins.
                                    </li>
                                    <li className={s.noteOlItem}>
                                        <span>Start Prescribing:</span> Log in to Kiwi EHR, go to
                                        a patient's profile, and click "Add and Manage
                                        Prescription." You'll be prompted to accept the
                                        e-Prescribing terms and verify your identity.
                                    </li>
                                </ol>
                            </div>
                            <div className={s.noteItem}>
                                <div className={s.noteTitle}>
                                    What do I need to add e-Prescription services?
                                </div>
                            </div>
                        </div>
                    )}

                    <div className={s.content}>
                        <div className={`${s.cContent} ${s.required}`}>e-Fax number</div>
                        <div className={s.cDes}>
                            A e-Fax number is required for e-Prescription.
                        </div>
                        {isHasEfaxNumber ? (
                            <div className={s.radioDescTips}>
                                We found you’ve already have a Kiwi EHR e-fax number with us.
                                <br />
                                We will use that for your e-Prescription. If you need updated
                                the e-fax number, please contact onboarding@helloklarity.com
                            </div>
                        ) : (
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) => {
                                    const eFaxOption = getFieldValue('eFaxOption');
                                    return (
                                        <Form.Item name="eFaxOption">
                                            <Radio.Group
                                                style={{
                                                    width: '100%',
                                                    display: 'block',
                                                }}
                                                defaultValue="1"
                                            >
                                                <Space direction="vertical">
                                                    <Radio value="1">
                                                        <div className={s.radioTitle}>
                                                            Obtain a dedicated e-Fax number with Kiwi
                                                            ($9.99/month)
                                                        </div>
                                                        {eFaxOption === '1' && (
                                                            <div className={s.radioDesc}>
                                                                <p className={s.desc}>
                                                                    This e-Fax number will be added to your
                                                                    cart, so you can review and purchase it
                                                                    alongside your e-Prescription. You won't be
                                                                    charged for it until you complete the
                                                                    checkout process.
                                                                </p>
                                                            </div>
                                                        )}
                                                    </Radio>
                                                    <Radio value="2">
                                                        <div className={s.radioTitle}>
                                                            I have my own fax number, and want to transfer
                                                            to the Kiwi EHR
                                                        </div>
                                                        {eFaxOption === '2' && (
                                                            <div className={s.radioDesc}>
                                                                <Form.Item
                                                                    style={{
                                                                        width: '360px',
                                                                        marginBottom: '4px',
                                                                        marginTop: '8px',
                                                                    }}
                                                                    label=""
                                                                    name="eFax"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Fax number is required',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        maxLength={25}
                                                                        placeholder="Enter your fax number"
                                                                    />
                                                                </Form.Item>
                                                                <p className={s.desc}>
                                                                    The activation process for using your
                                                                    current fax number in the Kiwi EHR may
                                                                    require 30-60 days.
                                                                </p>
                                                            </div>
                                                        )}
                                                    </Radio>
                                                    <Radio value="3">
                                                        <div className={s.radioTitle}>
                                                            I have my own fax number, and don’t transfer to
                                                            the Kiwi EHR
                                                        </div>
                                                        {eFaxOption === '3' && (
                                                            <div className={s.radioDesc}>
                                                                <Form.Item
                                                                    style={{
                                                                        width: '360px',
                                                                        marginBottom: '4px',
                                                                        marginTop: '8px',
                                                                    }}
                                                                    label=""
                                                                    name="eFax"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Fax number is required',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        maxLength={25}
                                                                        placeholder="Enter your fax number"
                                                                    />
                                                                </Form.Item>

                                                                <p className={s.desc}>
                                                                    If you choose to use your own fax number
                                                                    without transferring it to Kiwi EHR, you
                                                                    won't be able to receive faxes directly
                                                                    within your EHR. This means you'll miss out
                                                                    on convenient features, risk missing
                                                                    important documents, and have to manage
                                                                    faxes manually using separate tools or
                                                                    devices.
                                                                </p>
                                                            </div>
                                                        )}
                                                    </Radio>
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                    );
                                }}
                            </Form.Item>
                        )}
                    </div>
                    <div className={s.content}>
                        <div className={`${s.cContent} ${s.required}`}>Date of birth</div>
                        <div className={s.cDes}>
                            Your birth date is needed to set up e-Prescriptions for identity
                            verification. It will not be shared publicly.
                        </div>
                        <Form.Item
                            style={{ width: '200px' }}
                            label=""
                            name={['birthDate']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Date of birth is required',
                                },
                            ]}
                        >
                            <DatePicker
                                disabledDate={(current) => {
                                    const day18Ago = dayjs().subtract(18, 'year');
                                    return current.valueOf() > day18Ago.valueOf();
                                }}
                                style={{ width: '100%' }}
                                defaultPickerValue={currentBitrhday ? undefined : dayjs().subtract(18, 'year')}
                            />
                        </Form.Item>
                    </div>
                    <div className={s.content}>
                        <div className={`${s.cContent} ${s.required}`}>DoseSpot</div>
                        <div className={s.cDes}>
                            Are your currently using DoseSpot or FullScript with another organization?
                        </div>
                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) => {
                                const doseSpotOption = getFieldValue('doseSpotOption');
                                return (
                                    <Form.Item name={['doseSpotOption']}>
                                        <Radio.Group
                                            style={{
                                                width: '100%',
                                                display: 'block',
                                            }}
                                            defaultValue="N"
                                        >
                                            <Space direction="vertical">
                                                <Radio value="N">No</Radio>
                                                <Radio value="Y">
                                                    <div className={s.radioTitle}>Yes</div>
                                                    {doseSpotOption === 'Y' && (
                                                        <div className={s.radioDesc}>
                                                            <Form.Item
                                                                style={{
                                                                    width: '360px',
                                                                    marginBottom: '4px',
                                                                    marginTop: '8px',
                                                                }}
                                                                label=""
                                                                name="doseSpotName"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Organization name is required',
                                                                    },
                                                                ]}
                                                            >
                                                                <Input
                                                                    maxLength={25}
                                                                    placeholder="Enter the organization name"
                                                                />
                                                            </Form.Item>
                                                            <p className={s.desc}>
                                                                This information will be passed to our Klarity
                                                                onboarding team, who will follow up with you
                                                                via email regarding the next steps.
                                                            </p>
                                                        </div>
                                                    )}
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                );
                            }}
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export default SelfEPrescriptionModal;
