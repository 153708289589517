import React from 'react';
import { Form, Input, Modal, message, Checkbox } from 'antd';
import { createProspect, updateProspect } from 'api/prospect';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { formatDate } from 'utils/common';
import dayjs from 'dayjs';
// import useMyListedChannels from 'hooks/useMyListedChannels';
import { TConfigModule } from 'types/ai';

type Props = {
    show:boolean,
    item?:TConfigModule,
    moduleName?: string,
    onCannel?: (needRefresh?:boolean)=>void,
    onSuccess?: ()=>void
};

const AddAndUpdateAiConfigModule = (props: Props) => {
    const { show, item, moduleName, onCannel, onSuccess } = props;
    const [submiting, setSubmiting] = React.useState(false);
    const [form] = Form.useForm<TConfigModule>();
    const isEdit = !!item?.name;

    const onSubmit = async () => {
        try {
            const createTime = formatDate(dayjs());

            const formValues = await form.validateFields();

            const data:Partial<TConfigModule> = {
                ...formValues,
            };

            // setSubmiting(true);
            // if (isEdit) {
            //     data.id = item.id;
            //     const result = await updateProspect(item.id, data);
            //     if (result.error) {
            //         message.error(result.error);
            //     } else {
            //         message.success('Prospect info has updated');
            //         if (onSuccess) {
            //             onSuccess();
            //         }
            //     }
            // } else {
            //     data.createTime = createTime;
            //     const result = await createProspect(data);
            //     if (result.error) {
            //         //message.error(result.error);

            //     } else {
            //         message.success('Prospect has added');
            //         if (onSuccess) {
            //             onSuccess();
            //         }
            //     }
            // }
        } catch (e:any) {
            if (e.errorFields) {
                //form error
                return;
            }
            //const msg = e?.toString?.() || 'data error';
            //message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };

    React.useEffect(() => {
        if (item) {
            form.setFieldsValue({
                name: item.name,
                description: item.description,
            });
        }
    }, [item]);

    return (
        <Modal
            title={isEdit ? `Edit feature ${moduleName ? `for ${moduleName}` : ''}` : 'Add a new feature module'}
            closable
            className={commonS.modalFixHeightWrap}
            width="900px"
            onCancel={() => {
                onCannel?.();
            }}
            okText="Save"
            open={show}
            onOk={onSubmit}
            destroyOnClose
            okButtonProps={{ loading: submiting }}
        >
            <Form
                form={form}
                name="basic"
                className={commonS.formStyle1}
                initialValues={{ }}
                autoComplete="off"
                layout="vertical"
                scrollToFirstError
            >
                <div className={s.modalWrap}>
                    <h3>Feature information</h3>
                    <div className={s.des}>Section name and description are used to define a AI edit feature on the Kiwi web app</div>
                    <div className={s.row}>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '380px' }}
                                label="Feature name"
                                name={['name']}
                                rules={[
                                    { required: true, message: 'Feature name is required' },
                                ]}
                            >
                                <Input maxLength={50} placeholder="" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '700px' }}
                                label="Description"
                                name={['description']}
                                rules={[
                                ]}
                            >
                                <Input max={50} maxLength={100} placeholder="" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.rowItem}>
                            <Form.Item
                                style={{ width: '500px' }}
                                label=""
                                name={['img']}
                                rules={[
                                ]}
                            >
                                <Checkbox style={{ width: '90%' }}>Support img url</Checkbox>
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export default AddAndUpdateAiConfigModule;
