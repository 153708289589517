import { message, Form, Input, DatePicker, Button, Popover } from 'antd';
import React from 'react';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { FormType } from './types';
import { TCollaboratingPhysician } from 'types/common';
import FileUpload from 'components/form/FileUpload';
import FormValidationHelper from 'utils/validation';
import StateSelect from 'components/form/StateSelect';
import { formatDate, removeHMSInUTCTime } from 'utils/common';
import LicenseImg from 'components/LicenseImg';
import { REQUIRED_FIELD } from 'constants/common';
import dayjs from 'dayjs';

type Props = {
    value: Partial<TCollaboratingPhysician>;
    onAdd: (item: TCollaboratingPhysician) => void;
    name: string;
    existEmails: string[];
    onRemove: () => void;
    onEditStatusChange: (status: boolean) => void;
    existingState?: string[];
};

const EditCollaboratingPhysician = (props: Props) => {
    const { name, value, onAdd, existEmails, onRemove, onEditStatusChange, existingState } = props;
    const [form] = Form.useForm<FormType>();
    const [submiting, setSubmiting] = React.useState(false);
    const [isEdit, setIsEdit] = React.useState(true);

    const reset = () => {
        form.resetFields();
    };

    React.useEffect(() => {
        onEditStatusChange(isEdit);
    }, [isEdit]);

    const handleOk = async () => {
        try {
            setSubmiting(true);
            const formValues = await form.validateFields();

            let emailExist = false;
            if (existEmails.includes(formValues.email) && value?.email !== formValues.email) {
                emailExist = true;
            }
            if (emailExist) {
                form.setFields([{
                    name: 'email',
                    errors: ['Physician already exists.'],
                }]);
                return;
            }

            setIsEdit(false);
            const addData: TCollaboratingPhysician = {
                ...formValues,
                effectiveDate: formValues.effectiveDate?.format('YYYY-MM-DD') || '',
                expDate: formValues.expDate?.format('YYYY-MM-DD') || '',
                birthdayDate: formValues.birthdayDate?.format('YYYY-MM-DD') || '',
                physicianState: formValues.state,
            };
            console.log({
                addData,
            });
            onAdd?.(addData);
        } catch (e: any) {
            console.log(e);
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };

    React.useEffect(() => {
        if (value) {
            form.setFieldsValue({
                ...value,
                expDate: value.expDate ? dayjs(removeHMSInUTCTime(value.expDate)) : undefined,
                effectiveDate: value.effectiveDate ? dayjs(removeHMSInUTCTime(value.effectiveDate)) : undefined,
                birthdayDate: value.birthdayDate ? dayjs(removeHMSInUTCTime(value.birthdayDate)) : undefined,
            });
        }
    }, [value]);

    const onCancel = () => {
        if (value.email) {
            form.setFieldsValue({
                name: value.name,
                email: value.email,
            });
            setIsEdit(false);
        } else {
            onRemove();
        }
    };

    const currentBitrhday = form.getFieldValue('birthDate');

    return (
        <div className={s.wrap}>
            <div className={s.title}>
                <div className={s.name}>{name}</div>
                <div className={s.action}>
                    {
                        isEdit && (
                            <>
                                <Button className={s.btn} onClick={onCancel}>Cancel</Button>
                                <Button className={s.btn2} onClick={handleOk} type="primary">Save</Button>
                            </>
                        )
                    }
                    {
                        !isEdit && (
                            <>
                                <Button className={s.btn} onClick={onRemove}>Remove</Button>
                                <Button
                                    className={s.btn}
                                    onClick={() => {
                                        setIsEdit(true);
                                    }}
                                >Edit
                                </Button>
                            </>
                        )
                    }
                </div>
            </div>
            {
                !isEdit && (
                    <div className={s.formWrap}>
                        <div className={s.row}>
                            <div
                                style={{
                                    width: '300px',
                                }}
                                className={s.displayItem}
                            >
                                <div className={s.label}>Physician’s name</div>
                                <div className={s.value}>{form.getFieldValue('name')}</div>
                            </div>

                            <div
                                style={{
                                    width: '300px',
                                }}
                                className={s.displayItem}
                            >
                                <div className={s.label}>Personal phone number</div>
                                <div className={s.value}>{form.getFieldValue('tel')}</div>
                            </div>
                            <div
                                style={{
                                    width: '300px',
                                }}
                                className={s.displayItem}
                            >
                                <div className={s.label}>Email</div>
                                <div className={s.value}>{form.getFieldValue('email')}</div>
                            </div>
                        </div>
                        <div className={s.row}>
                            <div
                                style={{
                                    width: '300px',
                                }}
                                className={s.displayItem}
                            >
                                <div className={s.label}>Physician’s address</div>
                                <div className={s.value}>{form.getFieldValue('address')}</div>
                            </div>

                            <div
                                style={{
                                    width: '300px',
                                }}
                                className={s.displayItem}
                            >
                                <div className={s.label}>State</div>
                                <div className={s.value}>{form.getFieldValue('state')}</div>
                            </div>
                            <div
                                style={{
                                    width: '300px',
                                }}
                                className={s.displayItem}
                            >
                                <div className={s.label}>ZIP code</div>
                                <div className={s.value}>{form.getFieldValue('zip')}</div>
                            </div>
                        </div>
                        <div className={s.row}>
                            <div
                                style={{
                                    width: '300px',
                                }}
                                className={s.displayItem}
                            >
                                <div className={s.label}>Date of Birth</div>
                                <div className={s.value}>{formatDate(form.getFieldValue('birthdayDate'))}</div>
                            </div>
                        </div>
                        <div className={s.row}>
                            <div
                                style={{
                                    width: '300px',
                                }}
                                className={s.displayItem}
                            >
                                <div className={s.label}>Physician’s NPI number</div>
                                <div className={s.value}>{form.getFieldValue('npi')}</div>
                            </div>

                            <div
                                style={{
                                    width: '300px',
                                }}
                                className={s.displayItem}
                            >
                                <div className={s.label}>Licensed states</div>
                                <div className={s.value}>{form.getFieldValue('licenseState')}</div>
                            </div>
                            <div
                                style={{
                                    width: '180px',
                                }}
                                className={s.displayItem}
                            >
                                <div className={s.label}>Physician’s specialities</div>
                                <div className={s.value}>{form.getFieldValue('specialities')}</div>
                            </div>
                        </div>
                        <div className={s.row}>
                            <div
                                style={{
                                    width: '300px',
                                }}
                                className={s.displayItem}
                            >
                                <div className={s.label}>Effective date of the agreement</div>
                                <div className={s.value}>{formatDate(form.getFieldValue('effectiveDate'))}</div>
                            </div>
                            <div
                                style={{
                                    width: '300px',
                                }}
                                className={s.displayItem}
                            >
                                <div className={s.label}>Expiration date of the agreement</div>
                                <div className={s.value}>{form.getFieldValue('expDate') ? formatDate(form.getFieldValue('expDate')) : 'Null'}</div>
                            </div>
                        </div>
                        <div className={s.row}>
                            <div
                                style={{
                                    width: '80%',
                                }}
                                className={s.displayItem}
                            >
                                <div className={s.label}>Uploaded license</div>
                                <div className={s.value}>
                                    <LicenseImg src={form.getFieldValue('url')} />
                                </div>
                            </div>
                        </div>
                        <div className={s.row}>
                            <div
                                style={{
                                    width: '80%',
                                }}
                                className={s.displayItem}
                            >
                                <div className={s.label}>
                                    Collaborating physician’s DEA number
                                    <Popover
                                        placement="bottomLeft"
                                        content={<div className={s.toolWrap}>A DEA number is required for your collaborating physician to prescribe controlled medicines in Kiwi EHR when you purchase e-Prescription + EPCS for them. You can add the DEA number here or later in your UniProfile under Collaborating Physicians.</div>}
                                        trigger="hover"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                            <path d="M5.99582 6.98047C6.36261 6.20216 7.35231 5.64453 8.51566 5.64453C9.9913 5.64453 11.1875 6.54171 11.1875 7.64844C11.1875 8.58322 10.3341 9.36851 9.17977 9.58995C8.81747 9.65946 8.51566 9.9514 8.51566 10.3203M8.51562 12.3242H8.5223M14.5273 8.98438C14.5273 12.3046 11.8358 14.9961 8.51562 14.9961C5.19544 14.9961 2.50391 12.3046 2.50391 8.98438C2.50391 5.66419 5.19544 2.97266 8.51562 2.97266C11.8358 2.97266 14.5273 5.66419 14.5273 8.98438Z" stroke="#6B7280" strokeWidth="1.33594" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </Popover>
                                </div>
                                <div className={s.value}>{form.getFieldValue('deaNumber')}</div>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                isEdit && (
                    <div className={s.formWrap}>
                        <Form
                            form={form}
                            name="basic"
                            className={commonS.formStyle1}
                            initialValues={{}}
                            autoComplete="off"
                            layout="vertical"
                        >
                            <div className={s.row}>
                                <div className={s.rowItem} style={{ width: '30%' }}>
                                    <Form.Item
                                        label="Physician’s name"
                                        name="name"
                                        rules={[{ required: true, message: REQUIRED_FIELD }]}
                                    >
                                        <Input placeholder="Collaborating physician’s name" />
                                    </Form.Item>
                                </div>
                                <div className={s.rowItem} style={{ width: '30%' }}>
                                    <Form.Item
                                        label="Practice phone number"
                                        name="tel"
                                        rules={[
                                            { required: true, message: REQUIRED_FIELD },
                                            FormValidationHelper.validatePhoneNumber('Phone number must contain only number or -'),
                                        ]}
                                    >
                                        <Input maxLength={30} />
                                    </Form.Item>
                                </div>
                                <div className={s.rowItem} style={{ width: '30%' }}>
                                    <Form.Item
                                        label="Physician’s email"
                                        name="email"
                                        rules={[
                                            { required: true, message: REQUIRED_FIELD },
                                            { type: 'email', message: 'Email is invalid' },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.rowItem} style={{ width: '30%' }}>
                                    <Form.Item
                                        label="Physician’s address"
                                        name="address"
                                        rules={[{ required: true, message: REQUIRED_FIELD }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </div>
                                <div className={s.rowItem} style={{ width: '30%' }}>
                                    <Form.Item
                                        label="State"
                                        name="state"
                                        rules={[{ required: true, message: REQUIRED_FIELD }]}
                                    >
                                        <StateSelect disableValues={existingState} />
                                    </Form.Item>
                                </div>
                                <div className={s.rowItem} style={{ width: '30%' }}>
                                    <Form.Item
                                        label="ZIP code"
                                        name="zip"
                                        rules={[{ required: true, message: REQUIRED_FIELD }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.rowItem} style={{ width: '30%' }}>
                                    <Form.Item
                                        label="Date of Birth"
                                        name={['birthdayDate']}
                                        rules={[{ required: true, message: REQUIRED_FIELD }]}
                                    >
                                        <DatePicker
                                            disabledDate={(current) => {
                                                const day18Ago = dayjs().subtract(18, 'year');
                                                return current.valueOf() > day18Ago.valueOf();
                                            }}
                                            style={{ width: '100%' }}
                                            defaultPickerValue={currentBitrhday ? undefined : dayjs().subtract(18, 'year')}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.rowItem} style={{ width: '30%' }}>
                                    <Form.Item
                                        label="Physician’s NPI number"
                                        name="npi"
                                        validateFirst
                                        rules={[
                                            {
                                                required: true, message: REQUIRED_FIELD
                                            },
                                            FormValidationHelper.validateLicenseNumber('The NPI must contain only letters or numbers'),
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </div>
                                <div className={s.rowItem} style={{ width: '30%' }}>
                                    <Form.Item
                                        label="Licensed states"
                                        name={['licenseState']}
                                        rules={[{ required: true, message: REQUIRED_FIELD }]}
                                    >
                                        <StateSelect />
                                    </Form.Item>
                                </div>
                                <div className={s.rowItem} style={{ width: '30%' }}>
                                    <Form.Item
                                        label="Physician’s specialities"
                                        name="specialities"
                                        rules={[{ required: true, message: REQUIRED_FIELD }]}
                                    >
                                        <Input placeholder="Specialities and seperate with “,”" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.rowItem} style={{ width: '30%' }}>
                                    <Form.Item
                                        label="Effective date of the agreement"
                                        name={['effectiveDate']}
                                        rules={[{ required: true, message: REQUIRED_FIELD }]}
                                    >
                                        <DatePicker style={{ width: '100%' }} />
                                    </Form.Item>
                                </div>
                                <div className={s.rowItem} style={{ width: '30%' }}>
                                    <Form.Item
                                        label="Expiration date of the agreement"
                                        name={['expDate']}
                                    >
                                        <DatePicker
                                            disabledDate={(date) => {
                                                const now = (new Date()).getTime();
                                                if (!date) {
                                                    return false;
                                                }
                                                return date?.valueOf() < now;
                                            }}
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.rowItem}>
                                    <Form.Item
                                        style={{ width: '500px' }}
                                        label="Agreement upload"
                                        name={['url']}
                                        rules={[{ required: true, message: REQUIRED_FIELD }]}
                                    >
                                        <FileUpload />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.rowItem} style={{ width: '30%' }}>
                                    <Form.Item
                                        label={
                                            <div className={s.labelTitle}>
                                                Collaborating physician’s DEA number
                                                <Popover
                                                    placement="bottomLeft"
                                                    content={<div className={s.toolWrap}>A DEA number is required for your collaborating physician to prescribe controlled medicines in Kiwi EHR when you purchase e-Prescription + EPCS for them. You can add the DEA number here or later in your UniProfile under Collaborating Physicians.</div>}
                                                    trigger="hover"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                                        <path d="M5.99582 6.98047C6.36261 6.20216 7.35231 5.64453 8.51566 5.64453C9.9913 5.64453 11.1875 6.54171 11.1875 7.64844C11.1875 8.58322 10.3341 9.36851 9.17977 9.58995C8.81747 9.65946 8.51566 9.9514 8.51566 10.3203M8.51562 12.3242H8.5223M14.5273 8.98438C14.5273 12.3046 11.8358 14.9961 8.51562 14.9961C5.19544 14.9961 2.50391 12.3046 2.50391 8.98438C2.50391 5.66419 5.19544 2.97266 8.51562 2.97266C11.8358 2.97266 14.5273 5.66419 14.5273 8.98438Z" stroke="#6B7280" strokeWidth="1.33594" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </Popover>
                                            </div>
                                        }
                                        name="deaNumber"
                                        validateFirst
                                    >
                                        <Input />
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                    </div>
                )
            }
            {isEdit && (
                <div className={s.footerBtnWrap}>
                    <Button className={s.btn} onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button className={s.btn2} onClick={handleOk} type="primary">
                        Save
                    </Button>
                </div>
            )}

        </div>
    );
};

export default EditCollaboratingPhysician;
