import React from 'react';
import ReviewIntroPic from 'assets/survey/reviewIntroPic.svg';
import s from './s.module.less';
import { isFreeUser } from 'utils/provider';

interface IProps {
    hideIndex: boolean;
}

const ReviewIntro = ({
    hideIndex,
}: IProps) => {
    const isFree = isFreeUser();
    return (
        <div className={s.introWrap}>
            <div className={s.left}>
                { !hideIndex && <div className={s.stepText}>Step 3</div>}
                <div className={s.title}>Set up automated review invitations { isFree && <span className={s.freeTips}>Paid-users only</span>}</div>
                <div className={s.desc}>In this step, you can set up automated review invitations to maximize your positive feedback. These invitations will be sent specifically to patients who have provided high ratings in the survey. Customize invitation recipients and select preferred review channels to maximize the impact of your feedback collection process.</div>
                <div className={s.tip}>
                    <div className={s.tipIcon} />
                    <div className={s.tipContent}>Note: The number of review invitations you can send varies depending on your plan. However, there is a cap on the allowed number of invitation emails sent per week to be compliant with channel platform requirements.</div>
                </div>
            </div>
            <div className={s.right}>
                <img src={ReviewIntroPic} />
            </div>
        </div>
    );
};

export default ReviewIntro;
