// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-modules__title--_ziBh {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 140% */
  letter-spacing: -0.5px;
  margin-bottom: 16px;
  margin-top: 48px;
}
.s-modules__list--GkEyT {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 24px;
  grid-column-gap: 32px;
}
.s-modules__item--L3Iyf {
  border-radius: 10px;
  border: 1px solid #EAECF0;
  background: #FFF;
  padding: 10px 0 10px 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.s-modules__subTitle--pvZBW {
  color: var(--gray-900);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 137.5% */
  letter-spacing: -0.16px;
}
.s-modules__description--By46m {
  color: #6B7280;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
  margin-top: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/EhrProviderHomePage/components/Features/s.modules.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,SAAS;EAAT,sBAAA;EACA,mBAAA;EACA,gBAAA;AAEF;AACA;EACE,aAAA;EACA,qCAAA;EACA,kBAAA;EACA,qBAAA;AACF;AAEA;EACE,mBAAA;EACA,yBAAA;EACA,gBAAA;EACA,yBAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAAF;AAGA;EACE,sBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EADA,WAAW;EAEX,uBAAA;AAAF;AAGA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EADA,SAAS;EAET,gBAAA;AAAF","sourcesContent":[".title {\n  color: #000;\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 28px; /* 140% */\n  letter-spacing: -0.5px;\n  margin-bottom: 16px;\n  margin-top: 48px;\n}\n\n.list {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-row-gap: 24px;\n  grid-column-gap: 32px;\n}\n\n.item {\n  border-radius: 10px;\n  border: 1px solid #EAECF0;\n  background: #FFF;\n  padding: 10px 0 10px 24px;\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.subTitle {\n  color: var(--gray-900);\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 22px; /* 137.5% */\n  letter-spacing: -0.16px;\n}\n\n.description {\n  color: #6B7280;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 18px; /* 150% */\n  margin-top: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `s-modules__title--_ziBh`,
	"list": `s-modules__list--GkEyT`,
	"item": `s-modules__item--L3Iyf`,
	"subTitle": `s-modules__subTitle--pvZBW`,
	"description": `s-modules__description--By46m`
};
export default ___CSS_LOADER_EXPORT___;
