import React from 'react';
import s from './s.module.less';
import { WelcomeSteps } from 'constants/survey';

const Introduction = () => {
    return (
        <div className={s.wrap}>
            <div className={s.left}>
                <div className={s.title}>{'Enhancing your practice\'s reputation is now easier than ever'}</div>
                <div className={s.subTitle}>In just three simple steps, you can gather insightful feedback, engage with your patients, and build a stronger online presence.</div>
            </div>
            <div className={s.right}>
                <div className={s.content}>
                    {
                        WelcomeSteps.map((step, index) => {
                            return (
                                <div key={index} className={s.step}>
                                    <div className={s.stepInner}>
                                        <div className={s.stepNumber}>Step {index + 1}</div>
                                        <div className={s.textContent}>
                                            <div className={s.title}>{step.title}</div>
                                            <div className={s.desc}>{step.desc}</div>
                                        </div>
                                        <img className={s.stepImage} src={step.image} />
                                    </div>
                                    <div className={s.border}>
                                        <div className={s.borderLine} />
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default Introduction;
