import React, { useCallback, useState } from 'react';
import s from './s.module.less';
import { Button, Input, Select } from 'antd';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import countries from 'constants/country';
import { emailRegex } from 'pages/SalesProviderExp/constant';
import { IEHRCheckoutCartItemInput, IEHRCheckoutInput } from 'types/ehr';
import { shoppingCartCheckout } from 'api/ehr';

interface IProps {
    defaultErrorMessage: string;
    cartItems?: IEHRCheckoutCartItemInput[];
    onNext: () => void;
    setMainErrorMessage: (v: string) => void;
    setShowErrorMessageBox: (v: boolean) => void;
}

const filedName = {
    cardNumber: 'cardNumber',
    cardExpiry: 'cardExpiry',
    cardCvc: 'cardCvc',
    email: 'email',
    country: 'country',
    zipCode: 'zipCode',
};

const PaymentForm = ({
    defaultErrorMessage,
    cartItems,
    onNext,
    setMainErrorMessage,
    setShowErrorMessageBox,
}: IProps) => {
    const stripe = useStripe();
    const elements = useElements();
    const [fieldsVerifyResult, setFieldsVerifyResult] = useState({
        [filedName.cardNumber]: {
            error: true,
            message: 'Card number is required',
        },
        [filedName.cardExpiry]: {
            error: true,
            message: 'Card expiry date is required',
        },
        [filedName.cardCvc]: {
            error: true,
            message: 'Card CVC is required',
        },
        [filedName.email]: {
            error: true,
            message: 'Please enter the correct email',
        },
        [filedName.zipCode]: {
            error: true,
            message: 'This filed is required',
        },
        [filedName.country]: {
            error: false,
            message: 'This filed is required',
        },
    });
    const [showErrors, setShowErrors] = useState(false);
    const [email, setEmail] = useState('');
    const [country, setCountry] = useState('US');
    const [zipCode, setZipCode] = useState();
    const [paymentLoading, setPaymentLoading] = useState(false);

    const verifyAllField = useCallback(() => {
        return Object.values(fieldsVerifyResult).every((e) => !e.error);
    }, [fieldsVerifyResult]);

    const handleSubmit = useCallback(async (event: any) => {
        event?.preventDefault();

        setShowErrors(true);

        if (verifyAllField() && cartItems && cartItems.length > 0) {
            if (!stripe || !elements) {
                // Stripe.js has not loaded yet.
                return;
            }

            setPaymentLoading(true);

            const cardElement = elements.getElement(CardNumberElement);
            const res = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });

            if (res.error) {
                setMainErrorMessage(res.error.message || defaultErrorMessage);
                setShowErrorMessageBox(true);
                console.error(res.error);
            } else {
                const paymentSubmitData:IEHRCheckoutInput = {
                    email,
                    paymentMethodId: res.paymentMethod.id,
                    country,
                    postalCode: zipCode,
                    newCustomerOption: true,
                    ehrAddonShoppingCartReqList: cartItems,
                };
                try {
                    const resp = await shoppingCartCheckout(paymentSubmitData);

                    if (resp && !resp.error) {
                        onNext?.();
                    } else {
                        setMainErrorMessage(resp.error || defaultErrorMessage);
                        setShowErrorMessageBox(true);
                        setPaymentLoading(false);
                    }
                } catch (e) {
                    console.error(e);
                    setPaymentLoading(false);
                }
            }
        }
    }, [verifyAllField, cartItems, stripe, elements, setMainErrorMessage, defaultErrorMessage, setShowErrorMessageBox, email, country, zipCode, onNext]);

    const handleInputChange = useCallback((e: any, t: string) => {
        if (t === filedName.cardCvc || t === filedName.cardExpiry || t === filedName.cardNumber) {
            // e.complete && e.error (e.error.message)
            const correct = e.complete && !e.error;
            setFieldsVerifyResult({
                ...fieldsVerifyResult,
                [t]: {
                    error: !correct,
                    message: correct ? '' : e.error?.message,
                },
            });
        } else if (t === filedName.country) {
            setCountry(e);
            setFieldsVerifyResult({
                ...fieldsVerifyResult,
                [t]: {
                    error: !e,
                    message: e ? '' : 'This filed is required',
                },
            });
        } else if (t === filedName.email) {
            setEmail(e.target.value);
            const emailVerify = emailRegex.test(e.target.value);
            setFieldsVerifyResult({
                ...fieldsVerifyResult,
                [t]: {
                    error: !emailVerify,
                    message: emailVerify ? '' : 'Please enter the correct email',
                },
            });
        } else {
            if (t === filedName.zipCode) {
                setZipCode(e.target.value);
            }
            setFieldsVerifyResult({
                ...fieldsVerifyResult,
                [t]: {
                    error: !e?.target?.value,
                    message: e?.target?.value ? '' : 'This filed is required',
                },
            });
        }
    }, [fieldsVerifyResult]);

    return (
        <div>
            <form className={s.formBox}>
                <div className={s.formRow}>
                    <div className={s.formItem}>
                        <div className={s.label}>Email</div>
                        <div className={s.inputBox}>
                            <Input value={email} onChange={(e) => handleInputChange(e, filedName.email)} />
                        </div>
                        {
                            showErrors && fieldsVerifyResult[filedName.email].error && <div className={s.error}>{fieldsVerifyResult[filedName.email].message}</div>
                        }
                    </div>
                </div>
                <div className={s.formRow}>
                    <div className={s.formItem}>
                        <div className={s.label}>Card Number</div>
                        <div className={s.inputBox}>
                            <div className={s.inputBoxInner}>
                                <CardNumberElement className={`${s.input} ${s.inputWithoutBorder}`} onChange={(e) => handleInputChange(e, filedName.cardNumber)} />
                                <div className={s.cardIconList}>
                                    <div className={`${s.card} ${s.mastercard}`} />
                                    <div className={`${s.card} ${s.amex}`} />
                                    <div className={`${s.card} ${s.discover}`} />
                                    <div className={`${s.card} ${s.visa}`} />
                                </div>
                            </div>
                        </div>
                        {
                            showErrors && fieldsVerifyResult[filedName.cardNumber].error && <div className={s.error}>{fieldsVerifyResult[filedName.cardNumber].message}</div>
                        }
                    </div>
                </div>
                <div className={s.formRow}>
                    <div className={s.formItem}>
                        <div className={s.label}>Expiration</div>
                        <div className={s.inputBox}>
                            <CardExpiryElement className={s.input} onChange={(e) => handleInputChange(e, filedName.cardExpiry)} />
                        </div>
                        {
                            showErrors && fieldsVerifyResult[filedName.cardExpiry].error && <div className={s.error}>{fieldsVerifyResult[filedName.cardExpiry].message}</div>
                        }
                    </div>
                    <div className={s.formItem}>
                        <div className={s.label}>CVC</div>
                        <div className={s.inputBox}>
                            <CardCvcElement className={s.input} onChange={(e) => handleInputChange(e, filedName.cardCvc)} />
                        </div>
                        {
                            showErrors && fieldsVerifyResult[filedName.cardCvc].error && <div className={s.error}>{fieldsVerifyResult[filedName.cardCvc].message}</div>
                        }
                    </div>
                </div>
                <div className={s.formRow}>
                    <div className={s.formItem}>
                        <div className={s.label}>Country</div>
                        <div className={s.inputBox}>
                            <Select
                                showSearch
                                filterOption={(inputValue, option) =>
                                    option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                }
                                value={country}
                                options={countries}
                                className={s.select}
                                onChange={(e) => handleInputChange(e, filedName.country)}
                            />
                        </div>
                        {
                            showErrors && fieldsVerifyResult[filedName.country].error && <div className={s.error}>{fieldsVerifyResult[filedName.country].message}</div>
                        }
                    </div>
                    <div className={s.formItem}>
                        <div className={s.label}>Postal code</div>
                        <div className={s.inputBox}>
                            <Input value={zipCode} type="number" onChange={(e) => handleInputChange(e, filedName.zipCode)} />
                        </div>
                        {
                            showErrors && fieldsVerifyResult[filedName.zipCode].error && <div className={s.error}>{fieldsVerifyResult[filedName.zipCode].message}</div>
                        }
                    </div>
                </div>
                <Button className={s.checkoutButton} type="primary" onClick={handleSubmit} loading={paymentLoading}>Check out</Button>
            </form>
        </div>
    );
};

export default PaymentForm;
