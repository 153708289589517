import React, { useState } from 'react';
import { Modal, Form, Button, message, Input, Select, Spin } from 'antd';
import commonS from 'styles/common.module.less';
import s from './s.module.less';
import { generateSalesContractLink } from 'api/operation';
import { FormInterface, FormFieldEnum } from './type';
import { IGenerateSaleLinkInput } from 'types/operation';
import { NOT_SET, REQUIRED_FIELD, URL_LIMIT } from 'constants/common';
import useOpsSubscriptionPlan from 'hooks/useOpsSubscriptionPlan';
import HtmlContentInput from 'components/form/HtmlContentInput';
import FormValidationHelper from 'utils/validation';
import copy from 'copy-to-clipboard';
import useOpsUserInfo from 'hooks/useOpsUserInfo';
import { sanitizeHtml } from 'utils/common';

type Props = {
    show?: boolean;
    onCancel?: () => void;
    onSuccess?: () => void;
};

const kiwiDefaultMessage = '<p>Thank you for choosing to explore the possibilities with Kiwi. We\'re excited to welcome you aboard and guide you through the seamless process of activating your membership. Based on our recent conversation, we\'ve tailored a plan just for you, aimed at enhancing your practice and connecting you with more patients.</p><p>Right here, you\'ll finalize your membership, pay for your plan, agree to our collaborative terms, and set up your platform account.</p><p>Let\'s get started on this journey together!</p>';

const klarityDefaultMessage = '<p>Thank you for choosing to explore the possibilities with Klarity. We\'re excited to welcome you aboard and guide you through the seamless process of activating your membership. Based on our recent conversation, we\'ve tailored a plan just for you, aimed at enhancing your practice and connecting you with more patients.</p><p>Right here, you\'ll review the platform agreements and set up your platform account.</p><p>Let\'s get started on this journey together!</p>';

const GenerateLink = ({ show, onCancel, onSuccess }: Props) => {
    const [items] = useOpsSubscriptionPlan();
    const [submiting, setSubmiting] = useState(false);
    const [clientName, setClientName] = useState('');
    const [form] = Form.useForm<FormInterface>();
    const [sender, loading] = useOpsUserInfo();

    const planOptions = [
        {
            key: 'Klarity',
            value: 'Klarity',
        },
        {
            key: 'Growth',
            value: 'Growth',
        },
        {
            key: 'Essential',
            value: 'Essential',
        },
    ];

    const cycleOptions = [
        {
            label: 'Monthly',
            value: 'Monthly billing',
        },
        {
            label: 'Quarterly',
            value: 'Quarterly billing',
        },
        {
            label: 'Yearly',
            value: 'Yearly billing',
        },
    ];

    const handleFieldsChange = (changedFields) => {
        if (changedFields[0]?.name?.[0] === 'payPlanName') {
            if (changedFields[0]?.value === 'Klarity') {
                form.setFieldValue(FormFieldEnum.MESSAGE, klarityDefaultMessage);
            } else {
                form.setFieldValue(FormFieldEnum.MESSAGE, kiwiDefaultMessage);
            }
        }
    };

    const onSave = async () => {
        setSubmiting(true);
        try {
            const formValues = await form.validateFields();
            let targetPlan;
            if (formValues.payPlanName === 'Klarity') {
                targetPlan = items.find(
                    (item) => item.type === formValues.payPlanName,
                );
            } else {
                targetPlan = items.find(
                    (item) =>
                        item.type === formValues.payPlanName &&
                        item.subType === formValues.payPlanCycle,
                );
            }
            if (!targetPlan) {
                message.error('Plan not found');
                return;
            }

            const htmlMessage = sanitizeHtml(formValues.message || '');
            const params: IGenerateSaleLinkInput = {
                fullName: formValues.fullName,
                inviteEmail: formValues.inviteEmail,
                phoneNumber: formValues.phoneNumber,
                couponId: formValues.couponId,
                promotionCode: formValues.promotionCode,
                message: htmlMessage,
                payPlanId: targetPlan.id,
                onboardingCallBookLink: '',
                presentationEmbeddedLink: formValues.presentationEmbeddedLink,
            };
            const res = await generateSalesContractLink(params);
            if (!res.error) {
                message.success('Generate link successfully');
                onSuccess?.();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setSubmiting(false);
        }
    };

    React.useEffect(() => {
        if (!show) {
            form.resetFields();
            setClientName('');
        } else {
            form.setFieldsValue(
                {
                    [FormFieldEnum.MESSAGE]: klarityDefaultMessage,
                },
            );
        }
    }, [show, form]);

    const directBookingLink = sender?.bookingLinkUrl || '';

    return (
        <Modal
            title="Generate a link"
            closable
            className={commonS.modalFixHeightWrap}
            width="700px"
            destroyOnClose
            onCancel={onCancel}
            okText="Save"
            open
            footer={
                <div>
                    <Button loading={submiting} type="primary" onClick={onSave}>
                        Generate and send
                    </Button>
                </div>
            }
        >
            <div className={s.wrap}>
                <Form
                    form={form}
                    className={commonS.formStyle1}
                    initialValues={{ [FormFieldEnum.PAY_NAME]: 'Klarity', [FormFieldEnum.PAY_CYCLE]: 'Quarterly billing' }}
                    autoComplete="off"
                    layout="vertical"
                    scrollToFirstError
                    onFieldsChange={handleFieldsChange}
                >
                    <Spin spinning={loading}>
                        <div className={s.module}>
                            <h3>Sender</h3>
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <div className={s.label}>
                                        First name
                                    </div>
                                    <div className={s.value}>
                                        {sender?.firstName || NOT_SET}
                                    </div>
                                </div>
                                <div className={s.displayItem}>
                                    <div className={s.label}>
                                        Last name
                                    </div>
                                    <div className={s.value}>
                                        {sender?.lastName || NOT_SET}
                                    </div>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <div className={s.label}>
                                        Kiwi email
                                    </div>
                                    <div className={s.value}>
                                        {sender?.gmail || NOT_SET}
                                    </div>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <div className={s.label}>
                                        Title
                                    </div>
                                    <div className={s.value}>
                                        {sender?.title || NOT_SET}
                                    </div>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.displayItem}>
                                    <div className={s.label}>
                                        Phone number
                                    </div>
                                    <div className={s.value}>
                                        {sender?.tel || NOT_SET}
                                    </div>
                                </div>
                            </div>
                            <div className={s.row}>
                                <div className={s.displayItem} style={{ width: '100%' }}>
                                    <div className={s.label}>
                                        Direct booking link
                                    </div>
                                    <div className={s.value}>
                                        {
                                            directBookingLink &&
                                            <>
                                                <a href={directBookingLink || ''}>
                                                    {directBookingLink}
                                                </a>
                                                <span
                                                    style={{
                                                        marginLeft: 8,
                                                    }}
                                                    onClick={() => {
                                                        copy(directBookingLink || '', { format: 'text/plain' });
                                                        message.success('Copied to the clipboard');
                                                    }}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                        <path d="M6.66634 5.83333V12.5C6.66634 13.4205 7.41253 14.1667 8.33301 14.1667H13.333M6.66634 5.83333V4.16667C6.66634 3.24619 7.41253 2.5 8.33301 2.5H12.1545C12.3755 2.5 12.5875 2.5878 12.7438 2.74408L16.4223 6.42259C16.5785 6.57887 16.6663 6.79083 16.6663 7.01184V12.5C16.6663 13.4205 15.9201 14.1667 14.9997 14.1667H13.333M6.66634 5.83333H5.33301C4.22844 5.83333 3.33301 6.72876 3.33301 7.83333V15.8333C3.33301 16.7538 4.0792 17.5 4.99967 17.5H11.333C12.4376 17.5 13.333 16.6046 13.333 15.5V14.1667" stroke="#00816B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </span>
                                            </>
                                        }

                                        {
                                            !directBookingLink &&
                                            NOT_SET
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Spin>
                    <div className={s.module}>
                        <h3>Client</h3>
                        <div className={s.row}>
                            <div className={s.rowItem}>
                                <Form.Item
                                    style={{ width: '320px' }}
                                    label="Name"
                                    name={[FormFieldEnum.FULLNAME]}
                                    validateFirst
                                    rules={[
                                        { required: true, message: REQUIRED_FIELD },
                                    ]}
                                >
                                    <Input
                                        onChange={(e) => {
                                            setClientName(e.target.value);
                                        }}
                                        maxLength={100}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className={s.row}>
                            <div className={s.rowItem}>
                                <Form.Item
                                    style={{ width: '320px' }}
                                    label="Email"
                                    name={[FormFieldEnum.INVITE_EMAIL]}
                                    validateFirst
                                    rules={[
                                        { required: true, message: REQUIRED_FIELD },
                                        { type: 'email', message: 'Email is invalid' },
                                    ]}
                                >
                                    <Input maxLength={100} />
                                </Form.Item>
                            </div>
                        </div>
                        <div className={s.row}>
                            <div className={s.rowItem}>
                                <Form.Item
                                    style={{ width: '320px' }}
                                    label="Phone number"
                                    name={[FormFieldEnum.PHONE_NUMBER]}
                                    validateFirst
                                    rules={[
                                        { required: true, message: REQUIRED_FIELD },
                                    ]}
                                >
                                    <Input maxLength={30} />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className={s.module}>
                        <h3>Product</h3>
                        <div className={s.row}>
                            <div className={s.rowItem}>
                                <Form.Item
                                    style={{ width: '320px' }}
                                    label="Plan"
                                    name={[FormFieldEnum.PAY_NAME]}
                                    validateFirst
                                    rules={[
                                        { required: true, message: REQUIRED_FIELD },
                                    ]}
                                >
                                    <Select>
                                        {planOptions.map((item) => (
                                            <Select.Option key={item.key} value={item.value}>
                                                {item.key}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>

                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) => {
                                const type = getFieldValue(FormFieldEnum.PAY_NAME);
                                if (type === 'Klarity') {
                                    return '';
                                }
                                return (
                                    <div className={s.row}>
                                        <div className={s.rowItem}>
                                            <Form.Item
                                                style={{ width: '320px' }}
                                                label="Billing cycle"
                                                name={[FormFieldEnum.PAY_CYCLE]}
                                                validateFirst
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: REQUIRED_FIELD,
                                                    },
                                                ]}
                                            >
                                                <Select>
                                                    {cycleOptions
                                                        .map((item) => (
                                                            <Select.Option
                                                                key={item.label}
                                                                value={item.value}
                                                            >
                                                                {item.label}
                                                            </Select.Option>
                                                        ))}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                );
                            }}

                        </Form.Item>

                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) => {
                                const type = getFieldValue(FormFieldEnum.PAY_NAME);
                                if (type === 'Klarity') {
                                    return '';
                                }
                                return (
                                    <div className={s.row}>
                                        <div className={s.rowItem}>
                                            <Form.Item
                                                style={{ width: '320px' }}
                                                label="Promotion code"
                                                name={[
                                                    FormFieldEnum.PROMOTION_CODE,
                                                ]}
                                                validateFirst
                                            >
                                                <Input maxLength={20} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                );
                            }}
                        </Form.Item>

                        <div className={s.row}>
                            <div className={s.rowItem}>
                                <Form.Item
                                    style={{ width: '420px' }}
                                    label="Presentation deck"
                                    name={[FormFieldEnum.PRESENTATION_EMBEDDEDLINK]}
                                    validateFirst
                                    rules={[
                                        FormValidationHelper.validateLink('Please enter a valid link, starting with http:// or https://'),
                                    ]}
                                >
                                    <Input
                                        minLength={40}
                                        maxLength={URL_LIMIT}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </div>

                    <div className={s.module}>
                        <h3>Message</h3>
                        <div className={s.row}>
                            <div className={s.rowItem} style={{ width: '100%' }}>
                                <Form.Item
                                    label="Custom message"
                                    name={[FormFieldEnum.MESSAGE]}
                                    validateFirst
                                    rules={[
                                        { required: true, message: REQUIRED_FIELD },
                                    ]}
                                >
                                    <HtmlContentInput
                                        header={<div>👋 Hello {clientName}</div>}
                                        footer={
                                            <div>
                                                <div>Warm regards</div>
                                                <div>{sender?.firstName} {sender?.lastName}</div>
                                                <div>{sender?.title}</div>
                                            </div>}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className={s.tips}>
                            <div className={s.left}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M6.25706 1.09858C7.02167 -0.260724 8.97875 -0.260725 9.74336 1.09858L15.3237 11.0191C16.0736 12.3523 15.1102 13.9996 13.5805 13.9996H2.4199C0.890251 13.9996 -0.0731769 12.3523 0.676753 11.0191L6.25706 1.09858ZM9.00012 10.9998C9.00012 11.552 8.55241 11.9998 8.00012 11.9998C7.44784 11.9998 7.00012 11.552 7.00012 10.9998C7.00012 10.4475 7.44784 9.99976 8.00012 9.99976C8.55241 9.99976 9.00012 10.4475 9.00012 10.9998ZM8.00012 2.99976C7.44784 2.99976 7.00012 3.44747 7.00012 3.99976V6.99976C7.00012 7.55204 7.44784 7.99976 8.00012 7.99976C8.55241 7.99976 9.00012 7.55204 9.00012 6.99976V3.99976C9.00012 3.44747 8.55241 2.99976 8.00012 2.99976Z" fill="#FBBF24" />
                                </svg>
                            </div>
                            <div className={s.right}>
                                <div className={s.title}>
                                    Review before generate and send
                                </div>
                                <div className={s.content}>
                                    Please double-check all details. Pressing 'Generated and send' immediately dispatches the email with the contract link to the client.
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className={s.module}>
                        <h3>Onboarding</h3>
                        <div className={s.row}>
                            <div className={s.rowItem}>
                                <Form.Item
                                    style={{ width: '420px' }}
                                    label="Booking link"
                                    name={[FormFieldEnum.ONBOARDING_CALL_BOOKLINK]}
                                    validateFirst
                                    rules={[
                                        { required: true, message: REQUIRED_FIELD },
                                        FormValidationHelper.validateLink('Please enter a valid link, starting with http:// or https://'),
                                    ]}
                                >
                                    <Input
                                        onChange={(e) => {
                                            setClientName(e.target.value);
                                        }}
                                        minLength={40}
                                        maxLength={URL_LIMIT}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </div> */}
                </Form>
            </div>
        </Modal>
    );
};

export default GenerateLink;
