import React, { useCallback, useMemo } from 'react';
import { Button, Tooltip } from 'antd';
import s from './s.module.less';
import WatchVideo from '../WatchVideo';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import { calculateRemainingDays } from 'utils/provider';
import { ClockCircleOutlined } from '@ant-design/icons';
import { EStepTypes } from 'types/common';
import useGetStepInfo from 'hooks/useGetStepInfo';
import Alert from 'components/Alert';
import { UNLOCK_YOURY_KIWI_BENEFITS } from 'constants/common';
import TimeRemaining from '../TimeRemaining';
import { EProfileStatus } from 'types/channel';

const InitStep: React.FC<{
    firstAddOnPaidAt?: string;
    addOnPaid?: boolean;
    firstPracticeLaunchAt?: string;
    practiceFrontCompleted?: boolean;
    uniprofileApproveCompleted?: boolean;
    className: string;
    flag: boolean;
    isEhrSetUpCompleted: boolean;
    uniprofileStatus?: EProfileStatus,
}> = ({ className, flag, isEhrSetUpCompleted, firstAddOnPaidAt, addOnPaid, uniprofileStatus, uniprofileApproveCompleted, practiceFrontCompleted, firstPracticeLaunchAt }) => {
    const [stepInfo, stepInfoLoading] = useGetStepInfo(EStepTypes.KLARITY_STARTER_SET_UP);
    // if (!stepInfo || !stepInfo.currentStep) {
    //     navigate(`/${PATH.KIWI_KNOWLEDEG}`);
    // } else {
    //     navigate(`/${PATH.KLARITY_PLAN_PROCESS}`);
    // }
    const navigate = useNavigate();

    const handleClick = useCallback(() => {
        if (!stepInfo || !stepInfo.currentStep) {
            navigate(`/${PATH.KIWI_KNOWLEDEG}`);
        } else if ((stepInfo && Number(stepInfo.currentStep) < 2) || localStorage.getItem(UNLOCK_YOURY_KIWI_BENEFITS) === 'true') {
            navigate(`/${PATH.KLARITY_PLAN_PROCESS}`);
        } else if (stepInfo && stepInfo.currentStep === '2'){
            navigate(`${PATH.DASHBOARD}/${PATH.PRACTICE_FRONT}`);
        }
    }, [navigate, stepInfo]);

    const timeLeftRender = useMemo(() => {
        const remainingDays = calculateRemainingDays();
        if (!uniprofileStatus || uniprofileStatus === EProfileStatus.REQUIRED) {
            if (!remainingDays) {
                return (
                    <div className={s.timeLeft}>
                        <ClockCircleOutlined style={{color: '#9CA3AF'}} />
                        <div className={s.timeText}>Time left: 3 days</div>
                    </div>
                )
            } else if (remainingDays > 0) {
                return (
                    <div className={s.timeLeft}>
                        <div className={s.clockIcon}></div>
                        <div className={s.timeText}>Time left: {remainingDays} days</div>
                    </div>
                )
            } else {
                return (
                    <div className={s.timeLeft}>
                        <div className={s.warningIcon}></div>
                        <div className={s.timeText}>Overdue by {remainingDays} day</div>
                    </div>
                )
            }
        }

        if (uniprofileStatus === EProfileStatus.APPROVED) {
            return (
                <div className={s.timeLeft}>
                    <div className={s.checkIcon}></div>
                    <div className={s.timeText}>Completed on: 09-07-24</div>
                </div>
            )
        }
    }, [uniprofileStatus]);
    return (
        <div className={`${s.wrap} ${className}`}>
            <div className={s.header}>
                <h2 className={s.h2}>Explore Kiwi Health</h2>
                {
                    addOnPaid && (
                        <div className={s.statusNtime}>
                            <TimeRemaining completeTime={firstPracticeLaunchAt} isCompleted={practiceFrontCompleted} startTime={firstAddOnPaidAt} />
                        </div>
                    )
                }
            </div>
            {/* {
                klarityPlanOnboardingDone ? (
                    <div className={s.text}>
                        <p>Discover how Kiwi Health can boost your practice! Get listed on 40+ top directories, attract more patients in just 10 days, and simplify your practice management.</p>
                        <p>You can also browse <span className={s.link} onClick={handleGotoChannelsPage}>Channels</span> and customize your <span className={s.link} onClick={handleGotoPFPage}>Practice front</span> to expand your reach.</p>
                    </div>
                ) : (
                    <>
                        <div className={s.text}>Discover how Kiwi Health can boost your practice! Get listed on 40+ top directories, attract more patients in just 10 days, and simplify your practice management.</div>
                        <Button type="primary" onClick={handleClick} loading={stepInfoLoading}>Explore Kiwi Health</Button>
                    </>
                )
            } */}
            <div className={s.listItemWrap}>
                <div className={s.top}>
                    <div className={(stepInfo && stepInfo.currentStep && stepInfo.currentStep !== '3') || localStorage.getItem(UNLOCK_YOURY_KIWI_BENEFITS) === 'true' ? s.checked : s.icon}></div>
                    <div className={s.title}>Unlock your Kiwi benefits</div>
                </div>
                <div className={s.desc}>Explore Kiwi's features and plans, Kiwi Starter plan is free for all Klarity users.</div>
            </div>
            <div className={s.listItemWrap}>
                <div className={s.top}>
                    <div className={stepInfo && stepInfo.currentStep === '2' ? s.checked : s.icon}></div>
                    <div className={s.title}>Expand your reach with more channels</div>
                </div>
                <div className={s.desc}>Get listed on popular health directories.</div>
            </div>
            <div className={s.listItemWrap}>
                <div className={s.top}>
                    <div className={practiceFrontCompleted ? s.checked : s.icon}></div>
                    <div className={s.title}>Launch your Practice Front</div>
                </div>
                <div className={s.desc}>Create a professional website to attract new patients.</div>
            </div>
            {
                addOnPaid ? <div><Button type="primary" onClick={handleClick} loading={stepInfoLoading}>Continue</Button></div> : <div className={s.alert}><Alert text='Please customize add-ons in your EHR to unlock this step' /></div>
            }
        </div>
    );
};

export default InitStep;
