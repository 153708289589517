import React, { useRef, useState } from 'react';
import dayjs from 'dayjs';
import s from './s.module.less';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import type { ColumnsType } from 'antd/es/table';
import { Button, Checkbox, Input, message, Select, Space } from 'antd';
import CommonTable from 'components/CommonTable';
import { useRequest } from 'ahooks';
import { getProviderChannelwithdrawList, updateProviderChannelStatusByIds, updateProviderStatusByIds } from 'api/operation';
import { PATH } from 'constants/path';
import { CheckboxChangeEvent, CheckboxOptionType } from 'antd/lib/checkbox';
import { SearchOutlined } from '@ant-design/icons';
import { WithDrawChannelTableRecord } from 'types/operation';
import debounce from 'lodash/debounce';
import { EChannelWithdrawalsStatus } from 'types/channel';
import ChannelWithdrawStatusTag from 'components/ChannelWithdrawStatusTag';
import { EnumFields } from 'types/enumerationData';
import useGetSettingData from 'hooks/useGetSettingData';
import { PROVIDER_PAY_PLAN_TYPE } from 'constants/subscription';
import { channelPayTypeFilterMap } from 'constants/common';
import { EStatus } from 'types/common';
import Icons from './imgs';

const allValue = 'All';
type Params = {
    nameCond?: string,
    channelNameCond?: string,
    auditStatusList?: CheckboxValueType[],
    completeStatusList?: CheckboxValueType[],
    orderKey?: string,
    orderType?: 'desc' | 'asc',
    current: number,
    size: 10000,
    archiveStatusList?: string[],
    assignee?: string,
    providerPayPlanTypeList?: string[],
    withdrawReasonList?: string[],
    channelPayPlanTypeList?: string[],
};

const Page = () => {
    const [pendingNameFilter, setPendingNameFilter] = useState('');
    const [pendingChannelNameFilter, setPendingChannelNameFilter] = useState('');
    const [pendingAssigneeFilter, setPendingAssigneeFilter] = useState('');
    const [current, setCurrent] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);

    const [planTypeIndeterminate, setPlanTypeIndeterminate] = useState(false);
    const [planTypeCheckAll, setPlanTypeCheckAll] = useState(false);
    const [planTypeList, setPlanTypeList] = useState<Array<CheckboxValueType>>([]);
    const planTypeOptions = PROVIDER_PAY_PLAN_TYPE?.slice(0, 6)?.map((item) => ({ label: item.content, value: item.dictKey }));

    // ops 状态
    const [aduitStatusIndeterminate, setAduitStatusIndeterminate] = useState(false);
    const [aduitCheckAll, setAduitCheckAll] = useState(false);
    const [aduitList, setAduitList] = useState<Array<CheckboxValueType>>([]);
    const [auditStatusData] = useGetSettingData(EnumFields.WITHDRAW_STATUS);
    const auditStatusOptions = auditStatusData?.map((item) => ({ label: item.content, value: item.dictKey }));

    const [reasonIndeterminate, setReasonIndeterminate] = useState(false);
    const [reasonCheckAll, setReasonCheckAll] = useState(false);
    const [reasonList, setReasonList] = useState<Array<CheckboxValueType>>([]);
    const [reasonData] = useGetSettingData(EnumFields.WITHDRAW_REASON);
    const reasonOptions = reasonData?.map((item) => ({ label: item.content, value: item.dictKey }));

    const [channelPayTypeIndeterminate, setChannelPayTypeIndeterminate] = useState(false);
    const [channelPayTypeCheckAll, setChannelPayTypeCheckAll] = useState(false);
    const [channelPayTypeList, setChannelPayTypeList] = useState<Array<CheckboxValueType>>([]);
    //const [channelPayTypeData] = useGetSettingData(EnumFields.CHANNEL_PAY_PLAN_TYPE);

    const [selectKeys, setSelectKeys] = React.useState<React.Key[]>([]);
    const [statusFilter, setStatusFilter] = React.useState<string>(EStatus.Active);
    const [statusUpdating, setStatusUpdating] = React.useState(false);

    const channelPayTypeOptions = [
        {
            label: 'Free',
            value: 'free',
        },
        {
            label: 'Standard',
            value: 'standard',
        },
        {
            label: 'Premium',
            value: 'premium',
        },
    ];

    const defaultPrams = useRef<Params>({
        nameCond: pendingNameFilter,
        channelNameCond: pendingChannelNameFilter,
        assignee: pendingAssigneeFilter,
        orderKey: 'submit_time',
        orderType: 'desc',
        current: 1,
        size: 10000,
        archiveStatusList: statusFilter === allValue ? [] : [statusFilter],
    });

    const { data, loading, run } = useRequest(getProviderChannelwithdrawList, {
        defaultParams: [
            defaultPrams.current,
        ],
    });

    const listData: WithDrawChannelTableRecord[] = data?.data?.data?.records || [];

    const handleTableChange = (paginationData?: any, filtersData?: any, sorter?: any, action?: any) => {
        if (action?.action === 'paginate') {
            setCurrent(paginationData.current!);
            setPageSize(paginationData.pageSize!);
            return;
        }

        const params = defaultPrams.current;

        if (action?.action === 'sort') {
            params.orderType = sorter.order === 'ascend' ? 'asc' : 'desc';
            if (sorter.column.dataIndex === 'submitTime') {
                params.orderKey = 'submit_time';
            }
            if (sorter.column.dataIndex === 'listTime') {
                params.orderKey = 'list_time';
            }
            if (sorter.column.dataIndex === 'delListTime') {
                params.orderKey = 'dellist_time';
            }
        }

        // paginationData存在，说明是tableChange，否则是别处调用
        if (!paginationData) {
            let pendingChannelPayTypeList: string[] | undefined;
            if (filtersData.channelPayTypeList) {
                pendingChannelPayTypeList = [];
                filtersData.channelPayTypeList?.forEach((key: string) => {
                    const mapedKey: string[] = channelPayTypeFilterMap[key];
                    mapedKey?.forEach((val) => {
                        if (!pendingChannelPayTypeList!.includes(val)) {
                            pendingChannelPayTypeList!.push(val);
                        }
                    });
                });
            }

            params.nameCond = filtersData.name;
            params.channelNameCond = filtersData.channelName;
            params.auditStatusList = filtersData.auditStatusList;
            params.completeStatusList = filtersData.completeStatusList;
            params.assignee = filtersData.assignee;
            params.providerPayPlanTypeList = filtersData.planTypeList;
            params.withdrawReasonList = filtersData.reasonList;
            params.channelPayPlanTypeList = pendingChannelPayTypeList;
            params.archiveStatusList = filtersData.archiveStatusList;
        }
        run(params);
        setCurrent(1);
    };

    const filterNameDebounce = useRef(debounce((params) => {
        handleTableChange(null, params);
    }, 500));

    const filterChannelNameDebounce = useRef(debounce((params) => {
        handleTableChange(null, params);
    }, 500));

    const filterAssigneeDebounce = useRef(debounce((params) => {
        handleTableChange(null, params);
    }, 500));

    const currentFilter = {
        name: pendingNameFilter,
        channelName: pendingChannelNameFilter,
        assignee: pendingAssigneeFilter,
        auditStatusList: aduitList,
        planTypeList,
        reasonList,
        channelPayTypeList,
        archiveStatusList: statusFilter === allValue ? [] : [statusFilter],
    };

    const handlePlanTypeFilterCheckAll = (e: CheckboxChangeEvent) => {
        const checkedListData = e.target.checked && planTypeOptions ? planTypeOptions.map((option: CheckboxOptionType) => option.value) : [];

        setPlanTypeList(checkedListData);
        setPlanTypeIndeterminate(false);
        setPlanTypeCheckAll(e.target.checked);

        handleTableChange(null, {
            ...currentFilter,
            planTypeList: checkedListData,
        });
    };

    const handlePlanTypeFilterChange = (planTypeListParm: CheckboxValueType[]) => {
        setPlanTypeList(planTypeListParm);
        setPlanTypeIndeterminate(!!planTypeListParm.length && !!planTypeOptions && planTypeListParm.length < planTypeOptions.length);
        setPlanTypeCheckAll(planTypeListParm.length === planTypeOptions?.length);

        handleTableChange(null, {
            ...currentFilter,
            planTypeList: planTypeListParm,
        });
    };

    const handleAduitStatusFilterCheckAll = (e: CheckboxChangeEvent) => {
        const checkedListData = e.target.checked && auditStatusOptions ? auditStatusOptions.map((option: CheckboxOptionType) => option.value) : [];

        setAduitList(checkedListData);
        setAduitStatusIndeterminate(false);
        setAduitCheckAll(e.target.checked);

        handleTableChange(null, {
            ...currentFilter,
            auditStatusList: checkedListData,
        });
    };

    const handleAduitStatusFilterChange = (statusList: CheckboxValueType[]) => {
        setAduitList(statusList);
        setAduitStatusIndeterminate(!!statusList.length && !!auditStatusOptions && statusList.length < auditStatusOptions.length);
        setAduitCheckAll(statusList.length === auditStatusOptions?.length);

        handleTableChange(null, {
            ...currentFilter,
            auditStatusList: statusList,
        });
    };

    const handleReasonFilterCheckAll = (e: CheckboxChangeEvent) => {
        const checkedListData = e.target.checked && reasonOptions ? reasonOptions.map((option: CheckboxOptionType) => option.value) : [];

        setReasonList(checkedListData);
        setReasonIndeterminate(false);
        setReasonCheckAll(e.target.checked);

        handleTableChange(null, {
            ...currentFilter,
            reasonList: checkedListData,
        });
    };

    const handleReasonFilterChange = (list: CheckboxValueType[]) => {
        setReasonList(list);
        setPlanTypeIndeterminate(!!list.length && !!reasonOptions && list.length < reasonOptions.length);
        setPlanTypeCheckAll(list.length === reasonOptions?.length);

        handleTableChange(null, {
            ...currentFilter,
            reasonList: list,
        });
    };

    const handleChannelPayTypeFilterCheckAll = (e: CheckboxChangeEvent) => {
        const checkedListData = e.target.checked && channelPayTypeOptions ? channelPayTypeOptions.map((option: CheckboxOptionType) => option.value) : [];

        setChannelPayTypeList(checkedListData);
        setChannelPayTypeIndeterminate(false);
        setChannelPayTypeCheckAll(e.target.checked);

        handleTableChange(null, {
            ...currentFilter,
            channelPayTypeList: checkedListData,
        });
    };

    const handleChannelPayFilterChange = (list: CheckboxValueType[]) => {
        setChannelPayTypeList(list);
        setChannelPayTypeIndeterminate(!!list.length && !!channelPayTypeOptions && list.length < channelPayTypeOptions.length);
        setChannelPayTypeCheckAll(list.length === channelPayTypeOptions?.length);

        handleTableChange(null, {
            ...currentFilter,
            channelPayTypeList: list,
        });
    };

    const handleStatusFilterChange = (status: string) => {
        setStatusFilter(status);

        handleTableChange(undefined, {
            ...currentFilter,
            archiveStatusList: status === allValue ? [] : [status],
        });
    };

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectKeys(selectedRowKeys || []);
        },
        selectedRowKeys: selectKeys,
    };

    const selectItems = listData.filter((item: WithDrawChannelTableRecord) => selectKeys.includes(item.id));
    const hasSelect = selectItems.length > 0;
    let allActive = !!hasSelect;
    let allArchived = !!hasSelect;
    selectItems?.forEach((item: WithDrawChannelTableRecord) => {
        if (item.archiveStatus === EStatus.Active) {
            allArchived = false;
        }
        if (item.archiveStatus === EStatus.Archived) {
            allActive = false;
        }
    });

    const setStatus = async (val: EStatus) => {
        setStatusUpdating(true);
        const result = await updateProviderChannelStatusByIds(selectKeys as any, val);
        if (result.error) {
            //message.error(result.error);
        } else {
            const msg = val === EStatus.Active ? 'Activate successfully' : 'Archived successfully';
            message.success(msg);
            setSelectKeys([]);
            run({
                ...currentFilter,
                current: 1,
                size: 10000,
            });
        }
        setStatusUpdating(false);
    };

    const columns: ColumnsType<WithDrawChannelTableRecord> = [
        {
            title: 'NAME',
            dataIndex: 'name',
            filterSearch: true,
            className: s.tableColumn,
            filterIcon: <SearchOutlined />,
            width: '180px',
            fixed: 'left',
            filterDropdown: () => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        value={pendingNameFilter}
                        onChange={(e) => {
                            const filterName = e.target.value;
                            setPendingNameFilter(filterName);
                            filterNameDebounce.current({
                                ...currentFilter,
                                name: filterName,
                            });
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                </div>
            ),
            render: (name: string, { photo, firstName, lastName, email, archiveStatus }: WithDrawChannelTableRecord) => {
                return (
                    <div className={s.nameWrap}>
                        <div className={s.nameLeft}>
                            {
                                archiveStatus === EStatus.Active && <span className={s.activate} />
                            }
                            {
                                archiveStatus === EStatus.Archived && <span className={s.archive} />
                            }
                        </div>
                        <div className={s.logo} style={{ backgroundImage: `url(${photo})`, display: 'none' }} />
                        <div className={s.nameRight}>
                            <div className={s.name}>{firstName} {lastName}</div>
                            <div className={s.email}>{email}</div>
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'CHANNEL',
            dataIndex: 'channelName',
            width: '180px',
            className: s.tableColumn,
            filterIcon: <SearchOutlined />,
            filterDropdown: () => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        value={pendingChannelNameFilter}
                        onChange={(e) => {
                            const filterName = e.target.value;
                            setPendingChannelNameFilter(filterName);
                            filterChannelNameDebounce.current({
                                ...currentFilter,
                                channelName: filterName,
                            });
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                </div>
            ),
        },
        {
            title: 'OPS DELIST DATE',
            dataIndex: 'delListTime',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            width: '160px',
            className: s.tableColumn,
            render: (time) => {
                return (
                    <div>{time ? dayjs(time).format('MM/DD/YYYY') : 'Nil'}</div>
                );
            },
        },
        {
            title: 'PLAN',
            dataIndex: 'providerPayPlanType',
            width: '150px',
            className: s.tableColumn,
            filterDropdown: () => {
                return (
                    <div className={s.vertical} style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Checkbox style={{ marginBottom: '8px' }} indeterminate={planTypeIndeterminate} onChange={handlePlanTypeFilterCheckAll} checked={planTypeCheckAll}>
                            Check all
                        </Checkbox>
                        <Checkbox.Group options={planTypeOptions} value={planTypeList} onChange={handlePlanTypeFilterChange} />
                    </div>
                );
            },
            render: (plan) => {
                return (
                    <div>{plan}</div>
                );
            },
        },
        {
            title: 'CHANNEL TYPE',
            dataIndex: 'channelPayPlanType',
            width: '180px',
            className: s.tableColumn,
            filterDropdown: () => {
                return (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Space>
                            <Checkbox indeterminate={channelPayTypeIndeterminate} onChange={handleChannelPayTypeFilterCheckAll} checked={channelPayTypeCheckAll}>
                                Check all
                            </Checkbox>
                            <Checkbox.Group options={channelPayTypeOptions} value={channelPayTypeList} onChange={handleChannelPayFilterChange} />
                        </Space>
                    </div>
                );
            },
        },
        {
            title: 'REASON',
            dataIndex: 'withdrawReason',
            width: '180px',
            className: s.tableColumn,
            filterDropdown: () => {
                return (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Space>
                            <Checkbox indeterminate={reasonIndeterminate} onChange={handleReasonFilterCheckAll} checked={reasonCheckAll}>
                                Check all
                            </Checkbox>
                            <Checkbox.Group options={reasonOptions} value={reasonList} onChange={handleReasonFilterChange} />
                        </Space>
                    </div>
                );
            },
            render: (plan) => {
                return (
                    <div>{plan}</div>
                );
            },
        },
        {
            title: 'SUBMITTED ON',
            dataIndex: 'submitTime',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            width: '150px',
            className: s.tableColumn,
            render: (time) => {
                return (
                    <div>{dayjs(time).format('MM/DD/YYYY')}</div>
                );
            },
        },
        {
            title: 'OPS PROGRESS',
            dataIndex: 'status',
            width: '150px',
            className: s.tableColumn,
            filterDropdown: () => {
                return (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Space>
                            <Checkbox indeterminate={aduitStatusIndeterminate} onChange={handleAduitStatusFilterCheckAll} checked={aduitCheckAll}>
                                Check all
                            </Checkbox>
                            <Checkbox.Group options={auditStatusOptions} value={aduitList} onChange={handleAduitStatusFilterChange} />
                        </Space>
                    </div>
                );
            },
            render: (status: string) => status && <ChannelWithdrawStatusTag status={status} />,
        },
        {
            title: 'ASSIGNEE',
            dataIndex: 'assignee',
            filterSearch: true,
            className: s.tableColumn,
            filterIcon: <SearchOutlined />,
            width: '180px',
            filterDropdown: () => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        value={pendingAssigneeFilter}
                        onChange={(e) => {
                            const filterName = e.target.value;
                            setPendingAssigneeFilter(filterName);
                            filterAssigneeDebounce.current({
                                assignee: filterName,
                                name: pendingNameFilter,
                                channelName: pendingNameFilter,
                            });
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                </div>
            ),
            render: (assignee) => {
                return (
                    <div>{assignee || ''}</div>
                );
            },
        },
        {
            title: '',
            width: '100px',
            className: s.tableColumn,
            fixed: 'right',
            render: (item: WithDrawChannelTableRecord) => {
                const { channelName, channelId, providerId, id, delListTime } = item;
                const urlSearchParams = channelName ? `?channelName=${encodeURIComponent(channelName)}` : '';
                const url = `${window.location.protocol}//${window.location.host}`;
                const link = `${url}${PATH.OPERATION}/${PATH.CHANNEL_WITHDRRAW_DETAIL}/${id}/${channelId}/${providerId}${urlSearchParams}`;
                let showView = false;
                if (item.status !== 'Removed') {
                    if (!delListTime) {
                        showView = true;
                    } else {
                        const deTime = new Date(delListTime);
                        if (deTime.getTime() <= (new Date().getTime())) {
                            showView = true;
                        }
                    }
                }
                return (
                    <div>
                        {
                            showView &&
                            <Button
                                className={s.link}
                                onClick={() => {
                                    //navigate(`${url}/${PATH.OPERATION}/${PATH.LIST_TO_CHANNEL_DETAIL}/${channelId}/${providerId}${urlSearchParams}`);
                                    window.open(link, 'mozillaTab');
                                }}
                                type="text"
                            >
                                View
                            </Button>
                        }
                    </div>
                );
            },
        },
    ];

    return (
        <div className={s.wrap}>
            <div className={s.filter}>
                <div className={selectKeys?.length > 0 ? `${s.left} ${s.leftSelect}` : s.left}>
                    {
                        allArchived &&
                        <Button
                            loading={statusUpdating}
                            className={s.action}
                            onClick={() => { setStatus(EStatus.Active); }}
                        >
                            {Icons.CheckIcon}
                            <span className={s.label}>Set as active</span>
                        </Button>
                    }

                    {
                        allActive &&
                        <Button
                            loading={statusUpdating}
                            className={s.action}
                            onClick={() => { setStatus(EStatus.Archived); }}
                        >
                            {Icons.UnCheckIcon}
                            <span className={s.label}>Archive</span>
                        </Button>
                    }
                    <span />
                </div>
                <div className={s.right}>
                    <Select
                        value={statusFilter}
                        style={{ width: 160, marginRight: 16 }}
                        onChange={handleStatusFilterChange}
                        options={
                            [
                                { value: allValue, label: 'All status' },
                                { value: 'Active', label: 'Active' },
                                { value: 'Archived', label: 'Archived' },
                            ]}
                    />
                </div>
            </div>
            <div className={s.tableWrap}>
                <CommonTable
                    rowKey={(record: WithDrawChannelTableRecord) => record.id}
                    bordered
                    loading={loading}
                    pagination={{
                        current,
                        pageSize,
                    }}
                    scroll={{ x: '1500' }}
                    columns={columns}
                    data={listData || []}
                    onChange={handleTableChange}
                    rowSelection={rowSelection}
                />
            </div>
        </div>
    );
};

export default Page;
