import React, { useEffect, useState } from 'react';
import { Tooltip, Select, Spin } from 'antd';
import { getDashboard, ETimeType } from 'api/home';
import s from './s.module.less';
import cx from 'classnames';
import PatientReviewEntry from '../PatientReviewEntry';
import PricingReviewEntry from '../PricingReviewEntry';
import { TProfileData } from 'types/home';
import { ICCCDashboardItem } from 'types/ccc';

type IProps = {
    isKlarity: boolean;
    profileData: TProfileData
    setCCCDashboardData?: (val: ICCCDashboardItem[]) => void;
};

type TSummaryData = {
    averageSurveyRating: number | null;
    averageSurveyRatingRate: number | null;
    newPatientServed: number | null;
    newPatientServedRate: number | null;
    preAverageSurveyRating: number | null;
    preNewPatientServed: number | null;
    preProfileViews: number | null;
    preRecurringPatientBookings: number | null;
    preTotalProspect: number | null;
    profileViews: number | null;
    profileViewsRate: number | null;
    recurringPatientBookings: number | null;
    recurringPatientBookingsRate: number | null;
    totalProspect: number | null;
    totalProspectRate: number | null;
};

const Summary: React.FC<IProps> = ({ isKlarity, profileData, setCCCDashboardData }) => {
    const [dateRangeFilter, setDateRangeFilter] = useState(ETimeType.LAST_30_DAYS);
    const [summaryData, setSummaryData] = useState<TSummaryData>(
        {} as TSummaryData,
    );
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getData = async (params: { timeRange: ETimeType }) => {
        setIsLoading(true);
        try {
            const { data } = await getDashboard(params.timeRange);
            setSummaryData(data.data);
            setCCCDashboardData?.((data.data.cards || []) as ICCCDashboardItem[]);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getData({
            timeRange: dateRangeFilter,
        });
    }, []);

    const Card = ({
        title,
        tooltips,
        totalData,
        preData,
        rate,
        isStar,
    }: {
        title: string;
        tooltips: string;
        totalData: number | null;
        preData: number | null;
        rate: number | null;
        isStar?: boolean;
    }) => {
        return (
            <div className={s.card}>
                <div className={s.cardHeader}>
                    <h3 className={s.subTitle}>{title}</h3>
                    <Tooltip
                        overlayClassName={s.tipCard}
                        color="#fff"
                        overlayInnerStyle={{
                            padding: '12px',
                            color: '#15191E',
                            backgroundColor: '#fff',
                        }}
                        title={<div className={s.tipText}>{tooltips}</div>}
                    >
                        <div className={s.questionMarkIcon} />
                    </Tooltip>
                </div>
                <div className={s.cardContent}>
                    <div className={cx(s.number, isStar ? s.star : '')}>
                        <span className={s.biggerNumber}>{totalData || '0'}</span>
                        <span className={s.from}>from</span>
                        <span className={s.preData}>{preData || '0'}</span>
                    </div>
                    {rate !== null && rate > 0 && (
                        <div className={cx(s.rate, s.up)}>{`${rate}%` || '0'}</div>
                    )}
                    {rate !== null && rate < 0 && (
                        <div className={cx(s.rate, s.down)}>
                            {`${Math.abs(rate)}%` || '0'}
                        </div>
                    )}
                    {(rate === 0 || rate === null) && (
                        <div className={cx(s.rate, s.minus)}>0%</div>
                    )}
                </div>
            </div>
        );
    };

    const renderKiwiSummary = (
        <div className={s.content}>
            <div className={s.cardWrap}>
                <Card
                    title="Total Prospects"
                    tooltips="Patient has written or called you to inquire service details, or patient has clicked the scheduling link provided."
                    preData={summaryData.preTotalProspect}
                    totalData={summaryData.totalProspect}
                    rate={summaryData.totalProspectRate}
                />
                <Card
                    title="Profile Views"
                    tooltips="Patient has clicked and visited your dedicated profile page for your service details. Note: Some channel data updates monthly. You may notice some delay in data reporting."
                    preData={summaryData.preProfileViews}
                    totalData={summaryData.profileViews}
                    rate={summaryData.profileViewsRate}
                />
                <Card
                    isStar
                    title="Avg. Review Rating"
                    tooltips="Average rating received."
                    preData={summaryData.preAverageSurveyRating}
                    totalData={summaryData.averageSurveyRating}
                    rate={summaryData.averageSurveyRatingRate}
                />
            </div>
        </div>
    );
    const renderKlaritySummary = (
        <div className={s.content}>
            <div className={s.cardWrap}>
                <Card
                    title="Total Prospects"
                    tooltips="Patients with strong interest in your services who have written or called to inquire about service details, or have filled out patient information in Klarity check-out but didn't proceed to payment."
                    preData={summaryData.preTotalProspect}
                    totalData={summaryData.totalProspect}
                    rate={summaryData.totalProspectRate}
                />
                <Card
                    title="Profile Views"
                    tooltips="Patient has clicked and visited your dedicated profile page for your service details. Note: Some channel data updates monthly. You may notice some delay in data reporting."
                    preData={summaryData.preProfileViews}
                    totalData={summaryData.profileViews}
                    rate={summaryData.profileViewsRate}
                />
                <Card
                    title="Avg. Review Rating"
                    tooltips="Average rating received."
                    preData={summaryData.preAverageSurveyRating}
                    totalData={summaryData.averageSurveyRating}
                    rate={summaryData.averageSurveyRatingRate}
                    isStar
                />
            </div>
            <div className={s.cardWrap}>
                <Card
                    title="New Patients Served"
                    tooltips="New patient appointment scheduled."
                    preData={summaryData.preNewPatientServed}
                    totalData={summaryData.newPatientServed}
                    rate={summaryData.newPatientServedRate}
                />
                <Card
                    title="Recurring Patient Bookings"
                    tooltips="Established patient appointment scheduled."
                    preData={summaryData.preRecurringPatientBookings}
                    totalData={summaryData.recurringPatientBookings}
                    rate={summaryData.recurringPatientBookingsRate}
                />
            </div>
        </div>
    );
    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <h2 className={s.title}>Performance summary</h2>
                <div className={s.selectWrap}>
                    <Select
                        value={dateRangeFilter}
                        style={{ width: 135 }}
                        onChange={(val) => {
                            setDateRangeFilter(val);
                            getData({
                                timeRange: val,
                            });
                        }}
                        options={[
                            { value: ETimeType.THIS_WEEK, label: 'Last 7 days' },
                            { value: ETimeType.LAST_14_DAYS, label: 'Last 14 days' },
                            { value: ETimeType.LAST_30_DAYS, label: 'Last 30 days' },
                        ]}
                    />
                </div>
            </div>
            <PatientReviewEntry profileData={profileData} />
            <PricingReviewEntry profileData={profileData} />
            <Spin spinning={isLoading}>
                {isKlarity ? renderKlaritySummary : renderKiwiSummary}
            </Spin>
        </div>
    );
};

export default Summary;
