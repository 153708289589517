import React, { useCallback, useMemo } from 'react';
import s from './s.module.less';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
// import HomeStore from 'store/Home';
import { EStepTypes } from 'types/common';
import useGetStepInfo from 'hooks/useGetStepInfo';
import Alert from 'components/Alert';
import TimeRemaining from '../TimeRemaining';
import { EProfileStatus } from 'types/channel';

interface IProps {
    firstAddOnPaidAt?: string;
    firstPracticeLaunchAt?: string;
    firstUniprofileSubmitAt?: string;
    addOnPaid?: boolean;
    uniprofileSubmitted?: boolean;
    ehrSetUpCompleted?: boolean;
    uniprofileStatus?: EProfileStatus
}

const ExploreKiwiHealth = ({
    firstAddOnPaidAt,
    firstPracticeLaunchAt,
    firstUniprofileSubmitAt,
    addOnPaid,
    uniprofileSubmitted,
    ehrSetUpCompleted,
    uniprofileStatus,
}: IProps) => {
    const [stepInfo, stepInfoLoading] = useGetStepInfo(EStepTypes.KLARITY_STARTER_SET_UP);
    // const [getHomeStore] = HomeStore.useStore();
    // const homeStore = getHomeStore('data');
    // const practiceFrontCompleted = !!homeStore?.practiceFrontCompleted;
    const navigate = useNavigate();

    const handleClick = useCallback(() => {
        if (!ehrSetUpCompleted) {
            navigate(`${PATH.DASHBOARD}/${PATH.EHR_HOME_PROVIDER}`);
        } else {
            navigate(`${PATH.DASHBOARD}/${PATH.EHR_ADD_ON_PROVIDER}`);
        }
    }, [navigate, stepInfo]);

    const handleGotoChannelsPage = useCallback(() => {
        navigate(`${PATH.DASHBOARD}/${PATH.CHANNEL}`);
    }, [navigate]);

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <h2 className={s.h2}>Set up your practice</h2>
                {
                    uniprofileSubmitted && firstUniprofileSubmitAt && (
                        <div className={s.statusNtime}>
                            <TimeRemaining startTime={firstUniprofileSubmitAt} isCompleted={addOnPaid} completeTime={firstAddOnPaidAt} />
                        </div>
                    )
                }
            </div>
            {/* {
                ehrSetUpCompleted ? (
                    <div className={s.text}>
                        <p>Discover how Kiwi Health can boost your practice! Get listed on 40+ top directories, attract more patients in just 10 days, and simplify your practice management.</p>
                        <p>You can also browse <span className={s.link} onClick={handleGotoChannelsPage}>Channels</span> and customize your <span className={s.link} onClick={handleGotoPFPage}>Practice front</span> to expand your reach.</p>
                    </div>
                ) : (
                    <>
                        <div className={s.text}>Discover how Kiwi Health can boost your practice! Get listed on 40+ top directories, attract more patients in just 10 days, and simplify your practice management.</div>
                        <Button type="primary" onClick={handleClick} loading={stepInfoLoading}>Explore Kiwi Health</Button>
                    </>
                )
            } */}
            <div className={s.listItemWrap}>
                <div className={s.top}>
                    <div className={ehrSetUpCompleted ? s.checked : s.icon}></div>
                    <div className={s.title}>Set up your EHR</div>
                </div>
                <div className={s.desc}>Complete the EHR setup promptly to launch your Klarity practice and allow patients to book services. After your UniProfile get approval, all information you setup here will sync with your EHR.</div>
            </div>
            <div className={s.listItemWrap}>
                <div className={s.top}>
                    <div className={addOnPaid ? s.checked : s.icon}></div>
                    <div className={s.title}>Customize add-ons in your EHR</div>
                </div>
                <div className={s.desc}>Choose Kiwi EHR add-ons to customize your system for your needs and workflow. Explore options like e-prescribing, e-Fax, EPCS, and more.</div>
            </div>
            {
                uniprofileSubmitted ? <Button type="primary" onClick={handleClick} loading={stepInfoLoading}>Get started</Button> : <div className={s.alert}><Alert text='Please complete your UniProfile to unlock this step' /></div>
            }
        </div>
    );
};

export default ExploreKiwiHealth;
