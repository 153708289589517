// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--vCdBc .s-module__row--TsbgD {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.s-module__wrap--vCdBc .s-module__row--TsbgD .s-module__displayItem--ZTas3 {
  margin-bottom: 12px;
}
.s-module__wrap--vCdBc .s-module__row--TsbgD .s-module__displayItem--ZTas3 .s-module__label--ka46I {
  color: #6B7280;
  font-size: 14px;
  line-height: 20px;
}
.s-module__wrap--vCdBc .s-module__rowItem--PzcVp {
  margin-right: 36px;
}
.s-module__wrap--vCdBc .s-module__colItem--gYzia {
  display: flex;
  flex-direction: column;
}
.s-module__wrap--vCdBc .s-module__title--dx6QB {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.s-module__wrap--vCdBc .s-module__title--dx6QB .s-module__name--Ak1oL {
  color: #6B7280;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.s-module__wrap--vCdBc .s-module__title--dx6QB .s-module__btn--tnAzq {
  background: #fff;
  margin-left: 8px;
}
.s-module__wrap--vCdBc .s-module__title--dx6QB .s-module__btn2--GTkM_ {
  margin-left: 8px;
}
.s-module__wrap--vCdBc .s-module__formWrap--AYnHZ {
  padding: 24px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #DAD8D8;
  margin-top: 12px;
}
.s-module__wrap--vCdBc .s-module__imgWrap--qLKoE {
  max-width: 300px;
}
.s-module__footerBtnWrap--Q14zE {
  margin-top: 18px;
}
.s-module__footerBtnWrap--Q14zE .s-module__btn2--GTkM_ {
  margin-left: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/EhrProviderAddOnPage/components/SelfEPrescriptionAndEpcs/components/EditLicenses/s.module.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,mBAAA;EACA,uBAAA;AAAJ;AAJA;EAaM,mBAAA;AANN;AAPA;EAQQ,cAAA;EACA,eAAA;EACA,iBAAA;AAER;AAZA;EAkBI,kBAAA;AAHJ;AAfA;EAsBI,aAAA;EACA,sBAAA;AAJJ;AAnBA;EA2BI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;AALJ;AAzBA;EAiCM,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AALN;AA/BA;EAwCM,gBAAA;EACA,gBAAA;AANN;AAnCA;EA4CM,gBAAA;AANN;AAtCA;EAiDI,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;AARJ;AA7CA;EAwDI,gBAAA;AARJ;AAYA;EACE,gBAAA;AAVF;AASA;EAGI,gBAAA;AATJ","sourcesContent":[".wrap {\n  .row {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n\n    .displayItem {\n      .label {\n        color: #6B7280;\n        font-size: 14px;\n        line-height: 20px;\n      }\n\n      margin-bottom: 12px;\n    }\n  }\n\n  .rowItem {\n    margin-right: 36px;\n  }\n\n  .colItem {\n    display: flex;\n    flex-direction: column;\n  }\n\n  .title {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n\n    .name {\n      color: #6B7280;\n      font-size: 14px;\n      font-style: normal;\n      font-weight: 500;\n    }\n\n    .btn {\n      background: #fff;\n      margin-left: 8px;\n    }\n    .btn2{\n      margin-left: 8px;\n    }\n  }\n\n  .formWrap {\n    padding: 24px;\n    background: #fff;\n    border-radius: 10px;\n    border: 1px solid #DAD8D8;\n    margin-top: 12px;\n  }\n  .imgWrap {\n    max-width: 300px;\n  }\n}\n\n.footerBtnWrap {\n  margin-top: 18px;\n  .btn2 {\n    margin-left: 8px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--vCdBc`,
	"row": `s-module__row--TsbgD`,
	"displayItem": `s-module__displayItem--ZTas3`,
	"label": `s-module__label--ka46I`,
	"rowItem": `s-module__rowItem--PzcVp`,
	"colItem": `s-module__colItem--gYzia`,
	"title": `s-module__title--dx6QB`,
	"name": `s-module__name--Ak1oL`,
	"btn": `s-module__btn--tnAzq`,
	"btn2": `s-module__btn2--GTkM_`,
	"formWrap": `s-module__formWrap--AYnHZ`,
	"imgWrap": `s-module__imgWrap--qLKoE`,
	"footerBtnWrap": `s-module__footerBtnWrap--Q14zE`
};
export default ___CSS_LOADER_EXPORT___;
