import React, { useCallback, useRef, useState } from 'react';
import s from './s.module.less';
import CommonTable from 'components/CommonTable';
import { IEditBookingLink } from '../../../type';
import { Pagination, Popconfirm, Tooltip } from 'antd';

interface IProps {
    data: IEditBookingLink[]
    onEdit: (v: IEditBookingLink) => void;
    onRemove: (v: IEditBookingLink) => void;
    onCopyLink: (v: IEditBookingLink) => void;
    title: string;
}

const Table = ({
    data,
    onEdit,
    onRemove,
    onCopyLink,
    title,
}: IProps) => {
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = useRef(10);
    const handleEdit = useCallback((row: IEditBookingLink) => {
        onEdit?.(row);
    }, [onEdit]);

    const getData = (page: number, size: number) => {
        const start = (page - 1) * size;
        const end = page * size;
        return data.slice(start, end);
    };

    const mergedData = getData(currentPage, pageSize.current);

    const columns = [
        {
            title: 'STATE',
            dataIndex: 'state',
            key: 'state',
            align: 'left',
            width: '100px',
            render: (value, row, index) => {
                const current = currentPage;
                const ps = pageSize.current;
                // const { current, pageSize } = this.state.pagination;
                const obj = {
                    children: value,
                    props: {},
                };
                let sameRowCount = 1;

                let totalIndex = ps * current;
                totalIndex = totalIndex > data.length ? data.length : totalIndex;
                const fullIndex = ps * (current - 1) + index;

                if (index !== 0 && data[fullIndex - 1].state === data[fullIndex].state) {
                    sameRowCount = 0;
                } else {
                    for (let i = fullIndex + 1; i < totalIndex; i++) {
                        if (data[i].state === data[fullIndex].state) {
                            sameRowCount++;
                        } else {
                            break;
                        }
                    }
                }

                obj.props.rowSpan = sameRowCount;

                return obj;
            },
        },
        {
            title: 'TYPE',
            dataIndex: 'serviceType',
            key: 'serviceType',
            align: 'left',
            width: '300px',
        },
        {
            title: 'CONDITIONS',
            dataIndex: 'conditions',
            key: 'conditions',
            align: 'left',
            width: '400px',
            render: (value: string[]) => {
                const startIndex = 3;

                const firstHalf = value.slice(0, startIndex);
                const secondHalf = value.slice(startIndex);

                return (
                    <div className={s.conditions}>
                        <span className={s.text}>{`${firstHalf.join(',')}`}</span>
                        { secondHalf.length > 0 ? (
                            <Tooltip placement="bottom" overlayClassName={s.tip} color="#fff" overlayStyle={{ color: '#000' }} title={<div className={s.tipText}>{secondHalf.join(',')}</div>}>
                                <div className={s.moreConditions}>and <a>{secondHalf.length} more</a></div>
                            </Tooltip>
                        ) : ''}
                    </div>
                );
            },
        },
        {
            title: '',
            align: 'left',
            width: '300px',
            render: (row: IEditBookingLink) => (
                <div className={s.actionBtns}>
                    <Popconfirm title="Are you sure delete this data?" okText="Yes" cancelText="No" onConfirm={() => onRemove(row)}>
                        <div className={s.actionBtn}>Remove</div>
                    </Popconfirm>
                    <div className={s.actionBtn} onClick={() => onCopyLink(row)}>Copy link</div>
                    <div className={s.actionBtn} onClick={() => handleEdit(row)}>Edit</div>
                </div>
            ),
        },
    ];
    return (
        <div className={s.wrap}>
            <div className={s.title}>{title}</div>
            <CommonTable
                bordered
                borderColor="#E5E7EB"
                rowKey="uuid"
                columns={columns}
                data={mergedData}
                pagination={false}
            />
            <Pagination
                current={currentPage}
                pageSize={pageSize.current}
                total={data.length}
                onChange={(page) => setCurrentPage(page)}
            />
        </div>
    );
};

export default Table;
