import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Dropdown, MenuProps, Pagination, Space, message, Spin } from 'antd';
import s from './s.module.less';
import dayjs from 'utils/dayjs';
import { DownOutlined } from '@ant-design/icons';
import { PatientsFeedback } from 'types/common';
import Down from './down.svg';
import Up from './up.svg';
import Empty from './empty.svg';
import { getTimeStr } from 'utils/calendar';
import ScoreLabel from 'components/ScoreLabel';
import ProviderStore from 'store/Provider';
import ReplyFeedbackModal from '../ReplyFeedbackModal';
import { IOverviewData } from 'types/reputation';
import { getReputationOverviewData, getReputationFeedbackList } from 'api/survey';
import DataUpdatedFlagStore from 'store/DataUpdatedFlag';
import { isFreeUser } from 'utils/provider';
import { openSubscriptionModal } from 'utils/globalLayerControl';

enum ETimeFilter {
    THIS_WEEK = 'this_week',
    LAST_30_DAYS = 'last_30',
    LAST_90_DAYS = 'last_90',
}

enum EFeedbackFilter {
    OLDEST = 1,
    NEWEST = 0,
}

enum EFeedbackType {
    NORMAL = 1,
    ANONYMOUS = 2,
}

const Overview = () => {
    const [getUser] = ProviderStore.useStore();
    const navigate = useNavigate();
    const [timeFilter, setTimeFilter] = React.useState<ETimeFilter>(ETimeFilter.THIS_WEEK);
    const [feedbackFilter, setFeedbackFilter] = React.useState<EFeedbackFilter>(EFeedbackFilter.NEWEST);
    const [showReply, setShowReply] = React.useState<boolean>(false);
    const [currentFeedback, setCurrentFeedback] = React.useState<PatientsFeedback>();
    const [feedbackData, setFeedbackData] = useState<PatientsFeedback[]>();
    const [getDataUpdatedFlag] = DataUpdatedFlagStore.useStore();

    const [overviewData, setOverviewData] = useState<IOverviewData>({
        invitationSend: 0,
        averageSurveyRating: 0.0,
        feedbackReceived: 0,
        surveySendThisWeek: 0,
        surveySendTotal: 0,
        surveyDeliverySend: 0,
        preInvitationSend: 0,
        preAverageSurveyRating: 0.0,
        preFeedbackReceived: 0,
        preSurveySendTotal: 0,
        surveySendIncrRate: 0.0,
    });
    const [feedbackListPage, setFeedbackListPage] = useState(1);
    const [feedbackListPageSize, setFeedbackListPageSize] = useState(10);
    const [totalFeedback, setTotalFeedback] = useState(0);
    const patientFeedbackListUpdatedFlag = getDataUpdatedFlag('patientFeedbackListUpdated');
    const surveyDataUpdatedFlag = getDataUpdatedFlag('surveyDataUpdated');
    const [loading, setLoading] = React.useState(false);

    const isFree = isFreeUser();
    const user = getUser('data');
    const hasLoaded = !!user?.email;

    const showUpgrade = !!isFree && hasLoaded;
    const showChannelStore = !isFree && !user?.gmail && hasLoaded;
    const canReply = !showUpgrade && !showChannelStore;

    const fetchOverviewData = async (v: string) => {
        try {
            const res = await getReputationOverviewData(v);

            if (res && !res.error && res.data?.data) {
                setOverviewData(res.data.data);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getFeedbackList = async (
        { type, order, current, size }:
        { type: EFeedbackType, order?: EFeedbackFilter, current?: number, size?:number },
    ) => {
        try {
            setLoading(true);
            const res = await getReputationFeedbackList({
                isAnonymous: type === EFeedbackType.ANONYMOUS,
                orderAsc: order === EFeedbackFilter.OLDEST,
                size: size || feedbackListPageSize,
                current: current || feedbackListPage,
                replied: false,
            });
            setLoading(false);

            if (res && !res.error) {
                const { records = [], total = 0 } = res.data?.data || {};
                setFeedbackData(records || []);
                setFeedbackListPage(1);
                setTotalFeedback(total);
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getFeedbackList({ type: EFeedbackType.NORMAL });
        fetchOverviewData(ETimeFilter.THIS_WEEK);
    }, []);

    useEffect(() => {
        if (patientFeedbackListUpdatedFlag) {
            getFeedbackList({ type: EFeedbackType.NORMAL });
            fetchOverviewData(timeFilter);
        }
    }, [patientFeedbackListUpdatedFlag]);

    useEffect(() => {
        fetchOverviewData(timeFilter);
    }, [surveyDataUpdatedFlag]);

    const handlePaginationChange = (page: number, pageSize: number) => {
        setFeedbackListPage(page);
        setFeedbackListPageSize(pageSize);
        getFeedbackList({ type: EFeedbackType.NORMAL, current: page, size: pageSize });
    };

    let timeFilterLabel = '';
    if (timeFilter === ETimeFilter.THIS_WEEK) {
        timeFilterLabel = 'This week';
    }
    if (timeFilter === ETimeFilter.LAST_30_DAYS) {
        timeFilterLabel = 'Last 30 days';
    }
    if (timeFilter === ETimeFilter.LAST_90_DAYS) {
        timeFilterLabel = 'Last 90 days';
    }

    let feedbackFilterLabel = '';
    if (feedbackFilter === EFeedbackFilter.NEWEST) {
        feedbackFilterLabel = 'Newest';
    }
    if (feedbackFilter === EFeedbackFilter.OLDEST) {
        feedbackFilterLabel = 'Oldest';
    }

    const timeFilterItems: MenuProps['items'] = [
        {
            label: <span className={s.dropItem} onClick={() => { setTimeFilter(ETimeFilter.THIS_WEEK); }}>This week</span>,
            key: ETimeFilter.THIS_WEEK,
        },
        {
            label: <span className={s.dropItem} onClick={() => { setTimeFilter(ETimeFilter.LAST_30_DAYS); }}>Last 30 days</span>,
            key: ETimeFilter.LAST_30_DAYS,
        },
        {
            label: <span className={s.dropItem} onClick={() => { setTimeFilter(ETimeFilter.LAST_90_DAYS); }}>Last 90 days</span>,
            key: ETimeFilter.LAST_90_DAYS,
        },
    ];

    const feedbackFilterItems: MenuProps['items'] = [
        {
            label: <span
                className={s.dropItem}
                onClick={() => {
                    setFeedbackFilter(EFeedbackFilter.NEWEST);
                    setFeedbackListPage(1);
                    getFeedbackList({ type: EFeedbackType.NORMAL, order: EFeedbackFilter.NEWEST, current: 1 });
                }}
            >Newest</span>,
            key: EFeedbackFilter.NEWEST,
        },
        {
            label: <span
                className={s.dropItem}
                onClick={() => {
                    setFeedbackFilter(EFeedbackFilter.OLDEST);
                    setFeedbackListPage(1);
                    getFeedbackList({ type: EFeedbackType.NORMAL, order: EFeedbackFilter.OLDEST, current: 1 });
                }}
            >Oldest</span>,
            key: EFeedbackFilter.OLDEST,
        },
    ];

    const isEmpty = (feedbackData && feedbackData.length === 0);

    const timeFilterChange:MenuProps['onClick'] = ({ key }) => {
        fetchOverviewData(key);
    };

    const {
        invitationSend,
        averageSurveyRating,
        feedbackReceived,
        surveySendTotal,
        preInvitationSend,
        preAverageSurveyRating,
        preFeedbackReceived,
        preSurveySendTotal,
        surveySendIncrRate,
        averageRatingIncrRate,
        feedbackReceivedIncrRate,
        invitationSendIncrRate,
    } = overviewData;

    const renderRisingArrow = (val?: number) => {
        if (!val) {
            return <span />;
        }

        if (val > 0) {
            return (
                <div className={s.up}>
                    <img src={Up} alt="" />
                    <span className={s.rate}>{val}%</span>
                </div>
            );
        }

        if (val < 0) {
            return (
                <div className={s.down}>
                    <img src={Down} alt="" />
                    <span className={s.rate}>{val}%</span>
                </div>
            );
        }
    };

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <div className={s.timeFilter}>
                    <Dropdown menu={{ items: timeFilterItems, onClick: timeFilterChange }} trigger={['click']}>
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                <span className={s.filterLabel}>{timeFilterLabel}</span>
                                <DownOutlined />
                            </Space>
                        </a>
                    </Dropdown>
                </div>
                <div className={s.rowWrap}>
                    <div className={s.rowIn}>
                        <div className={s.item}>
                            <div className={s.top}>
                                <div className={s.label}>Total survey sent</div>
                            </div>
                            <div className={s.bottom}>
                                <div className={s.left}><span className={s.val}>{surveySendTotal}</span> from {preSurveySendTotal}</div>
                                <div className={s.right}>
                                    {
                                        renderRisingArrow(surveySendIncrRate)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={s.item}>
                            <div className={s.top}>
                                <div className={s.label}>Average survey rating</div>
                            </div>
                            <div className={s.bottom}>
                                <div className={s.left}><span className={s.val}>{averageSurveyRating}</span> <span className={s.valDes}>out of 5</span> from {preAverageSurveyRating}</div>
                                <div className={s.right}>
                                    {
                                        renderRisingArrow(averageRatingIncrRate)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={s.item}>
                            <div className={s.top}>
                                <div className={s.label}>Total feedback received</div>
                            </div>
                            <div className={s.bottom}>
                                <div className={s.left}><span className={s.val}>{feedbackReceived}</span> from {preFeedbackReceived}</div>
                                <div className={s.right}>
                                    {
                                        renderRisingArrow(feedbackReceivedIncrRate)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={s.item}>
                            <div className={s.top}>
                                <div className={s.label}>Total invitation sent</div>
                            </div>
                            <div className={s.bottom}>
                                <div className={s.left}><span className={s.val}>{invitationSend}</span> from {preInvitationSend}</div>
                                <div className={s.right}>
                                    {
                                        renderRisingArrow(invitationSendIncrRate)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={s.tips}>
                {
                    showUpgrade && (
                        <div className={s.upgrade}>
                            <div className={s.nodeLeft}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M5 2C5.55228 2 6 2.44772 6 3V4H7C7.55228 4 8 4.44772 8 5C8 5.55228 7.55228 6 7 6H6V7C6 7.55228 5.55228 8 5 8C4.44772 8 4 7.55228 4 7V6H3C2.44772 6 2 5.55228 2 5C2 4.44772 2.44772 4 3 4H4V3C4 2.44772 4.44772 2 5 2ZM5 12C5.55228 12 6 12.4477 6 13V14H7C7.55228 14 8 14.4477 8 15C8 15.5523 7.55228 16 7 16H6V17C6 17.5523 5.55228 18 5 18C4.44772 18 4 17.5523 4 17V16H3C2.44772 16 2 15.5523 2 15C2 14.4477 2.44772 14 3 14H4V13C4 12.4477 4.44772 12 5 12Z" fill="#FBBF24" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M11.9999 2C12.4537 2 12.8505 2.30548 12.9667 2.74411L14.1459 7.19893L17.4997 9.13381C17.8092 9.31241 17.9999 9.64262 17.9999 10C17.9999 10.3574 17.8092 10.6876 17.4997 10.8662L14.1459 12.8011L12.9667 17.2559C12.8505 17.6945 12.4537 18 11.9999 18C11.5462 18 11.1493 17.6945 11.0332 17.2559L9.85402 12.8011L6.50027 10.8662C6.19072 10.6876 6 10.3574 6 10C6 9.64262 6.19072 9.31241 6.50027 9.13382L9.85402 7.19893L11.0332 2.74411C11.1493 2.30548 11.5462 2 11.9999 2Z" fill="#FBBF24" />
                                </svg>
                            </div>
                            <div className={s.nodeRight}>
                                <div className={s.text}>
                                    Elevate patient communication with our &apos;Reply Feedback&apos; tool. As a premium feature, it enables timely responses to patient feedback, building stronger trust and engagement. Enhance your practice&apos;s responsiveness and patient care by upgrading your plan now.
                                </div>
                                <div
                                    onClick={() => {
                                        openSubscriptionModal();
                                    }}
                                    className={s.upText}
                                >
                                    Upgrade plan
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    showChannelStore && (
                        <div className={s.upgrade}>
                            <div className={s.nodeLeft}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M5 2C5.55228 2 6 2.44772 6 3V4H7C7.55228 4 8 4.44772 8 5C8 5.55228 7.55228 6 7 6H6V7C6 7.55228 5.55228 8 5 8C4.44772 8 4 7.55228 4 7V6H3C2.44772 6 2 5.55228 2 5C2 4.44772 2.44772 4 3 4H4V3C4 2.44772 4.44772 2 5 2ZM5 12C5.55228 12 6 12.4477 6 13V14H7C7.55228 14 8 14.4477 8 15C8 15.5523 7.55228 16 7 16H6V17C6 17.5523 5.55228 18 5 18C4.44772 18 4 17.5523 4 17V16H3C2.44772 16 2 15.5523 2 15C2 14.4477 2.44772 14 3 14H4V13C4 12.4477 4.44772 12 5 12Z" fill="#FBBF24" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M11.9999 2C12.4537 2 12.8505 2.30548 12.9667 2.74411L14.1459 7.19893L17.4997 9.13381C17.8092 9.31241 17.9999 9.64262 17.9999 10C17.9999 10.3574 17.8092 10.6876 17.4997 10.8662L14.1459 12.8011L12.9667 17.2559C12.8505 17.6945 12.4537 18 11.9999 18C11.5462 18 11.1493 17.6945 11.0332 17.2559L9.85402 12.8011L6.50027 10.8662C6.19072 10.6876 6 10.3574 6 10C6 9.64262 6.19072 9.31241 6.50027 9.13382L9.85402 7.19893L11.0332 2.74411C11.1493 2.30548 11.5462 2 11.9999 2Z" fill="#FBBF24" />
                                </svg>
                            </div>
                            <div className={s.nodeRight}>
                                <div className={s.text}>
                                    To reply to feedback in Reputation Management, you must be listed on at least one channel. Visit the Channel Store to select and add your preferred channels.
                                </div>
                                <div
                                    onClick={() => {
                                        navigate('/dashboard/channel');
                                    }}
                                    className={s.upText}
                                >
                                    Browse channel store
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            <div className={s.feedback}>
                <div className={s.feedbackHeader}>
                    <h2>Feedbacks need reply ({totalFeedback})</h2>
                    <div className={s.feedbackFilter}>
                        <Dropdown menu={{ items: feedbackFilterItems }} trigger={['click']}>
                            <a onClick={(e) => e.preventDefault()}>
                                <Space>
                                    <span className={s.filterLabel}>{feedbackFilterLabel}</span>
                                    <DownOutlined />
                                </Space>
                            </a>
                        </Dropdown>
                    </div>
                </div>
                <Spin className={s.feedbackContent} spinning={loading}>
                    {
                        isEmpty && (
                            <div className={s.emtpyWrap}>
                                <div className={s.emptyTitle}>Great job! You’re all caught up.</div>
                                <div className={s.emptyDes}>No feedbacks need to be replied.</div>
                                <div className={s.emptyImg}>
                                    <img src={Empty} alt="" />
                                </div>
                            </div>
                        )
                    }
                    {
                        feedbackData?.map((feedback:PatientsFeedback) => {
                            return (
                                <div className={s.feedbackItem} key={`${feedback.id}`}>
                                    <div className={s.left}>
                                        <div className={s.nameAndScore}>
                                            <div className={s.name}>{`${feedback.firstName} ${feedback.lastName}`} </div>
                                            <div className={s.score}><ScoreLabel value={feedback.overallScore} /> </div>
                                        </div>
                                        <div className={s.contentAndTime}>
                                            <div className={s.content}>{feedback.feedbackContent}</div>
                                            <div className={s.time}>{getTimeStr(dayjs(feedback?.answerAt), 'MMMM D, YYYY')}</div>
                                        </div>
                                    </div>
                                    <div className={s.right}>
                                        <Button
                                            onClick={() => {
                                                setCurrentFeedback(feedback);
                                                setShowReply(true);
                                            }}
                                            className={`${canReply ? s.btn : s.btnDisabled}`}
                                            disabled={!canReply}
                                        >Reply
                                        </Button>
                                    </div>
                                </div>
                            );
                        })
                    }
                    <div className={s.paginationBox}>
                        <Pagination current={feedbackListPage} total={totalFeedback} pageSize={feedbackListPageSize} onChange={handlePaginationChange} />
                    </div>
                </Spin>

            </div>
            <ReplyFeedbackModal
                show={showReply}
                item={currentFeedback}
                onSuccess={() => {
                    setShowReply(false);
                }}
                onCannel={() => {
                    setShowReply(false);
                }}
            />
        </div>
    );
};

export default Overview;
