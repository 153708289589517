export const TITLE = 'Overview of Kiwi Health\'s key features';

export const CONFIG = [
    {
        title: 'Channel listing',
        description: 'Get your practice listed on essential and premium directories like Psychology Today and WebMD, improving your online presence.',
    },
    {
        title: 'Virtual Front Desk',
        description: 'Our HIPAA-trained virtual receptionists manage patient calls and appointments, ensuring you never miss an opportunity.',
    },
    {
        title: 'Medical SEO',
        description: 'Enhance your search engine ranking with our expert SEO services, attracting more potential clients.',
    },
    {
        title: 'Kiwi Practice Front',
        description: 'Build a user-friendly Kiwi Health profile page with local SEO benefits and your Klarity booking link, enhancing your online visibility.',
    },
];
