import React from 'react';
import s from './s.module.less';
import { Button } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';

type IProps = {
    link: string
};

const WatchVideo: React.FC<IProps> = ({ link }) => {
    const handleClick = () => {
        window.open(link, '_blank');
    };
    // return <a className={s.videoLink} href={link} target="_blank" rel="noreferrer">Watch tutorial</a>;
    return (
        <div className={s.button}>
            <Button ghost icon={<PlayCircleOutlined/>} onClick={handleClick}>Watch tutorial on YouTube</Button>
        </div>
    )
};

export default WatchVideo;
