// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--NyFJE {
  padding: 40px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 1260px;
  position: relative;
}
.s-module__wrap--NyFJE h1 {
  font-size: 30px;
  line-height: 36px;
  font-weight: bolder;
  color: #111827;
}
.s-module__wrap--NyFJE .s-module__des--Vqado {
  font-size: 16px;
  line-height: 24px;
  color: #6B7280;
  margin-top: 16px;
}
.s-module__wrap--NyFJE .s-module__header--S1rhn {
  flex-shrink: 0;
}
.s-module__wrap--NyFJE .s-module__content--hheJe {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 36px;
  font-size: 16px;
  line-height: 24px;
  color: var(--gray-500);
}
.s-module__wrap--NyFJE .s-module__content--hheJe .s-module__info--LBGcV {
  flex-shrink: 0;
}
.s-module__wrap--NyFJE .s-module__content--hheJe .s-module__btn--MUtdO {
  width: 186px;
  flex-shrink: 0;
  margin: 24px 0;
}
.s-module__wrap--NyFJE .s-module__content--hheJe .s-module__imgWrap--aaNTp {
  flex-grow: 1;
}
.s-module__wrap--NyFJE .s-module__content--hheJe .s-module__imgWrap--aaNTp img {
  height: 90%;
  max-height: 400px;
  max-width: 960px;
  width: 96%;
  -o-object-fit: contain;
     object-fit: contain;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/ProspectsPage/s.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,6BAAA;EACA,iBAAA;EACA,kBAAA;AACF;AARA;EAUI,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,cAAA;AACJ;AAdA;EAiBI,eAAA;EACA,iBAAA;EACA,cAAA;EACA,gBAAA;AAAJ;AApBA;EAwBI,cAAA;AADJ;AAvBA;EA4BI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AAFJ;AAhCA;EAqCM,cAAA;AAFN;AAnCA;EAyCM,YAAA;EACA,cAAA;EACA,cAAA;AAHN;AAxCA;EA8CM,YAAA;AAHN;AA3CA;EAgDQ,WAAA;EACA,iBAAA;EACA,gBAAA;EACA,UAAA;EACA,sBAAA;KAAA,mBAAA;AAFR","sourcesContent":[".wrap {\n  padding: 40px;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-around;\n  max-width: 1260px;\n  position: relative;\n\n  h1 {\n    font-size: 30px;\n    line-height: 36px;\n    font-weight: bolder;\n    color: #111827;\n  }\n\n  .des {\n    font-size: 16px;\n    line-height: 24px;\n    color: #6B7280;\n    margin-top: 16px;\n  }\n\n  .header{\n    flex-shrink: 0;\n  }\n\n  .content {\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n    margin-top: 36px;\n    font-size: 16px;\n    line-height: 24px;\n    color: var(--gray-500);\n\n    .info{\n      flex-shrink: 0;\n    }\n    .btn{\n      flex-shrink: 0;\n      width: 186px;\n      flex-shrink: 0;\n      margin: 24px 0;\n    }\n    .imgWrap {\n      flex-grow: 1;\n      img {\n        height: 90%;\n        max-height: 400px;\n        max-width: 960px;\n        width: 96%;\n        object-fit: contain;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--NyFJE`,
	"des": `s-module__des--Vqado`,
	"header": `s-module__header--S1rhn`,
	"content": `s-module__content--hheJe`,
	"info": `s-module__info--LBGcV`,
	"btn": `s-module__btn--MUtdO`,
	"imgWrap": `s-module__imgWrap--aaNTp`
};
export default ___CSS_LOADER_EXPORT___;
