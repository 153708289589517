import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox, Input, message, Pagination, Space } from 'antd';
import s from './s.module.less';
import ProviderStore from 'store/Provider';
import { CheckboxChangeEvent, CheckboxOptionType } from 'antd/lib/checkbox';
import { ColumnsType } from 'antd/es/table';
import { SearchOutlined, PhoneOutlined,
} from '@ant-design/icons';
import CommonTable from 'components/CommonTable';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { Prospect } from 'types/common';
import useMyListedChannels from 'hooks/useMyListedChannels';
import { getDisableSurveyCapNotice } from 'utils/localstore';
import SendSurveyNoteModal from '../SendSurveyNoteModal';
import { reputationSendSurvey } from 'api/provider';
import PatientTableEmpty from 'components/EmptyHolder/PatientTableEmpty';
import { getReputationPatientOverviewData, getSurveyHistory, getReputationPatientsList } from 'api/survey';
import SurveyHistoryModal from '../SurveyHistoryModal';
import { IPatientOverviewData } from 'types/reputation';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields, SettingEnum } from 'types/enumerationData';
import useMyPatientList from 'hooks/useMyPatientList';
import { isValidEmail } from 'utils/form';
import DataUpdatedFlagStore from 'store/DataUpdatedFlag';

type Props = {
    onSelectChange: (selectedRowKeys: number[]) => void;
};

const Patients = (props:Props) => {
    const { onSelectChange } = props;
    const navigate = useNavigate();
    const [getUser] = ProviderStore.useStore();

    const [data, setData] = useState<Prospect[]>([]);
    const [loading, setLoading] = useState(false);
    const [showNote, setShowNote] = React.useState<boolean>(false);
    const [showSurveyHistory, setShowSurveyHistory] = React.useState<boolean>(false);

    const [channels, loadingMychannel] = useMyListedChannels();
    const [selectKeys, setSelectKeys] = React.useState<React.Key[]>([]);

    const [pendingNameFilter, setPendingNameFilter] = React.useState('');
    const [nameFilter, setNameFilter] = React.useState('');

    const [pendingEmailFilter, setPendingEmailFilter] = React.useState('');
    const [emailFilter, setEmailFilter] = React.useState('');

    //undefined: all
    const [lastSurveySentFilter, setLastSurveySentFilter] = React.useState<string>();
    const [lastSendAnsweredFilter, setLastSendAnsweredFilter] = React.useState<boolean>();

    const [channelFilterCheckAll, setChannelFilterCheckAll] = React.useState<boolean>(false);
    const [channelFilter, setChannelFilter] = React.useState<number[]>([]);

    const disableSurveyCapNotice = getDisableSurveyCapNotice();
    const [surveyHistoryData, setSurveyHistoryData] = useState();

    const [myPatientListData] = useMyPatientList();

    const [overviewData, setOverviewData] = useState<IPatientOverviewData>({
        averageSurveyRating: 0,
        feedbackReceived: 0,
        invitationSend: 0,
        surveyDeliverySend: 0,
        surveySendThisWeek: 0,
        surveySendTotal: 0,
    });
    const [feedbackListPage, setFeedbackListPage] = useState(1);
    const [feedbackListPageSize, setFeedbackListPageSize] = useState(10);
    const [lastSurveySentOptionsData] = useGetSettingData(EnumFields.PATIENT_SURVEY_SEND_STATUS);

    const [currentPatient, setCurrentPatient] = React.useState<Prospect>();

    const [getDataUpdatedFlag] = DataUpdatedFlagStore.useStore();

    const patientListUpdated = getDataUpdatedFlag('patientListUpdated');
    const fetchPatientsList = async () => {
        if (loading) {
            return;
        }
        setLoading(true);
        try {
            //return all
            const res = await getReputationPatientsList({
                size: feedbackListPageSize,
                current: feedbackListPage,
            });

            if (res && !res.error && res.data?.data) {
                const record:Prospect[] = res.data.data || [];

                setData(record || []);
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    const fetchOverviewData = async () => {
        try {
            const res = await getReputationPatientOverviewData();

            if (res && !res.error && res.data?.data) {
                setOverviewData(res.data.data);
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        fetchOverviewData();
        fetchPatientsList();
    }, [patientListUpdated]);

    useEffect(() => {
        fetchPatientsList();
    }, [myPatientListData]);

    const handleSendSurvey = async () => {
        const patientData = data?.filter((item) => selectKeys.includes(item.id));
        const result = await reputationSendSurvey(selectKeys as number[]);
        if (!result.error) {
            message.success('Survey sent');
            setSelectKeys([]);
            fetchPatientsList();
        }
    };

    const handleClickSendSurvey = () => {
        if (!disableSurveyCapNotice) {
            //show confirm modal
            setShowNote(true);
        } else {
            handleSendSurvey();
        }
    };
    const lastSurveySentOptions:CheckboxOptionType[] = lastSurveySentOptionsData?.map((item:SettingEnum) => {
        return {
            label: item.content,
            value: item.dictKey,
        };
    }) || [];

    const lastSurveyRespondedOptions:CheckboxOptionType[] = [
        {
            label: 'No',
            value: false,
        },
        {
            label: 'Yes',
            value: true,
        },
    ];

    const channelFilterOptions:CheckboxOptionType[] = channels?.map((item) => {
        return {
            label: item.name,
            value: item.id,
        };
    }) || [];
    channelFilterOptions.push({
        label: 'Others',
        value: -1,
    });

    const handleChannelFilterCheckAll = (e:CheckboxChangeEvent) => {
        const check = !!e.target.checked;
        setChannelFilterCheckAll(e.target.checked);
        const checkedList = check ? channelFilterOptions.map((item) => item.value) : [];
        setChannelFilter(checkedList as number[]);
    };

    const handleChannelFilterChange = (values: CheckboxValueType[]) => {
        setChannelFilter(values as number[]);
        if (values?.length === channelFilterOptions?.length) {
            setChannelFilterCheckAll(true);
        } else {
            setChannelFilterCheckAll(false);
        }
    };

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectKeys(selectedRowKeys || []);
            onSelectChange(selectedRowKeys as number[]);
        },
        selectedRowKeys: selectKeys,
        getCheckboxProps: (record:Prospect) => {
            let disabled = true;
            const target = data.find((item) => item?.id === record?.id);
            if (target && target.email && isValidEmail(target.email)) {
                disabled = false;
            }
            return {
                disabled,
            };
        },
    };

    const getDataAfterFilter = () => {
        const retData:Prospect[] = data?.filter((item:Prospect) => {
            let ret = true;
            if (lastSurveySentFilter && ret) {
                ret = item.lastSurveySendStatus === lastSurveySentFilter;
            }
            if (lastSendAnsweredFilter !== undefined && ret) {
                ret = item.lastSendAnswered === lastSendAnsweredFilter;
            }
            if (nameFilter && ret) {
                ret = item?.firstName?.toLowerCase().includes(nameFilter) || item?.lastName?.toLowerCase().includes(nameFilter);
            }
            if (emailFilter !== undefined && ret) {
                ret = item?.email?.toLowerCase().includes(emailFilter);
            }
            // length > 0 means filter is on
            if (channelFilter && channelFilter.length > 0 && !channelFilterCheckAll && ret) {
                ret = !!item.channelId && channelFilter.includes(item.channelId as number);
            }
            return ret;
        });
        return retData;
    };

    const dataAfterFilter = getDataAfterFilter();

    // const handlePaginationChange = (page: number, pageSize: number) => {
    //     setFeedbackListPage(page);
    //     setFeedbackListPageSize(pageSize);
    //     fetchPatientsList();
    // };

    const columns: ColumnsType<Prospect> = [
        {
            title: 'NAME',
            dataIndex: 'name',
            filterSearch: true,
            width: '220px',
            filterIcon: <SearchOutlined />,
            filterDropdown: ({ close }) => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        value={pendingNameFilter}
                        onChange={(e) => {
                            setPendingNameFilter(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && pendingNameFilter) {
                                setNameFilter(pendingNameFilter);
                            }
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                setNameFilter(pendingNameFilter);
                                close();
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => {
                                setPendingNameFilter('');
                                setNameFilter('');
                                close();
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            render: (name:string, item:Prospect) => {
                return (
                    <div className={s.nameWrap}>
                        <div className={s.name}>
                            <span>{item.firstName} {item.lastName}</span>
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'EMAIL',
            dataIndex: 'email',
            filterSearch: true,
            filterIcon: <SearchOutlined />,
            filterDropdown: ({ close }) => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        value={pendingEmailFilter}
                        onChange={(e) => {
                            setPendingEmailFilter(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && pendingEmailFilter) {
                                setEmailFilter(pendingEmailFilter);
                            }
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                setEmailFilter(pendingEmailFilter);
                                close();
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => {
                                setPendingEmailFilter('');
                                setEmailFilter('');
                                close();
                            }}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
            render: (name:string, item:Prospect) => {
                return (
                    <div className={s.nameWrap}>
                        <div className={s.nameRight}>
                            <div className={s.name}>{item.email}</div>
                            {
                                !loading && item.tel &&
                                <div className={s.itemWithIcon}>
                                    <PhoneOutlined />
                                    <p className={s.val}>{item.tel || 'No phone number provided'}</p>
                                </div>
                            }
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'CHANNEL',
            dataIndex: 'channel',
            width: '180px',
            filterDropdown: () => {
                return (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Space>
                            <Checkbox onChange={handleChannelFilterCheckAll} checked={channelFilterCheckAll}>
                                Check all
                            </Checkbox>
                            <Checkbox.Group options={channelFilterOptions} value={channelFilter} onChange={handleChannelFilterChange} />
                        </Space>
                    </div>
                );
            },
            render: (channel, item) => {
                return (
                    <div>{`${item.channelId}` === '-1' ? 'Others' : channel}</div>
                );
            },
        },
        {
            title: 'LAST SURVEY SENT',
            dataIndex: 'lastSurveySendStatus',
            width: '220px',
            filterDropdown: ({ close }) => {
                return (
                    <div className={s.dropWrap}>
                        {
                            lastSurveySentOptions.map((item) => {
                                return (
                                    <div
                                        onClick={() => {
                                            setLastSurveySentFilter(item.value as string);
                                            close();
                                        }}
                                        className={s.dropItem}
                                        key={item.value as string}
                                    >
                                        {item.label}
                                    </div>
                                );
                            })
                        }
                        <div
                            onClick={() => {
                                setLastSurveySentFilter(undefined);
                                close();
                            }}
                            className={s.dropItem}
                        >All status
                        </div>
                    </div>
                );
            },
            render: (status) => {
                const item = lastSurveySentOptionsData?.find((item) => item.dictKey === status);
                const text = item ? item.content : status;
                return (
                    <div>{text || 'Never sent'}</div>
                );
            },
        },
        {
            title: 'LAST SURVEY RESPONDED',
            dataIndex: 'lastSendAnswered',
            width: '240px',
            filterDropdown: ({ close }) => {
                return (
                    <div className={s.dropWrap} onKeyDown={(e) => e.stopPropagation()}>
                        {
                            lastSurveyRespondedOptions?.map((item) => {
                                return (
                                    <div
                                        onClick={() => {
                                            setLastSendAnsweredFilter(item.value as boolean);
                                            close();
                                        }}
                                        className={s.dropItem}
                                        key={item.value as string}
                                    >
                                        {item.label}
                                    </div>
                                );
                            })
                        }
                        <div
                            onClick={() => {
                                setLastSendAnsweredFilter(undefined);
                                close();
                            }}
                            className={s.dropItem}
                        >All status
                        </div>
                    </div>
                );
            },
            render: (status:boolean) => {
                return (
                    <div>{status ? 'Yes' : 'No'}</div>
                );
            },
        },
        {
            title: 'SURVEY HISTORY',
            width: '160px',
            render: (item: Prospect) => {
                if (!item.sendCount) {
                    return null;
                }
                return (
                    <Button
                        type="text"
                        className={s.btnLink}
                        onClick={() => {
                            setCurrentPatient(item);
                            setShowSurveyHistory(true);
                        }}
                    >View
                    </Button>
                );
            },
        },
    ];

    const {
        surveySendThisWeek,
        surveySendTotal,
        surveyDeliverySend,
    } = overviewData;

    return (
        <div className={s.wrap}>
            <div className={s.rowWrap}>
                <div className={s.rowIn}>
                    <div className={s.item}>
                        <div className={s.top}>
                            <div className={s.label}>Survey sent this week</div>
                        </div>
                        <div className={s.bottom}>
                            <span className={s.val}>{surveySendThisWeek || 0}</span>
                        </div>
                    </div>
                    <div className={s.item}>
                        <div className={s.top}>
                            <div className={s.label}>Survey scheduled for delivery</div>
                        </div>
                        <div className={s.bottom}>
                            <span className={s.val}>{surveyDeliverySend || 0}</span>
                        </div>
                    </div>
                    <div className={s.item}>
                        <div className={s.top}>
                            <div className={s.label}>Total survey sent</div>
                        </div>
                        <div className={s.bottom}>
                            <span className={s.val}>{surveySendTotal || 0}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={s.header}>
                <h2>Patient list</h2>
            </div>
            <div className={s.content}>
                <div className={s.cHeader}>
                    {
                        selectKeys.length ?
                            <>
                                <div className={s.item}>
                                    {selectKeys.length} patients selected
                                </div>
                                <div className={s.btn}>
                                    <Button onClick={handleClickSendSurvey}>Send survey</Button>
                                </div>
                            </>
                            :
                            <div className={s.item}>Total {dataAfterFilter.length} patients</div>
                    }

                </div>
                <div className={s.tableWrap}>
                    <CommonTable
                        bordered
                        rowKey="id"
                        loading={loading}
                        columns={columns}
                        data={dataAfterFilter || []}
                        rowSelection={rowSelection}
                        locale={{
                            emptyText: <PatientTableEmpty />,
                        }}
                    />
                </div>
            </div>
            <SendSurveyNoteModal
                show={showNote}
                onCancel={() => {
                    setShowNote(false);
                }}
                onOk={() => {
                    handleSendSurvey();
                    setShowNote(false);
                }}
            />
            <SurveyHistoryModal
                show={showSurveyHistory}
                item={currentPatient}
                onCancel={() => {
                    setShowSurveyHistory(false);
                }}
            />
        </div>
    );
};

export default Patients;
