// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--c1C_q {
  padding-top: 105px;
  padding-bottom: 100px;
}
.s-module__wrap--c1C_q .s-module__header--wInwR {
  height: 74px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-100);
  background-color: var(--white);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}
.s-module__wrap--c1C_q .s-module__header--wInwR .s-module__logo--n2sxk {
  height: 38px;
}
.s-module__wrap--c1C_q .s-module__footer--aSKhG {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70px;
  padding: 0 24px;
  border-top: 1px solid var(--gray-200);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: var(--white);
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/KlarityProcessPages/KlarityPlanSubscribePage/s.module.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,qBAAA;AACJ;AAHA;EAIQ,YAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,wCAAA;EACA,8BAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,aAAA;AAER;AAjBA;EAkBY,YAAA;AAEZ;AApBA;EAsBQ,eAAA;EACA,OAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,qCAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,8BAAA;AACR","sourcesContent":[".wrap {\n    padding-top: 105px;\n    padding-bottom: 100px;\n    .header {\n        height: 74px;\n        padding: 0 24px;\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n        border-bottom: 1px solid var(--gray-100);\n        background-color: var(--white);\n        position: fixed;\n        top: 0;\n        left: 0;\n        width: 100%;\n        z-index: 1000;\n\n        .logo {\n            height: 38px;\n        }\n    }\n    .footer {\n        position: fixed;\n        left: 0;\n        bottom: 0;\n        width: 100%;\n        height: 70px;\n        padding: 0 24px;\n        border-top: 1px solid var(--gray-200);\n        display: flex;\n        align-items: center;\n        justify-content: flex-end;\n        background-color: var(--white);\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--c1C_q`,
	"header": `s-module__header--wInwR`,
	"logo": `s-module__logo--n2sxk`,
	"footer": `s-module__footer--aSKhG`
};
export default ___CSS_LOADER_EXPORT___;
