// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--N9HBz {
  color: #333;
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 146px);
}
.s-module__wrap--N9HBz .s-module__msg--m34DS {
  background: #F9FAFB;
  margin-bottom: 16px;
}
.s-module__wrap--N9HBz .s-module__msgFromMe--EHGKm {
  background: rgba(238, 242, 255, 0.5);
}
.s-module__wrap--N9HBz .s-module__msgHeader--BhEKS {
  display: flex;
  padding: 16px;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
  margin-bottom: 8px;
}
.s-module__wrap--N9HBz .s-module__title--uHndM {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
}
.s-module__wrap--N9HBz .s-module__from--TI_pA {
  font-size: 12px;
  color: #374151;
  margin-top: 4px;
}
.s-module__wrap--N9HBz .s-module__right--yf0hM {
  font-size: 12px;
  color: #6B7280;
}
.s-module__wrap--N9HBz .s-module__msgContent--lkMsU {
  padding: 16px;
}
.s-module__wrap--N9HBz .s-module__replyWrap--J9QQn {
  width: 100%;
  background: #fff;
  padding: 0;
}
.s-module__wrap--N9HBz .s-module__replyWrap--J9QQn h4 {
  line-height: 36px;
}
.s-module__wrap--N9HBz .s-module__replyWrap--J9QQn .s-module__btn--Z62LO {
  margin-top: 8px;
  margin-bottom: 8px;
}
.s-module__wrap--N9HBz .s-module__listWrap--Ou8ca {
  flex: 1;
  flex-shrink: 0;
  overflow: auto;
  padding: 0 4px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/ProspectsDetailPage/components/notes/s.module.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,2BAAA;AACF;AANA;EAOI,mBAAA;EACA,mBAAA;AAEJ;AAVA;EAWI,oCAAA;AAEJ;AAbA;EAcI,aAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;EACA,6BAAA;EACA,kBAAA;AAEJ;AAtBA;EAuBI,eAAA;EACA,iBAAA;EACA,iBAAA;AAEJ;AA3BA;EA4BI,eAAA;EACA,cAAA;EACA,eAAA;AAEJ;AAhCA;EAiCI,eAAA;EACA,cAAA;AAEJ;AApCA;EAqCI,aAAA;AAEJ;AAvCA;EAwCI,WAAA;EACA,gBAAA;EACA,UAAA;AAEJ;AA5CA;EA4CM,iBAAA;AAGN;AA/CA;EA+CM,eAAA;EACA,kBAAA;AAGN;AAnDA;EAoDI,OAAA;EACA,cAAA;EACA,cAAA;EACA,cAAA;AAEJ","sourcesContent":[".wrap{\n  color: #333;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  height: calc(100vh - 146px);\n  .msg{\n    background: #F9FAFB;\n    margin-bottom: 16px;\n  }\n  .msgFromMe{\n    background: rgba(238, 242, 255, 0.5);\n  }\n  .msgHeader{\n    display: flex;\n    padding: 16px;\n    flex-direction: row;\n    justify-content: space-between;\n    padding-bottom: 8px;\n    border-bottom: 1px solid #eee;\n    margin-bottom: 8px;\n  }\n  .title{\n    font-size: 14px;\n    line-height: 20px;\n    font-weight: bold;\n  }\n  .from{\n    font-size: 12px;\n    color: #374151;\n    margin-top: 4px;\n  }\n  .right{\n    font-size: 12px;\n    color: #6B7280;\n  }\n  .msgContent{\n    padding: 16px;\n  }\n  .replyWrap{\n    width: 100%;\n    background: #fff;\n    padding: 0;\n    h4 {\n      line-height: 36px;\n    }\n    .btn {\n      margin-top: 8px;\n      margin-bottom: 8px;\n    }\n  }\n  .listWrap{\n    flex: 1;\n    flex-shrink: 0;\n    overflow: auto;\n    padding: 0 4px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--N9HBz`,
	"msg": `s-module__msg--m34DS`,
	"msgFromMe": `s-module__msgFromMe--EHGKm`,
	"msgHeader": `s-module__msgHeader--BhEKS`,
	"title": `s-module__title--uHndM`,
	"from": `s-module__from--TI_pA`,
	"right": `s-module__right--yf0hM`,
	"msgContent": `s-module__msgContent--lkMsU`,
	"replyWrap": `s-module__replyWrap--J9QQn`,
	"btn": `s-module__btn--Z62LO`,
	"listWrap": `s-module__listWrap--Ou8ca`
};
export default ___CSS_LOADER_EXPORT___;
