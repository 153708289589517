import { Modal, Checkbox, Tooltip, Button } from 'antd';
import React from 'react';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { TCollaboratingPhysician } from 'types/common';
import useCollaboratingPhysician from 'hooks/useCollaboratingPhysician';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import EditCollaboratingPhysician from './components/EditCollaboratingPhysician';
import { generateUUID } from 'utils/common';
import { EhrAddOnItem } from 'types/ehr';
import { addToShoppingCart } from 'api/ehr';
import { getUserId } from 'utils/localstore';

type Props = {
    clearFlag?: {};
    open:boolean;
    onAdd:(item:TCollaboratingPhysician[])=>void;
    onClose: ()=>void;
    item?: EhrAddOnItem;
};

type PendingCollaboratingPhysician = Partial<TCollaboratingPhysician> & {
    uuid: string;
    isEdit?: boolean;
};

const CollaboratingPhysicianSeatModal = (props:Props) => {
    const { clearFlag, open, onAdd, onClose } = props;
    const product: EhrAddOnItem | undefined = props.item;
    const [submiting, setSubmiting] = React.useState(false);
    const [allCollaboratingPhysician, loading, refetch] = useCollaboratingPhysician(product?.productCode as any);
    const [pendingSelected, setPendingSelected] = React.useState<TCollaboratingPhysician[]>([]);
    const [pendingAdd, setPendingAdd] = React.useState<PendingCollaboratingPhysician[]>([]);
    const [existingState, setExistingState] = React.useState<string[]>();

    const addCount = pendingAdd?.filter((item) => !!item.email).length || 0;
    const allCount = (pendingSelected?.length || 0) + addCount;

    const PRICE_PER = product?.price || 0;

    React.useEffect(() => {
        const _existingState:string[] = [];
        allCollaboratingPhysician?.forEach((item) => {
            if (!_existingState.includes(item.state)) {
                _existingState.push(item.state);
            }
        });
        pendingAdd?.forEach((item) => {
            if (item.state && !_existingState.includes(item.state)) {
                _existingState.push(item.state);
            }
        });
        setExistingState(_existingState);
    }, [allCollaboratingPhysician, pendingAdd]);

    const reset = () => {
        setPendingAdd([]);
        setPendingSelected([]);
        refetch();
        onAdd?.([]);
    };

    const handleOk = async () => {
        const items: TCollaboratingPhysician[] = [];
        const physicianIdList: number[] = [];
        const physicianList: TCollaboratingPhysician[] = [];
        pendingSelected?.forEach((item) => {
            items.push(item);
            physicianIdList.push(item.id!);
        });
        pendingAdd?.forEach((item) => {
            const newItem:TCollaboratingPhysician = {
                providerId: getUserId()!,
                choice: 3,
                name: item.name!,
                email: item.email!,
                tel: item.tel!,
                address: item.address!,
                zip: item.zip!,
                state: item.state!, //'AZ',
                physicianState: item.state!, // 'AK',
                npi: item.npi!,
                yearExp: item.yearExp!,
                licenseState: item.licenseState!,
                specialities: item.specialities!, //'specialities1',
                effectiveDate: item.effectiveDate!, // '2023-08-17T00:00:00.000+00:00',
                expDate: item.expDate!, // '2023-11-23T00:00:00.000+00:00',
                url: item.url!, //  'https://saas-dev-jp.s3.ap-northeast-1.amazonaws.com/dev/6ced5c1c-9df7-4857-af90-805f3bfffaf8.png'
                deaNumber: item.deaNumber!,
                birthdayDate: item.birthdayDate!,
            };
            physicianList.push(newItem);
            items.push(newItem);
        });
        //call api
        setSubmiting(true);
        const result = await addToShoppingCart(product?.productCode!, items.length, {
            physicianIdList,
            physicianList,
        });
        setSubmiting(false);
        if (!result.error) {
            onAdd?.(items);
        }
    };

    React.useEffect(() => {
        if (clearFlag) {
            reset();
        }
    }, [clearFlag]);

    const handleCheckboxChange = (checkedValues: CheckboxValueType[]) => {
        const numberValues = checkedValues.map((value) => Number(value)) as number[];
        setPendingSelected(allCollaboratingPhysician.filter((item) => numberValues.includes(item.id!)));
    };

    const allEmails = [...(pendingAdd?.map((item) => item.email).filter(Boolean) || []), ...(allCollaboratingPhysician?.map((item) => item.email).filter(Boolean) || [])];

    const hasEditing = pendingAdd.find((item) => item.isEdit) && (pendingSelected?.length > 0 || pendingAdd?.length > 0);

    return (
        <Modal
            title="Collaborating physician seat"
            open={open}
            destroyOnClose
            className={commonS.modalFixHeightWrap}
            width="80%"
            style={{ maxWidth: '1200px' }}
            onCancel={() => onClose()}
            footer={[
                <Button key="back" onClick={onClose}>
                    Cancel
                </Button>,
                <Tooltip
                    key="ok"
                    title={
                        hasEditing
                            ? "Click “Save” to confirm the collaborating physician's information before adding it to Kiwi EHR."
                            : ''
                    }
                >
                    <Button
                        className={s.btn2}
                        type="primary"
                        onClick={handleOk}
                        loading={submiting}
                        disabled={!!hasEditing}
                    >
                        Add
                    </Button>
                </Tooltip>,
            ]}
        >
            <div className={s.wrap}>
                <div className={s.title}>
                    You&apos;ve added {allCount || 0} collaborating physicians, it will
                    be ${PRICE_PER * (allCount || 0)} monthly.
                </div>

                <div className={s.content}>
                    <div className={s.cContent}>
                        Existing collaborating physicians in your UniProfile
                    </div>
                    <div className={s.cDes}>
                        Add collaborating physicians from your UniProfile to your Kiwi EHR
                    </div>
                    <Checkbox.Group
                        style={{ width: '100%', display: 'block' }}
                        onChange={handleCheckboxChange}
                    >
                        {allCollaboratingPhysician.map((item) => {
                            const checked = pendingSelected?.find((i) => i.id === item.id);
                            return (
                                <div key={item.id} className={s.item}>
                                    <Checkbox
                                        value={item.id!}
                                        checked={!!checked}
                                        disabled={item.productPurchased}
                                    >
                                        {item.name} - {item.email}
                                    </Checkbox>
                                </div>
                            );
                        })}
                    </Checkbox.Group>
                </div>
                <div className={s.content}>
                    <div className={s.cContent}>New collaborating physician</div>
                    <div className={s.cDes}>
                        Add new collaborating physicians. All information syncs with your
                        UniProfile/Collaborating physician for streamlined management.
                    </div>
                    <div className={s.cAss}>
                        {pendingAdd?.map((item, inx) => {
                            return (
                                <div className={s.addItem} key={item.uuid}>
                                    <EditCollaboratingPhysician
                                        onEditStatusChange={(isEdit: boolean) => {
                                            const newPendingAdd = [...pendingAdd];
                                            newPendingAdd[inx] = { ...newPendingAdd[inx], isEdit };
                                            setPendingAdd(newPendingAdd);
                                        }}
                                        existingState={existingState}
                                        onRemove={() => {
                                            const newPendingAdd = [...pendingAdd];
                                            newPendingAdd.splice(inx, 1);
                                            setPendingAdd(newPendingAdd);
                                        }}
                                        existEmails={allEmails as string[]}
                                        onAdd={(i) => {
                                            const newPendingAdd = [...pendingAdd];
                                            newPendingAdd[inx] = {
                                                ...i,
                                                uuid: newPendingAdd[inx].uuid,
                                                isEdit: false,
                                            };
                                            setPendingAdd(newPendingAdd);
                                        }}
                                        name={`Collaborating physician ${inx + 1}`}
                                        value={item}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    {!hasEditing && (
                        <div className={s.actionWrap}>
                            <span
                                className={s.link}
                                onClick={() => {
                                    setPendingAdd([
                                        ...(pendingAdd || []),
                                        { uuid: generateUUID(), isEdit: true },
                                    ]);
                                }}
                            >
                                Add a collaborating physician
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default CollaboratingPhysicianSeatModal;
