import React, { useState, useEffect } from 'react';
import { Button, Descriptions, message, Spin } from 'antd';
import { getEhrAccount, getEhrPwd } from 'api/ehr';
import copy from 'copy-to-clipboard';

import s from './s.module.less';

type TInfo = {
    accountEmail: string;
    ehrLoginUrl: string;
    hasPassword: boolean;
    providerId: number;
};

const AccountCard: React.FC = () => {
    const [info, setInfo] = useState<TInfo>({} as TInfo);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingInfo, setIsLoadingInfo] = useState<boolean>(false);

    const hanldeGetEhrAccount = async () => {
        setIsLoadingInfo(true);
        try {
            const { data } = await getEhrAccount();
            setInfo(data?.data);
            setIsLoadingInfo(false);
        } catch (error) {
            setIsLoadingInfo(false);
        }
    };

    const handleGetEhrPwd = async () => {
        setIsLoading(true);
        try {
            const { data } = await getEhrPwd();
            copy(data?.data, {
                format: 'text/plain',
            });
            message.success('Copied to the clipboard');
            setIsLoading(false);
        } catch (e) {
            message.error('Copied to the clipboard failed');
            setIsLoading(false);
        }
    };

    const handleCopyEmail = (email: string) => {
        copy(email, {
            format: 'text/plain',
        });
        message.success('Copied to the clipboard');
    };

    useEffect(() => {
        hanldeGetEhrAccount();
    }, []);
    return (
        <Spin spinning={isLoadingInfo}>
            <div className={s.cardItem}>
                <Descriptions
                    title="Account"
                    layout="vertical"
                    extra={
                        <Button
                            onClick={() => {
                                window.open(info.ehrLoginUrl, '_blank');
                            }}
                        >
                            Kiwi EHR login
                        </Button>
                    }
                    column={1}
                    colon={false}
                    size="small"
                    contentStyle={{
                        marginBottom: '16px',
                    }}
                >
                    <Descriptions.Item label="Email address">
                        <div className={s.descriptionRow}>
                            <p className={s.email}>{info?.accountEmail}</p>
                            <span
                                onClick={() => handleCopyEmail(info?.accountEmail)}
                                className={s.copyIcon}
                            />
                        </div>
                    </Descriptions.Item>
                    <Descriptions.Item label="Password">
                        <Spin spinning={isLoading}>
                            <div className={s.descriptionRow}>
                                <p className={s.pwd}>*************</p>
                                <span
                                    onClick={() => handleGetEhrPwd()}
                                    className={s.copyIcon}
                                />
                            </div>
                        </Spin>
                    </Descriptions.Item>
                </Descriptions>
                <div className={s.warningTip}>
                    <div className={s.tipIcon} />
                    <div className={s.tipContent}>
                        <div className={s.tipDesc}>
                            To allow Klarity Onboarding team best assist you, please don't
                            update your initial password within the next 60 days.
                        </div>
                    </div>
                </div>
            </div>
        </Spin>
    );
};

export default AccountCard;
