import React from 'react';
import { Form, Input, Modal, message } from 'antd';
import { PatientsFeedback } from 'types/common';
import s from './s.module.less';
import dayjs from 'utils/dayjs';
import commonS from 'styles/common.module.less';
import { FormReply } from './types';
import ScoreLabel from 'components/ScoreLabel';
import { getTimeStr } from 'utils/calendar';
import TextArea from 'antd/es/input/TextArea';
import { postReputationFeedbackReply } from 'api/survey';
import DataUpdatedFlagStore from 'store/DataUpdatedFlag';

type Props = {
    show:boolean,
    item?:PatientsFeedback,
    onCannel?: ()=>void,
    onSuccess?: ()=>void
};

const ReplyFeedbackModal = (props: Props) => {
    const { show, item, onCannel, onSuccess } = props;
    const [submiting, setSubmiting] = React.useState(false);
    const [form] = Form.useForm<FormReply>();
    const [, setDataUpdatedFlag] = DataUpdatedFlagStore.useStore();

    React.useEffect(() => {
        if (!show) {
            form.resetFields();
        }
    }, [show]);

    const onSubmit = async () => {
        try {
            const formValues = await form.validateFields();

            const data = {
                ...formValues,
                answerId: item?.id!,
            };

            setSubmiting(true);

            const result = await postReputationFeedbackReply(data);
            if (!result.error) {
                message.success('Reply success');
                setDataUpdatedFlag('patientFeedbackListUpdated', {});
                if (onSuccess) {
                    onSuccess();
                }
            }
        } catch (e:any) {
            if (e.errorFields) {
                //form error
                return;
            }
            //const msg = e?.toString?.() || 'data error';
            //message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };

    return (
        <Modal
            title="Reply feedback"
            closable
            className={commonS.modalFixHeightWrap}
            width="1100px"
            onCancel={() => {
                onCannel?.();
            }}
            okText="Send"
            open={show}
            onOk={onSubmit}
            destroyOnClose
            okButtonProps={{ loading: submiting }}
            cancelButtonProps={{ style: { display: 'none' } }}
        >
            <div className={s.wrap}>
                <div className={s.feedbackItem} key={`${item?.firstName} ${item?.lastName}`}>
                    <div className={s.left}>
                        <div className={s.nameAndScore}>
                            <div className={s.name}>{`${item?.firstName} ${item?.lastName}`}</div>
                            <div className={s.score}><ScoreLabel value={item?.overallScore} /> </div>
                        </div>
                        <div className={s.contentAndTime}>
                            <div className={s.content}>{item?.feedbackContent}</div>
                            <div className={s.time}>{getTimeStr(dayjs(item?.answerAt), 'MMMM D, YYYY')}</div>
                        </div>
                    </div>
                </div>
                <Form
                    form={form}
                    name="basic"
                    className={commonS.formStyle1}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    layout="vertical"
                    scrollToFirstError
                >
                    <div className={s.modalWrap}>
                        <div className={s.row}>
                            <div className={s.rowItem}>
                                <Form.Item
                                    style={{ width: '700px' }}
                                    label="Subject"
                                    name="subject"
                                    rules={[
                                        { required: true, message: 'Subject is required' },
                                        { max: 100, message: 'Subject maximum character length of 100' },
                                    ]}
                                >
                                    <Input maxLength={100} placeholder="" />
                                </Form.Item>
                            </div>
                        </div>

                        <div className={s.maxTips}>Max. 3000 characters</div>
                        <div className={s.row} style={{ marginTop: '-24px' }}>
                            <div className={s.rowItem}>
                                <Form.Item
                                    style={{ width: '700px' }}
                                    label="Message"
                                    name="content"
                                    rules={[
                                        { required: true, message: 'Message is required' },
                                        { max: 3000, message: 'Message maximum character length of 3000' },
                                    ]}
                                >
                                    <TextArea rows={6} placeholder="" maxLength={3000} />
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </Modal>
    );
};

export default ReplyFeedbackModal;
