import React, { useMemo } from 'react';
import s from './s.module.less';
// import BasicInfo from 'components/PracticeFrontFromComponents/BasicInfo';
import BasicInfo from 'components/HelloKlarityFormComponents/BasicInfo';
import Recognition from 'components/HelloKlarityFormComponents/Recognition';
import { EditModuleEnum } from 'types/practiceFront';
import WorkExperience from 'components/PracticeFrontFromComponents/WorkExperience';
import Education from 'components/PracticeFrontFromComponents/Education';
import { UniprofileFormData } from 'types/provider';
import { IBasicInfo } from 'types/helloKlarity';

interface IProps {
    providerData: UniprofileFormData;
    onSubmit?: (formValue, field: EditModuleEnum, callback?: () => void) => void;
    refetch: () => void;
}

const About = ({
    onSubmit,
    refetch,
    providerData,
    // setTabKey,
}: IProps) => {
    const { profile, id, practice, experienceList, educationList, addtional } = providerData;
    const { firstName, lastName, middleName, credential, headLine, title, yearExp, npi, intro, primaryLanguage, foreignLanguage, photo } = profile;
    const basicInfo: IBasicInfo = {
        id,
        firstName: firstName as string,
        lastName: lastName as string,
        middleName,
        credential,
        headLine,
        title,
        yearExp,
        npi,
        intro,
        language: `${primaryLanguage}${foreignLanguage && foreignLanguage.length > 0 ? `, ${foreignLanguage.join(', ')}` : ''}`,
        photo,
        providerPractice: practice,
    };

    const commomProps = useMemo(() => ({
        onSubmit,
        refetch,
        onEditStatusChange: () => {},
    }), [onSubmit, refetch]);

    return (
        <div className={s.wrap}>
            <div className={s.editItem}>
                <BasicInfo {...commomProps} initFormValue={basicInfo} />
            </div>
            <div className={s.editItem}>
                <WorkExperience readonly {...commomProps} initFormValue={experienceList} />
            </div>
            <div className={s.editItem}>
                <Education readonly {...commomProps} initFormValue={educationList} />
            </div>
            <div className={s.editItem}>
                <Recognition initFormValue={addtional} />
            </div>
        </div>
    );
};

export default About;
