import React from 'react';
import SurveyIntroPic from 'assets/survey/surveyIntroPic.svg';
import s from './s.module.less';

interface IProps {
    hideIndex: boolean;
}

const SurveyIntro = ({
    hideIndex,
}: IProps) => {
    return (
        <div className={s.introWrap}>
            <div className={s.left}>
                { !hideIndex && <div className={s.stepText}>Step 1</div> }
                <div className={s.title}>Send survey to existing patients to gather baseline feedback</div>
                <div className={s.desc}>In this step, you will have the opportunity to edit and refine the wording of both the survey invitation email template and the survey questions. The survey results and feedback collected will be accessible in “Reputation Management” tab.</div>
                <div className={s.tip}>
                    <div className={s.tipIcon} />
                    <div className={s.tipContent}>The number of surveys you can send each week depends on your current account level, but upgrading for additional capacity is always an option.</div>
                </div>
            </div>
            <div className={s.right}>
                <img src={SurveyIntroPic} />
            </div>
        </div>
    );
};

export default SurveyIntro;
