// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__formBody--jOGKx .ant-form-item-required::after {
  display: none !important;
}
.s-module__modalWrap--gNMi_ h3 {
  margin-top: 24px;
  padding-top: 36px;
  width: 96%;
}
.s-module__modalWrap--gNMi_ .s-module__h3Btm--rgAt4 {
  border-top: 1px solid #D9D9D9;
}
.s-module__display--RH2J_ {
  height: auto;
}
.s-module__row--G6dsA {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
}
.s-module__row--G6dsA .s-module__displayItem--jIH9J {
  margin-bottom: 12px;
}
.s-module__row--G6dsA .s-module__displayItem--jIH9J .s-module__label--bf0SR {
  color: #6B7280;
  font-size: 14px;
  line-height: 20px;
}
.s-module__rowItem--rd3fR {
  margin-right: 24px;
}
.s-module__colItem--Q3QOg {
  display: flex;
  flex-direction: column;
}
.s-module__add--EcleK {
  color: var(--primary-color);
  font-size: 14px;
  line-height: 20px;
  margin-top: 36px;
}
.s-module__add--EcleK:hover {
  text-decoration: underline;
  opacity: 0.7;
  cursor: pointer;
}
.s-module__labelWrap--ycakz {
  width: 460px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.s-module__formBody--jOGKx:first-child {
  margin-top: 36px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/ProspectsManagePage/UpdateEmailModal/s.module.less"],"names":[],"mappings":"AAAA;EAGM,wBAAA;AADN;AAKA;EAEI,gBAAA;EACA,iBAAA;EACA,UAAA;AAJJ;AAAA;EAOI,6BAAA;AAJJ;AAOA;EACE,YAAA;AALF;AAQA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;AANF;AAEA;EAWI,mBAAA;AAVJ;AADA;EAOM,cAAA;EACA,eAAA;EACA,iBAAA;AAHN;AASA;EACE,kBAAA;AAPF;AAUA;EACE,aAAA;EACA,sBAAA;AARF;AAWA;EACE,2BAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AATF;AAWA;EACE,0BAAA;EACA,YAAA;EACA,eAAA;AATF;AAWA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;AATF;AAYA;EACE,gBAAA;AAVF","sourcesContent":[".formBody {\n  :global{\n    .ant-form-item-required::after{\n      display: none !important;\n    }\n  }\n}\n.modalWrap{\n  h3 {\n    margin-top: 24px;\n    padding-top: 36px;\n    width: 96%;\n  }\n  .h3Btm{\n    border-top: 1px solid #D9D9D9;\n  }\n}\n.display {\n  height: auto;\n}\n\n.row {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-top: 16px;\n  .displayItem{\n    .label {\n      color: #6B7280;\n      font-size: 14px;\n      line-height: 20px;\n    }\n    margin-bottom: 12px;\n  }\n}\n\n.rowItem {\n  margin-right: 24px;\n}\n\n.colItem {\n  display: flex;\n  flex-direction: column;\n}\n\n.add{\n  color: var(--primary-color);\n  font-size: 14px;\n  line-height: 20px;\n  margin-top: 36px;\n}\n.add:hover{\n  text-decoration: underline;\n  opacity: 0.7;\n  cursor: pointer;\n}\n.labelWrap{\n  width: 460px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.formBody:first-child {\n  margin-top: 36px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formBody": `s-module__formBody--jOGKx`,
	"modalWrap": `s-module__modalWrap--gNMi_`,
	"h3Btm": `s-module__h3Btm--rgAt4`,
	"display": `s-module__display--RH2J_`,
	"row": `s-module__row--G6dsA`,
	"displayItem": `s-module__displayItem--jIH9J`,
	"label": `s-module__label--bf0SR`,
	"rowItem": `s-module__rowItem--rd3fR`,
	"colItem": `s-module__colItem--Q3QOg`,
	"add": `s-module__add--EcleK`,
	"labelWrap": `s-module__labelWrap--ycakz`
};
export default ___CSS_LOADER_EXPORT___;
