import { Button, notification, Spin } from 'antd';
import React from 'react';
import s from './s.module.less';
import AddAppointmentModal from 'components/AddAppointmentModal';
import { useRequest } from 'ahooks';
import { getProspectAppointList } from 'api/prospect';
import { Prospect } from 'types/common';
import { ServerEvent } from 'types/calendar';
import { getTimeStr } from 'utils/calendar';
import dayjs from 'dayjs';

type Props = {
    prospect?: Prospect,
    onUpcomingChange?: (amount: number) => void,
};
const Page = (prop:Props) => {
    const { prospect, onUpcomingChange } = prop;
    const [api, contextHolder] = notification.useNotification();
    const [showAddAppointment, setShowAddAppointment] = React.useState(false);
    const { data, loading, run } = useRequest(getProspectAppointList, {
        manual: true,
    });
    const prospectId = prospect?.id;

    React.useEffect(() => {
        if (prospectId) {
            run(prospectId);
        }
    }, [prospectId]);

    React.useEffect(() => {
        if (data?.data?.data) {
            const items = data?.data?.data?.upComing || [];
            if (onUpcomingChange) {
                onUpcomingChange(items.length);
            }
        }
    }, [data]);

    const upcomingList:ServerEvent[] = data?.data?.data?.upComing || [];
    const historyList:ServerEvent[] = data?.data?.data?.history || [];

    return (
        <div className={s.wrap}>
            <div className={s.btnWrap}>
                <Button
                    onClick={() => {
                        setShowAddAppointment(true);
                    }}
                    type="primary"
                >
                    Book an appointment
                </Button>
            </div>
            <Spin spinning={loading}>
                {
                    upcomingList.length !== 0 &&
                    <div className={s.appWrap}>
                        <h3>Upcoming Appointment</h3>
                        <div className={s.listWrap}>
                            {
                                upcomingList.map((item:ServerEvent) => {
                                    return (
                                        <div className={s.item} key={item.eventId}>
                                            <div className={s.itemHeader}>
                                                {item?.serviceName || item?.summary}
                                            </div>
                                            <div className={s.itemContent}>
                                                <div className={s.itemContentItem}>
                                                    <div className={s.itemContentItemLabel}>Appointment time</div>
                                                    <div className={s.itemContentItemValue}>{getTimeStr(dayjs(item.start), 'MMM D, YYYY HH:mm A')}</div>
                                                </div>
                                                <div className={s.itemContentItem}>
                                                    <div className={s.itemContentItemLabel}>Duration</div>
                                                    <div className={s.itemContentItemValue}>{item.duration}</div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                }
                {
                    historyList.length !== 0 &&
                    <div className={s.appWrap}>
                        <h3>Appointment history</h3>
                        <div className={s.listWrap}>
                            {
                                historyList.map((item:ServerEvent) => {
                                    return (
                                        <div className={s.item} key={item.eventId}>
                                            <div className={s.itemHeader}>
                                                {item?.serviceName || item?.summary}
                                            </div>
                                            <div className={s.itemContent}>
                                                <div className={s.itemContentItem}>
                                                    <div className={s.itemContentItemLabel}>Appointment time</div>
                                                    <div className={s.itemContentItemValue}>{getTimeStr(dayjs(item.start), 'MMM D, YYYY HH:mm A')}</div>
                                                </div>
                                                <div className={s.itemContentItem}>
                                                    <div className={s.itemContentItemLabel}>Duration</div>
                                                    <div className={s.itemContentItemValue}>{item.duration}</div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                }
            </Spin>
            {
                showAddAppointment &&
                <AddAppointmentModal
                    onCancel={() => {
                        setShowAddAppointment(false);
                    }}
                    onSuccess={() => {
                        setShowAddAppointment(false);
                        run(prospectId!);
                    }}
                    api={api}
                    currentChannelId={prospect?.channelId as number}
                    prospect={prospect}
                />
            }
            {contextHolder}
        </div>
    );
};

export default Page;
