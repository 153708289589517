import React, { useEffect, useState, useCallback } from 'react';
import s from './s.module.less';
import { Tooltip, message } from 'antd';
import { getQuickAction } from 'api/home';
import copy from 'copy-to-clipboard';
import { useNavigate } from 'react-router-dom';
import type { TProfileData } from 'types/home';

type IProps = {
    isKlarity: boolean;
    profileData: TProfileData;
};

const Index: React.FC<IProps> = ({ profileData, isKlarity }) => {
    const navigate = useNavigate();

    const renderEmpty = (
        <div className={s.empty}>Link not yet available. Stay tuned!</div>
    );

    const handleCopy = useCallback((value?: string) => {
        if (value) {
            copy(value || '', {
                format: 'text/plain',
            });
            message.success('Copied to clipboard!');
        }
    }, []);

    const renderKlarity = (
        <>
            <div className={s.shareWrap}>
                <div className={s.shareItem}>
                    <div className={s.shareHeader}>
                        <h3 className={s.shareTitle}>Share Google profile</h3>
                        <Tooltip
                            overlayClassName={s.tipCard}
                            color="#fff"
                            overlayInnerStyle={{
                                padding: '12px',
                                color: '#15191E',
                                backgroundColor: '#fff',
                            }}
                            title={
                                <div className={s.tipText}>
                                    Share your google profile to collect review
                                </div>
                            }
                        >
                            <div className={s.questionMarkIcon} />
                        </Tooltip>
                    </div>
                    {profileData.googleProfileLink ? (
                        <div className={s.shareContent}>
                            <div className={s.shareLink}>
                                {profileData.googleProfileLink}
                            </div>
                            <div
                                className={s.copyButton}
                                onClick={() => handleCopy(profileData.googleProfileLink)}
                            >
                                Copy
                            </div>
                        </div>
                    ) : (
                        renderEmpty
                    )}
                </div>
                <div className={s.shareItem}>
                    <div className={s.shareHeader}>
                        <h3 className={s.shareTitle}>Share Klarity profile</h3>
                        <Tooltip
                            overlayClassName={s.tipCard}
                            color="#fff"
                            overlayInnerStyle={{
                                padding: '12px',
                                color: '#15191E',
                                backgroundColor: '#fff',
                            }}
                            title={
                                <div className={s.tipText}>
                                    Share your Klarity profile page on social media to gain new
                                    patients
                                </div>
                            }
                        >
                            <div className={s.questionMarkIcon} />
                        </Tooltip>
                    </div>
                    {profileData.klarityProfileLink ? (
                        <div className={s.shareContent}>
                            <div className={s.shareLink}>
                                {profileData.klarityProfileLink}
                            </div>
                            <div
                                className={s.copyButton}
                                onClick={() => handleCopy(profileData.klarityProfileLink)}
                            >
                                Copy
                            </div>
                        </div>
                    ) : (
                        renderEmpty
                    )}
                </div>
                <div className={s.shareItem}>
                    <div className={s.shareHeader}>
                        <h3 className={s.shareTitle}>Share Practice Front</h3>
                        <Tooltip
                            overlayClassName={s.tipCard}
                            color="#fff"
                            overlayInnerStyle={{
                                padding: '12px',
                                color: '#15191E',
                                backgroundColor: '#fff',
                            }}
                            title={
                                <div className={s.tipText}>
                                    Share your Practice Front to existing patients to showcase
                                    your full services
                                </div>
                            }
                        >
                            <div className={s.questionMarkIcon} />
                        </Tooltip>
                    </div>
                    {profileData.pfLink ? (
                        <div className={s.shareContent}>
                            <div className={s.shareLink}>{profileData.pfLink}</div>
                            <div
                                className={s.copyButton}
                                onClick={() => handleCopy(profileData.pfLink)}
                            >
                                Copy
                            </div>
                        </div>
                    ) : (
                        renderEmpty
                    )}
                </div>
            </div>
            <div className={s.profileWrap}>
                {profileData.klarityProfileLink && (
                    <div
                        className={s.profileItem}
                        onClick={() => window.open(profileData.klarityProfileLink)}
                    >
                        My profile page
                    </div>
                )}
                <div
                    className={s.profileItem}
                    onClick={() =>
                        navigate('/create-uni-profile?type=uniprofile&from=home&step=7')
                    }
                >
                    My practice templates
                </div>
                <div
                    className={s.profileItem}
                    onClick={() => navigate('/dashboard/channel')}
                >
                    My live channels ({profileData.listedChannelCount})
                </div>
                <div
                    className={s.profileItem}
                    onClick={() =>
                        navigate('/create-uni-profile?type=uniprofile&from=home')
                    }
                >
                    Update my UniProfile
                </div>
                <div
                    className={s.profileItem}
                    onClick={() => navigate('/dashboard/practice-front')}
                >
                    Update my Practice Front
                </div>
            </div>
        </>
    );

    const renderKiwi = (
        <>
            <div className={s.shareWrap}>
                <div className={s.shareItem}>
                    <div className={s.shareHeader}>
                        <h3 className={s.shareTitle}>Share Google profile</h3>
                        <Tooltip
                            overlayClassName={s.tipCard}
                            color="#fff"
                            overlayInnerStyle={{
                                padding: '12px',
                                color: '#15191E',
                                backgroundColor: '#fff',
                            }}
                            title={
                                <div className={s.tipText}>
                                    Share your google profile to collect review
                                </div>
                            }
                        >
                            <div className={s.questionMarkIcon} />
                        </Tooltip>
                    </div>
                    {profileData.googleProfileLink ? (
                        <div className={s.shareContent}>
                            <div className={s.shareLink}>
                                {profileData.googleProfileLink}
                            </div>
                            <div
                                className={s.copyButton}
                                onClick={() => handleCopy(profileData.googleProfileLink)}
                            >
                                Copy
                            </div>
                        </div>
                    ) : (
                        renderEmpty
                    )}
                </div>
                <div className={s.shareItem}>
                    <div className={s.shareHeader}>
                        <h3 className={s.shareTitle}>Share Practice Front</h3>
                        <Tooltip
                            overlayClassName={s.tipCard}
                            color="#fff"
                            overlayInnerStyle={{
                                padding: '12px',
                                color: '#15191E',
                                backgroundColor: '#fff',
                            }}
                            title={
                                <div className={s.tipText}>
                                    Share your Practice Front to existing patients to showcase
                                    your full services
                                </div>
                            }
                        >
                            <div className={s.questionMarkIcon} />
                        </Tooltip>
                    </div>
                    {profileData.pfLink ? (
                        <div className={s.shareContent}>
                            <div className={s.shareLink}>{profileData.pfLink}</div>
                            <div
                                className={s.copyButton}
                                onClick={() => handleCopy(profileData.pfLink)}
                            >
                                Copy
                            </div>
                        </div>
                    ) : (
                        renderEmpty
                    )}
                </div>
            </div>
            <div className={s.profileWrap}>
                <div
                    className={s.profileItem}
                    onClick={() => navigate('/dashboard/channel')}
                >
                    My live channels ({profileData.listedChannelCount})
                </div>
                <div
                    className={s.profileItem}
                    onClick={() =>
                        navigate('/create-uni-profile?type=uniprofile&from=home')
                    }
                >
                    Update my UniProfile
                </div>
                <div
                    className={s.profileItem}
                    onClick={() => navigate('/dashboard/practice-front')}
                >
                    Update my Practice Front
                </div>
            </div>
        </>
    );

    return (
        <div className={s.wrap}>
            <h2 className={s.title}>Quick actions</h2>
            {isKlarity ? renderKlarity : renderKiwi}
        </div>
    );
};

export default Index;
