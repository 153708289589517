import { Button, message, Modal, Spin, Tooltip } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ChannelCard from './components/ChannelCard';
import s from './s.module.less';
import useGetRecommendChannels from 'hooks/useGetRecommendChannels';
import remove from 'lodash/remove';
import SingleServiceTypeChannelModal from 'components/SingleServiceTypeChannelModal';
import { Service, Channel } from 'types/common';
import useService from 'hooks/useService';
import { PRACTICE_FRONT_FROM, SINGLE_TYPE_SINGLE_SERVICE } from 'constants/common';
import { useNavigate } from 'react-router-dom';
import useSubmitRecommendChannels from 'hooks/useSubmitRecommendChannels';
import { ChannelPayPlanTypeEnum, IRecommendChannel } from 'types/channel';
import { PATH } from 'constants/path';
import { getUrlParam } from 'utils/common';
import useSubscriptionPlan from 'hooks/useSubscriptionPlan';
import { getLiveChannelList } from 'api/channel';
import { useRequest } from 'ahooks';
import SubscriptionStore from 'store/Subscription';
import { TSubscriptionType } from 'types/subscription';
import { openSubscriptionModal } from 'utils/globalLayerControl';
import useLoadBasicData from 'hooks/useLoadBasicData';
import { isFreeUser } from 'utils/provider';

interface IChannelGroup {
    free: IRecommendChannel[];
    paid: IRecommendChannel[];
    premium: IRecommendChannel[];
}

interface IListedChannelGroup {
    free: Channel[];
    paid: Channel[];
    premium: Channel[];
}

// const channelLimit = {
//     free: 2,
//     paid: 8,
// };

const RecommendChannelsPage = () => {
    const navigate = useNavigate();
    const from = getUrlParam('from');
    const channelName = decodeURIComponent(getUrlParam('channelName') || '');
    const channelId = getUrlParam('channelId');
    const [services, servicesLoading] = useService();
    const [messageApi, contextHolder] = message.useMessage();
    const [data, isNonRecommandChannels, error, loading] = useGetRecommendChannels();
    const [selectedChannels, setSelectedChannels] = useState<Array<number>>([]);
    const [open, setOpen] = useState(false);
    const hasSelectedSingleServiceType = useRef(false);
    const [singleServiceTypeIds, setSingleServiceTypeIds] = useState<Array<number>>([]);
    // 非initial vitis的service 集合
    const [otherServiceList, setOtherServiceList] = useState<Array<Service>>([]);
    const [singleServiceTypeChannels, setSingleServiceTypeChannels] = useState<Array<IRecommendChannel>>([]);

    const [submitRecommandChannels] = useSubmitRecommendChannels();

    const [plansData, loadingSubscriptionPlan] = useSubscriptionPlan();
    const subscriptionPlan = plansData.kiwiPlan;
    const isFree = isFreeUser();

    const [getSubscription] = SubscriptionStore.useStore();
    const currentPlan = getSubscription('currentPlan');
    const [currentPlanLoaded, setCurrentPlanLoaded] = useState(false);
    // type;
    const [channelLimit, setChannelLimit] = useState<{
        free: number | null,
        paid: number | null
    }>({
        free: 0,
        paid: 0,
    });

    const [channelGroup, setChannelGroup] = useState<IChannelGroup>({
        [ChannelPayPlanTypeEnum.FREE]: [],
        [ChannelPayPlanTypeEnum.PAID]: [],
        [ChannelPayPlanTypeEnum.PREMIUM]: [],
    });
    const [listedChannelGroup, setListChannelGroup] = useState<IListedChannelGroup>({
        [ChannelPayPlanTypeEnum.FREE]: [],
        [ChannelPayPlanTypeEnum.PAID]: [],
        [ChannelPayPlanTypeEnum.PREMIUM]: [],
    });
    const { data: channelListData = { data: { data: [] } } } = useRequest(getLiveChannelList) || {};
    const [freeSelected, setFreeSelected] = useState(0);
    const [paidSelected, setPaidSelected] = useState(0);

    const [loadBaiscData] = useLoadBasicData();

    useEffect(() => {
        if (!currentPlanLoaded && !currentPlan) {
            loadBaiscData();
            setCurrentPlanLoaded(true);
        }
    }, [currentPlan, currentPlanLoaded, loadBaiscData]);

    useEffect(() => {
        if (error) {
            messageApi.error(error);
        }
    }, [error, messageApi]);

    useEffect(() => {
        const providerPlanType = currentPlan ? currentPlan.type : TSubscriptionType.FREE;

        const myLimitPlan = subscriptionPlan.find((plan) => {
            const { type } = plan;

            return type === providerPlanType;
        });

        if (myLimitPlan && (myLimitPlan.freeChannelLimit !== channelLimit.free || myLimitPlan.paidChannelLimit !== channelLimit.paid)) {
            channelLimit.free = myLimitPlan.freeChannelLimit!;
            channelLimit.paid = myLimitPlan.paidChannelLimit!;

            setChannelLimit({
                ...channelLimit,
            });
        }
    }, [channelLimit, currentPlan, subscriptionPlan]);

    useEffect(() => {
        if (typeof isNonRecommandChannels === 'boolean' && isNonRecommandChannels) {
            if (from === PATH.CHANNEL_DETAIL) {
                const path = channelId ? `/${PATH.CHANNEL_DETAIL_LIST_SUCCESS}?channelId=${channelId}` : `/${PATH.CHANNEL_DETAIL_LIST_SUCCESS}`;
                navigate(path);
            } else {
                navigate(`/${PATH.PRACTICE_FRONT_CREATE_SUCCESS}?from=${PRACTICE_FRONT_FROM}`);
            }
        }
    }, [channelId, from, isNonRecommandChannels, navigate]);

    useEffect(() => {
        const singleServiceTypes = singleServiceTypeIds.map((id) => {
            const serv = services.find((e) => e.id === id);

            return serv?.type;
        });
        setOtherServiceList(services.filter((service) => !singleServiceTypes.includes(service.type)));
    }, [services, singleServiceTypeIds]);

    useEffect(() => {
        const result: IChannelGroup = data.reduce((pre: IChannelGroup, cur: IRecommendChannel) => {
            const { payPlanType } = cur;

            if (payPlanType === ChannelPayPlanTypeEnum.FREE) {
                pre.free.push({
                    ...cur,
                    channelType: ChannelPayPlanTypeEnum.FREE,
                });
            } else if (!payPlanType || payPlanType === ChannelPayPlanTypeEnum.PAID) {
                pre.paid.push({
                    ...cur,
                    channelType: ChannelPayPlanTypeEnum.PAID,
                });
            }

            return pre;
        }, {
            [ChannelPayPlanTypeEnum.FREE]: [],
            [ChannelPayPlanTypeEnum.PAID]: [],
            [ChannelPayPlanTypeEnum.PREMIUM]: [],
        });

        setChannelGroup(result);
    }, [data]);

    useEffect(() => {
        const listedChannelData = channelListData?.data?.data || [];

        if (listedChannelData.length > 0) {
            const result: IListedChannelGroup = (channelListData?.data?.data || []).reduce((pre: IListedChannelGroup, cur: Channel) => {
                const { payPlanType, listStatus } = cur;

                if (listStatus === 'listed' || listStatus === 'under-review') {
                    if (payPlanType === ChannelPayPlanTypeEnum.FREE) {
                        pre.free.push(cur);
                    } else if (!payPlanType || payPlanType === ChannelPayPlanTypeEnum.PAID) {
                        pre.paid.push(cur);
                    } else if (payPlanType === ChannelPayPlanTypeEnum.PREMIUM) {
                        pre.premium.push(cur);
                    }
                }

                return pre;
            }, {
                [ChannelPayPlanTypeEnum.FREE]: [],
                [ChannelPayPlanTypeEnum.PAID]: [],
                [ChannelPayPlanTypeEnum.PREMIUM]: [],
            });

            setListChannelGroup(result);
        }
    }, [channelListData]);

    const checkThereIsSingleServiceTypeChannelInData = useCallback(() => {
        const channels: Array<IRecommendChannel> = [];
        selectedChannels.forEach((id) => {
            const channel = data.find((e) => e.id === id);

            if (channel && channel.serviceConfig === SINGLE_TYPE_SINGLE_SERVICE) {
                channels.push(channel);
            }
        });

        setSingleServiceTypeChannels(channels);

        return channels.length > 0;
    }, [data, selectedChannels]);

    const checkSelectedChannelIsSingleServiceType = useCallback((channelId: number, checked: boolean) => {
        const targetChannel = data.find((e) => e.id === channelId);

        // 当前点击的是单一service type的，如果是选择，弹出，否则就是取消，重置掉servicetypeid
        if (targetChannel && targetChannel.serviceConfig === SINGLE_TYPE_SINGLE_SERVICE) {
            if (checked && hasSelectedSingleServiceType.current) {
                setOpen(true);
            }
        }
    }, [data]);

    const handleChannelSelect = useCallback((id: number, checked: boolean, payType: ChannelPayPlanTypeEnum) => {
        if (checked) {
            selectedChannels.push(id);
            checkSelectedChannelIsSingleServiceType(id, checked);
            if (payType === ChannelPayPlanTypeEnum.FREE) {
                const remaining = Number(freeSelected) + 1;
                setFreeSelected(remaining);
            } else if (payType === ChannelPayPlanTypeEnum.PAID) {
                const remaining = Number(paidSelected) + 1;
                setPaidSelected(remaining);
            }
        } else {
            remove(selectedChannels, (e) => e === id);
            if (payType === ChannelPayPlanTypeEnum.FREE) {
                const remaining = Number(freeSelected) - 1;
                setFreeSelected(remaining < 0 ? 0 : remaining);
            } else if (payType === ChannelPayPlanTypeEnum.PAID) {
                const remaining = Number(paidSelected) - 1;
                setPaidSelected(remaining < 0 ? 0 : remaining);
            }
        }
        setSelectedChannels([...selectedChannels]);
    }, [checkSelectedChannelIsSingleServiceType, freeSelected, paidSelected, selectedChannels]);

    const handleSingleServiceTypeSelected = useCallback((ids: Array<number>) => {
        setSingleServiceTypeIds(ids);
    }, []);

    const handleModalOk = useCallback(() => {
        setOpen(false);
        hasSelectedSingleServiceType.current = true;
    }, []);

    const handleCloseModal = useCallback(() => {
        setOpen(false);
    }, []);

    const handleSubmit = useCallback(async () => {
        // 已经选过，或者选择的channel里没有唯一servicetype的channel， 否则需要重新选择唯一servicetype
        if (hasSelectedSingleServiceType.current || !checkThereIsSingleServiceTypeChannelInData()) {
            let multServiceList = services;
            if (singleServiceTypeIds.length > 0) {
                const singleSerivce = singleServiceTypeIds.map((id) => {
                    const service = services.find((serv) => serv.id === id) as Service;

                    return service;
                });

                multServiceList = [...otherServiceList, ...singleSerivce];
            }

            if (selectedChannels.length === 0) {
                if (from === PATH.CHANNEL_DETAIL) {
                    const path = channelId ? `/${PATH.CHANNEL_DETAIL_LIST_SUCCESS}?channelId=${channelId}` : `/${PATH.CHANNEL_DETAIL_LIST_SUCCESS}`;
                    navigate(path);
                } else {
                    navigate(`/${PATH.PRACTICE_FRONT_CREATE_SUCCESS}?from=${PRACTICE_FRONT_FROM}`);
                }
            }
            const res = await submitRecommandChannels({
                channelIdList: selectedChannels,
                multServiceList,
            });

            if (!res?.error) {
                if (from === PATH.CHANNEL_DETAIL) {
                    const path = channelId ? `/${PATH.CHANNEL_DETAIL_LIST_SUCCESS}?channelId=${channelId}` : `/${PATH.CHANNEL_DETAIL_LIST_SUCCESS}`;
                    navigate(path);
                } else {
                    navigate(`/${PATH.PRACTICE_FRONT_CREATE_SUCCESS}?from=${PRACTICE_FRONT_FROM}`);
                }
            } else {
                messageApi.error(res.error);
            }
        } else {
            setOpen(true);
        }
    }, [channelId, checkThereIsSingleServiceTypeChannelInData, from, messageApi, navigate, otherServiceList, selectedChannels, services, singleServiceTypeIds, submitRecommandChannels]);

    const handleShowUpgardePlan = useCallback(() => {
        openSubscriptionModal();
    }, []);

    const handleBack = useCallback(() => {
        navigate(-1);
        // navigate(`${PATH.CREATE_UNI_PROFILE}?type=${UNIPROFILE}`, { replace: true });
    }, [navigate]);

    const collapseRender = useMemo(() => {
        return Object.values(channelGroup)
            .reduce((pre: IRecommendChannel[], v: IRecommendChannel[]) => {
                return pre.concat(v);
            }, [])
            .map((channel: IRecommendChannel) => {
                const { id, channelType } = channel;
                let channelSelectable = false;
                // GROWTH 全部不限制， free 除了2个free以外，全部限制，ESSENTIAL，2free，8paid以外，其他都不限制。
                if (currentPlan?.type === TSubscriptionType.GROWTH) {
                    channelSelectable = true;
                } else if (channelType === ChannelPayPlanTypeEnum.FREE) {
                    const listedFreeChannelLength = listedChannelGroup.free.length;
                    if (channelLimit.free === null) {
                        channelSelectable = true;
                    } else {
                        const remaining = channelLimit.free - listedFreeChannelLength;

                        channelSelectable = remaining > freeSelected;
                    }
                } else if (channelType === ChannelPayPlanTypeEnum.PAID) {
                    if (isFree) {
                        channelSelectable = false;
                    } else {
                        const listedPaidChannelLength = listedChannelGroup.paid.length;

                        if (channelLimit.paid === null) {
                            channelSelectable = true;
                        } else {
                            const remaining = channelLimit.paid - listedPaidChannelLength;

                            channelSelectable = remaining > paidSelected;
                        }
                    }
                } else if (channelType === ChannelPayPlanTypeEnum.PREMIUM) {
                    channelSelectable = !isFree;
                }

                const isChecked = selectedChannels.includes(id);
                const disable = isChecked ? false : !channelSelectable;

                return disable && channelType !== ChannelPayPlanTypeEnum.FREE ? (
                    <Tooltip
                        key={id}
                        overlayClassName={s.tipCard}
                        overlayInnerStyle={{ padding: '26px 32px' }}
                        color="#FFFFFF"
                        title={
                            <>
                                <div className={s.titleText}>Upgrade to unlock more channels</div>
                                <div className={s.tipText}>You’ve already reached 6 standard channel limitation in the Essential Plan. Please upgrade to the Growth plan to unlock unlimited standard channels.</div>
                                <Button type="primary" onClick={handleShowUpgardePlan}>Upgrade to unlock</Button>
                            </>
                        }
                    >
                        <div className={s.cardWrap}>

                            <ChannelCard
                                disable={disable}
                                checked={isChecked}
                                {...channel}
                                onChange={(checked) => handleChannelSelect(id, checked, channelType)}
                            />
                        </div>
                    </Tooltip>
                ) :
                    <div key={id} className={s.cardWrap}>

                        <ChannelCard
                            disable={disable}
                            checked={isChecked}
                            {...channel}
                            onChange={(checked) => handleChannelSelect(id, checked, channelType)}
                        />
                    </div>;
            });
    }, [channelGroup, channelLimit, currentPlan?.type, freeSelected, handleChannelSelect, handleShowUpgardePlan, isFree, listedChannelGroup.free.length, listedChannelGroup.paid.length, paidSelected, selectedChannels]);

    return (
        <Spin spinning={loading || servicesLoading || loadingSubscriptionPlan || false}>
            <Modal
                centered
                open={open}
                onOk={handleModalOk}
                onCancel={handleCloseModal}
                width={1000}
            >
                <SingleServiceTypeChannelModal
                    logo={singleServiceTypeChannels[0]?.logo}
                    name={singleServiceTypeChannels[0]?.name}
                    checkedIds={singleServiceTypeIds}
                    onChange={handleSingleServiceTypeSelected}
                />
            </Modal>
            <div className={s.wrap}>
                {contextHolder}
                <div className={s.main}>
                    {
                        channelName && (
                            <div className={s.listedTip}>
                                <div className={s.listedTipIcon} />
                                <div className={s.listedTipText}>Your request to list on {decodeURIComponent(channelName)} has been sent to the Kiwi Ops team! We'll get back to you shortly.</div>
                            </div>
                        )
                    }
                    <div className={s.titleBox}>
                        {!channelName && <div className={s.title}>Your Practice Front has been successfully launched. Proceed to the next step and check it out.</div>}
                        <div className={s.subTitle}>Boost your online presence with more channels!</div>
                    </div>
                    <div className={s.cardList}>
                        {collapseRender}
                    </div>
                </div>
                <div className={s.footer}>
                    <div className={s.quit}>
                        {/* <Button type="default" className={s.exit} onClick={handleExit}>Exit</Button> */}
                        <div className={s.back} onClick={handleBack}>Back</div>
                    </div>
                    <Button type="primary" onClick={handleSubmit}>List me on selected channels</Button>
                </div>
            </div>
        </Spin>
    );
};

export default RecommendChannelsPage;
