import React from 'react';
import s from './s.module.less';
import type { ColumnsType } from 'antd/es/table';
import CommonTable from 'components/CommonTable';
import { useNavigate } from 'react-router-dom';
import { TConfigModule, TCta } from 'types/ai';
import AddAndUpdateAiConfigModuleControl from './components/AddAndUpdateAiConfigModuleControl';
import AddAndUpdateAiConfigModule from './components/AddAndUpdateAiConfigModule';
import useAiConfigModule from 'hooks/useAiConfigModule';

const Page = () => {
    const [data, loading, refetch] = useAiConfigModule(true);
    const [showAddOrUpdateControlModal, setShowAddOrUpdateControlModal] = React.useState(false);
    const [currentEditingControl, setCurrentEditingControl] = React.useState<TCta>();

    const [showAddOrUpdateModuleModal, setShowAddOrUpdateModuleModal] = React.useState(false);
    const [currentEditingModule, setCurrentEditingModule] = React.useState<TConfigModule>();

    const [currentEditingModuleName, setCurrentEditingModuleName] = React.useState<string>();

    const generateColumn = (module:TConfigModule):ColumnsType<TCta> => {
        let hasImg = false;
        if (module?.ctas && module?.ctas?.length > 0) {
            hasImg = !!module?.ctas?.find((c) => !!c.imageUrl);
        }
        const columns = [
            {
                title: 'NAME',
                dataIndex: 'name',
                filterSearch: true,
                width: '240px',
                render: (name:string, item:TCta) => {
                    return (
                        <div className={s.nameWrap}>
                            <span>{name}</span>
                            {
                                item.isDefault && <span className={s.init}>Initial</span>
                            }
                        </div>
                    );
                },
            },
            hasImg && {
                title: 'AVATAR',
                width: '160px',
                dataIndex: 'imageUrl',
                render: (avatar:string) => {
                    return (
                        <img src={avatar} className={s.avatar} />
                    );
                },
            },
            {
                title: 'PROMPT',
                width: 'auto',
                dataIndex: 'aiPrompt',
                render: (prompt:string) => {
                    return (
                        <div className={s.nameWrap}>{prompt}</div>
                    );
                },
            },
            // {
            //     title: '',
            //     width: '200px',
            //     render: (item:TCta) => {
            //         return (
            //             <div>
            //                 <Button
            //                     className={s.link}
            //                     onClick={() => {
            //                         setCurrentEditingModuleName(module.name);
            //                         setCurrentEditingControl(item);
            //                         setShowAddOrUpdateControlModal(true);
            //                     }}
            //                     type="text"
            //                 >
            //                     Edit
            //                 </Button>
            //             </div>
            //         );
            //     },
            // },
        ].filter(Boolean);
        return columns as ColumnsType<TCta>;
    };

    return (
        <div className={s.wrap}>
            {/* <div className={s.header}>
                <h1>Ai Config</h1>
                <Button
                    type="primary"
                    onClick={() => {
                        setCurrentEditingModule(undefined);
                        setShowAddOrUpdateModuleModal(true);
                    }}
                >
                    + Add a new feature module
                </Button>
            </div> */}
            <div className={s.content}>
                {
                    data?.map((item: TConfigModule) => {
                        return (
                            <div key={item.name} className={s.itemWrap}>
                                <div className={s.itemHeader}>
                                    <div className={s.left}>
                                        <h4>{item.name}</h4>
                                        <div className={s.des}>{item.description}</div>
                                    </div>
                                    {/* <div className={s.btnWrap}>
                                        <Button
                                            onClick={() => {
                                                setCurrentEditingModuleName(item.name);
                                                setCurrentEditingModule(item);
                                                setShowAddOrUpdateModuleModal(true);
                                            }}
                                            className={s.btn}
                                        >
                                            Edit feature module
                                        </Button>
                                        <span
                                            onClick={() => {
                                                setCurrentEditingModuleName(item.name);
                                                setCurrentEditingControl(undefined);
                                                setShowAddOrUpdateControlModal(true);
                                            }}
                                            className={s.lightBtn}
                                        >
                                            + Add a new control
                                        </span>
                                    </div> */}
                                </div>
                                <div className={s.itemTable}>
                                    <CommonTable
                                        bordered
                                        rowKey="id"
                                        loading={loading}
                                        columns={generateColumn(item)}
                                        data={item.ctas || []}
                                        pagination={false}
                                    />
                                </div>
                            </div>
                        );
                    })
                }
            </div>
            <AddAndUpdateAiConfigModuleControl
                onCannel={() => {
                    setShowAddOrUpdateControlModal(false);
                }}
                show={showAddOrUpdateControlModal}
                item={currentEditingControl}
                moduleName={currentEditingModuleName}
            />
            <AddAndUpdateAiConfigModule
                onCannel={() => {
                    setShowAddOrUpdateModuleModal(false);
                }}
                show={showAddOrUpdateModuleModal}
                item={currentEditingModule}
                moduleName={currentEditingModuleName}
            />
        </div>
    );
};

export default Page;
