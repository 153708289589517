import React, { useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { Button, Input, message } from 'antd';
import { updateOpsChannelWithdrawNotes } from 'api/operation';

interface IProps {
    isEditing: boolean;
    showError: boolean;
    id: number;
    channelId: number;
    providerId: number;
    notes: string;
    onClick: (s:boolean) => void;
    refetch: () => void;
}

const Notes = ({
    id,
    channelId,
    providerId,
    isEditing,
    showError,
    notes,
    onClick,
    refetch,
}: IProps) => {
    const [notesValue, setNotesValue] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setNotesValue(notes);
    }, [notes]);

    const handleClick = (status: boolean) => {
        onClick(status);
    };

    const handleSave = async () => {
        setLoading(true);
        const requestParam:any = {
            id,
            providerId,
            channelId,
            notes: notesValue,
        };
        const res = await updateOpsChannelWithdrawNotes(requestParam);

        if (res?.error) {
            message.error(res.error);
        } else {
            message.success('notes saved successfully');
            onClick(false);
            refetch();
        }
        setLoading(false);
    };

    const handleChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNotesValue(e.target.value);
    }, []);

    return (
        <div className={s.wrap}>
            {
                isEditing ?
                    <>
                        <div className={s.textarea}>
                            <Input.TextArea maxLength={1000} showCount style={{ height: '144px' }} value={notesValue} onChange={handleChange} />
                            { showError && <p>Please finish editing notes before changing tabs.</p>}
                        </div>
                        <div className={s.btns}>
                            <Button type="primary" loading={loading} onClick={handleSave}>Save changes</Button>
                            <Button type="primary" ghost onClick={() => handleClick(false)}>Cancel</Button>
                        </div>
                    </> :
                    <>
                        <p>{notes}</p>
                        <Button type="primary" ghost onClick={() => handleClick(true)}>Edit</Button>
                    </>
            }
        </div>
    );
};

export default Notes;
