import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Dropdown, MenuProps, Modal, Select, Space, Spin, Tabs } from 'antd';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import ProviderStore from 'store/Provider';
import WaitForReply from './components/WaitForReply';
import Down from './images/down.svg';
import Up from './images/up.svg';

import FrequencyQuestionSecion from 'components/FrequencyQuestionSecion';
import { FAQ } from '../KlarityReviewsPage/components/IntroHomeView/constant';
import { EDisputeStatusType, EOpenType, ESortType, ETimeType, TOverviewData } from './types';
import { Email } from 'constants/contact';
import { getKlarityReviewOverviewData, getKlarityScoreInfoData } from 'api/survey';
import { setHasDoneKlarityTutorial } from 'utils/localstore';
import { TReviewSummary } from 'types/survey';
import ReviewDisputeViewModal from 'components/ReviewDisputeModals/ViewModal';
import ReviewDisputeEditModal from 'components/ReviewDisputeModals/EditModal';
import ReviewDisputeStore from 'store/ReviewDispute';

//import ReviewInvitation from './components/ReviewInvitation';

export enum ETabType {
    WAITING_FOR_REPLY = 'WAITING_FOR_REPLY',
    DISPUTE_REVIEWS = 'DISPUTE_REVIEWS',
    ALL_REVIEWS = 'ALL_REVIEWS',
}

const Page = () => {
    const [getDiputeStore] = ReviewDisputeStore.useStore();
    const reviewItem = getDiputeStore('currentReview');
    const [currentTab, setCurrentTab] = React.useState<ETabType>(ETabType.WAITING_FOR_REPLY);
    const [showLearnMore, setShowLearnMore] = React.useState<boolean>(false);

    const [timeFilter, setTimeFilter] = React.useState<ETimeType>(ETimeType.THIS_WEEK);
    const [openFilter, setOpenFilter] = React.useState<EOpenType>(EOpenType.BOTH_PUBLIC_AND_PRIVATE);
    const [sortFilter, setSortFilter] = React.useState<ESortType>(ESortType.MOST_RECENT);
    const [disputeStatusFilter, setDisputeStatusFilter] = React.useState<EDisputeStatusType>(EDisputeStatusType.ALL);

    const [loadingOverview, setLoadingOverview] = useState<boolean>(false);
    const [overviewData, setOverviewData] = useState<TOverviewData>();

    //todo
    const [scoreInfo, setScoreInfo] = React.useState<TReviewSummary>();
    const [loadingScoreInfo, setLoadingScoreInfo] = React.useState<boolean>(false);

    const [totalWait, seTotalWait] = React.useState<number>(0);
    const [totalDispute, setTotalDispute] = React.useState<number>(0);
    const [totalAll, setTotalAll] = React.useState<number>(0);

    const totalCount = React.useRef<number>(0);

    const [forceListUpdate, setForceListUpdate] = React.useState<{}>();

    const timeFilterItems: MenuProps['items'] = [
        {
            label: <span className={s.dropItem} onClick={() => { setTimeFilter(ETimeType.THIS_WEEK); }}>This week</span>,
            key: ETimeType.THIS_WEEK,
        },
        {
            label: <span className={s.dropItem} onClick={() => { setTimeFilter(ETimeType.LAST_30_DAYS); }}>Last 30 days</span>,
            key: ETimeType.LAST_30_DAYS,
        },
        {
            label: <span className={s.dropItem} onClick={() => { setTimeFilter(ETimeType.LAST_90_DAYS); }}>Last 90 days</span>,
            key: ETimeType.LAST_90_DAYS,
        },
    ];

    let timeFilterLabel = '';
    if (timeFilter === ETimeType.THIS_WEEK) {
        timeFilterLabel = 'This week';
    }
    if (timeFilter === ETimeType.LAST_30_DAYS) {
        timeFilterLabel = 'Last 30 days';
    }
    if (timeFilter === ETimeType.LAST_90_DAYS) {
        timeFilterLabel = 'Last 90 days';
    }

    const fetchOverviewData = async (v: string) => {
        setLoadingOverview(true);
        try {
            const res = await getKlarityReviewOverviewData(v);
            if (res && !res.error && res.data?.data) {
                setOverviewData(res.data.data);
            }
        } catch (e) {
            console.error(e);
        }
        setLoadingOverview(false);
    };

    const fetchScoreInfoData = async () => {
        setLoadingScoreInfo(true);
        try {
            const res = await getKlarityScoreInfoData();
            if (res && !res.error) {
                setScoreInfo(res.data?.data || {
                    avgRating: 0,
                    totalReviewCount: 0,
                });
            }
        } catch (e) {
            console.error(e);
        }
        setLoadingScoreInfo(false);
    };

    React.useEffect(() => {
        fetchScoreInfoData();
        setHasDoneKlarityTutorial('true');
    }, []);

    React.useEffect(() => {
        if (timeFilter) {
            fetchOverviewData(timeFilter);
        }
    }, [timeFilter]);

    const timeFilterChange: MenuProps['onClick'] = ({ key }) => {
        setTimeFilter(key as ETimeType);
    };

    const renderRisingArrow = (val?: number) => {
        if (val === undefined || val === null) {
            return <span />;
        }

        if (val >= 0) {
            return (
                <div className={s.up}>
                    <img src={Up} alt="" />
                    <span className={s.rate}>{val}%</span>
                </div>
            );
        }

        if (val < 0) {
            return (
                <div className={s.down}>
                    <img src={Down} alt="" />
                    <span className={s.rate}>{val}%</span>
                </div>
            );
        }
    };

    const hasRating = !!(overviewData?.averageSurveyRating || overviewData?.preAverageSurveyRating);
    const hasFeedback = !!(overviewData?.feedbackReceivedCount || overviewData?.preFeedbackReceivedCount);
    const hasReply = !!(overviewData?.feedbackRepliedCount || overviewData?.preFeedbackRepliedCount);

    const handleScrollToTop = () => {
        const wrap = document.getElementById('siteLayout');
        if (wrap && wrap.scrollTop > 400) {
            setTimeout(() => {
                wrap?.scrollTo({
                    top: 300,
                    behavior: 'smooth',
                });
            }, 300);
        }
    };

    return (
        <div className={s.wrap}>
            { !!getDiputeStore('showViewModal') && <ReviewDisputeViewModal /> }
            { !!getDiputeStore('showEditModal') && <ReviewDisputeEditModal onOk={() => setForceListUpdate({})} /> }
            <div className={s.header}>
                <h1>Klarity reviews</h1>
                <div className={s.btnWrap}>
                    <Button
                        onClick={() => {
                            setShowLearnMore(true);
                        }}
                        className={s.btnNormal}
                    >
                        Learn more
                    </Button>
                </div>
            </div>
            <div className={s.ratingInfo}>
                <span>Your overall rating:</span>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path d="M8.47059 0L11.3499 4.9091L16.9412 6.11146L13.1294 10.3478L13.7057 16L8.47059 13.7091L3.23548 16L3.81176 10.3478L0 6.11146L5.59128 4.9091L8.47059 0Z" fill="#FFCB45" />
                    </svg>
                </span>
                <span>{scoreInfo ? scoreInfo.avgRating : ''}</span>
                <span className={s.light}>based on {scoreInfo ? (totalCount.current || scoreInfo.totalReviewCount || '0') : ''} reviews</span>
            </div>
            <div className={s.filter}>
                <div className={s.timeFilter}>
                    <Dropdown
                        overlayStyle={{
                            width: '160px',
                        }}
                        menu={{ items: timeFilterItems, onClick: timeFilterChange }}
                        trigger={['click']}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                <span className={s.filterLabel}>{timeFilterLabel}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.92548 9.19697C6.24278 8.85101 6.75722 8.85101 7.07452 9.19697L12.4255 15.0313C12.7428 15.3772 13.2572 15.3772 13.5745 15.0313L18.9255 9.19697C19.2428 8.85101 19.7572 8.85101 20.0745 9.19697C20.3918 9.54293 20.3918 10.1038 20.0745 10.4498L14.7236 16.2841C13.7717 17.322 12.2283 17.322 11.2764 16.2841L5.92548 10.4498C5.60817 10.1038 5.60817 9.54293 5.92548 9.19697Z" fill="black" fillOpacity="0.4" />
                                </svg>
                            </Space>
                        </a>
                    </Dropdown>
                </div>
            </div>
            <Spin spinning={loadingOverview}>
                <div className={s.rowWrap}>
                    <div className={s.rowIn}>
                        <div className={s.item}>
                            <div className={s.top}>
                                <div className={s.label}>Average survey rating</div>
                            </div>
                            {
                                hasRating && overviewData &&
                                <div className={s.bottom}>
                                    <div className={s.left}><span className={s.val}>{overviewData?.averageSurveyRating}</span> <span className={s.valDes}>out of {overviewData?.preAverageSurveyRating}</span></div>
                                    <div className={s.right}>
                                        {
                                            renderRisingArrow(overviewData?.averageSurveyRatingRate)
                                        }
                                    </div>
                                </div>
                            }
                            {
                                !hasRating && overviewData &&
                                <div className={s.bottom}>No rating yet</div>
                            }
                        </div>
                        <div className={s.item}>
                            <div className={s.top}>
                                <div className={s.label}>Feedback</div>
                            </div>
                            {
                                hasFeedback && overviewData &&
                                <div className={s.bottom}>
                                    <div className={s.left}><span className={s.val}>{overviewData?.feedbackReceivedCount}</span> <span className={s.valDes}>out of {overviewData?.preFeedbackReceivedCount}</span></div>
                                    <div className={s.right}>
                                        {
                                            renderRisingArrow(overviewData?.feedbackReceivedRate)
                                        }
                                    </div>
                                </div>
                            }
                            {
                                !hasFeedback && overviewData &&
                                <div className={s.bottom}>No feedbacks yet</div>
                            }
                        </div>
                        {
                            (hasFeedback || hasReply) &&
                            <div className={s.item}>
                                <div className={s.top}>
                                    <div className={s.label}>Feedback replied</div>
                                </div>
                                {
                                    hasReply && overviewData &&
                                    <div className={s.bottom}>
                                        <div className={s.left}><span className={s.val}>{overviewData?.feedbackRepliedCount}</span> <span className={s.valDes}>out of {overviewData?.preFeedbackRepliedCount}</span></div>
                                        <div className={s.right}>
                                            {
                                                renderRisingArrow(overviewData?.feedbackRepliedRate)
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    !hasReply && overviewData &&
                                    <div className={s.bottom}>No feedbacks replied</div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </Spin>
            <div className={s.line} />
            <div className={s.titleAndFilter}>
                <div className={s.reviewTitle}>
                    Reviews
                </div>
                <div className={s.tabAndActionWrap}>
                    <div className={s.left}>
                        <div
                            onClick={() => {
                                setCurrentTab(ETabType.WAITING_FOR_REPLY);
                                handleScrollToTop();
                            }}
                            className={`${s.tabBtn} ${currentTab === ETabType.WAITING_FOR_REPLY ? s.tabBtnActive : ''}`}
                        >
                            Waiting for reply ({totalWait})
                        </div>
                        <div
                            onClick={() => {
                                setCurrentTab(ETabType.DISPUTE_REVIEWS);
                                handleScrollToTop();
                            }}
                            className={`${s.tabBtn} ${currentTab === ETabType.DISPUTE_REVIEWS ? s.tabBtnActive : ''}`}
                        >
                            Disputed reviews ({totalDispute})
                        </div>
                        <div
                            onClick={() => {
                                setCurrentTab(ETabType.ALL_REVIEWS);
                                handleScrollToTop();
                            }}
                            className={`${s.tabBtn} ${currentTab === ETabType.ALL_REVIEWS ? s.tabBtnActive : ''}`}
                        >
                            All reviews ({totalAll})
                        </div>
                    </div>
                    <div className={s.right}>
                        {
                            currentTab === ETabType.DISPUTE_REVIEWS ? (
                                <Select
                                    defaultValue={EDisputeStatusType.ALL}
                                    style={{ width: 180 }}
                                    onChange={(val: EDisputeStatusType) => {
                                        setDisputeStatusFilter(val);
                                    }}
                                    options={[
                                        { value: EDisputeStatusType.ALL, label: 'All disputed reviews' },
                                        { value: EDisputeStatusType.INVESTIGATION, label: 'Under investigation' },
                                        { value: EDisputeStatusType.VALID, label: 'Dispute valid' },
                                        { value: EDisputeStatusType.INVALID, label: 'Dispute invalid' },
                                    ]}
                                />
                            ) : (
                                <>
                                    <Select
                                        defaultValue={EOpenType.BOTH_PUBLIC_AND_PRIVATE}
                                        style={{ width: 200, marginRight: 16 }}
                                        onChange={(val: EOpenType) => {
                                            setOpenFilter(val);
                                        }}
                                        options={[
                                            { value: EOpenType.BOTH_PUBLIC_AND_PRIVATE, label: 'Both public and private' },
                                            { value: EOpenType.ONLY_PUBLIC_REVIEWS, label: 'Only public reviews' },
                                            { value: EOpenType.ONLY_PRIVATE_REVIEWS, label: 'Only private reviews' },
                                        ]}
                                    />
                                    <Select
                                        defaultValue={ESortType.MOST_RECENT}
                                        style={{ width: 180 }}
                                        onChange={(val: ESortType) => {
                                            setSortFilter(val);
                                        }}
                                        options={[
                                            { value: ESortType.MOST_RECENT, label: 'Most recent' },
                                            { value: ESortType.HIGHTEST_RATED, label: 'Hightest rated' },
                                            { value: ESortType.LOWEST_RATED, label: 'Lowest rated' },
                                        ]}
                                    />
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className={s.content}>
                <div className={`${s.tableWrap} ${currentTab === ETabType.WAITING_FOR_REPLY ? s.tableWrapShow : ''}`}>
                    <WaitForReply
                        openFilter={openFilter}
                        sortFilter={sortFilter}
                        isShow={currentTab === ETabType.WAITING_FOR_REPLY}
                        waitToReply
                        onTotalUpdate={(total) => {
                            seTotalWait(total);
                        }}
                        scrollToTop={handleScrollToTop}
                        forceUpdate={forceListUpdate}
                        onReplySuccess={() => {
                            setForceListUpdate({});
                        }}
                    />
                </div>
                <div className={`${s.tableWrap} ${currentTab === ETabType.DISPUTE_REVIEWS ? s.tableWrapShow : ''}`}>
                    <div className={s.disputeTip}>
                        <div className={s.tipIcon}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM11 6C11 6.55228 10.5523 7 10 7C9.44772 7 9 6.55228 9 6C9 5.44772 9.44772 5 10 5C10.5523 5 11 5.44772 11 6ZM9 9C8.44772 9 8 9.44772 8 10C8 10.5523 8.44772 11 9 11V14C9 14.5523 9.44772 15 10 15H11C11.5523 15 12 14.5523 12 14C12 13.4477 11.5523 13 11 13V10C11 9.44772 10.5523 9 10 9H9Z" fill="#60A5FA" />
                            </svg>
                        </div>
                        <div className={s.tipText}>
                            Reviews under dispute are hidden and do not affect your overall rating on Klarity until the dispute is resolved. If the dispute is found invalid and the patient chose to make the review public, it will be shown again and will count towards your rating.
                        </div>
                    </div>
                    <WaitForReply
                        openFilter={openFilter}
                        sortFilter={sortFilter}
                        disputeFilter={disputeStatusFilter}
                        disputedList
                        isShow={currentTab === ETabType.DISPUTE_REVIEWS}
                        onTotalUpdate={(total) => {
                            if (totalCount.current < total) {
                                totalCount.current = total;
                            }
                            setTotalDispute(total);
                        }}
                        scrollToTop={handleScrollToTop}
                        forceUpdate={forceListUpdate}
                        onReplySuccess={() => {
                            setForceListUpdate({});
                        }}
                    />
                </div>
                <div className={`${s.tableWrap} ${currentTab === ETabType.ALL_REVIEWS ? s.tableWrapShow : ''}`}>
                    <WaitForReply
                        openFilter={openFilter}
                        sortFilter={sortFilter}
                        isShow={currentTab === ETabType.ALL_REVIEWS}
                        onTotalUpdate={(total) => {
                            if (totalCount.current < total) {
                                totalCount.current = total;
                            }
                            setTotalAll(total);
                        }}
                        scrollToTop={handleScrollToTop}
                        forceUpdate={forceListUpdate}
                        onReplySuccess={() => {
                            setForceListUpdate({});
                        }}
                    />
                </div>
                <Modal
                    open={showLearnMore}
                    width="84%"
                    style={{
                        maxWidth: '1400px',
                    }}
                    onCancel={() => {
                        setShowLearnMore(false);
                    }}
                    className={`${commonS.modalFixHeightWrap} ${s.modalWrap}`}
                    footer={
                        <div className={s.footwrap}>
                            <div className={s.helpWrap}>
                                Need help? Contact <a href={`mailto:${Email.klarity_provider_support}`}>{Email.klarity_provider_support}</a> for more information.
                            </div>
                            <Button
                                onClick={() => {
                                    setShowLearnMore(false);
                                }}
                                type="primary"
                            >Ok
                            </Button>
                        </div>
                    }
                >
                    <div className={s.popWrap}>
                        <div className={s.title}>Your ultimate guide for the Klarity Reviews</div>
                        <div className={s.des}>Klarity reviews is designed to empower you to build trust, attract new patients, and grow your practice. We've made it effortlessly to collect valuable patient feedback, showcase your success, and foster stronger patient relationships. </div>
                        <div className={s.faqWrap}>
                            <div className={s.line} />
                            <FrequencyQuestionSecion
                                data={FAQ}
                            />
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default Page;
