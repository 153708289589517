/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useCallback, useEffect, useMemo } from 'react';
import s from './s.module.less';
import ConnectChannel from './components/ConnectChannel';
import ReviewRecommand from './components/ReviewRecommand';
import PFIntro from './components/PFIntro';
import ProcessBar from 'components/ProgressBar';
// import Logo from 'assets/logo/kiwihealth.png';
import Logo from 'assets/campain/blackfriday2024/kiwiLogo.png';
import { Button, Spin, message } from 'antd';
import { klarityPlanStepSubmit } from 'api/klarityPlan';
import { ISubmitKlarityPlanChannelConnectInput, ISubmitKlarityPlanRecommandChannelsInput } from 'types/klarityPlan';
import { getUserId } from 'utils/localstore';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import useGetStepInfo from 'hooks/useGetStepInfo';
import { EStepTypes } from 'types/common';
import useLoadBasicData from 'hooks/useLoadBasicData';
import { UNLOCK_YOURY_KIWI_BENEFITS } from 'constants/common';

const KlarityPlanProcess = () => {
    const [stepInfo, stepInfoLoading] = useGetStepInfo(EStepTypes.KLARITY_STARTER_SET_UP);
    const navigate = useNavigate();
    const providerId = getUserId();
    const [currentStep, setCurrentStep] = React.useState(1);
    const [submitLoading, setSubmitLoading] = React.useState(false);
    const [nextButtonDisabled, setNextButtonDisabled] = React.useState(false);
    const [connectChannes, setConnectChannes] = React.useState([]);
    const [selectedRecommandChannelIds, setSelectedRecommandChannelIds] = React.useState<number[]>([]);
    const [showNonChannelSelectedAlert, setShowNonChannelSelectedAlert] = React.useState(false);
    const [nonRecommandChannelsReson, setNonRecommandChannelsReson] = React.useState(-1);
    const [loadBaiscData] = useLoadBasicData();

    useEffect(() => {
        loadBaiscData();
    }, [loadBaiscData]);

    useEffect(() => {
        if (stepInfo && stepInfo.currentStep) {
            setCurrentStep(parseInt(stepInfo.currentStep, 10) + 1);
        } else {
            setCurrentStep(1);
        }
    }, [stepInfo]);

    const handleSubmit = useCallback(async (formData: ISubmitKlarityPlanChannelConnectInput | ISubmitKlarityPlanRecommandChannelsInput) => {
        setSubmitLoading(true);

        try {
            const res = await klarityPlanStepSubmit({
                stepTypeEnum: 'KLARITY_STARTER_SET_UP',
                step: currentStep.toString(),
                data: formData,
            });
            if (res && !res.error) {
                setCurrentStep(currentStep + 1);
                localStorage.removeItem(UNLOCK_YOURY_KIWI_BENEFITS);
            } else {
                message.error(res?.error);
            }
        } catch (e) {
            console.error(e);
        }
        setSubmitLoading(false);
    }, [currentStep]);

    // recommend channel 没有任何选择的时候弹出框里选择，然后提交
    const submitRecommendChannelsWithoutChannels = useCallback(() => {
        const formData = {
            providerSelectRecordReq: {
                recordType: 'KLARITY_CHANNEL_PREFERENCE',
                value: nonRecommandChannelsReson.toString(),
            },
            providerChannelSubmitReq: null,
        };

        handleSubmit(formData);
    }, [handleSubmit, nonRecommandChannelsReson]);

    const handleNext = useCallback(() => {
        if (currentStep === 1) {
            // next和sbumit同时执行，第一步的时候因为connectChannes不为空，等于这个function被跳过
            if (connectChannes.length === 0) {
                handleSubmit({
                    reqList: [],
                });
            }
        } else if (currentStep === 2) {
            if (selectedRecommandChannelIds.length === 0) {
                setShowNonChannelSelectedAlert(true);
            } else {
                const formData = {
                    providerChannelSubmitReq: {
                        channelIdList: selectedRecommandChannelIds,
                        providerId: Number(providerId),
                    },
                    providerSelectRecordReq: null,
                };

                handleSubmit(formData);
            }
        } else if (currentStep === 3) {
            navigate(`/${PATH.CUSTOMIZE_PRACTICE_FRONT}`);
        }
    }, [connectChannes, currentStep, handleSubmit, navigate, providerId, selectedRecommandChannelIds]);

    const forceNext = useCallback(() => {
        setCurrentStep(currentStep + 1);
    }, [currentStep]);

    const handleBack = useCallback(() => {
        const step = currentStep - 1;

        setCurrentStep(step < 1 ? 1 : step);
    }, [currentStep]);

    const commonProps = useMemo(() => ({
        handleSubmit,
        setNextButtonDisabled,
    }), [handleSubmit]);

    const contentRender = useMemo(() => {
        switch (currentStep) {
            case 1:
                return <ConnectChannel {...commonProps} setConnectChannes={setConnectChannes} forceNext={forceNext} />;
            case 2:
                return <ReviewRecommand
                    connectChannes={connectChannes}
                    setSelectedRecommandChannelIds={setSelectedRecommandChannelIds}
                    showNonChannelSelectedAlert={showNonChannelSelectedAlert}
                    nonRecommandChannelsReson={nonRecommandChannelsReson}
                    setShowNonChannelSelectedAlert={setShowNonChannelSelectedAlert}
                    setNonRecommandChannelsReson={setNonRecommandChannelsReson}
                    submitRecommendChannelsWithoutChannels={submitRecommendChannelsWithoutChannels}
                    setNextButtonDisabled={setNextButtonDisabled}
                />;
            case 3:
                return <PFIntro />;
            default:
                return null;
        }
    }, [commonProps, connectChannes, currentStep, forceNext, nonRecommandChannelsReson, showNonChannelSelectedAlert, submitRecommendChannelsWithoutChannels]);

    if (stepInfoLoading) {
        return <Spin spinning />;
    }
    return (

        <div className={s.wrap}>
            <div className={s.header}>
                <img className={s.logo} src={Logo} alt="Kiwi Health logo" />
            </div>
            <div className={s.body}>
                {contentRender}
            </div>
            <ProcessBar totalStep={3} step={currentStep - 1} bottom="62px" />
            <div className={s.footer}>
                <div className={s.leftPart}>
                    { currentStep !== 1 && <Button onClick={handleBack}>Back</Button> }
                </div>
                <div className={s.rightPart}>
                    <Button
                        disabled={nextButtonDisabled}
                        form="formId"
                        type="primary"
                        htmlType="submit"
                        loading={submitLoading}
                        onClick={handleNext}
                    >Next
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default KlarityPlanProcess;
