import React, { useCallback, useEffect } from 'react';
import s from './s.module.less';
import { Button, Modal, Radio, Space, Spin } from 'antd';
import ChannelCard from 'components/ChannelSelectModal/components/ChannelCard';
import useGetKlarityRecommandChannels from 'hooks/useGetKlarityRecommandChannels';
import { Channel, ChannelPayPlanTypeEnum } from 'types/common';
import useProviderHomeInfo from 'hooks/useProviderHomeInfo';
import type { RadioChangeEvent } from 'antd';
import { getProviderKlarityPlanLevel } from 'utils/provider';
import useGetProviderConnnectedChannlesInKlarityPlanProcess from 'hooks/useGetProviderConnnectedChannlesInKlarityPlanProcess';

interface IProps {
    setSelectedRecommandChannelIds: (channelIds: number[]) => void;
    showNonChannelSelectedAlert: boolean;
    setShowNonChannelSelectedAlert: (value: boolean) => void;
    nonRecommandChannelsReson: number;
    setNonRecommandChannelsReson: (value: number) => void;
    submitRecommendChannelsWithoutChannels: () => void;
    setNextButtonDisabled: (value: boolean) => void;
}

const ReviewRecommand = ({
    setSelectedRecommandChannelIds,
    showNonChannelSelectedAlert,
    setShowNonChannelSelectedAlert,
    nonRecommandChannelsReson,
    setNonRecommandChannelsReson,
    submitRecommendChannelsWithoutChannels,
    setNextButtonDisabled,
}: IProps) => {
    const [connnectedChannels] = useGetProviderConnnectedChannlesInKlarityPlanProcess();
    const [homeInfo, loadingHome] = useProviderHomeInfo();
    const [channels, loading] = useGetKlarityRecommandChannels();
    const [groupedChannels, setGroupedChannels] = React.useState<{
        premiumChannels: Channel[];
        standardChannels: Channel[];
    }>({
        premiumChannels: [],
        standardChannels: [],
    });
    const [checkedChannelIds, setCheckedChannelIds] = React.useState<number[]>([]);

    const myPlanLevel = getProviderKlarityPlanLevel(homeInfo);

    useEffect(() => {
        setNextButtonDisabled(false);
    }, [setNextButtonDisabled]);

    useEffect(() => {
        if (channels.length > 0) {
            const selectedChannles: number[] = [];
            // 排除前面一步connect选中的channel
            // const madeUpChannels = channels.filter((e) => !connnectedChannels.some((c) => c.channelId === e.id)).map((channel) => {
            const madeUpChannels = channels.map((channel) => {
                const locked = myPlanLevel < channel.klarityPlanLevel;
                if (!locked && channel.listStatus === 'not-listed') {
                    selectedChannles.push(channel.id);
                }
                return {
                    ...channel,
                    locked,
                };
            });
            const premiumChannels = madeUpChannels.filter((channel) => channel.payPlanType === ChannelPayPlanTypeEnum.PREMIUM && channel.listStatus === 'not-listed');
            const standardChannels = madeUpChannels.filter((channel) => channel.payPlanType === ChannelPayPlanTypeEnum.PAID && channel.listStatus === 'not-listed');
            setGroupedChannels({
                premiumChannels,
                standardChannels,
            });

            setCheckedChannelIds(selectedChannles);
        }
    }, [channels, connnectedChannels, myPlanLevel]);

    useEffect(() => {
        setSelectedRecommandChannelIds(checkedChannelIds);
    }, [checkedChannelIds, setSelectedRecommandChannelIds]);

    const handleSelectAll = useCallback(() => {
        const allPremiumChannelIds = groupedChannels.premiumChannels.filter((channel) => !channel.locked).map((channel) => channel.id);
        const allStandardChannelIds = groupedChannels.standardChannels.filter((channel) => !channel.locked).map((channel) => channel.id);

        setCheckedChannelIds([...allPremiumChannelIds, ...allStandardChannelIds]);
    }, [groupedChannels]);

    const handleDeselectAll = useCallback(() => {
        setCheckedChannelIds([]);
    }, []);

    const handleResonSelect = useCallback((e:RadioChangeEvent) => {
        setNonRecommandChannelsReson(e.target.value);
    }, [setNonRecommandChannelsReson]);

    const handleChannelSelect = useCallback((channelId: number) => {
        let channelIds = [];
        if (checkedChannelIds.includes(channelId)) {
            channelIds = checkedChannelIds.filter((id) => id !== channelId);
        } else {
            channelIds = [...checkedChannelIds, channelId];
        }

        setCheckedChannelIds(channelIds);
    }, [checkedChannelIds]);

    return (
        <div className={s.wrap}>
            <Modal
                width={780}
                closable={false}
                open={showNonChannelSelectedAlert}
                footer={
                    <div className={s.modalFooter}>
                        <Button onClick={() => {
                            setNonRecommandChannelsReson(-1);
                            setShowNonChannelSelectedAlert(false);
                        }}
                        >Cancel
                        </Button>
                        <Button disabled={nonRecommandChannelsReson === -1} type="primary" onClick={submitRecommendChannelsWithoutChannels}>Confirm</Button>
                    </div>
                }
            >
                <div className={s.modalWrap}>
                    <div className={s.tipIcon} />
                    <div className={s.content}>
                        <div className={s.title}>Just checking In...</div>
                        <div className={s.desc}>To get the most out of Kiwi, we recommend selecting a few channels to start. Is there a reason you haven't chosen any yet?</div>
                        <Radio.Group onChange={handleResonSelect} value={nonRecommandChannelsReson}>
                            <Space direction="vertical">
                                <Radio value={1}>{'I\'m not interested in listing outside of Klarity.'}</Radio>
                                <Radio value={2}>{'I\'ll select channels later.'}</Radio>
                            </Space>
                        </Radio.Group>
                    </div>
                </div>
            </Modal>
            <Spin spinning={loading || loadingHome}>
                <div className={s.header}>
                    <div className={s.textContent}>
                        <div className={s.step}>Step 2 of 3: Review your channel recommendations</div>
                        <div className={s.desc}><span className={s.avaLogo}>Ava</span>, your Kiwi AI assistant, has selected these channels for you to be listed on to reach your ideal patients. For maximum exposure, we recommend keeping them all.</div>
                    </div>
                    {checkedChannelIds.length > 0 ? <Button onClick={handleDeselectAll}>Deselect all</Button> : <Button onClick={handleSelectAll}>Select all</Button>}
                </div>
                <div className={s.body}>
                    <div className={s.channels}>
                        <div className={s.categoryLabel}>Premium channels</div>
                        <div className={s.channelList}>
                            {groupedChannels.premiumChannels.map((channel) => {
                                const { locked, listStatus } = channel;
                                const checked = checkedChannelIds.includes(channel.id);
                                const showStatus = listStatus !== 'not-listed' && !locked;
                                const disabled = showStatus;

                                return (
                                    <ChannelCard
                                        showStatus={showStatus}
                                        listStatus={listStatus}
                                        checked={checked}
                                        locked={locked}
                                        disable={disabled}
                                        key={channel.id}
                                        item={channel}
                                        onSelectChange={() => handleChannelSelect(channel.id)}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className={s.channels}>
                        <div className={s.categoryLabel}>Standard channels</div>
                        <div className={s.channelList}>
                            {groupedChannels.standardChannels.map((channel) => {
                                const { locked, listStatus } = channel;
                                const checked = checkedChannelIds.includes(channel.id);
                                const showStatus = listStatus !== 'not-listed' && !locked;
                                const disabled = showStatus;

                                return (
                                    <ChannelCard
                                        showStatus={showStatus}
                                        listStatus={listStatus}
                                        checked={checked}
                                        locked={locked}
                                        disable={disabled}
                                        key={channel.id}
                                        item={channel}
                                        onSelectChange={() => handleChannelSelect(channel.id)}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    );
};

export default ReviewRecommand;
