// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--SRAG4 .s-module__title--sf_gi {
  margin-bottom: 8px;
  font-family: 'Inter';
  font-style: normal;
  font-size: 16px;
  line-height: 28px;
  color: var(--gray-800);
}
.s-module__wrap--SRAG4 .s-module__btns--xWNKf {
  margin-top: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/operation/ChannelWithdrawDetailPage/components/Assignee/s.module.less"],"names":[],"mappings":"AAAA;EAEQ,kBAAA;EACA,oBAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AAAR;AAPA;EAUQ,eAAA;AAAR","sourcesContent":[".wrap {\n    .title {\n        margin-bottom: 8px;\n        font-family: 'Inter';\n        font-style: normal;\n        font-size: 16px;\n        line-height: 28px;\n        color: var(--gray-800);\n    }\n    .btns {\n        margin-top: 8px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--SRAG4`,
	"title": `s-module__title--sf_gi`,
	"btns": `s-module__btns--xWNKf`
};
export default ___CSS_LOADER_EXPORT___;
