// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__introWrap--Tcg5x {
  padding: 196px 203px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
}
.s-module__introWrap--Tcg5x .s-module__left--mca9q {
  flex: 0 0 50%;
}
.s-module__introWrap--Tcg5x .s-module__left--mca9q .s-module__stepText--yYqRK {
  margin-bottom: 16px;
  color: var(--main-green);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
}
.s-module__introWrap--Tcg5x .s-module__left--mca9q .s-module__title--_Gxpe {
  margin-bottom: 34px;
  color: var(--primary-black);
  font-family: Inter;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
}
.s-module__introWrap--Tcg5x .s-module__left--mca9q .s-module__desc--cL80p {
  color: var(--gray-500, #6B7280);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px;
}
.s-module__introWrap--Tcg5x .s-module__right--zLElv {
  flex: 0 0 30%;
}
.s-module__introWrap--Tcg5x .s-module__right--zLElv img {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/ReputationConfigPage/components/Configration/Patient/Intro/s.module.less"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,aAAA;AACF;AANA;EAOI,aAAA;AAEJ;AATA;EASM,mBAAA;EACA,wBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAGN;AAlBA;EAkBM,mBAAA;EACA,2BAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAGN;AA3BA;EA4BM,+BAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAEN;AAnCA;EAsCI,aAAA;AAAJ;AAtCA;EAwCM,WAAA;AACN","sourcesContent":[".introWrap {\n  padding: 196px 203px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  height: 100vh;\n  .left {\n    flex: 0 0 50%;\n    .stepText {\n      margin-bottom: 16px;\n      color: var(--main-green);\n      font-family: Inter;\n      font-size: 24px;\n      font-style: normal;\n      font-weight: 600;\n      line-height: 40px;\n    }\n    .title {\n      margin-bottom: 34px;\n      color: var(--primary-black);\n      font-family: Inter;\n      font-size: 34px;\n      font-style: normal;\n      font-weight: 600;\n      line-height: 40px;\n    }\n  \n    .desc {\n      color: var(--gray-500, #6B7280);\n      font-family: Inter;\n      font-size: 18px;\n      font-style: normal;\n      font-weight: 400;\n      line-height: 31px;\n    }\n  }\n\n  .right {\n    flex: 0 0 30%;\n    img {\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"introWrap": `s-module__introWrap--Tcg5x`,
	"left": `s-module__left--mca9q`,
	"stepText": `s-module__stepText--yYqRK`,
	"title": `s-module__title--_Gxpe`,
	"desc": `s-module__desc--cL80p`,
	"right": `s-module__right--zLElv`
};
export default ___CSS_LOADER_EXPORT___;
