import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Spin, message } from 'antd';
import { PATH } from 'constants/path';
import s from './s.module.less';
import { ELicenseType, License } from 'types/common';
import LandingIcon from 'assets/practiceFront/pfLandingImage.svg';
import InternalIcon from 'assets/practiceFront/globIcon.svg';
import PeopleGroupIcon from 'assets/practiceFront/peopleGroupIcon.svg';
import RiseIcon from 'assets/practiceFront/riseIcon.svg';
import { getPFStatusData, activePF, deactivePF } from 'api/practiceFront';
import { UNIPROFILE, PRACTICE_FRONT_FROM, HTML_DIV_ID } from 'constants/common';
import { transferServerLicenseTypeToFormData } from 'data/provider';
import { isLicenseExpired } from 'utils/common';
import Notes from 'components/Notes';
import {
    getHasViewLocalSeoInfo,
    getHasOpenPracticeFrontThemePopup,
    setHasOpenPracticeFrontThemePopup,
} from 'utils/localstore';
import GlobalLayerStore from 'store/GlobalLayer';

const Page = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [hideTip, setHideTip] = useState<boolean>(false);
    const [hideSecTip, setHideSecTip] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [isShowTipsModal, setIsShowTipsModal] = useState(
        !getHasOpenPracticeFrontThemePopup());
    const [data, setData] = useState({
        uniprofile: false,
        practiceFront: false,
        status: 'inactive',
        website: '',
        licenseDtoList: [] as License[],
        firstName: '',
        lastName: '',
        news: '',
        kiwihealthWebsite: '',
    });
    const [open, setOpen] = useState(false);
    const [getGlobalLayer, setGlobalLayer] = GlobalLayerStore.useStore();

    const { uniprofile, practiceFront, status, website, kiwihealthWebsite, licenseDtoList, firstName, lastName, news } = data;

    const fetchStatusData = useCallback(async () => {
        setLoading(true);
        const res = await getPFStatusData();

        if (!res?.error) {
            setData(res.data?.data);
        } else {
            message.error(res.error);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        fetchStatusData();
    }, [fetchStatusData]);

    const handleHideTip = useCallback(() => setHideTip(true), []);

    const handleCreatePractice = useCallback(() => {
        navigate(`/${PATH.UNIPROFILE_RESUME}?type=${UNIPROFILE}&from=${PRACTICE_FRONT_FROM}`);
    }, [navigate]);

    const activeMyPF = useCallback(async () => {
        navigate(`/${PATH.CUSTOMIZE_PRACTICE_FRONT}`);
    }, [navigate]);

    const renderButton = useMemo(() => {
        if (!uniprofile) {
            return <Button type="primary" onClick={handleCreatePractice}>Create my Practice Front</Button>;
        } else if (!practiceFront) {
            return <Button type="primary" onClick={activeMyPF}>Create my Practice Front</Button>;
        }
    }, [activeMyPF, handleCreatePractice, practiceFront, uniprofile]);

    const handleEdit = useCallback(() => {
        navigate(`/${PATH.CUSTOMIZE_PRACTICE_FRONT}?type=edit`);
    }, [navigate]);

    const handleActive = async () => {
        const res = await activePF();

        if (!res?.error) {
            messageApi.open({
                type: 'success',
                content: 'Practice Front activated!',
                duration: 3,
            });
            fetchStatusData();
        } else {
            message.error(res.error);
        }
    };

    const handleDeactive = useCallback(async () => {
        const res = await deactivePF();

        if (!res?.error) {
            setOpen(false);
            fetchStatusData();
        } else {
            message.error(res.error);
        }
    }, [fetchStatusData]);

    const hanldeCancelModal = useCallback(() => {
        setHasOpenPracticeFrontThemePopup('1');
        setIsShowTipsModal(false);
    }, []);

    const licenseType = useMemo(() => {
        const nurce = licenseDtoList?.find((item) => { return item.type === ELicenseType.NURSE && !isLicenseExpired(item); });
        if (nurce) {
            const typeList = Object.keys(transferServerLicenseTypeToFormData(nurce.licenseType) || {});

            return typeList;
        }

        return [];
    }, [licenseDtoList]);

    const renderPFCard = useMemo(() => {
        return (
            <>
                <div className={s.header}>
                    <h1>Practice Front</h1>
                    <div className={s.des}>
                        Empower your patient-facing website with Practice Front editor: easily update content and add new features to enhance your online presence.
                    </div>
                    <Notes
                        defaultShow={getHasViewLocalSeoInfo()}
                        title={(
                            <div className={s.noteTitle}>New <span className={s.highlight}>App: </span><div className={s.normal}> LocalSEO+</div></div>
                        )}
                        des={(
                            <div className={s.noteDes}>Elevate your local and online presence with Kiwi Health&apos;s advanced algorithms that automatically identify and include locations near your practice on your Practice Front.</div>
                        )}
                        onTry={() => {
                            navigate(`/${PATH.CUSTOMIZE_PRACTICE_FRONT}?type=edit&scrollTo=${HTML_DIV_ID.LOCAL_SEO_WRAP}`);
                        }}
                    />
                    {
                        !hideSecTip && practiceFront && news &&
                        <div className={s.tip}>
                            <div className={s.tipContent}>
                                {news}
                            </div>
                            <div className={s.close} onClick={() => setHideSecTip(true)} />
                        </div>
                    }
                    {
                        // localStorage.getItem('sawNewFeature') === '1' &&
                        // <div className={s.paidTip}>
                        //     <div className={s.starIcon} />
                        //     <div className={s.TipContent}>
                        //         <div className={s.tipText}>Enabling this option allows you take full control of your calendar.</div>
                        //         {/* <a className={s.upgradeAction} onClick={handleShowUpgardePlan}>Upgrade plan</a> */}
                        //     </div>
                        // </div>
                    }
                </div>
                <div
                    onClick={
                        () => {
                            handleEdit();
                        }}
                    className={s.content}
                >
                    <div className={s.pfCard}>
                        <div className={s.top} />
                        <div className={s.bottom}>
                            <div className={s.nameStatus}>
                                <div className={s.providerName}>{firstName || ''} {lastName || ''}</div>
                                <div className={`${s.statusLabel} ${status === 'inactive' ? s.deactive : ''}`}>{status}</div>
                            </div>
                            <div className={s.licenseTypes}>{licenseType.join(',')}</div>
                            {website && <a className={s.website} href={kiwihealthWebsite || website} target="_blank" rel="noreferrer">{kiwihealthWebsite || website || ''}</a>}
                            <div className={s.buttons}>
                                <Button type="primary" onClick={handleEdit}>Edit</Button>
                                {status === 'inactive' ?
                                    <Button
                                        type="primary"
                                        ghost
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleActive();
                                        }}
                                    >Activate
                                    </Button> :
                                    <Button
                                        type="primary"
                                        ghost
                                        danger
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setOpen(true);
                                        }
                                        }
                                    >Deactivate
                                    </Button>}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }, [firstName, handleActive, handleEdit, hideSecTip, lastName, licenseType, news, practiceFront, status, website]);

    const renderContant = useMemo(() => {
        return practiceFront ?
            renderPFCard :
            (
                <>
                    <div className={s.header}>
                        <h1>Build a patient-facing website for your own practice</h1>
                        <div className={s.des}>
                            A professionally designed website can be a powerful tool for promoting your practice and building your brand. Establish a competitive edge in the healthcare industry by launching your own Practice Front.
                        </div>
                        <div className={s.createTimeBox}>
                            <div className={s.timeIcon} />
                            <div className={s.timeLable}>Estimated create time:</div>
                            <div className={s.time}>{uniprofile ? '2 mins' : '5 mins'}</div>
                        </div>
                        {
                            !hideTip && !uniprofile &&
                            <div className={s.tip}>
                                <div className={s.tipContent}>You’ll need to create a UniProfile first before creating your Practice Front.</div>
                                <div className={s.close} onClick={handleHideTip} />
                            </div>
                        }
                    </div>
                    <div className={s.content}>
                        <div className={s.left}>
                            <div className={s.list}>
                                <div className={s.item}>
                                    <img src={InternalIcon} />
                                    <div className={s.info}>
                                        <div className={s.itemTitle}>Increased visibility</div>
                                        <div className={s.itemContent}>Increase your online presence by utilizing the best SEO techniques to make your website easily discoverable.</div>
                                    </div>
                                </div>
                                <div className={s.item}>
                                    <img src={PeopleGroupIcon} />
                                    <div className={s.info}>
                                        <div className={s.itemTitle}>Enhanced patient experience</div>
                                        <div className={s.itemContent}>Provide patients with easy access to information such as hours, services offered, pricing, contact info, health tips, news, and updates and online scheduling.</div>
                                    </div>
                                </div>
                                <div className={s.item}>
                                    <img src={RiseIcon} />
                                    <div className={s.info}>
                                        <div className={s.itemTitle}>Increased revenue</div>
                                        <div className={s.itemContent}>No more fees to third-party platforms. Handling your own online appointment booking and payment processing.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={s.right}>
                            <img src={LandingIcon} />
                        </div>
                    </div>
                    <div className={s.footer}>
                        {renderButton}
                    </div>
                </>
            );
    }, [handleHideTip, hideTip, practiceFront, renderButton, renderPFCard, uniprofile]);

    return (
        <div className={s.wrap}>
            {contextHolder}
            <Modal
                title="Deactivate Practice Front?"
                open={open}
                onOk={handleDeactive}
                onCancel={() => setOpen(false)}
                okText="Deactivate"
                cancelText="Cancel"
                okButtonProps={{ danger: true }}
            >
                <p>
                    Deactivating your Practice Front will temporarily make it
                    unavailable to visitors; however, you can reactivate it at any time.
                    Are you sure you want to proceed?
                </p>
            </Modal>
            {loading ? <Spin spinning /> : renderContant}
            <Modal
                width={992}
                className={s.tipsModal}
                title={null}
                open={isShowTipsModal}
                footer={null}
                onCancel={() => hanldeCancelModal()}
                maskClosable={false}
            >
                <div className={s.introWrap}>
                    <div className={s.imgWrap} />
                    <div className={s.infoWrap}>
                        <div className={s.infoTop}>
                            <div className={s.infoHeader}>
                                <div className={s.infoAvatar} />
                                <div className={s.infoDetail}>
                                    <h3 className={s.infoTitle}>Theme Customization</h3>
                                    <p className={s.infoDesc}>Created by Kiwi Health</p>
                                </div>
                            </div>
                            <div className={s.newFeature}>New Feature</div>
                            <h4 className={s.infoSubtitle}>
                                Elevate your website experience with our new theme selection
                            </h4>
                            <p className={s.infoIntro}>
                                With our theme customization feature, you can change the theme
                                of your practice front page to better match your style
                                preferences and the needs of your patients. Kiwi Health offers
                                a variety of theme options, enhancing the overall user
                                experience of your practice front, making your page both
                                professional and appealing.
                            </p>
                        </div>
                        <Button
                            type="primary"
                            className={s.enterBtn}
                            onClick={() => {
                                handleEdit();
                            }}
                        >
                            Check it out
                        </Button>
                    </div>
                </div>
                {/* <KiwiPlanAdvertise /> */}
            </Modal>
        </div>
    );
};

export default Page;
