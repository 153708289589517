import React, { useMemo, useRef, useState } from 'react';
import s from './s.module.less';
import { Prospect, EmailContentItem, EEmailStatus } from 'types/common';
import { getTimeStr } from 'utils/calendar';
import dayjs from 'utils/dayjs';
import { Button, Alert, message } from 'antd';
import AvaIcon from 'assets/common/ava.png';
import {
    sendEmailToProspect,
    getProspectEmaiListByIds,
    getProspectEmailList,
} from 'api/prospect';
import { getEmailDisplay, convertLinksToAnchorTags } from 'utils/common';
import refreshIcon from 'assets/common/refreshGreen.svg';
import AiTextEditor from 'components/AI/AiTextEditor';
import ProviderStore from 'store/Provider';
import type { Provider } from 'types/provider';
import { EAIEditorType } from 'types/ai';
import AIMessageFeedback from './AIMessageFeedback';

type Props = {
    prospect?: Prospect;
};

const Page = (props: Props) => {
    const { prospect } = props;
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [aiReplyMessageFlag, setAiReplyMessageFlag] = useState<string | null>(null);
    const [loadingMore, setLoadingMore] = useState(false);
    const [allIds, setAllIds] = useState<string[]>([]);
    const [list, setList] = useState<EmailContentItem[]>([]);
    const isShowEvaTips = !localStorage.getItem('avaTips');
    const [flag, setFlag] = useState({});
    const messagesEndRef = useRef(null);
    const email = prospect?.email;
    const [get] = ProviderStore.useStore();
    const provider: Provider = get('data') as Provider;
    const prospectName = useMemo(
        () =>
            `${props.prospect?.firstName || ''} ${props.prospect?.lastName || ''}`,
        [props.prospect],
    );
    const prospectSimpleName = useMemo(
        () =>
            `${props.prospect?.firstName.slice(0, 1) || ''}${props.prospect?.lastName.slice(0, 1) || ''
                }`.toLocaleUpperCase(),
        [props.prospect],
    );

    //const hasMore = list.length < allIds.length && !loading && allIds.length > 10;
    const hasMore = false;

    const refresh = async () => {
        setLoading(true);
        const result = await getProspectEmailList(email!, prospect?.id!);
        if (!result.error) {
            const sortedList: EmailContentItem[] =
                result.data?.data?.sort(
                    (a: EmailContentItem, b: EmailContentItem) =>
                        dayjs(a.date).valueOf() - dayjs(b.date).valueOf(),
                ) || [];
            let aiReplyMessage = '';
            if (sortedList.length > 0) {
                setList(sortedList);
                aiReplyMessage = sortedList[sortedList.length - 1].aiReply || '';
                setContent(aiReplyMessage);
                setAiReplyMessageFlag(aiReplyMessage);
                setTimeout(() => {
                    // @ts-ignore
                    messagesEndRef.current?.scrollIntoView({
                        behavior: 'smooth',
                    });
                }, 200);
            }
        }
        setLoading(false);
        // if (!result.error) {
        //     const ids:string[] = result?.data?.data || [];
        //     setAllIds(ids);
        //     if (list.length === 0) {
        //         const emailIds:string[] = ids.slice(0, 10);
        //         const listResult = await getProspectEmaiListByIds(emailIds);
        //         if (!listResult.error) {
        //             const newList:EmailContentItem[] = listResult?.data?.data || [];
        //             setList(newList);
        //         }
        //     } else {
        //         const firstId = list[0].id;
        //         const emailIds:string[] = [];
        //         for (let i = 0; i < ids.length && i < 10; i++) {
        //             const id = ids[i];
        //             if (firstId === id) {
        //                 break;
        //             } else {
        //                 emailIds.push(id);
        //             }
        //         }
        //         if (emailIds.length > 0) {
        //             const listResult = await getProspectEmaiListByIds(emailIds);
        //             if (!listResult.error) {
        //                 const newList:EmailContentItem[] = [...(listResult?.data?.data || []), ...list];
        //                 setList(newList);
        //             }
        //         }
        //     }
        //     setLoading(false);
        // }
    };

    React.useEffect(() => {
        if (email) {
            refresh();
        }
    }, [email]);

    const loadMore = async () => {
        if (list.length > 0) {
            const lastId = list[list.length - 1].id;
            const emailIds: string[] = [];
            let count = 0;
            let passLast = false;
            for (let i = 0; i < allIds.length && count < 10; i++) {
                if (count >= 10) {
                    break;
                }
                if (passLast) {
                    emailIds.push(allIds[i]);
                    count++;
                }
                if (allIds[i] === lastId) {
                    passLast = true;
                }
            }
            if (emailIds.length > 0) {
                setLoadingMore(true);
                const listResult = await getProspectEmaiListByIds(emailIds);
                if (!listResult.error) {
                    const newList: EmailContentItem[] = [
                        ...list,
                        ...(listResult?.data?.data || []),
                    ];
                    setList(newList);
                }
                setLoadingMore(false);
            }
        }
    };

    const sendEmail = async () => {
        let title = 'From kiwi health';
        if (list && list.length > 0) {
            title = `Re: ${list[list.length - 1].subject}`;
        }
        setIsSending(true);
        const result = await sendEmailToProspect({
            to: prospect?.email!,
            content: convertLinksToAnchorTags(content)?.replace('\n', '<br/>'),
            title,
        });
        setIsSending(false);
        if (result.error) {
            //message.error(result.error);
        } else {
            message.success('Reply successed');
            setContent('');
            setFlag({});
            refresh();
        }
    };

    const handleTipsClose = () => {
        localStorage.setItem('avaTips', 'false');
    };

    return (
        <div className={s.wrap}>
            <div className={s.wrapper}>
                <div className={s.listWrap}>
                    {list.map((msg: EmailContentItem) => {
                        //system@kiwihealth.com
                        const fromMe = !!(provider?.email && msg.from?.includes(provider?.email)) || msg.direction === EEmailStatus.SENT;
                        const fromProspect = !!(prospect?.email && msg.from?.includes(prospect?.email));
                        const fromSystem = !fromProspect && !fromMe;
                        const fromReplyByAi = msg.isSendByAi;

                        let clsName = s.msg;
                        if (fromSystem) {
                            clsName = `${s.msg} ${s.msgFromSystem}`;
                        } else if (fromMe) {
                            clsName = `${s.msg} ${s.msgFromMe}`;
                        }
                        if (fromReplyByAi) {
                            clsName = `${clsName} ${s.msgFromAi}`;
                        }
                        return (
                            <div
                                className={clsName}
                                key={msg.id}
                            >
                                <div className={s.msgHeader}>
                                    {msg.direction === EEmailStatus.SENT && (
                                        <div className={s.avatar}>
                                            <img src={provider.photo} alt="avatar" />
                                        </div>
                                    )}
                                    {msg.direction === EEmailStatus.INBOX && (
                                        <>
                                            <div className={s.patientAvatar}>
                                                {fromSystem ? 'Sys' : prospectSimpleName}
                                            </div>
                                            <div className={s.from}>{fromSystem ? msg.from : prospectName}</div>
                                        </>
                                    )}
                                    <div className={s.date}>
                                        {getTimeStr(dayjs(msg.date), 'MMM D, HH:mm A')}
                                    </div>
                                </div>
                                <div className={s.msgContent}>
                                    <div
                                        className={s.msgContentText}
                                        dangerouslySetInnerHTML={{
                                            __html: getEmailDisplay(msg),
                                        }}
                                    />
                                    {
                                        fromReplyByAi &&
                                        <div className={s.msgContentAiTag}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289L9 10.5858L7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L8.29289 12.7071C8.68342 13.0976 9.31658 13.0976 9.70711 12.7071L13.7071 8.70711Z" fill="#069D70" />
                                            </svg>
                                            Replied by
                                            <img className={s.ava} src={AvaIcon} />
                                        </div>
                                    }
                                </div>
                                {
                                    fromReplyByAi &&
                                    <div className={s.feedbackWrap}>
                                        <AIMessageFeedback
                                            feedback={msg.feedback}
                                            messageId={msg.id} />
                                    </div>
                                }
                            </div>
                        );
                    })}
                    {hasMore && (
                        <Button onClick={loadMore} type="primary" loading={loadingMore}>
                            Load More
                        </Button>
                    )}
                    <div className={s.btnWrap} ref={messagesEndRef}>
                        {loading ? (
                            <div className={`${s.btn} ${s.loadingStatus}`}>
                                <img src={refreshIcon} className={s.refreshIcon} />
                            </div>
                        ) : (
                            <div onClick={refresh} className={`${s.btn} ${s.refreshBtn}`}>
                                click to refresh
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className={s.replyWrap}>
                {isShowEvaTips && !!aiReplyMessageFlag && (
                    <Alert
                        message={
                            <div className={s.text}>
                                🎉 <i className={s.ava} />
                                has drafted a reply for you! Review and click &apos;Send&apos;
                                to reply if it&apos;s ok.
                            </div>
                        }
                        type="info"
                        closable
                        afterClose={() => handleTipsClose()}
                    />
                )}

                <AiTextEditor
                    rows={6}
                    onChange={(e) => {
                        if (typeof e === 'string') {
                            setContent(e as string);
                        } else {
                            setContent(e ? e.target.value : '');
                        }
                    }}
                    value={content}
                    type={EAIEditorType.PROSPECT}
                    resetFlag={flag}
                />
                <Button
                    disabled={!content}
                    className={s.btn}
                    type="primary"
                    onClick={sendEmail}
                    loading={isSending}
                >
                    Send
                </Button>
            </div>
        </div>
    );
};

export default Page;
