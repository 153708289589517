import React, { useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { useParams, useNavigate } from 'react-router-dom';
import { Spin, Button, message } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { PATH } from 'constants/path';
import useChannelWithdrawDetailData from './hooks/useChannelWithdrawDetailData';
import { StepValue } from 'pages/CreateUniProfilePage/types';
import Detail from './components/Detail';
import ChannelWithdrawStatusTag from 'components/ChannelWithdrawStatusTag';
import { NoticeType } from 'antd/es/message/interface';
import globalFormsEditStatusStore from 'store/ChannelApplicationProvider/FormsEditStatus';
import { IProfileFormSubmit } from 'types/channelApplicationProvider';
import { getUrlParam } from 'utils/common';

type ISubmitData = Partial<IProfileFormSubmit>;
export interface FormInterface {
    gmail: string;
    gmailPassword: string;
}

const Page = () => {
    const { channelId, providerId, id } = useParams();
    const channelName = decodeURIComponent(getUrlParam('channelName') || '');
    const idInt = id ? parseInt(id, 10) : 0;
    const channelIdInt = channelId ? parseInt(channelId, 10) : 0;
    const providerIdInt = providerId ? parseInt(providerId, 10) : 0;
    const navigate = useNavigate();
    const [messageApi, messageContextHolder] = message.useMessage();
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const [, setGloalEditStatus] = globalFormsEditStatusStore.useStore();

    // const [form] = Form.useForm<FormInterface>();
    // const [getUser] = ProviderStore.useStore();
    // const user = getUser('data');

    const { data: auditDetailData, loading, refetch } = useChannelWithdrawDetailData({
        id: idInt,
        channelId: channelIdInt,
        providerId: providerIdInt,
    });

    const { profile } = auditDetailData;
    const { auditStatus, updateStatus } = profile;
    useEffect(() => {
        return () => setGloalEditStatus('globalEditStatus', false);
    }, []);

    const handleMessageContent = useCallback((type: NoticeType, content: string) => {
        messageApi.open({
            type,
            content,
        });
    }, [messageApi]);

    const onSubmit = async (formValue: ISubmitData, step: StepValue) => {

    };

    const channelNameTail = channelName ? ` - ${decodeURIComponent(channelName)}` : '';
    const providerNameDisplay = (profile.firstName || profile.lastName) ? `${profile?.firstName} ${profile?.lastName}` : profile?.email;

    return (
        <div className={s.wrap}>
            {messageContextHolder}
            <Spin spinning={loading || saveLoading}>
                <div className={s.header}>
                    <div className={s.headerInner}>
                        <div className={s.left}>
                            <div
                                className={s.leftTop}
                                onClick={() => {
                                    navigate(`${PATH.OPERATION}/${PATH.CHANNEL}?type=offboarding`);
                                }}
                            >
                                <span className={s.leftTopHome}><ArrowLeftOutlined /></span>
                                <span
                                    className={s.link}
                                >
                                    Back to Channel offboarding
                                </span>
                            </div>
                            <h1 className={s.leftBtm}>
                                <span className={s.title}>{`${providerNameDisplay}${channelNameTail}`}</span>
                                <span className={s.tag}>
                                    <ChannelWithdrawStatusTag status={auditStatus} />
                                </span>

                            </h1>
                        </div>
                        <div className={s.right}>
                            <Button
                                type="primary"
                                ghost
                                onClick={() => {
                                    const link = `${PATH.OPERATION}/${PATH.UNIPROFILE_DETAIL}/-1/${profile?.id}`;
                                    window.open(link, '_blank');
                                }}
                            >
                                Link to profile
                            </Button>
                        </div>
                    </div>
                </div>
                <Detail
                    id={idInt}
                    providerId={providerIdInt}
                    channelId={channelIdInt}
                    formData={auditDetailData}
                    onSubmit={onSubmit}
                    refetch={refetch}
                    onShowMessage={handleMessageContent}
                />
            </Spin>
        </div>
    );
};

export default Page;
