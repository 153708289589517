import React, { useMemo } from 'react';
import s from './s.module.less';
import { Tabs } from 'antd';
import AccessStore from 'store/Access/permission';
import { PermissionModuleKeys } from 'constants/access';
import AccessDenied from 'components/AccessDenied';
import HomeConfigPage from 'pages/operation/HomeConfigPage';
import AiConfigPage from 'pages/operation/AiConfigPage';
import FeaturedItem from './components/FeaturedItem';

const ConfigurationsPage = () => {
    const [getAccessStore] = AccessStore.useStore();
    const access = getAccessStore('data');
    const items = useMemo(() => {
        return [
            {
                key: '1',
                label: 'Home config',
                children: access?.[PermissionModuleKeys.HOME_CONFIG] ? (
                    <HomeConfigPage />
                ) : (
                    <AccessDenied />
                ),
            },
            {
                key: '2',
                label: 'AI config',
                children: access?.[PermissionModuleKeys.AI_CONFIG] ? (
                    <AiConfigPage />
                ) : (
                    <AccessDenied />
                ),
            },
            {
                key: '3',
                label: 'Featured items',
                children: access?.[PermissionModuleKeys.FEATURED_ITEMS] ? (
                    <FeaturedItem />
                ) : (
                    <AccessDenied />
                ),
            },
        ];
    }, [access]);
    return (
        <div className={s.wrap}>
            <div className={s.title}>Configurations</div>
            <Tabs items={items} />
        </div>
    );
};

export default ConfigurationsPage;
