// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--Pk7JX {
  max-width: 1200px;
  margin: 0 auto;
}
.s-module__wrap--Pk7JX .s-module__header--H43Yn {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.s-module__wrap--Pk7JX .s-module__header--H43Yn .s-module__left--ZYzcR {
  width: 48%;
}
.s-module__wrap--Pk7JX .s-module__header--H43Yn .s-module__left--ZYzcR .s-module__title--YkI2x {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  /* 142.857% */
  letter-spacing: -0.28px;
}
.s-module__wrap--Pk7JX .s-module__header--H43Yn .s-module__left--ZYzcR .s-module__title--YkI2x .s-module__ava--G6JZx {
  height: 22px;
  display: inline;
  margin-top: -6px;
}
.s-module__wrap--Pk7JX .s-module__header--H43Yn .s-module__left--ZYzcR .s-module__des--flpaZ {
  color: #6B7280;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin-top: 16px;
}
.s-module__wrap--Pk7JX .s-module__header--H43Yn .s-module__left--ZYzcR .s-module__des--flpaZ .s-module__ava--G6JZx {
  height: 14px;
  display: inline;
  margin-top: -4px;
}
.s-module__wrap--Pk7JX .s-module__header--H43Yn .s-module__right--j2AXz {
  flex-shrink: 0;
  width: 48%;
}
.s-module__wrap--Pk7JX .s-module__header--H43Yn .s-module__right--j2AXz img {
  width: 100%;
}
.s-module__wrap--Pk7JX .s-module__tabs--VTcOy {
  position: sticky;
  top: 0px;
  background-color: #fff;
}
.s-module__wrapFull--EavtR {
  background-color: #fff !important;
}
.s-module__contentFull--eXLlQ {
  padding-top: 0 !important;
}
.s-module__footer--hUEIP {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/ProspectsManagePage/AvaConfigHome/s.module.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,cAAA;AACF;AAHA;EAKI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,8BAAA;AACJ;AATA;EAWM,UAAA;AACN;AAZA;EAcQ,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACN,aAAa;EACP,uBAAA;AACR;AApBA;EAsBU,YAAA;EACA,eAAA;EACA,gBAAA;AACV;AAzBA;EA6BQ,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AADR;AAjCA;EAqCU,YAAA;EACA,eAAA;EACA,gBAAA;AADV;AAtCA;EA6CM,cAAA;EACA,UAAA;AAJN;AA1CA;EAiDQ,WAAA;AAJR;AA7CA;EAuDI,gBAAA;EACA,QAAA;EACA,sBAAA;AAPJ;AAWA;EACE,iCAAA;AATF;AAYA;EACE,yBAAA;AAVF;AAaA;EACE,aAAA;EACA,WAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;AAXF","sourcesContent":[".wrap {\n  max-width: 1200px;\n  margin: 0 auto;\n\n  .header {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n    justify-content: space-between;\n\n    .left {\n      width: 48%;\n\n      .title {\n        font-size: 28px;\n        font-style: normal;\n        font-weight: 700;\n        line-height: 40px;\n        /* 142.857% */\n        letter-spacing: -0.28px;\n\n        .ava {\n          height: 22px;\n          display: inline;\n          margin-top: -6px;\n        }\n      }\n\n      .des {\n        color: #6B7280;\n        font-size: 16px;\n        font-style: normal;\n        font-weight: 400;\n        line-height: 26px;\n        margin-top: 16px;\n\n        .ava {\n          height: 14px;\n          display: inline;\n          margin-top: -4px;\n        }\n      }\n    }\n\n    .right {\n      flex-shrink: 0;\n      width: 48%;\n\n      img {\n        width: 100%;\n      }\n    }\n  }\n\n  .tabs {\n    position: sticky;\n    top: 0px;\n    background-color: #fff;\n  }\n}\n\n.wrapFull {\n  background-color: #fff !important;\n}\n\n.contentFull {\n  padding-top: 0 !important;\n}\n\n.footer {\n  display: flex;\n  width: 100%;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--Pk7JX`,
	"header": `s-module__header--H43Yn`,
	"left": `s-module__left--ZYzcR`,
	"title": `s-module__title--YkI2x`,
	"ava": `s-module__ava--G6JZx`,
	"des": `s-module__des--flpaZ`,
	"right": `s-module__right--j2AXz`,
	"tabs": `s-module__tabs--VTcOy`,
	"wrapFull": `s-module__wrapFull--EavtR`,
	"contentFull": `s-module__contentFull--eXLlQ`,
	"footer": `s-module__footer--hUEIP`
};
export default ___CSS_LOADER_EXPORT___;
