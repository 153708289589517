import React from 'react';
import s from './s.modules.less';
import { TITLE, CONFIG } from './constants';

const Benefits: React.FC = () => {
    return (
        <div className={s.wrap}>
            <h2 className={s.title}>{TITLE}</h2>
            <div className={s.list}>
                {CONFIG.map((configItem) => {
                    return (
                        <div className={s.item}>
                            <span className={s.icon}>{configItem.icon}</span>
                            <h3 className={s.subTitle}>{configItem.title}</h3>
                            <p className={s.description}>{configItem.description}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Benefits;
