import React, { useState } from 'react';
import { Modal, Form, Input, Button, message } from 'antd';
import commonS from 'styles/common.module.less';
import {
    updateConfigFeaturedItemsInfo,
} from 'api/operation';
import StateSelect from 'components/form/StateSelect';
import ConditionSelect from 'components/form/ConditionSelect';
import type { ServerMeta } from 'types/common';

type Props = {
    isOps?: boolean;
    list: ServerMeta[];
    popupType: 'CONDITION' | 'STATE';
    onCancel?: () => void;
    onSuccess?: () => void;
};

const CreateAndEditPage = ({ list, popupType, onCancel, onSuccess }: Props) => {
    const [submiting, setSubmiting] = useState(false);
    const [form] = Form.useForm<{ state: string[] } & { active: boolean }>();

    const onSave = async () => {
        setSubmiting(true);
        try {
            const formValues = await form.validateFields();
            const arr: ServerMeta[] = formValues.state?.map((item: string, index) => {
                return {
                    type: popupType,
                    seq: index,
                    dictKey: item,
                };
            });
            const { data } = await updateConfigFeaturedItemsInfo(arr);
            if (data.code === 200) {
                onSuccess?.();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setSubmiting(false);
        }
    };

    React.useEffect(() => {
        if (list) {
            form.setFieldsValue({
                state: list.map((item: ServerMeta) => item.dictKey),
            });
        } else {
            form.resetFields();
        }
    }, [list]);

    return (
        <Modal
            title={`Edit top ${popupType.toLocaleLowerCase()}s`}
            closable
            className={commonS.modalFixHeightWrap}
            width="500px"
            destroyOnClose
            onCancel={onCancel}
            okText="Save"
            open
            footer={
                <div>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button loading={submiting} type="primary" onClick={onSave}>
                        Save
                    </Button>
                </div>
            }
        >
            <Form
                form={form}
                className={commonS.formStyle1}
                initialValues={{ active: true }}
                autoComplete="off"
                layout="vertical"
                scrollToFirstError
            >
                {popupType === 'STATE' && (
                    <Form.Item
                        style={{ width: '100%' }}
                        label="States"
                        name="state"
                        rules={[{ required: false, message: 'State is required' }]}
                    >
                        <StateSelect mode="multiple" isShowValue maxCount={10} />
                    </Form.Item>
                )}
                {popupType === 'CONDITION' && (
                    <Form.Item
                        style={{ width: '100%' }}
                        label="Conditions"
                        name="state"
                        rules={[{ required: false, message: 'Condition is required' }]}
                    >
                        <ConditionSelect mode="multiple" maxCount={10} />
                    </Form.Item>
                )}
            </Form>
        </Modal>
    );
};

export default CreateAndEditPage;
