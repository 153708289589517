import React, { useState } from 'react';
import cx from 'classnames';
import s from '../GrowYourBusiness/s.module.less';
import c from './s.module.less';
import type { TTaskItem } from 'types/home';
import { Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';

type IProps = {
    isKlarity: boolean;
    task: TTaskItem;
    index: number;
};

const Index: React.FC<IProps> = ({
    isKlarity,
    task,
    index,
}) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleHideModal = () => {
        setShowModal(false);
    };

    const handleRoute = () => {
        navigate('/create-uni-profile?type=uniprofile&from=home&step=7');
    };

    const handleTask = () => {
        if (task.completed) {
            return;
        }
        setShowModal(true);
    };

    return (
        <>
            <div
                className={cx(s.listItem, task.completed ? s.completed : '')}
                onClick={() => handleTask()}
            >
                <div className={s.listInner}>
                    <i className={s.num}>{index + 1}</i>
                    <div className={s.content}>
                        <div className={s.goalTitle}>{task.taskName}</div>
                        <div className={s.goalDescription}>{task.description}</div>
                    </div>
                    <div className={s.arrow} />
                </div>
            </div>
            <Modal
                title={`Acquire a total of ${task.taskCode.replace(
                    'ACQUIRE_PROSPECT_',
                    '',
                )} prospects 🚀`}
                centered
                open={showModal}
                onCancel={handleHideModal}
                width={534}
                footer={null}
            >
                {isKlarity ? (
                    <div className={s.modalContent}>
                        <p>
                            Supercharge your practice growth by acquiring more prospects,
                            laying the foundation for a thriving patient base!
                        </p>
                        <p>
                            Remember, each prospect represents more than just a potential
                            appointment – they're stepping stones towards building a loyal
                            patient base and establishing your practice's reputation.
                            Leverage Kiwi's tools and your expertise to convert these
                            prospects into long-term patients, steadily growing your
                            practice and solidifying your position in the healthcare market.
                            By focusing on acquiring more prospects, you're not just growing
                            numbers, but creating a sustainable pathway for your practice's
                            success.
                        </p>
                        <div className={s.buttonWrap}>
                            <Button type="primary" onClick={() => handleRoute()}>
                                Get pricing insights
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className={s.modalContent}>
                        <p>
                            To attract more prospects and grow your practice, pricing your
                            services strategically is crucial. Set rates that are affordable
                            for patients while ensuring your practice remains sustainable.
                        </p>
                        <p>
                            Remember, attractive pricing is a key factor in acquiring new
                            prospects. This approach not only helps in attracting patients
                            but also in converting prospects into long-term clients,
                            fostering practice growth. If you need guidance on pricing
                            strategies to effectively attract more prospects, don't hesitate
                            to reach out to our support team at{' '}
                            <a
                                target="_blank"
                                href="mailto:support@kiwihealth.com"
                                rel="noreferrer"
                            >
                                support@kiwihealth.com
                            </a>
                            . We're here to help you optimize your pricing approach and
                            achieve your prospect acquisition goals.
                        </p>
                        <div className={s.buttonWrap}>
                            <Button type="primary" onClick={() => handleRoute()}>
                                Review my services
                            </Button>
                        </div>
                    </div>
                )}
            </Modal>
        </>
    );
};

export default Index;
