// export interface IGenerateSaleLinkInput {
//     fullName: string,
//     inviteEmail: string,
//     phoneNumber: string,
//     payPlanId: number,
//     couponId: string,
//     promotionCode: string,
//     message: string,
//     onboardingCallBookLink: string
// }

export enum FormFieldEnum {
    FULLNAME = 'fullName',
    INVITE_EMAIL = 'inviteEmail',
    PHONE_NUMBER = 'phoneNumber',
    PAY_NAME = 'payPlanName',
    PAY_CYCLE = 'payPlanCycle',
    COUPON_ID = 'couponId',
    PROMOTION_CODE = 'promotionCode',
    MESSAGE = 'message',
    ONBOARDING_CALL_BOOKLINK = 'onboardingCallBookLink',
    PRESENTATION_EMBEDDEDLINK = 'presentationEmbeddedLink',
}

export interface FormInterface {
    [FormFieldEnum.FULLNAME]: string;
    [FormFieldEnum.INVITE_EMAIL]: string;
    [FormFieldEnum.PHONE_NUMBER]: string;
    [FormFieldEnum.PAY_NAME]: string;
    [FormFieldEnum.PAY_CYCLE]: string
    [FormFieldEnum.COUPON_ID]?: string;
    [FormFieldEnum.PROMOTION_CODE]: string;
    [FormFieldEnum.MESSAGE]: string;
    [FormFieldEnum.ONBOARDING_CALL_BOOKLINK]: string;
    [FormFieldEnum.PRESENTATION_EMBEDDEDLINK]: string;
}
