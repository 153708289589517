import { message, Modal, Form, Input, Radio, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { FormType } from './types';
import { EhrAddOnItem } from 'types/ehr';
import { getAddOnUniprofileInfo, updateEFax } from 'api/ehr/index';
import dayjs from 'dayjs';

type Props = {
    clearFlag?: {};
    open: boolean;
    onChange: (item: { isRemoveEFaxNumber: boolean }) => void;
    onClose: () => void;
    item?: EhrAddOnItem;
};

const SelfEFaxWarning = (props: Props) => {
    const { clearFlag, open, item, onChange, onClose } = props;
    const [form] = Form.useForm<FormType>();
    const [submiting, setSubmiting] = useState(false);
    const reset = () => {
        form.resetFields();
    };

    const handleOk = async () => {
        try {
            setSubmiting(true);
            const formValues = await form.validateFields();
            setSubmiting(true);
            const result = await updateEFax({
                eFaxOption: formValues.eFaxOption,
                eFax: formValues.eFax,
            });
            if (!result.error) {
                // remove efax number logic

                onChange?.({
                    isRemoveEFaxNumber: formValues.eFaxOption !== '1',
                });
                onClose();
            }
            setSubmiting(false);
        } catch (e: any) {
            if (e.errorFields) {
                //form error
                return;
            }
            const msg = e?.toString?.() || 'data error';
            message.error(msg);
        } finally {
            setSubmiting(false);
        }
    };

    const getData = async () => {
        try {
            const { data } = await getAddOnUniprofileInfo();
            const { birthDate, addonService } =
                data?.data as FormType;

            form.setFieldsValue({
                birthDate: birthDate as dayjs.Dayjs,
                doseSpotOption: addonService?.doseSpotOption || 'N',
                doseSpotName: addonService?.doseSpotName || '',
                eFaxOption: '1',
                eFax: '',
            });
        } catch (e) {

        }
    };

    useEffect(() => {
        if (clearFlag) {
            reset();
        }
    }, [clearFlag]);

    useEffect(() => {
        if (open) {
            getData();
        }
    }, [open]);

    return (
        <Modal
            title=""
            open={open}
            closable={false}
            destroyOnClose
            className={commonS.modalFixHeightWrap}
            width="700px"
            onCancel={() => {
                onClose();
            }}
            onOk={() => {
                handleOk();
            }}
            okButtonProps={{
                loading: submiting,
            }}
            okText="Confirm"
        >
            <Form
                form={form}
                name="basic"
                className={commonS.formStyle1}
                initialValues={{ remember: true }}
                autoComplete="off"
                layout="vertical"
            >
                <div className={s.wrap}>
                    <div className={s.warningTip}>
                        <div className={s.tipIcon} />
                        <div className={s.tipContent}>
                            <div className={s.cContent}>
                                e-Fax number is necessary for e-Prescription
                            </div>
                            <div className={s.cContentDesc}>
                                An e-fax number is required for e-Prescription. You cannot
                                directly remove it from your cart while you selected an
                                e-Prescription add-on but have no e-fax number recorded in our
                                system.
                            </div>
                            <Form.Item noStyle shouldUpdate>
                                {({ getFieldValue }) => {
                                    const eFaxOption = getFieldValue('eFaxOption');
                                    return (
                                        <Form.Item name="eFaxOption">
                                            <Radio.Group
                                                style={{
                                                    width: '100%',
                                                    display: 'block',
                                                }}
                                                defaultValue="1"
                                            >
                                                <Space direction="vertical">
                                                    <Radio value="1">
                                                        <div className={s.radioTitle}>
                                                            Keep the e-Fax add-on in my cart
                                                        </div>
                                                        {eFaxOption === '1' && (
                                                            <div className={s.radioDesc}>
                                                                <p className={s.desc}>
                                                                    If you don’t want the e-Prescription and
                                                                    e-Fax, you can remove e-Prescription from
                                                                    your cart and then remove e-Fax.
                                                                </p>
                                                            </div>
                                                        )}
                                                    </Radio>
                                                    <Radio value="2">
                                                        <div className={s.radioTitle}>
                                                            I have my own fax number, and want to transfer
                                                            to the Kiwi EHR
                                                        </div>
                                                        {eFaxOption === '2' && (
                                                            <div className={s.radioDesc}>
                                                                <Form.Item
                                                                    style={{
                                                                        width: '360px',
                                                                        marginBottom: '4px',
                                                                        marginTop: '8px',
                                                                    }}
                                                                    label=""
                                                                    name="eFax"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Fax number is required',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        maxLength={25}
                                                                        placeholder="Enter your fax number"
                                                                    />
                                                                </Form.Item>
                                                                <p className={s.desc}>
                                                                    The activation process for using your
                                                                    current fax number in the Kiwi EHR may
                                                                    require 30-60 days.
                                                                </p>
                                                            </div>
                                                        )}
                                                    </Radio>
                                                    <Radio value="3">
                                                        <div className={s.radioTitle}>
                                                            I have my own fax number, and don’t transfer to
                                                            the Kiwi EHR
                                                        </div>
                                                        {eFaxOption === '3' && (
                                                            <div className={s.radioDesc}>
                                                                <Form.Item
                                                                    style={{
                                                                        width: '360px',
                                                                        marginBottom: '4px',
                                                                        marginTop: '8px',
                                                                    }}
                                                                    label=""
                                                                    name="eFax"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Fax number is required',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        maxLength={25}
                                                                        placeholder="Enter your fax number"
                                                                    />
                                                                </Form.Item>

                                                                <p className={s.desc}>
                                                                    If you choose to use your own fax number
                                                                    without transferring it to Kiwi EHR, you
                                                                    won't be able to receive faxes directly
                                                                    within your EHR. This means you'll miss out
                                                                    on convenient features, risk missing
                                                                    important documents, and have to manage
                                                                    faxes manually using separate tools or
                                                                    devices.
                                                                </p>
                                                            </div>
                                                        )}
                                                    </Radio>
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                    );
                                }}
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export default SelfEFaxWarning;
