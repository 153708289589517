import { Modal, Checkbox, Button, Tooltip } from 'antd';
import React from 'react';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { TAssistant } from 'types/common';
import useAssistant from 'hooks/useAssistant';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import EditAssistant from './components/EditAssistant';
import { generateUUID } from 'utils/common';
import { EhrAddOnItem } from 'types/ehr';
import { getUserId } from 'utils/localstore';
import { addToShoppingCart } from 'api/ehr';

type Props = {
    clearFlag?: {};
    open:boolean;
    onAdd:(item:TAssistant[])=>void;
    onClose: ()=>void;
    item?: EhrAddOnItem;
};

type PendingAssistant = Partial<TAssistant> & {
    uuid: string;
    isEdit?: boolean;
};

const AssistantSeatModal = (props:Props) => {
    const { clearFlag, open, onAdd, onClose } = props;
    const [submiting, setSubmiting] = React.useState(false);
    const product: EhrAddOnItem | undefined = props.item;
    const [allAssistants, loading, refetch] = useAssistant(product?.productCode as any);
    const [pendingSelected, setPendingSelected] = React.useState<TAssistant[]>([]);
    const [pendingAdd, setPendingAdd] = React.useState<PendingAssistant[]>([]);

    const addCount = pendingAdd?.filter((item) => !!item.assistantEmail).length || 0;
    const allCount = (pendingSelected?.length || 0) + addCount;

    const PRICE_PER_ASSISTANT = product?.price || 0;

    const reset = () => {
        setPendingAdd([]);
        setPendingSelected([]);
        refetch();
        onAdd?.([]);
    };

    const handleOk = async () => {
        const items: TAssistant[] = [];
        const assistantIdList: number[] = [];
        const assistantList: TAssistant[] = [];
        pendingSelected?.forEach((item) => {
            items.push(item);
            assistantIdList.push(item.id!);
        });
        pendingAdd?.forEach((item) => {
            const newItem:TAssistant = {
                providerId: getUserId()!,
                assistantName: item.assistantName!,
                assistantEmail: item.assistantEmail!,
            };
            assistantList.push(newItem);
            items.push(newItem);
        });
        setSubmiting(true);
        const result = await addToShoppingCart(product?.productCode!, items.length, {
            assistantIdList,
            assistantList,
        });
        setSubmiting(false);
        if (!result.error) {
            onAdd?.(items);
        }
    };

    React.useEffect(() => {
        if (clearFlag) {
            reset();
        }
    }, [clearFlag]);

    React.useEffect(() => {
        if (open) {
            //refetch and add default
            refetch();
        }
    }, [open]);

    const handleCheckboxChange = (checkedValues: CheckboxValueType[]) => {
        const numberValues = checkedValues.map((value) => Number(value)) as number[];
        setPendingSelected(allAssistants.filter((item) => numberValues.includes(item.id!)));
    };

    const allEmails = [...(pendingAdd?.map((item) => item.assistantEmail).filter(Boolean) || []), ...(allAssistants?.map((item) => item.assistantEmail).filter(Boolean) || [])];

    const hasEditing = pendingAdd.find((item) => item.isEdit) && (pendingSelected?.length > 0 || pendingAdd?.length > 0);

    return (
        <Modal
            title="Assistant seat"
            open={open}
            destroyOnClose
            className={commonS.modalFixHeightWrap}
            width="700px"
            onCancel={() => {
                onClose();
            }}
            footer={[
                <Button key="back" onClick={onClose}>
                    Cancel
                </Button>,
                <Tooltip
                    key="ok"
                    title={
                        hasEditing
                            ? 'Click “Save” to confirm the assistant’s information before adding it to Kiwi EHR. '
                            : ''
                    }
                >
                    <Button
                        className={s.btn2}
                        type="primary"
                        onClick={handleOk}
                        loading={submiting}
                        disabled={!!hasEditing}
                    >
                        Add
                    </Button>
                </Tooltip>,
            ]}
        >
            <div className={s.wrap}>
                <div className={s.title}>
                    You&apos;ve added {allCount || 0} assistants, it will be $
                    {PRICE_PER_ASSISTANT * (allCount || 0)} monthly.
                </div>

                <div className={s.content}>
                    <div className={s.cContent}>
                        Existing assistants in your UniProfile
                    </div>
                    <div className={s.cDes}>
                        Add assistants from your UniProfile to your Kiwi EHR
                    </div>
                    <Checkbox.Group
                        style={{ width: '100%', display: 'block' }}
                        onChange={handleCheckboxChange}
                    >
                        {allAssistants.map((item) => {
                            const checked = pendingSelected?.find((i) => i.id === item.id);
                            return (
                                <div key={item.id} className={s.item}>
                                    <Checkbox
                                        value={item.id!}
                                        checked={!!checked}
                                        disabled={item.productPurchased}
                                    >
                                        {item.assistantName} - {item.assistantEmail}
                                    </Checkbox>
                                </div>
                            );
                        })}
                    </Checkbox.Group>
                </div>
                <div className={s.content}>
                    <div className={s.cContent}>New assistant</div>
                    <div className={s.cDes}>
                        Need name and email for adding new assistant
                    </div>
                    <div className={s.cAss}>
                        {pendingAdd?.map((item, inx) => {
                            return (
                                <div className={s.assistant} key={item.uuid}>
                                    <EditAssistant
                                        onEditStatusChange={(isEdit: boolean) => {
                                            const newPendingAdd = [...pendingAdd];
                                            newPendingAdd[inx] = { ...newPendingAdd[inx], isEdit };
                                            setPendingAdd(newPendingAdd);
                                        }}
                                        onRemove={() => {
                                            const newPendingAdd = [...pendingAdd];
                                            newPendingAdd.splice(inx, 1);
                                            setPendingAdd(newPendingAdd);
                                        }}
                                        existEmails={allEmails as string[]}
                                        onAdd={(i) => {
                                            const newPendingAdd = [...pendingAdd];
                                            newPendingAdd[inx] = {
                                                ...i,
                                                uuid: newPendingAdd[inx].uuid,
                                                isEdit: false,
                                            };
                                            setPendingAdd(newPendingAdd);
                                        }}
                                        name={`Assistant ${inx + 1}`}
                                        value={item}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    {!hasEditing && (
                        <div className={s.actionWrap}>
                            <span
                                className={s.link}
                                onClick={() => {
                                    setPendingAdd([
                                        ...(pendingAdd || []),
                                        { uuid: generateUUID(), isEdit: true },
                                    ]);
                                }}
                            >
                                Add a new assistant
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default AssistantSeatModal;
