import React, { useCallback, useRef, useState } from 'react';
import dayjs from 'dayjs';
import s from './s.module.less';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import type { ColumnsType } from 'antd/es/table';
import { Button, Input, Checkbox, Space, message, Select } from 'antd';
import CommonTable from 'components/CommonTable';
import { useRequest } from 'ahooks';
import { getProviderChannelAuditList, updateListToChannelStatusByIds } from 'api/operation';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import { SearchOutlined } from '@ant-design/icons';
import { EProduct, ListToChannelTableRecord } from 'types/operation';
import debounce from 'lodash/debounce';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields } from 'types/enumerationData';
import { CheckboxChangeEvent, CheckboxOptionType } from 'antd/lib/checkbox';
import ChannelAuditStatusTag from 'components/ChannelAuditStatusTag';
import Icons from './imgs';
import { EStatus } from 'types/common';
import { PROVIDER_PAY_PLAN_TYPE } from 'constants/subscription';
import { channelPayTypeFilterMap } from 'constants/common';

const allValue = 'All';
type Params = {
    nameCond?: string,
    channelNameCond?: string,
    auditStatusList?: CheckboxValueType[],
    completeStatusList?: CheckboxValueType[],
    orderKey?: string,
    orderType?: 'desc' | 'asc',
    current: number,
    size: number,
    archiveStatusList?: string[],
    assignee?: string,
    providerPayPlanTypeList?: string[],
    channelPayPlanTypeList?: string[],
    productList?: string[];
    uidPublic?: string;
    listingTypeList?: string[];
};

const Page = () => {
    const navigate = useNavigate();
    const [pendingNameFilter, setPendingNameFilter] = useState('');
    const [pendingChannelNameFilter, setPendingChannelNameFilter] = useState('');
    const [pendingAssigneeFilter, setPendingAssigneeFilter] = useState('');

    // ops 状态
    const [aduitStatusIndeterminate, setAduitStatusIndeterminate] = useState(false);
    const [aduitCheckAll, setAduitCheckAll] = useState(false);
    const [aduitList, setAduitList] = useState<Array<CheckboxValueType>>([]);

    // 医生端状态
    const [listingStatusIndeterminate, setListingStatusIndeterminate] = useState(false);
    const [listingStatusCheckAll, setListingStatusCheckAll] = useState(false);
    const [listingStatusList, setListingStatusList] = useState<Array<CheckboxValueType>>([]);

    const [auditStatusData] = useGetSettingData(EnumFields.CHANNEL_AUDIT_STATUS);
    const [listingStatusData] = useGetSettingData(EnumFields.PROFILE_COMPLETE);

    const auditStatusOptions = auditStatusData?.map((item) => ({ label: item.content, value: item.dictKey }));
    const listingStatusOptions = listingStatusData?.map((item) => ({ label: item.content, value: item.dictKey }));

    const [selectKeys, setSelectKeys] = React.useState<React.Key[]>([]);

    const [statusUpdating, setStatusUpdating] = React.useState(false);
    const [statusFilter, setStatusFilter] = React.useState<EStatus | 'All'>(EStatus.Active);
    const [current, setCurrent] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);

    const [planTypeIndeterminate, setPlanTypeIndeterminate] = useState(false);
    const [planTypeCheckAll, setPlanTypeCheckAll] = useState(false);
    const [planTypeList, setPlanTypeList] = useState<Array<CheckboxValueType>>([]);
    const planTypeOptions = PROVIDER_PAY_PLAN_TYPE?.slice(0, 6)?.map((item) => ({ label: item.content, value: item.dictKey }));

    const [channelPayTypeIndeterminate, setChannelPayTypeIndeterminate] = useState(false);
    const [channelPayTypeCheckAll, setChannelPayTypeCheckAll] = useState(false);
    const [channelPayTypeList, setChannelPayTypeList] = useState<Array<CheckboxValueType>>([]);
    //const [channelPayTypeData] = useGetSettingData(EnumFields.CHANNEL_PAY_PLAN_TYPE);

    const [productFilterList, setProductFilterList] = useState<Array<EProduct>>([]);
    const [currentFilter, setCurrentFilter] = useState<any>({});

    const [pendingUidFilter, setPendingUidFilter] = useState('');
    const listingTypeOptions = useRef([
        {
            text: 'MANAGED',
            value: 'M',
        },
        {
            text: 'LISTED',
            value: 'L',
        },
    ]);

    const channelPayTypeOptions = [
        {
            label: 'Free',
            value: 'free',
        },
        {
            label: 'Standard',
            value: 'standard',
        },
        {
            label: 'Premium',
            value: 'premium',
        },
    ];

    const defaultPrams: Params = {
        nameCond: pendingNameFilter,
        channelNameCond: pendingChannelNameFilter,
        auditStatusList: aduitList || [],
        completeStatusList: listingStatusList || [],
        orderKey: 'submit_time',
        orderType: 'desc',
        current: 1,
        size: 10,
        archiveStatusList: [statusFilter],
        assignee: pendingAssigneeFilter,
        uidPublic: pendingUidFilter,
        listingTypeList: [],
    };
    if (productFilterList && productFilterList.length === 1) {
        defaultPrams.productList = [productFilterList[0]];
    }

    const { data, loading, run } = useRequest(getProviderChannelAuditList, {
        defaultParams: [
            defaultPrams,
        ],
    });

    const listData: ListToChannelTableRecord[] = data?.data?.data?.records || [];
    const total = data?.data?.data?.total || 0;

    // const currentFilter: any = {
    // name: pendingNameFilter,
    // channelName: pendingChannelNameFilter,
    // assignee: pendingAssigneeFilter,
    // auditStatusList: aduitList,
    // completeStatusList: listingStatusList,
    // planTypeList,
    // archiveStatusList: statusFilter === allValue ? undefined : [statusFilter],
    // productList: productFilterList,
    // };

    const handleTableChange = (paginationData?: any, filtersData?: any, sorter?: any, action?: any) => {
        if (action?.action === 'paginate') {
            setCurrent(paginationData.current!);
            setPageSize(paginationData.pageSize!);
        } else {
            setCurrent(1);
        }

        const params = { ...defaultPrams, current: paginationData?.current || 1 };
        params.listingTypeList = filtersData.listingType;

        if (action?.action === 'sort') {
            params.orderType = sorter.order === 'ascend' ? 'asc' : 'desc';
            if (sorter.column.dataIndex === 'submitTime') {
                params.orderKey = 'submit_time';
            }
            if (sorter.column.dataIndex === 'listTime') {
                params.orderKey = 'list_time';
            }
        }

        let pendingChannelPayTypeList: string[] | undefined;
        const channelPayTypeListFilterData = filtersData.channelPayTypeList || currentFilter.channelPayTypeList;
        if (channelPayTypeListFilterData) {
            pendingChannelPayTypeList = [];
            channelPayTypeListFilterData?.forEach((key: string) => {
                const mapedKey: string[] = channelPayTypeFilterMap[key];
                mapedKey?.forEach((val) => {
                    if (!pendingChannelPayTypeList!.includes(val)) {
                        pendingChannelPayTypeList!.push(val);
                    }
                });
            });
        }

        const nameCond = filtersData.name || currentFilter.name;
        const channelNameCond = filtersData.channelName || currentFilter.channelName;
        const assignee = filtersData.assignee || currentFilter.assignee;
        const auditStatusList = filtersData.auditStatusList || currentFilter.auditStatusList;
        const completeStatusList = filtersData.completeStatusList || currentFilter.completeStatusList;
        const planTypeListFilterData = filtersData.planTypeList || currentFilter.planTypeList;
        const archiveStatusList = filtersData.archiveStatusList;
        const productList = filtersData.productList || currentFilter.productList;
        const uidPublic = filtersData.uidPublic || currentFilter.uidPublic;

        params.nameCond = nameCond;
        params.channelNameCond = channelNameCond;
        params.auditStatusList = auditStatusList;
        params.completeStatusList = completeStatusList;
        params.assignee = assignee;
        params.providerPayPlanTypeList = planTypeListFilterData;
        params.channelPayPlanTypeList = pendingChannelPayTypeList;
        params.uidPublic = uidPublic;
        params.productList = productList;
        params.archiveStatusList = archiveStatusList;

        setCurrentFilter({
            name: nameCond,
            channelName: channelNameCond,
            assignee,
            auditStatusList,
            completeStatusList,
            planTypeList: planTypeListFilterData,
            archiveStatusList,
            productList,
            uidPublic,
            channelPayTypeList: channelPayTypeListFilterData,
        });
        run(params);
    };

    const filterNameDebounce = useRef(debounce((params) => {
        handleTableChange(null, params);
    }, 500));

    const filterChannelNameDebounce = useRef(debounce((params) => {
        handleTableChange(null, params);
    }, 500));

    const filterAssigneeDebounce = useRef(debounce((params) => {
        handleTableChange(null, params);
    }, 500));

    const filterUidDebounce = useRef(debounce((params) => {
        handleTableChange(undefined, params);
    }, 500));

    // if (productFilterList && productFilterList.length === 1) {
    //     currentFilter.productList = [productFilterList[0]];
    // }

    const handleListingStatusFilterCheckAll = (e: CheckboxChangeEvent) => {
        const checkedListData = e.target.checked && listingStatusOptions ? listingStatusOptions.map((option: CheckboxOptionType) => option.value) : [];

        setListingStatusList(checkedListData);
        setListingStatusIndeterminate(false);
        setListingStatusCheckAll(e.target.checked);

        handleTableChange(null, {
            ...currentFilter,
            completeStatusList: checkedListData,
        });
    };

    const handleListingStatusFilterChange = (statusList: CheckboxValueType[]) => {
        setListingStatusList(statusList);
        setListingStatusIndeterminate(!!statusList.length && !!listingStatusOptions && statusList.length < listingStatusOptions.length);
        setListingStatusCheckAll(statusList.length === listingStatusOptions?.length);

        handleTableChange(null, {
            ...currentFilter,
            completeStatusList: statusList,
        });
    };

    const handleAduitStatusFilterCheckAll = (e: CheckboxChangeEvent) => {
        const checkedListData = e.target.checked && auditStatusOptions ? auditStatusOptions.map((option: CheckboxOptionType) => option.value) : [];

        setAduitList(checkedListData);
        setAduitStatusIndeterminate(false);
        setAduitCheckAll(e.target.checked);

        handleTableChange(null, {
            ...currentFilter,
            auditStatusList: checkedListData,
        });
    };

    const handleAduitStatusFilterChange = (statusList: CheckboxValueType[]) => {
        setAduitList(statusList);
        setAduitStatusIndeterminate(!!statusList.length && !!auditStatusOptions && statusList.length < auditStatusOptions.length);
        setAduitCheckAll(statusList.length === auditStatusOptions?.length);

        handleTableChange(null, {
            ...currentFilter,
            auditStatusList: statusList,
        });
    };

    const handlePlanTypeFilterCheckAll = (e: CheckboxChangeEvent) => {
        const checkedListData = e.target.checked && planTypeOptions ? planTypeOptions.map((option: CheckboxOptionType) => option.value) : [];

        setPlanTypeList(checkedListData);
        setPlanTypeIndeterminate(false);
        setPlanTypeCheckAll(e.target.checked);

        handleTableChange(null, {
            ...currentFilter,
            planTypeList: checkedListData,
        });
    };

    const handlePlanTypeFilterChange = (planTypeListParm: CheckboxValueType[]) => {
        setPlanTypeList(planTypeListParm);
        setPlanTypeIndeterminate(!!planTypeListParm.length && !!planTypeOptions && planTypeListParm.length < planTypeOptions.length);
        setPlanTypeCheckAll(planTypeListParm.length === planTypeOptions?.length);

        handleTableChange(null, {
            ...currentFilter,
            planTypeList: planTypeListParm,
        });
    };

    const handleChannelPayTypeFilterCheckAll = (e: CheckboxChangeEvent) => {
        const checkedListData = e.target.checked && channelPayTypeOptions ? channelPayTypeOptions.map((option: CheckboxOptionType) => option.value) : [];

        setChannelPayTypeList(checkedListData);
        setChannelPayTypeIndeterminate(false);
        setChannelPayTypeCheckAll(e.target.checked);

        handleTableChange(null, {
            ...currentFilter,
            channelPayTypeList: checkedListData,
        });
    };

    const handleChannelPayFilterChange = (list: CheckboxValueType[]) => {
        setChannelPayTypeList(list);
        setChannelPayTypeIndeterminate(!!list.length && !!channelPayTypeOptions && list.length < channelPayTypeOptions.length);
        setChannelPayTypeCheckAll(list.length === channelPayTypeOptions?.length);

        handleTableChange(null, {
            ...currentFilter,
            channelPayTypeList: list,
        });
    };

    const columns: ColumnsType<ListToChannelTableRecord> = [
        {
            title: 'NAME',
            dataIndex: 'name',
            filterSearch: true,
            className: s.tableColumn,
            filterIcon: <SearchOutlined />,
            width: '180px',
            fixed: 'left',
            filterDropdown: () => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        value={pendingNameFilter}
                        onChange={(e) => {
                            const filterName = e.target.value;
                            setPendingNameFilter(filterName);
                            filterNameDebounce.current({
                                ...currentFilter,
                                name: filterName,
                            });
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                </div>
            ),
            render: (name: string, { photo, firstName, lastName, email }: ListToChannelTableRecord) => {
                return (
                    <div className={s.nameWrap}>
                        <div className={s.logo} style={{ backgroundImage: `url(${photo})`, display: 'none' }} />
                        <div className={s.nameRight}>
                            <div className={s.name}>{firstName} {lastName}</div>
                            <div className={s.email}>{email}</div>
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'UID',
            dataIndex: 'uidPublic',
            width: '120px',
            className: s.tableColumn,
            filterSearch: true,
            filterIcon: <SearchOutlined />,
            filterDropdown: () => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        value={pendingUidFilter}
                        onChange={(e) => {
                            const filterId = e.target.value;
                            setPendingUidFilter(filterId);
                            filterUidDebounce.current({
                                ...currentFilter,
                                uidPublic: filterId,
                            });
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                </div>
            ),
            render: (providerId: string) => {
                return (
                    <div>
                        {providerId}
                    </div>
                );
            },
        },
        {
            title: 'PRODUCT',
            dataIndex: 'product',
            key: 'product',
            width: '160px',
            className: s.tableColumn,
            filterDropdown: () => {
                return (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Space>
                            <Checkbox.Group
                                options={[
                                    {
                                        label: 'Klarity',
                                        value: EProduct.KLARITY,
                                    },
                                    {
                                        label: 'Kiwi',
                                        value: EProduct.KIWI,
                                    },
                                ]}
                                value={productFilterList}
                                onChange={(val: CheckboxValueType[]) => {
                                    setProductFilterList(val as EProduct[]);
                                    const newFilter: any = { ...currentFilter };
                                    if (val && val.length === 1) {
                                        newFilter.productList = [val[0]];
                                    } else {
                                        delete newFilter.productList;
                                    }
                                    handleTableChange(undefined, newFilter);
                                }}
                            />
                        </Space>
                    </div>
                );
            },
            render: (product?: string) => {
                return (
                    <div>
                        {product}
                    </div>
                );
            },
        },
        {
            title: 'PLAN',
            dataIndex: 'providerPayPlanType',
            width: '150px',
            className: s.tableColumn,
            filterDropdown: () => {
                return (
                    <div className={s.vertical} style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Checkbox style={{ marginBottom: '8px' }} indeterminate={planTypeIndeterminate} onChange={handlePlanTypeFilterCheckAll} checked={planTypeCheckAll}>
                            Check all
                        </Checkbox>
                        <Checkbox.Group options={planTypeOptions} value={planTypeList} onChange={handlePlanTypeFilterChange} />
                    </div>
                );
            },
            render: (plan) => {
                return (
                    <div>{plan}</div>
                );
            },
        },
        {
            title: 'CHANNEL',
            dataIndex: 'channelName',
            width: '180px',
            className: s.tableColumn,
            filterIcon: <SearchOutlined />,
            filterDropdown: () => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        value={pendingChannelNameFilter}
                        onChange={(e) => {
                            const filterName = e.target.value;
                            setPendingChannelNameFilter(filterName);
                            filterChannelNameDebounce.current({
                                ...currentFilter,
                                channelName: filterName,
                            });
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                </div>
            ),
        },
        {
            title: 'CHANNEL TYPE',
            dataIndex: 'channelPayPlanType',
            width: '180px',
            className: s.tableColumn,
            filterDropdown: () => {
                return (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Space>
                            <Checkbox indeterminate={channelPayTypeIndeterminate} onChange={handleChannelPayTypeFilterCheckAll} checked={channelPayTypeCheckAll}>
                                Check all
                            </Checkbox>
                            <Checkbox.Group options={channelPayTypeOptions} value={channelPayTypeList} onChange={handleChannelPayFilterChange} />
                        </Space>
                    </div>
                );
            },
        },
        {
            title: 'SUBMITTED ON',
            dataIndex: 'submitTime',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            width: '180px',
            className: s.tableColumn,
            render: (time) => {
                return (
                    <div>{dayjs(time).format('MM/DD/YYYY')}</div>
                );
            },
        },
        {
            title: 'LISTED ON',
            dataIndex: 'listTime',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            width: '180px',
            className: s.tableColumn,
            render: (time) => {
                return (
                    <div>{time ? dayjs(time).format('MM/DD/YYYY') : 'Nil'}</div>
                );
            },
        },
        {
            title: 'LISTING TYPE',
            dataIndex: 'listingType',
            width: '180px',
            className: s.tableColumn,
            filters: listingTypeOptions.current,
            render: (value) => {
                if (value === 'M') {
                    return 'MANAGED';
                }

                if (value === 'L') {
                    return 'LISTED';
                }

                return value;
            },
        },
        {
            title: 'UNIPROFILE',
            dataIndex: 'profileCompleteStatus',
            width: '180px',
            className: s.tableColumn,
            filterDropdown: () => {
                return (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Space>
                            <Checkbox indeterminate={listingStatusIndeterminate} onChange={handleListingStatusFilterCheckAll} checked={listingStatusCheckAll}>
                                Check all
                            </Checkbox>
                            <Checkbox.Group options={listingStatusOptions} value={listingStatusList} onChange={handleListingStatusFilterChange} />
                        </Space>
                    </div>
                );
            },
        },
        {
            title: 'OPS PROGRESS',
            dataIndex: 'status',
            width: '280px',
            className: s.tableColumn,
            filterDropdown: () => {
                return (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Space>
                            <Checkbox indeterminate={aduitStatusIndeterminate} onChange={handleAduitStatusFilterCheckAll} checked={aduitCheckAll}>
                                Check all
                            </Checkbox>
                            <Checkbox.Group options={auditStatusOptions} value={aduitList} onChange={handleAduitStatusFilterChange} />
                        </Space>
                    </div>
                );
            },
            render: (status: string) => status && <ChannelAuditStatusTag status={status} />,
        },
        {
            title: 'ASSIGNEE',
            dataIndex: 'assignee',
            filterSearch: true,
            className: s.tableColumn,
            filterIcon: <SearchOutlined />,
            width: '180px',
            filterDropdown: () => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        value={pendingAssigneeFilter}
                        onChange={(e) => {
                            const filterName = e.target.value;
                            setPendingAssigneeFilter(filterName);
                            filterAssigneeDebounce.current({
                                ...currentFilter,
                                assignee: filterName,
                            });
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                </div>
            ),
            render: (assignee) => {
                return (
                    <div>{assignee || ''}</div>
                );
            },
        },
        {
            title: '',
            width: '100px',
            className: s.tableColumn,
            fixed: 'right',
            render: (item) => {
                const { channelName, channelId, providerId } = item;
                const urlSearchParams = channelName ? `?channelName=${encodeURIComponent(channelName)}` : '';
                const url = `${window.location.protocol}//${window.location.host}`;
                const link = `${url}${PATH.OPERATION}/${PATH.LIST_TO_CHANNEL_DETAIL}/${channelId}/${providerId}${urlSearchParams}`;
                return (
                    <div>
                        <Button
                            className={s.link}
                            onClick={() => {
                                //navigate(`${url}/${PATH.OPERATION}/${PATH.LIST_TO_CHANNEL_DETAIL}/${channelId}/${providerId}${urlSearchParams}`);
                                window.open(link, 'mozillaTab');
                            }}
                            type="text"
                        >
                            View
                        </Button>
                        {/* <a
                            target="_blank"
                            className={s.link}
                            href={link}
                            rel="noreferrer"
                        >
                            View
                        </a> */}
                    </div>
                );
            },
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectKeys(selectedRowKeys || []);
        },
        selectedRowKeys: selectKeys,
    };

    const selectItems = listData.filter((item) => selectKeys.includes(item.id));
    const hasSelect = selectItems.length > 0;
    let allActive = !!hasSelect;
    let allArchived = !!hasSelect;

    selectItems?.forEach((item: ListToChannelTableRecord) => {
        if (item.archiveStatus === EStatus.Active) {
            allArchived = false;
        }
        if (item.archiveStatus === EStatus.Archived) {
            allActive = false;
        }
    });

    const setStatus = async (val: EStatus) => {
        setStatusUpdating(true);
        const result = await updateListToChannelStatusByIds(selectKeys as any, val);
        if (result.error) {
            //message.error(result.error);
        } else {
            const msg = val === EStatus.Active ? 'Activate successfully' : 'Archived successfully';
            message.success(msg);
            setSelectKeys([]);
            run(defaultPrams);
        }
        setStatusUpdating(false);
    };

    return (
        <div className={s.wrap}>
            <div className={s.filter}>
                <div className={selectKeys?.length > 0 ? `${s.left} ${s.leftSelect}` : s.left}>
                    {
                        hasSelect && <div className={s.tips}>Selected {selectItems.length} Application</div>
                    }
                    {
                        allArchived &&
                        <Button
                            loading={statusUpdating}
                            className={s.action}
                            onClick={() => { setStatus(EStatus.Active); }}
                        >
                            {Icons.CheckIcon}
                            <span className={s.label}>Activate</span>
                        </Button>
                    }

                    {
                        allActive &&
                        <Button
                            loading={statusUpdating}
                            className={s.action}
                            onClick={() => { setStatus(EStatus.Archived); }}
                        >
                            {Icons.UnCheckIcon}
                            <span className={s.label}>Archive</span>
                        </Button>
                    }
                </div>
                <div className={s.right}>
                    <Select
                        value={statusFilter}
                        style={{ width: 160 }}
                        onChange={(val) => {
                            setStatusFilter(val);
                            handleTableChange(null, {
                                ...currentFilter,
                                archiveStatusList: val === allValue ? undefined : [val],
                            });
                        }}
                        options={
                            [
                                { value: allValue, label: 'All status' },
                                { value: 'Active', label: 'Active' },
                                { value: 'Archived', label: 'Archived' },
                            ]}
                    />
                </div>
            </div>
            <div className={s.tableWrap}>
                <CommonTable
                    rowKey={(record: ListToChannelTableRecord) => record.id}
                    bordered
                    loading={loading}
                    pagination={{
                        current,
                        pageSize,
                        total,
                    }}
                    scroll={{ x: '1500' }}
                    columns={columns}
                    data={listData || []}
                    onChange={handleTableChange}
                    rowSelection={rowSelection}
                />
            </div>
        </div>
    );
};

export default Page;
