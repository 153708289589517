import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import s from './s.module.less';
import { Form, Input } from 'antd';
import { Desktop } from 'assets/preview/Desktop';
import { Mobile } from 'assets/preview/Mobile';
import commonS from 'styles/common.module.less';
import os from 'assets/preview/OS.svg';
import { SurveyQuestions } from 'data/surveyQuestions';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { ISurveyForm, SurveyFormFileds, SurveyStepValue } from 'types/survey';
import surveyEditorMapping from 'constants/surveyEditorMapping';
import { isProductionEnv } from 'utils/common';
import { Swiper as ISwiper } from 'swiper/types';
import { handleProviderNameReplacement, handleCredentialReplacement } from 'utils/survey';
import { Provider } from 'types/provider';

interface IProps {
    initFormValue?: ISurveyForm[];
    onSiwperChange?: (index: number) => void;
    onNext: (data: ISurveyForm[]) => void;
    providerData?: Provider;
}

const SurveyForm = forwardRef(({
    initFormValue,
    onSiwperChange,
    onNext,
    providerData,
}: IProps, ref) => {
    const swiperRef = useRef<ISwiper>();
    const [formInstance] = Form.useForm();
    const [isMobileMode, setIsMobileMode] = useState(false);
    const [iframeInstance, setIframeInstance] = useState<HTMLIFrameElement | null>(null);
    const host = isProductionEnv() ? 'https://provider.kiwihealth.com' : 'https://dev.d31frxe1u41op.amplifyapp.com';
    // const host = isProductionEnv() ? 'https://provider.kiwihealth.com' : 'http://localhost:3009';

    const mergeSurveyQuestionData = useCallback(() => {
        const formValues = formInstance.getFieldsValue();

        return SurveyQuestions.map((question, index) => {
            const { configFrontend, elementListValues } = question;
            const formValue = formValues[index];

            return {
                ...question,
                title: formValue?.[SurveyFormFileds.TITLE],
                subtext: formValue?.[SurveyFormFileds.SUBTEXT],
                configFrontend: formValue?.configFrontend || configFrontend,
                elementListValues: initFormValue?.[index]?.elementListValues || elementListValues,
            };
        });
    }, [formInstance, initFormValue]);

    const sendMsg = useCallback(() => {
        const SurveyFormData = mergeSurveyQuestionData();
        const iframeWin = iframeInstance?.contentWindow;

        if (iframeWin) {
            iframeWin.postMessage({ form: SurveyFormData, siwperIndex: swiperRef.current?.activeIndex }, `${host}/survey?isPreview=true`);
        }
    }, [host, iframeInstance, mergeSurveyQuestionData]);

    useEffect(() => {
        if (initFormValue) {
            formInstance.setFieldsValue(initFormValue?.map((e) => {
                return {
                    ...e,
                    title: handleCredentialReplacement(handleProviderNameReplacement(e.title, providerData), providerData),
                    subtext: handleCredentialReplacement(handleProviderNameReplacement(e.subtext, providerData), providerData),
                };
            }));
        }
    }, [formInstance, initFormValue, providerData]);

    useEffect(() => {
        const iframeEl = document.getElementById('previewIframe') as HTMLIFrameElement;
        // eslint-disable-next-line func-names
        iframeEl.onload = function () {
            setTimeout(() => {
                sendMsg();
            }, 1000);
        };

        return () => onSiwperChange?.(0);
    }, [sendMsg, onSiwperChange]);

    useEffect(() => {
        const iframeEl = document.getElementById('previewIframe') as HTMLIFrameElement;

        setIframeInstance(iframeEl);
    }, []);

    const getCurrentSwiperIndex = useCallback(() => {
        return swiperRef.current?.activeIndex || 0;
    }, []);

    const checkStepIsError = useCallback((stepIndex: number = 0) => {
        const formData = formInstance.getFieldsValue();

        const val = formData[stepIndex];

        return !!Object.entries(val).find(([, v]) => !v);
    }, [formInstance]);

    const handleSlideNext = useCallback(() => {
        const currentIndex = getCurrentSwiperIndex();
        const res = checkStepIsError(currentIndex);

        if (!res) {
            swiperRef.current?.slideNext();
            sendMsg();
        }
    }, [checkStepIsError, getCurrentSwiperIndex, sendMsg]);

    const handleSlidePrev = useCallback(() => {
        swiperRef.current?.slidePrev();
        sendMsg();
    }, [sendMsg]);

    useImperativeHandle(ref, () => ({
        slideNext: handleSlideNext,
        slidePrev: handleSlidePrev,
        getCurrentSwiperIndex,
    }));

    const handleSwiperChange = useCallback((e: ISwiper) => {
        onSiwperChange?.(e.activeIndex);
    }, [onSiwperChange]);

    const handleFormValuesChange = useCallback(() => {
        sendMsg();
    }, [sendMsg]);

    const handleFormFinish = useCallback((formData: Record<number, ISurveyForm>) => {
        const submitData = Object.values(formData).map((e:ISurveyForm, i) => {
            const questionConfig = SurveyQuestions[i];
            const initData = initFormValue?.[i];

            const configBackendData = questionConfig?.configBackend || '';
            const configFrontendData = questionConfig?.configFrontend || '';

            return {
                ...e,
                id: initFormValue?.[i]?.id!,
                configBackend: typeof configBackendData === 'string' ? configBackendData : JSON.stringify(configBackendData),
                configFrontend: typeof configFrontendData === 'string' ? configFrontendData : JSON.stringify(configFrontendData),
                isAnonymous: initData?.isAnonymous || 0,
                isOverallRating: initData?.isOverallRating || 0,
                elementListValues: initData?.elementListValues || JSON.parse(questionConfig.configFrontend).elementListValues,
            };
        });

        onNext(submitData);
    }, [initFormValue, onNext]);

    return (
        <div className={s.wrap}>
            <div className={s.body}>
                <Form
                    id={SurveyStepValue.SURVEY_FORM}
                    form={formInstance}
                    name="surveyForm"
                    className={`${commonS.formStyle1} ${s.form}`}
                    autoComplete="off"
                    layout="vertical"
                    onValuesChange={handleFormValuesChange}
                    onFinish={handleFormFinish}
                >
                    <Swiper
                        allowTouchMove={false}
                        slidesPerView={1}
                        onSlideChange={handleSwiperChange}
                        onSwiper={(swiper) => {
                            swiperRef.current = swiper;
                        }}
                    >
                        {
                            SurveyQuestions.map((question, index) => {
                                const configBackend = JSON.parse(question.configBackend);
                                const { title, tips, icon, titleFiledEditorType, subTextEditorType, titleMaxLength, subTextMaxLength } = configBackend;
                                const TitleEditor = surveyEditorMapping[titleFiledEditorType];
                                const SubTextEditor = subTextEditorType ? surveyEditorMapping[subTextEditorType] : null;
                                return (
                                    <SwiperSlide key={index} className={s.leftWrap}>
                                        <div className={s.quetionHeader}>
                                            <div className={s.quetionMark}>
                                                <img src={icon} />
                                                <span>{tips}</span>
                                            </div>
                                            <div className={s.title}>{`Survey question ${index + 1}`}</div>
                                        </div>
                                        <div className={s.questionTitle}>{title}</div>
                                        <Form.Item
                                            className={s.formItem}
                                            name={[index, SurveyFormFileds.TITLE]}
                                            label="Title"
                                            rules={[
                                                { required: true, message: 'Title is a required field' },
                                                { max: titleMaxLength, message: `Title maximum character length of ${titleMaxLength}` },
                                            ]}
                                        >
                                            <TitleEditor className={s.textarea} maxLength={titleMaxLength} showCount />
                                        </Form.Item>
                                        {
                                            SubTextEditor && (
                                                <Form.Item
                                                    className={s.formItem}
                                                    name={[index, SurveyFormFileds.SUBTEXT]}
                                                    label="Subtext"
                                                    rules={[
                                                        { required: true, message: 'Subtext is a required field' },
                                                        { max: subTextMaxLength, message: `Subtext maximum character length of ${subTextMaxLength}` },
                                                    ]}
                                                >
                                                    <Input.TextArea className={s.textarea} maxLength={subTextMaxLength} showCount />
                                                </Form.Item>
                                            )
                                        }
                                    </SwiperSlide>
                                );
                            })
                        }
                    </Swiper>
                </Form>
                <div className={s.preview}>
                    <div className={s.modeWrap}>
                        <span
                            onClick={() => {
                                setIsMobileMode(false);
                            }}
                            className={isMobileMode ? '' : s.active}
                        >
                            <Desktop />
                        </span>
                        <span
                            onClick={() => {
                                setIsMobileMode(true);
                            }}
                            className={isMobileMode ? s.active : ''}
                        >
                            <Mobile />
                        </span>

                    </div>
                    <div
                        className={isMobileMode ? `${s.iframeWrap} ${s.iframeWrapMobile}` : s.iframeWrap}
                    >
                        <div className={s.topBar}>
                            <img src={os} />
                        </div>
                        <div className={s.previewContent}>
                            <iframe className={s.iframe} title="previewIframe" id="previewIframe" src={`${host}/survey?isPreview=true`} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default SurveyForm;
