import React, { useEffect, useMemo, useState } from 'react';
import ChannelCard from './components/ChannelCard';
import s from './s.module.less';
import { useRequest } from 'ahooks';
import { getLiveChannelList } from 'api/channel';
import { Channel, ChannelItem } from 'types/common';
import { message, Spin, Tabs } from 'antd';
import useProviderHomeInfo from 'hooks/useProviderHomeInfo';
import useHomeConfig from 'hooks/useHomeConfig';
import type { TabsProps } from 'antd';
import KlarityInitStep1 from './components/KlarityInitStep1';
import KlarityInitStep2 from './components/KlarityInitStep2';
import KlarityInitStep3 from './components/KlarityInitStep3';
import KiwiInitStep1 from './components/KiwiInitStep1';
import KiwiInitStep2 from './components/KiwiInitStep2';
import KiwiInitStep3 from './components/KiwiInitStep3';
import PaymentStatus from 'components/PaymentStatus';
import AgreementStatus from './components/AgreementStatus';
import HomeStore from 'store/Home';
import {
    IS_BACK_FROM_STRIPE,
    SHOW_KLARITY_ADVERTISING_ICON,
} from 'constants/common';
import {
    openKlarityPlanAdvertisingModal,
    openKlaritySubscriptionModal,
    openUniprofileAgreementSignModal,
} from 'utils/globalLayerControl';
import SubscriptionStore from 'store/Subscription';
import { CouponType } from 'types/provider';
import CommonStore from 'store/Common';
import ExploreKiwiHealth from './components/ExploreKiwiHealth';
import { PATH } from 'constants/path';
import { setSubscriptionCallbackPath } from 'utils/localstore';
import { isFreeUserByInfo } from 'utils/provider';
import GrowthHub from './components/GrowthHub';

const ProviderHomePage = () => {
    // const [getUser] = ProviderStore.useStore();
    const [getCommonStore, setCommonStore] = CommonStore.useStore();
    const [, contextHolder] = message.useMessage();
    const { data: channelListData = { data: { data: [] } } } =
    useRequest(getLiveChannelList) || {};
    let listData = channelListData?.data?.data?.filter((item: Channel) => {
        return item.listStatus === 'not-listed';
    });
    listData?.sort((a: ChannelItem, b: ChannelItem) => {
        if (a.top) {
            return -1;
        }
        if (b.top) {
            return 1;
        }
        return 0;
    });
    listData = listData?.slice(0, 6);
    const [data, loadingHome, fetchData] = useProviderHomeInfo();
    const [getHomeStore] = HomeStore.useStore();
    const showKlarityAdvertisingIcon = getCommonStore('showAdvertisingIcon');
    const [configList, loadingConfig] = useHomeConfig();
    const [currentInx, setCurrentInx] = useState(0);
    const itemList = configList || [];
    const currentItem = itemList?.[currentInx] || {
        title: '',
        headLine: '',
        content: '',
    };
    const practiceFrontCompleted = !!data?.practiceFrontCompleted;
    const uniprofileApproved = !!data?.uniprofileApproved;
    const uniprofileSubmitted = !!data?.uniprofileSubmitted;
    const uniprofileApproveCompleted = !!data?.uniprofileApproveCompleted;
    const ehrSetUpCompleted = !!data?.ehrSetUpCompleted;
    const agreementReviewTypeList = data?.agreementReviewTypeList;
    const klarityUserWithoutKiwiPlan = !!data?.klarityUserWithoutKiwiPlan;
    const klarityPlanOnboardingDone = !!data?.klarityStarterSetUpCompleted;
    const uniprofileStatus = data?.uniprofileStatus;
    const firstUniprofileApprovedAt = data?.firstUniprofileApprovedAt;
    const firstUniprofileSubmitAt = data?.firstUniprofileSubmitAt;
    const firstPracticeLaunchAt = data?.firstPracticeLaunchAt;
    const ehrSetUpCompletedAt = data?.ehrSetUpCompletedAt; //用户可能没有选择addon，导致之前的bug是这一步显示没有完成，但是后续步骤都完成了。所以如果用户没有选addon，也就是没有addpaid时间，那么就用这个时间作为完成时间以及第三步骤的过期计算的开始时间
    const firstAddOnPaidAt = data?.firstAddOnPaidAt || ehrSetUpCompletedAt;
    const addOnPaid = !!data?.firstAddOnPaidAt || !!ehrSetUpCompletedAt;
    const isKlarity = !!data?.klarityUser;
    const completedOnboarding = !!data?.completedOnboarding;
    const firstLoginAt = data?.firstLoginAt;
    const [activeKey, setActiveKey] = useState('');
    const shouldRefetch = getHomeStore('reload');
    const payPlan = SubscriptionStore.get('currentPlan');
    const isFreeUser = payPlan && isFreeUserByInfo(payPlan);
    const showFreeUserStep = isFreeUser && !isKlarity;
    const [finishedStep, setFinishedStep] = useState(0);

    useEffect(() => {
        const showAdvertisingIconLS = localStorage.getItem(
            SHOW_KLARITY_ADVERTISING_ICON,
        );
        if (
            isFreeUser &&
      klarityUserWithoutKiwiPlan &&
      showAdvertisingIconLS !== '1' &&
      showAdvertisingIconLS !== '2'
        ) {
            // if (isFreeUser && showAdvertisingIconLS !== '1' && showAdvertisingIconLS !== '2') {

            localStorage.setItem(SHOW_KLARITY_ADVERTISING_ICON, '1');
            setCommonStore('showAdvertisingIcon', '1');
            //removed on 08/28
        }
    }, [
        showKlarityAdvertisingIcon,
        isFreeUser,
        klarityUserWithoutKiwiPlan,
        setCommonStore,
        getCommonStore,
    ]);

    useEffect(() => {
        if (typeof shouldRefetch === 'boolean' && shouldRefetch) {
            fetchData();
        }
    }, [shouldRefetch]);

    useEffect(() => {
        const isBackFromStripe = sessionStorage.getItem(IS_BACK_FROM_STRIPE);
        if (
            isBackFromStripe !== 'true' &&
      agreementReviewTypeList &&
      agreementReviewTypeList.length > 0
        ) {
            openUniprofileAgreementSignModal('Confirm agreement of service', false);
        }
    }, [agreementReviewTypeList]);

    const spinning = loadingHome || loadingConfig;

    const kiwiItems: TabsProps['items'] = [
        {
            key: '1',
            label: 'Create the UniProfile',
            children: (
                <KiwiInitStep1
                    className={s.step}
                    isSubmittedUniproile={uniprofileSubmitted}
                />
            ),
        },
        {
            key: '2',
            label: isFreeUser
                ? 'Upgrade to Growth plan to expand your reach'
                : 'Apply your coupon and upgrade to Growth plan to expand your reach',
            children: <KiwiInitStep2 className={s.step} />,
        },
        {
            key: '3',
            label: 'Set up your Practice Front and list on multiple channels   ',
            children: <KiwiInitStep3 className={s.step} />,
        },
    ];

    const klarityItems = [
        {
            key: '1',
            label: (
                <div
                    className={`${s.klarityTabText} ${
                        firstUniprofileSubmitAt ? s.tabCompleted : ''
                    }`}
                >
                    Complete UniProfile
                </div>
            ),
            // disabled: uniprofileApproveCompleted,
            children: (
                <KlarityInitStep1
                    firstUniprofileSubmitAt={firstUniprofileSubmitAt}
                    uniprofileStatus={uniprofileStatus}
                    firstLoginAt={firstLoginAt}
                    className={s.step}
                    isSubmittedUniproile={uniprofileSubmitted}
                />
            ),
        },
        klarityUserWithoutKiwiPlan
            ? {
                key: '2',
                label: (
                    <div
                        className={`${s.klarityTabText} ${
                            addOnPaid ? s.tabCompleted : ''
                        }`}
                    >
                        Set up your practice
                    </div>
                ),
                // disabled: ehrSetUpCompleted && addOnPaid,
                children: (
                    <ExploreKiwiHealth
                        firstAddOnPaidAt={firstAddOnPaidAt}
                        uniprofileStatus={uniprofileStatus}
                        addOnPaid={addOnPaid}
                        firstUniprofileSubmitAt={firstUniprofileSubmitAt}
                        uniprofileSubmitted={uniprofileSubmitted}
                        ehrSetUpCompleted={ehrSetUpCompleted}
                    />
                ),
            }
            : undefined,
        {
            key: '3',
            label: (
                <div
                    className={`${s.klarityTabText} ${
                        practiceFrontCompleted ? s.tabCompleted : ''
                    }`}
                >
                    Explore Kiwi Health
                </div>
            ),
            children: (
                <KlarityInitStep2
                    firstAddOnPaidAt={firstAddOnPaidAt}
                    addOnPaid={addOnPaid}
                    uniprofileStatus={uniprofileStatus}
                    firstPracticeLaunchAt={firstPracticeLaunchAt}
                    uniprofileApproveCompleted={uniprofileApproveCompleted}
                    className={s.step}
                    flag={uniprofileApproveCompleted && !ehrSetUpCompleted}
                    isEhrSetUpCompleted={ehrSetUpCompleted}
                    practiceFrontCompleted={practiceFrontCompleted}
                />
            ),
        },
    // {
    //     key: '4',
    //     label: 'Explore practice enablement add-ons',
    //     children: (
    //         <KlarityInitStep3 className={s.step} flag={ehrSetUpCompleted} />
    //     ),
    // },
    ].filter((e) => !!e);

    useEffect(() => {
        if (isKlarity) {
            if (!uniprofileSubmitted) {
                setActiveKey('1');
                setFinishedStep(0);
                return;
            }
            if ((!ehrSetUpCompleted || !addOnPaid) && klarityUserWithoutKiwiPlan) {
                setActiveKey('2');
                setFinishedStep(1);
                return;
            }
            if (uniprofileSubmitted && ehrSetUpCompleted && addOnPaid) {
                setActiveKey('3');
                setFinishedStep(2);
            }

            if (practiceFrontCompleted) {
                setFinishedStep(3);
            }
        } else {
            if (!isFreeUser && uniprofileSubmitted) {
                setActiveKey('3');
                return;
            }
            if (uniprofileSubmitted) {
                setActiveKey('2');
                return;
            }
            setActiveKey('1');
        }
    }, [
        isKlarity,
        ehrSetUpCompleted,
        uniprofileApproveCompleted,
        uniprofileApproved,
        practiceFrontCompleted,
        uniprofileSubmitted,
        klarityUserWithoutKiwiPlan,
        isFreeUser,
        addOnPaid,
    ]);

    const handleChangeTab = (key: string) => {
        setActiveKey(key);
    };

    const renderInit = () => {
        return (
            <div className={s.KlarityInitWrap}>
                <h2 className={s.initTitle}>
                    {isKlarity
                        ? `Setup checklist for your Klarity practice (${finishedStep}/3 completed)`
                        : 'Get started'}
                </h2>
                <Tabs
                    className={s.tabBorder}
                    activeKey={activeKey}
                    onChange={(currentKey) => handleChangeTab(currentKey)}
                    tabPosition="left"
                    items={isKlarity ? (klarityItems as TabsProps['items']) : kiwiItems}
                    animated={{ inkBar: false, tabPane: false }}
                />
            </div>
        );
    };

    if (completedOnboarding) {
        return <GrowthHub />;
    }

    return (
        <Spin tip="Loading" size="large" spinning={spinning}>
            <div className={s.wrapper}>
                <div className={s.inner}>
                    {contextHolder}
                    <div className={s.header}>
                        <div className={s.title}>Welcome 👋</div>
                    </div>
                    <PaymentStatus />
                    {
                        payPlan?.couponType !== CouponType.HEADWAY &&
                        agreementReviewTypeList &&
                        agreementReviewTypeList.length > 0 && (
                            <AgreementStatus fetchData={fetchData} />
                        )
                    }
                    <div className={s.initWrap}>
                        <div className={s.left}>{renderInit()}</div>
                        {!isKlarity && (
                            <div className={s.right}>
                                <div className={s.panel}>
                                    <div className={s.pTitle}>
                                        <h3>{currentItem.title}</h3>
                                        <div className={s.switch}>
                                            <span
                                                onClick={() => {
                                                    if (currentInx === 0) {
                                                        return;
                                                    }
                                                    setCurrentInx(currentInx - 1);
                                                }}
                                                className={
                                                    currentInx !== 0
                                                        ? `${s.sLeft} ${s.sLeftEnable}`
                                                        : s.sLeft
                                                }
                                            >
                                                {'<'}
                                            </span>
                                            <span className={s.val}>
                                                {currentInx + 1}/{itemList.length}
                                            </span>
                                            <span
                                                onClick={() => {
                                                    if (currentInx === itemList.length - 1) {
                                                        return;
                                                    }
                                                    setCurrentInx(currentInx + 1);
                                                }}
                                                className={
                                                    currentInx < itemList.length - 1
                                                        ? `${s.sRight} ${s.sRightEnable}`
                                                        : s.sRight
                                                }
                                            >
                                                {'>'}
                                            </span>
                                        </div>
                                    </div>
                                    <div className={s.pContentWrap}>
                                        <div
                                            className={s.pContentInner}
                                            style={{
                                                transform: `translateX(-${currentInx * 33.3}%)`,
                                            }}
                                        >
                                            {itemList?.map((item, inx) => {
                                                return (
                                                    <div
                                                        style={{ left: `${inx * 33.3}%` }}
                                                        className={s.pContent}
                                                        key={item.title}
                                                    >
                                                        <h3>{item.headLine}</h3>
                                                        <div className={s.pDes}>{item.content}</div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {!(isKlarity && klarityUserWithoutKiwiPlan) && (
                        <div className={s.body}>
                            <h3>
                                Explore the most popular marketing channels for healthcare
                                practices
                            </h3>
                            <div className={s.rChannelWrap}>
                                {listData?.map((item: Channel) => {
                                    return (
                                        <div key={item.id} className={s.rChannelItem}>
                                            <ChannelCard key={item.id} item={item} />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Spin>
    );
};

export default ProviderHomePage;
