import React from 'react';
import s from './s.modules.less';
import { TITLE, CONFIG } from './constants';

const Features: React.FC = () => {
    return (
        <div className={s.wrap}>
            <h2 className={s.title}>{TITLE}</h2>
            <div className={s.list}>
                {CONFIG.map((configItem) => {
                    return (
                        <div className={s.item}>
                            <div className={s.left}>
                                <h3 className={s.subTitle}>{configItem.title}</h3>
                                <p className={s.description}>
                                    {configItem.description}
                                </p>
                            </div>
                            <div className={s.right}>
                                <img src={configItem.imgUrl} alt={configItem.title} />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Features;
