import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Tabs } from 'antd';
import { PATH } from 'constants/path';
import s from './s.module.less';
import ProviderStore from 'store/Provider';
import Overview from './components/Overview';
import Patients from './components/Patients';
import Feedback from './components/Feedback';
import SettingsOverviewModal from './components/SettingsOverviewModal';
import AddPatientsOverviewModal from './components/AddPatientsOverviewModal';
import UploadPatientListModal from 'components/UploadPatientListModal';
import BatchAddPatientModal from 'components/BatchAddPatientModal';
import SendSurveyModal from './components/SendSurveyModal';

//import ReviewInvitation from './components/ReviewInvitation';

export enum ETabType {
    OVERVIEW = 'OVERVIEW',
    PATIENTS = 'PATIENTS',
    FEEDBACK = 'FEEDBACK',
    REVIEW_INVITATION = 'REVIEW_INVITATION',
}

const Page = () => {
    const navigate = useNavigate();
    const [getUser] = ProviderStore.useStore();
    const [currentTab, setCurrentTab] = React.useState<ETabType>(ETabType.OVERVIEW);
    const [showSetting, setShowSetting] = React.useState<boolean>(false);
    const [showAddPatientOverview, setShowAddPatientOverview] = React.useState<boolean>(false);
    const [showUploadPatientList, setShowUploadPatientList] = React.useState(false);
    const [showBatchAddPatient, setShowBatchAddPatient] = React.useState(false);
    const [showSendSurvey, setShowSendSurvey] = React.useState<boolean>(false);
    const [hasSelectPatient, setHasSelectPatient] = React.useState<boolean>(false);

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <h1>Reputation management</h1>
                <div className={s.btnWrap}>
                    {
                        (currentTab !== ETabType.PATIENTS || !hasSelectPatient) &&
                        <>
                            <Button
                                onClick={() => {
                                    setShowSetting(true);
                                }}
                                className={s.btnNormal}
                            >
                                Settings
                            </Button>
                            <Button
                                onClick={() => {
                                    setShowAddPatientOverview(true);
                                }}
                                className={s.btnNormal}
                            >
                                Add patients
                            </Button>

                            <Button
                                type="primary"
                                onClick={() => {
                                    setShowSendSurvey(true);
                                }}
                                className={s.btnPrimary}
                            >
                                Send survey
                            </Button>
                        </>
                    }
                </div>
                {/* <div className={s.des}>
            Streamline patient management by centralizing patient information, tracking message status, and enabling easy communication and scheduling.
        </div> */}
            </div>
            <div className={s.content}>
                <Tabs
                    defaultActiveKey={currentTab}
                    activeKey={currentTab}
                    onChange={(key: string) => {
                        setCurrentTab(key as ETabType);
                    }}
                    items={[
                        {
                            label: <span className={s.tabLabel}>Overview</span>,
                            key: ETabType.OVERVIEW,
                            children: <Overview />,
                        },
                        {
                            label: <span className={s.tabLabel}>Patients</span>,
                            key: ETabType.PATIENTS,
                            children: <Patients
                                onSelectChange={(selected:number[]) => {
                                    setHasSelectPatient(!!selected && selected.length > 0);
                                }}
                            />,
                        },
                        {
                            label: <span className={s.tabLabel}>Feedback</span>,
                            key: ETabType.FEEDBACK,
                            children: <Feedback />,
                        },
                    // {
                    //     label: <span className={s.tabLabel}>Review invitation</span>,
                    //     key: ETabType.REVIEW_INVITATION,
                    //     children: <ReviewInvitation />,
                    // },
                    ]}
                />
                <SettingsOverviewModal
                    show={showSetting}
                    onCannel={() => {
                        setShowSetting(false);
                    }}
                />
                <SendSurveyModal
                    show={showSendSurvey}
                    onCannel={() => {
                        setShowSendSurvey(false);
                    }}
                    onClickAddPatient={() => {
                        setShowAddPatientOverview(true);
                    }}
                />
                <AddPatientsOverviewModal
                    show={showAddPatientOverview}
                    onCannel={() => {
                        setShowAddPatientOverview(false);
                    }}
                    onClickDirectUpload={() => {
                        setShowAddPatientOverview(false);
                        setShowUploadPatientList(true);
                    }}
                    onClickManualInput={() => {
                        setShowAddPatientOverview(false);
                        setShowBatchAddPatient(true);
                    }}
                />
                <UploadPatientListModal
                    onCannel={() => {
                        setShowUploadPatientList(false);
                    }}
                    onSuccess={() => {
                        setCurrentTab(ETabType.PATIENTS);
                        setShowUploadPatientList(false);
                    }}
                    show={showUploadPatientList}
                />
                <BatchAddPatientModal
                    onCannel={() => {
                        setShowBatchAddPatient(false);
                    }}
                    onSuccess={() => {
                        setCurrentTab(ETabType.PATIENTS);
                        setShowBatchAddPatient(false);
                    }}
                    show={showBatchAddPatient}
                />
            </div>
        </div>
    );
};

export default Page;
