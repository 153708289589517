import React from 'react';

const EhrOff = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.99992 3.33333H4.66659C3.93021 3.33333 3.33325 3.93029 3.33325 4.66667V12.6667C3.33325 13.403 3.93021 14 4.66659 14H11.3333C12.0696 14 12.6666 13.403 12.6666 12.6667V4.66667C12.6666 3.93029 12.0696 3.33333 11.3333 3.33333H9.99992M5.99992 3.33333C5.99992 4.06971 6.59687 4.66667 7.33325 4.66667H8.66659C9.40297 4.66667 9.99992 4.06971 9.99992 3.33333M5.99992 3.33333C5.99992 2.59695 6.59687 2 7.33325 2H8.66659C9.40297 2 9.99992 2.59695 9.99992 3.33333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </svg>

);

export default EhrOff;
