// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--xKcbA {
  background: #f9fafc;
  padding: 40px;
  min-height: 100vh;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/ReputationManagementPage/s.module.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,aAAA;EACA,iBAAA;AACF","sourcesContent":[".wrap {\n  background: #f9fafc;\n  padding: 40px;\n  min-height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--xKcbA`
};
export default ___CSS_LOADER_EXPORT___;
