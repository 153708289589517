import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import {
    Button,
    Input,
    Space,
    message,
    Modal,
    Checkbox,
    Spin,
    Tooltip,
} from 'antd';
import CommonTable from 'components/CommonTable';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import debounce from 'lodash/debounce';
import {
    SearchOutlined,
} from '@ant-design/icons';
import GenerateLink from './components/GenerateLink';
import s from './s.module.less';
import type { CheckboxOptionType } from 'antd/es/checkbox';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import useContractLinkPage from 'hooks/useContractLinkPage';
import cx from 'classnames';
import {
    EOrderType,
    IBPContactLinkItem,
    EContractLinkStatus,
    EContractLinkStatusLabel,
    TContractPage,
} from 'types/contractLink';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import UpdateContact from './components/UpdateContact';
import copy from 'copy-to-clipboard';
import { getViewPdf, getSalesInfo, getContractVersionWithType } from 'api/operation';
import { getUserId } from 'utils/localstore';
import useOpsUserInfo from 'hooks/useOpsUserInfo';
import ProfileStatusTag from 'components/ProfileAuditStatusTag';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields } from 'types/enumerationData';
import { ColumnFilterItem } from 'antd/es/table/interface';
import capitalize from 'lodash/capitalize';
import { useRequest } from 'ahooks';

const planSelectedListing = [
    {
        label: 'Essential',
        value: 'Essential',
    },
    {
        label: 'Klarity',
        value: 'Klarity',
    },
    {
        label: 'Growth',
        value: 'Growth',
    },
] as CheckboxOptionType[];

const linkStatusListing = [
    {
        label: 'Link created',
        value: 'LS',
    },
    {
        label: 'Link clicked',
        value: 'LC',
    },
    {
        label: 'Payment received',
        value: 'PR',
    },
    {
        label: 'Contract signed',
        value: 'CS',
    },
    {
        label: 'Onboarding scheduled',
        value: 'OCS',
    },
    {
        label: 'Account created',
        value: 'DONE',
    },
] as CheckboxOptionType[];

dayjs.extend(relativeTime);

const ContractLinkPage = () => {
    const [auditStatusData] = useGetSettingData(EnumFields.PROFILE_AUDIT_STATUS);
    const [profileStatusData] = useGetSettingData(EnumFields.PROFILE_STATUS);
    const auditStatusOptions: ColumnFilterItem[] = useMemo(() => auditStatusData?.map((item) => ({ text: item.content, value: item.dictKey })) || [], [auditStatusData]);
    const [uniprofileStatusData] = useGetSettingData(EnumFields.PROFILE_STATUS);
    const uniprofileStatusOptions: ColumnFilterItem[] = useMemo(() => uniprofileStatusData?.map((item) => ({ text: capitalize(item.content?.replace(/(UniProfile\s|-)/g, (match: any) => {
        if (match === '-') {
            return ' '; // 将 '-' 替换为空格
        }
        return ''; // 去掉 'UniProfile '
    })),
    value: item.dictKey })) || [], [uniprofileStatusData]);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [result = {}, loading, refresh] = useContractLinkPage();
    const { records, total = 0, size = 0 } = result as TContractPage;
    const [isViewLoading, setIsViewLoading] = React.useState(false);
    const [sender, loadingSender, refetch] = useOpsUserInfo();

    // name filter
    const [nameFilter, setNameFilter] = React.useState('');

    // contract version
    const [contractVersionFilter, setContractVersionFilter] = useState<string[]>([]);

    // owner filter
    const [ownerFilter, setOwnerFilter] = React.useState('');

    // plan filter
    const [planIndeterminate, setplanIndeterminate] = useState(false);
    const [planCheckAll, setplanCheckAll] = useState(false);
    const [planList, setplanList] = useState<Array<CheckboxValueType>>([]);

    // link status filter
    const [linkStatusIndeterminate, setlinkStatusIndeterminate] = useState(false);
    const [linkStatusCheckAll, setlinkStatusCheckAll] = useState(false);
    const [linkStatusList, setlinkStatusList] = useState<
    Array<CheckboxValueType>
    >([]);

    // order
    const [orderType, setOrderType] = useState<EOrderType | undefined>(
        EOrderType.DESC,
    );
    const [orderKey, setOrderKey] = useState<string>('CREATE_AT');
    const [opsStatusFilter, setOpsStatusFilter] = useState<string[]>([]);
    const [uniprofileStatusFilter, setUniprofileStatusFilter] = useState<string[]>([]);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isAdd, setIsAdd] = useState<boolean>(false);
    const [showGenerateLink, setShowGenerateLink] = useState<boolean>(false);
    const [modal, contextHolder] = Modal.useModal();

    const { data: contractVersionData, loading: contractVersionDataLoading } = useRequest(getContractVersionWithType);

    const contractVersionOptions = useMemo(() => {
        const restult = contractVersionData?.data?.data?.map((version: string) => ({
            text: version,
            value: version,
        })) || [];

        restult.push({
            text: '-',
            value: 'empty',
        });

        return restult;
    }, [contractVersionData]);

    // plan selected filter
    const handlePlanListingFilterCheckAll = useCallback(
        (e: CheckboxChangeEvent) => {
            const checkedListData =
        e.target.checked && planSelectedListing
            ? planSelectedListing.map(
                (option: CheckboxOptionType) => option.value,
            )
            : [];

            setplanList(checkedListData);
            setplanIndeterminate(false);
            setplanCheckAll(e.target.checked);
            setCurrent(1);
        },
        [],
    );

    const handlePlanListingFilter = useCallback(
        (statusList: CheckboxValueType[]) => {
            setplanList(statusList);
            setplanIndeterminate(
                !!statusList.length &&
          !!planSelectedListing &&
          statusList.length < planSelectedListing.length,
            );
            setplanCheckAll(statusList.length === planSelectedListing?.length);
            setCurrent(1);
        },
        [],
    );

    // link status filter
    const handleLinkStatusFilterCheckAll = useCallback(
        (e: CheckboxChangeEvent) => {
            const checkedListData =
        e.target.checked && planSelectedListing
            ? planSelectedListing.map(
                (option: CheckboxOptionType) => option.value,
            )
            : [];

            setlinkStatusList(checkedListData);
            setlinkStatusIndeterminate(false);
            setlinkStatusCheckAll(e.target.checked);
            setCurrent(1);
        },
        [],
    );

    const handleLinkStatusFilter = useCallback(
        (statusList: CheckboxValueType[]) => {
            setlinkStatusList(statusList);
            setlinkStatusIndeterminate(
                !!statusList.length &&
          !!planSelectedListing &&
          statusList.length < planSelectedListing.length,
            );
            setlinkStatusCheckAll(statusList.length === linkStatusListing?.length);
            setCurrent(1);
        },
        [],
    );

    const handleTableChange = useCallback(
        (
            paginationData?: TablePaginationConfig,
            filtersData?: any,
            sorter?: any,
            action?: any,
        ) => {
            setCurrent(paginationData?.current!);
            setPageSize(paginationData?.pageSize!);
            setOpsStatusFilter(filtersData?.uniprofileOpsStatus);
            setUniprofileStatusFilter(filtersData?.uniprofileUpdateStatus);

            if (filtersData?.contractVersion) {
                setContractVersionFilter(filtersData.contractVersion);
            }

            if (sorter.field) {
                let oKey = '';
                if (sorter.field === 'lastActionAt') {
                    oKey = 'LAST_ACTION_AT';
                } else {
                    oKey = 'CREATE_AT';
                }
                setOrderKey(oKey);
            }

            if (action?.action === 'sort') {
                const oType =
          sorter.order === 'ascend' ? EOrderType.ASC : EOrderType.DESC;

                setOrderType(oType);
            }
        },
        [],
    );

    const filterNameDebounce = useRef(
        debounce((setFn, value) => {
            setFn(value);
            setCurrent(1);
        }, 500),
    );

    const getTimeDisplay = useCallback((time: string) => {
        const currentTime = dayjs();

        const inputTime = dayjs(time);

        const diffMilliseconds = currentTime.diff(inputTime);

        const diffHours = Math.abs(diffMilliseconds / (1000 * 60 * 60));

        if (diffHours < 1) {
            return 'Just now';
        } else if (
            diffHours >= 1 &&
        diffHours < 24 &&
        inputTime.isSame(currentTime, 'day')
        ) {
            return 'Today';
        } else if (inputTime.isSame(currentTime.subtract(1, 'day'), 'day')) {
            return 'Yesterday';
        } else if (diffHours >= 24 && diffHours <= 24 * 14) {
            return 'Yesterday';
        } else {
            return '14 days ago';
        }
    }, []);

    const handleUpdateContract = () => {
        setIsAdd(false);
        setIsOpen(true);
    };

    const handleGenerate = () => {
        setShowGenerateLink(true);
    };

    const refreshTable = () => {
        let params = {
            current: 1,
            size: pageSize,
            inviteName: nameFilter,
            orderType,
            orderKey,
            auditStatusList: opsStatusFilter,
            uniprofileStatusList: uniprofileStatusFilter,
            linkStatusList: linkStatusList as string[],
            providerPayPlanTypeList: planList as string[],
            owner: ownerFilter,
        };
        if (contractVersionFilter) {
            params = {
                ...params,
                contractVersion: contractVersionFilter,
            };
        }
        refresh(params);
    };

    const onUpdateContractSuccess = () => {
        setIsOpen(false);
        setCurrent(1);
        refreshTable();
        refetch();
        message.success('Updated successfully');
    };

    const onSuccess = () => {
        setShowGenerateLink(false);
        setCurrent(1);
        refreshTable();
    };

    const handlePreviewPdf = async (contractSignUrl: string) => {
        setIsViewLoading(true);
        try {
            const { data } = await getViewPdf({
                contractSignUrl,
            });
            if (data?.data) {
                setIsViewLoading(false);
                window.open(data?.data);
            }
        } catch (e) {
            setIsViewLoading(false);
        }
    };

    const checkUserInfo = async () => {
        const { data } = await getSalesInfo(getUserId());
        if (!data?.data?.title) {
            setIsOpen(true);
            setIsAdd(true);
        }
    };

    useEffect(() => {
        checkUserInfo();
    }, []);

    useEffect(() => {
        let params = {
            current,
            size: pageSize,
            inviteName: nameFilter,
            orderType,
            orderKey,
            auditStatusList: opsStatusFilter,
            uniprofileStatusList: uniprofileStatusFilter,
            linkStatusList: linkStatusList as string[],
            providerPayPlanTypeList: planList as string[],
            owner: ownerFilter,
        };

        if (contractVersionFilter) {
            params = {
                ...params,
                contractVersion: contractVersionFilter,
            };
        }

        refresh(params);
    }, [
        refresh,
        current,
        handleTableChange,
        pageSize,
        nameFilter,
        planList,
        linkStatusList,
        orderType,
        orderKey,
        ownerFilter,
        contractVersionFilter,
        opsStatusFilter,
        uniprofileStatusFilter,
    ]);

    const columns: ColumnsType<IBPContactLinkItem> = [
        {
            title: 'LINK RECEIVER',
            dataIndex: 'name',
            filterSearch: true,
            width: '200px',
            filterIcon: <SearchOutlined />,
            fixed: 'left',
            filterDropdown: () => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        onChange={(e) => {
                            const filterName = e.target.value;
                            filterNameDebounce.current(setNameFilter, filterName);
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                </div>
            ),
            render: (name: string, p: IBPContactLinkItem) => {
                const { fullName, inviteEmail } = p;
                return (
                    <div className={s.nameWrap}>
                        <div className={s.name}>
                            {fullName}
                            <div className={s.email}>{inviteEmail}</div>
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'PLAN SELECTED',
            dataIndex: 'type',
            width: '170px',
            filterDropdown: () => {
                return (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Space>
                            <Checkbox
                                indeterminate={planIndeterminate}
                                onChange={handlePlanListingFilterCheckAll}
                                checked={planCheckAll}
                            >
                                Check all
                            </Checkbox>
                            <Checkbox.Group
                                options={planSelectedListing}
                                value={planList}
                                onChange={handlePlanListingFilter}
                            />
                        </Space>
                    </div>
                );
            },
        },
        {
            title: 'DATE CREATED',
            dataIndex: 'createAt',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            width: '150px',
            className: s.tableColumn,
            render: (time) => {
                return <div>{getTimeDisplay(time)}</div>;
            },
        },
        {
            title: 'LINK STATUS',
            dataIndex: 'linkStatus',
            width: '170px',
            filterDropdown: () => {
                return (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Space>
                            <Checkbox
                                indeterminate={linkStatusIndeterminate}
                                onChange={handleLinkStatusFilterCheckAll}
                                checked={linkStatusCheckAll}
                            >
                                Check all
                            </Checkbox>
                            <Checkbox.Group
                                options={linkStatusListing}
                                value={linkStatusList}
                                onChange={handleLinkStatusFilter}
                            />
                        </Space>
                    </div>
                );
            },
            render: (status: EContractLinkStatus) => {
                return (
                    <div className={s.tagWrap}>
                        <span className={cx(s.tag, s[status || 'DONE'])}>
                            <span className={s.tagContent}>
                                {EContractLinkStatusLabel[status || 'DONE']}
                            </span>
                        </span>
                    </div>
                );
            },
        },
        {
            title: 'CONTRACT VERSION',
            dataIndex: 'contractVersion',
            width: '200px',
            className: s.tableColumn,
            // filterIcon: <SearchOutlined />,
            filters: contractVersionOptions,
            // filterDropdown: () => (
            //     <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            //         <Input
            //             onChange={(e) => {
            //                 const filterName = e.target.value;
            //                 filterNameDebounce.current(
            //                     setContractVersionFilter,
            //                     filterName,
            //                 );
            //             }}
            //             style={{ marginBottom: 8, display: 'block' }}
            //         />
            //     </div>
            // ),
            render: (version) => {
                return <div>{version ?? '-'}</div>;
            },
        },
        {
            title: 'OWNER',
            dataIndex: 'owner',
            filterSearch: true,
            width: '170px',
            filterIcon: <SearchOutlined />,
            filterDropdown: () => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        onChange={(e) => {
                            const filterName = e.target.value;
                            filterNameDebounce.current(setOwnerFilter, filterName);
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                </div>
            ),
        },
        {
            title: 'UNIPROFILE ACTION',
            dataIndex: 'uniprofileUpdateStatus',
            width: '200px',
            className: s.tableColumn,
            filters: uniprofileStatusOptions,
            render: (status) => (status ? profileStatusData?.find((e) => e.dictKey === status)?.content : '-'),
        },
        {
            title: 'OPS PROGRESS',
            dataIndex: 'uniprofileOpsStatus',
            width: '200px',
            className: s.tableColumn,
            filters: auditStatusOptions,
            render: (status) => (status ? <ProfileStatusTag status={status} /> : '-'),
        },
        {
            title: 'URL',
            dataIndex: 'copyURL',
            width: '120px',
            className: s.tableColumn,
            fixed: 'right',
            render: (link) => {
                return (
                    <div>
                        <Button
                            className={s.link}
                            onClick={() => {
                                copy(link || '', { format: 'text/plain' });
                                message.success('Copied to the clipboard');
                            }}
                            type="text"
                        >
                            Copy
                        </Button>
                    </div>
                );
            },
        },
        {
            title: 'CONTRACT',
            dataIndex: 'contractSignUrl',
            width: '130px',
            fixed: 'right',
            className: s.tableColumn,
            render: (url) => {
                return (
                    <div>
                        {url ? (
                            <Button
                                className={s.link}
                                onClick={() => handlePreviewPdf(url)}
                                type="text"
                            >
                                View
                            </Button>
                        ) : (
                            ''
                        )}
                    </div>
                );
            },
        },
    ];

    const canGenerate = sender?.bookingLinkUrl && sender?.title && sender?.tel;

    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <div className={s.headerBar}>
                    <div className={s.title}>Contract link</div>
                    <div className={s.buttonWrap}>
                        <Button onClick={handleUpdateContract} className={s.updateBtn}>
                            Update contacts
                        </Button>
                        <Tooltip
                            color="#fff"
                            overlayInnerStyle={{ color: '#333', padding: 16 }}
                            placement="bottom"
                            title={canGenerate ? '' : 'Please update your contact information before generating a link.'}
                        >
                            <Button
                                disabled={!canGenerate}
                                onClick={handleGenerate}
                                type="primary"
                            >
                                Generate a link
                            </Button>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <Spin spinning={isViewLoading}>
                <CommonTable
                    bordered
                    loading={loading || contractVersionDataLoading}
                    columns={columns}
                    data={records || []}
                    onChange={handleTableChange}
                    scroll={{ x: '1500' }}
                    pagination={{
                        current,
                        pageSize: size,
                        total,
                    }}
                />
            </Spin>
            {isOpen && (
                <UpdateContact
                    isAdd={isAdd}
                    onCancel={() => setIsOpen(false)}
                    onSuccess={onUpdateContractSuccess}
                />
            )}
            {showGenerateLink && (
                <GenerateLink
                    onCancel={() => setShowGenerateLink(false)}
                    onSuccess={onSuccess}
                    show={showGenerateLink}
                />
            )}
            {contextHolder}
        </div>
    );
};

export default ContractLinkPage;
