import React from 'react';
import { Button } from 'antd';
import s from './s.module.less';
import WatchVideo from '../WatchVideo';
import { useNavigate } from 'react-router-dom';
import { HOME, UNIPROFILE } from 'constants/common';
import useGetUniprofileEntry from 'hooks/useGetUniprofileEntry';

const InitStep: React.FC<{
    className: string;
    isSubmittedUniproile: boolean;
}> = ({ className, isSubmittedUniproile }) => {
    const [uniprofileEntryPath] = useGetUniprofileEntry();
    const navigate = useNavigate();
    return (
        <div className={className}>
            <div className={s.content}>
                <p>
                    UniProfile is your all-in-one profile that markets you across all
                    healthcare directories. Invest time to build an awesome UniProfile so
                    you can stand out among peers and gain online visibility.
                </p>
            </div>
            <div className={s.buttonWrap}>
                {isSubmittedUniproile ? (
                    <Button
                        type="primary"
                        onClick={() => {
                            navigate(`${uniprofileEntryPath}?type=${UNIPROFILE}&from=${HOME}`);
                        }}
                    >
                        Open UniProfile
                    </Button>
                ) : (
                    <Button
                        type="primary"
                        onClick={() => {
                            navigate(`${uniprofileEntryPath}?type=${UNIPROFILE}`);
                        }}
                    >
                        Create UniProfile
                    </Button>
                )}

                <WatchVideo link="https://www.youtube.com/watch?v=nXXvhwEWFfY" />
            </div>
        </div>
    );
};

export default InitStep;
