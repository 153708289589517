import React, { useCallback, useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import CommonTable from 'components/CommonTable';
import PatientUploadSelectPanel from 'components/PatientUploadSelectPanel';
import s from './s.module.less';
import UploadPatientListModal from 'components/UploadPatientListModal';
import BatchAddPatientModal from 'components/BatchAddPatientModal';
import { getReputationProspectCount, deleteReputationByEmail } from 'api/survey';
import { Prospect } from 'types/common';
import { PhoneOutlined,
} from '@ant-design/icons';
import PatientTableEmpty from 'components/EmptyHolder/PatientTableEmpty';
import { Button, message } from 'antd';
import { getImportedPatients, setImportedPatients } from 'utils/localstore';
import remove from 'lodash/remove';

const PatientImport = () => {
    const [showUploadPatientList, setShowUploadPatientList] = React.useState(false);
    const [showBatchAddPatient, setShowBatchAddPatient] = React.useState(false);

    const [data, setData] = React.useState<Prospect[]>([]);
    const [patientsCount, setPatientsCount] = useState(0);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [deletingDataId, setDeletingDataId] = useState(-1);
    const [update, forceUpdate] = React.useState({});

    const fetchProspectCount = useCallback(async () => {
        try {
            const res = await getReputationProspectCount();

            if (res && !res.error) {
                setPatientsCount(res.data?.data || 0);
            }
        } catch (e) {
            console.error(e);
        }
    }, []);

    useEffect(() => {
        fetchProspectCount();
    }, [fetchProspectCount]);

    useEffect(() => {
        const patientsStorage = getImportedPatients();

        if (patientsStorage) {
            try {
                const patientsData = JSON.parse(patientsStorage);

                setData(patientsData);
            } catch (e) {
                console.error(e);
            }
        }
    }, [update]);

    const removePatientByEmail = useCallback((email: string) => {
        remove(data, (item: Prospect) => item.email === email);

        const newData = [...data];
        setData(newData);
        setImportedPatients(JSON.stringify(newData));
    }, [data]);

    const handleRemoveByEmail = useCallback(async (email: string) => {
        if (email) {
            setDeleteLoading(true);
            try {
                const res = await deleteReputationByEmail(email);
                if (res && !res.error) {
                    removePatientByEmail(email);
                    fetchProspectCount();
                }
            } catch (e) {
                console.error(e);
            }
            setDeleteLoading(false);
        }
    }, [fetchProspectCount, removePatientByEmail]);

    const columns: ColumnsType<Prospect> = [
        {
            title: 'NAME',
            dataIndex: 'name',
            filterSearch: true,
            width: '220px',
            render: (name:string, item:Prospect) => {
                return (
                    <div className={s.nameWrap}>
                        <div className={s.name}>
                            <span>{item.firstName} {item.lastName}</span>
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'EMAIL',
            dataIndex: 'email',
            render: (name:string, item:Prospect) => {
                return (
                    <div className={s.nameWrap}>
                        <div className={s.nameRight}>
                            <div className={s.name}>{item.email}</div>
                            {
                                item.tel &&
                                <div className={s.itemWithIcon}>
                                    <PhoneOutlined />
                                    <p className={s.val}>{item.tel ? item.tel.replace(/(\d{3})(\d{3})(\d{4})/g, '$1-$2-$3') : 'No phone number provided'}</p>
                                </div>
                            }
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'CHANNEL',
            dataIndex: 'channel',
            width: '180px',
            render: (channel, item) => {
                return (
                    <div>{`${item.channelId}` === '-1' ? 'Others' : channel || 'Others'}</div>
                );
            },
        },
        {
            title: '',
            width: '150px',
            className: s.tableColumn,
            fixed: 'right',
            render: (item: Prospect) => {
                const { email, id } = item;

                return email && (
                    <div>
                        <Button
                            loading={deletingDataId === id && deleteLoading}
                            className={s.link}
                            onClick={() => {
                                setDeletingDataId(id);
                                handleRemoveByEmail(email);
                            }}
                            type="text"
                        >
                            Remove
                        </Button>
                    </div>
                );
            },
        },
    ];

    const onClickDirectUpload = () => {
        setShowUploadPatientList(true);
    };
    const onClickManualInput = () => {
        setShowBatchAddPatient(true);
    };

    const handleNewDataUpdated = (newPatientsData: Partial<Prospect>[]) => {
        const newPaitientsStorage = getImportedPatients();
        let storageData = [];

        if (newPaitientsStorage) {
            try {
                storageData = JSON.parse(newPaitientsStorage);
            } catch (e) {
                console.error(e);
            }
        }

        const emails:string[] = storageData?.map((item:any) => item.email);
        const newPatientsDataAfterFilter = newPatientsData?.filter((item) => item.email && !emails.includes(item.email));

        const newData = [...storageData, ...newPatientsDataAfterFilter];

        setImportedPatients(JSON.stringify(newData));
        forceUpdate({});
    };
    return (
        <div className={s.wrap}>
            <div className={s.title}>Patients synced from “My Prospect”</div>
            <div className={s.patientIcon} />
            <div className={s.subTitle}>{`You have ${patientsCount} patients in “My Prospect”`}</div>
            <div className={s.desc}>We will automatically sync individuals identified as “patients,” not “prospects,” to here</div>
            <div className={s.border} />
            <div className={s.body}>
                <div className={s.importTitle}>Add additional patients via:</div>
                <div className={s.importButtons}>
                    <PatientUploadSelectPanel
                        onClickDirectUpload={onClickDirectUpload}
                        onClickManualInput={onClickManualInput}
                    />
                </div>
                {
                    data.length > 0 &&
                    <div className={s.patientsList}>
                        <div className={s.listTitle}>{`Total ${data?.length} patients`}</div>
                        <CommonTable
                            rowKey={(record: Prospect) => record.id}
                            locale={{
                                emptyText: <PatientTableEmpty />,
                            }}
                            columns={columns}
                            data={data || []}
                        />
                    </div>
                }
            </div>
            <UploadPatientListModal
                onNewDataUpdated={handleNewDataUpdated}
                onCannel={() => {
                    setShowUploadPatientList(false);
                }}
                onSuccess={() => {
                    setShowUploadPatientList(false);
                }}
                show={showUploadPatientList}
            />
            <BatchAddPatientModal
                onNewDataUpdated={handleNewDataUpdated}
                onCannel={() => {
                    setShowBatchAddPatient(false);
                }}
                onSuccess={() => {
                    setShowBatchAddPatient(false);
                }}
                show={showBatchAddPatient}
            />
        </div>
    );
};

export default PatientImport;
