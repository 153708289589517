import React, { useCallback } from 'react';
import s from './s.module.less';
import { openUniprofileAgreementSignModal } from 'utils/globalLayerControl';

interface IProps {
    fetchData: () => void;
}

const AgreementStatus = ({ fetchData }:IProps) => {
    const showAgreementModal = useCallback(() => {
        openUniprofileAgreementSignModal('Confirm agreement of service', false);
        fetchData?.();
    }, []);
    return (
        <div className={s.wrap}>
            <div className={s.tipContent}>
                <div className={s.tipIcon} />
                <div className={s.tip}>
                    <div className={s.tipTitle}>Action required: review and confirm agreement</div>
                    <div className={s.tipDesc}>Please review and accept our user agreement to continue using Kiwi Health.</div>
                </div>
            </div>
            <div className={s.viewBtn} onClick={showAgreementModal}>Review agreement</div>
        </div>
    );
};

export default AgreementStatus;
